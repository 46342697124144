import { IsDefined, IsNumber, IsString } from 'class-validator';

export class RegisterMessagesTokenRequest {
  @IsDefined()
  @IsString()
  public token: string;

  @IsDefined()
  @IsNumber()
  public expiration: number;

  constructor(
    token: string,
    expiration: number,
  ) {
    this.token = token;
    this.expiration = expiration;
  }
}
