import { TransProps, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { ConfirmUnverifiedEmailRequest } from '@chic/shared/models';

export const useConfirmUnverifiedEmailValidation: () => Yup.SchemaOf<ConfirmUnverifiedEmailRequest>
  = (): Yup.SchemaOf<ConfirmUnverifiedEmailRequest> => {
    const { t }: TransProps<never> = useTranslation();

    return Yup.object().shape({
      value: Yup.string()
        .email(t('chic.website.global.wrongValue'))
        .required(t('chic.website.global.requiredField')),
      userHash: Yup.string()
        .required(t('chic.website.global.requiredField')),
      authHash: Yup.string()
        .required(t('chic.website.global.requiredField')),
    });
  };
