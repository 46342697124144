import {
  ButtonTheme,
  MailToUsTheme,
  UseNotifications,
  UseRedirect,
  UseState,
  getPathWithParams,
  useNotifications,
  useRedirect
} from '@chic-loyalty/ui';
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { getSubscriptionDetails } from '@chic/frontend/api/requests';
import { BaseViewWithEditProducts } from '@chic/frontend/components';
import { FullscreenAlertAssets, QueryKey } from '@chic/frontend/enums';
import { UseSubscriptionEditProducts } from '@chic/frontend/interfaces';
import { ProductAvailability, RouteNameEnum, SubscriptionPermissionType } from '@chic/shared/enums';
import { NameValueInterface, SubscriptionDetails, SubscriptionPlanGroup, SubscriptionProductInfo } from '@chic/shared/interfaces';
import { IdValueObject } from '@chic/shared/models';
import { getRouteDetailsByName } from '@chic/shared/utils';

import { useSubscriptionEditProducts } from '../hooks';

import {
  ButtonsContainer,
  ButtonsWrapper,
  Container,
  Content,
  ContentWrapper,
  ContentWrapperStepTwo,
  DetailsBox,
  StyledButton,
  StyledMailToUs,
  StyledTransactionProductBox,
} from './subscriptionEditProducts.styled';

export const SubscriptionEditProductsView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { subscriptionId }: Readonly<Record<string, string | undefined>> = useParams();
  const [subscriptionDetails, setSubscriptionDetails]: UseState<SubscriptionDetails | null>
    = useState<SubscriptionDetails | null>(null);
  const {
    goBack,
    stepNumber,
    onSaveButtonAction,
    setSearchedProductName,
    cancelEdit,
    missingGroupsItemsNames,
    getProductMaxValue,
    filteredSubscriptionProducts,
    chosenProductsSummary,
    chosenProducts,
    isEditValid,
    setProductsIdsChosenInSubscription,
    setChosenProducts,
    setProductMaxCount,
    isSubmitRequestLoading,
  }: UseSubscriptionEditProducts = useSubscriptionEditProducts(subscriptionDetails?.plan);
  const { showFullscreenAlert, hideFullscreenAlert }: UseNotifications = useNotifications();
  const { redirect }: UseRedirect = useRedirect();

  useQuery(
    [QueryKey.SubscriptionDetails, subscriptionId],
    (): Promise<SubscriptionDetails> => getSubscriptionDetails(parseInt(subscriptionId ?? '', 10)),
    {
      enabled: !!subscriptionId,
      onSuccess: (data: SubscriptionDetails): void => {
        if (!data.permissions.find((permission: NameValueInterface<SubscriptionPermissionType, boolean>): boolean =>
          permission.name === SubscriptionPermissionType.CanEditSubscriptionProducts
        )?.value) {
          showFullscreenAlert({
            title: t('chic.website.global.error'),
            description: t('chic.website.subscriptionEditProductsView.error.showFullscreenAlert.description'),
            iconImage: FullscreenAlertAssets.ErrorIcon,
            acceptButtonSettings: {
              label: t('chic.website.global.ok'),
              action: (): void => {
                redirect(
                  getPathWithParams(
                    getRouteDetailsByName(RouteNameEnum.SubscriptionDetails)?.url ?? '', { id: subscriptionId ?? '' }
                  )
                );
                hideFullscreenAlert();
              }
            },
          });

          return;
        }

        const chosenProductsCopy: Record<number, IdValueObject<number, number>[]> = {};
        const productMaxCountCopy: Record<number, number> = {};
        setSubscriptionDetails(data);
        setProductsIdsChosenInSubscription(data?.products.map((dataProduct: IdValueObject<number, number>): number => dataProduct.id));
        data.plan.groups.forEach((group: SubscriptionPlanGroup): void => {
          productMaxCountCopy[group.id] = group.amount;
          chosenProductsCopy[group.id] = [];
        });
        data.products.forEach((product: IdValueObject<number, number>): void => {
          const groupId: number | null = data.plan.groups
            .find((group: SubscriptionPlanGroup): boolean => group.products.includes(product.id))?.id ?? null;
          const productAvailability: ProductAvailability = data.plan.products
            .find((item: SubscriptionProductInfo): boolean => product.id === item.id)?.availability ?? ProductAvailability.Unknown;
          const isProductAvailable: boolean = [
            ProductAvailability.Available,
            ProductAvailability.PartiallyAvailable,
          ].includes(productAvailability);

          if (groupId !== null) {
            if (isProductAvailable) {
              chosenProductsCopy[groupId].push({ ...product });
            }
          }
        });
        setChosenProducts(chosenProductsCopy);
        setProductMaxCount(productMaxCountCopy);
      },
      // TODO: add logger
      onError: (): void => undefined
    }
  );

  return (
    <Container>
      <ContentWrapper>
        {subscriptionDetails && (
          <BaseViewWithEditProducts
            setChosenProducts={setChosenProducts}
            setSearchedProductName={setSearchedProductName}
            getProductMaxValue={getProductMaxValue}
            subscriptionPlan={subscriptionDetails.plan}
            filteredSubscriptionProducts={filteredSubscriptionProducts}
            cancelEdit={cancelEdit}
            missingGroupsItemsNames={missingGroupsItemsNames}
            stepNumber={stepNumber}
            isEditValid={isEditValid}
            onSaveButtonAction={onSaveButtonAction}
            goBack={goBack}
          />
        )}
      </ContentWrapper>
      {stepNumber === 2 && (
        <ContentWrapperStepTwo>
          <Content>
            <DetailsBox>
              {chosenProductsSummary.map((product: SubscriptionProductInfo): JSX.Element => (
                <StyledTransactionProductBox
                  key={product.id}
                  name={product.name}
                  image={product.photos[0]}
                  category={product.category?.name}
                  amount={
                    Object.values(chosenProducts).flat()
                      .find((chosenProduct: IdValueObject<number, number>): boolean => chosenProduct.id === product.id)?.value
                  }
                />
              ))}
            </DetailsBox>
            <StyledMailToUs
              title={t('chic.website.global.mailToUs.title')}
              actionLabel={t('chic.website.global.mailToUs.actionLabel')}
              actionSettings={{ internalPath: getRouteDetailsByName(RouteNameEnum.SubscriptionContact)?.url ?? '' }}
              mailToUsTheme={MailToUsTheme.Orange}
            />
          </Content>
          <ButtonsWrapper>
            <ButtonsContainer>
              <StyledButton
                label={t('chic.website.global.save')}
                buttonTheme={ButtonTheme.PrimaryOrange}
                onClick={onSaveButtonAction}
                disabled={!isEditValid}
                isLoading={isSubmitRequestLoading}
              />
              <StyledButton
                label={t('chic.website.global.cancel')}
                buttonTheme={ButtonTheme.SecondaryWhite}
                onClick={cancelEdit}
              />
            </ButtonsContainer>
          </ButtonsWrapper>
        </ContentWrapperStepTwo>
      )}
    </Container>
  );
};
