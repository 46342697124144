import { IsDefined, IsEmail, IsEnum, IsString, Matches } from 'class-validator';

import { eanRegexp, phoneRegexp } from '@chic/shared/constants';
import { ContactFormType } from '@chic/shared/enums';

export class SendContactFormRequestWithType {
  @IsDefined()
  @Matches(eanRegexp)
  public ean: string;

  @IsDefined()
  @IsString()
  public name: string;

  @IsDefined()
  @IsEmail()
  public email: string;

  @IsDefined()
  @Matches(phoneRegexp)
  public phone: string;

  @IsDefined()
  @IsString()
  public message: string;

  @IsDefined()
  @IsEnum(ContactFormType)
  public type: ContactFormType;

  constructor(
    type: ContactFormType,
    ean: string,
    name: string,
    email: string,
    phone: string,
    message: string,
  ) {
    this.ean = ean;
    this.name = name;
    this.email = email;
    this.phone = phone;
    this.message = message;
    this.type = type;
  }
}
