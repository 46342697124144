import { BenefitBox, Breakpoint, useRedirect, UseRedirect } from '@chic-loyalty/ui';
import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { FileFromViews } from '@chic/frontend/enums';
import { RouteNameEnum } from '@chic/shared/enums';
import { getRouteDetailsByName } from '@chic/shared/utils';

import {
  BatteriesImage,
  BenefitBoxes,
  BenefitsSection,
  BenefitsTitle,
  Container,
  InnerBenefitsSectionContainer,
  InnerTopSectionContainer,
  LogoImage,
  TopSection,
  TopSectionLeft,
  TopSectionText,
  TopSectionTitle,
  WomanImage,
} from './carePlus.styled';

export const CarePlusView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });
  const { redirect }: UseRedirect = useRedirect();

  return (
    <Container>
      <TopSection>
        <InnerTopSectionContainer>
          <TopSectionLeft>
            <TopSectionTitle>{t('chic.website.carePlusView.benefitsPackage')}</TopSectionTitle>
            <LogoImage src={FileFromViews.CarePlusLogo} />
            <TopSectionText>
              {t('chic.website.carePlusView.carePlusDescription')}
            </TopSectionText>
          </TopSectionLeft>
          <WomanImage src={isMobile ? FileFromViews.CarePlusWomanWithMugMobile : FileFromViews.CarePlusWomanWithMug} />
        </InnerTopSectionContainer>
      </TopSection>
      <BenefitsSection>
        <InnerBenefitsSectionContainer>
          <BenefitsTitle>{t('chic.website.carePlusView.discoverBenefits')}</BenefitsTitle>
          <BenefitBoxes>
            <BenefitBox
              label={t('chic.website.carePlusView.birthdayBonus')}
              image={FileFromViews.CarePlusBirthdayBonus}
              onButtonClick={(): void => redirect(getRouteDetailsByName(RouteNameEnum.BirthdayBonus)?.url ?? '')}
            />
            <BenefitBox
              label={t('chic.website.carePlusView.freeService')}
              image={FileFromViews.CarePlusFreeService}
              onButtonClick={(): void => redirect(getRouteDetailsByName(RouteNameEnum.FreeService)?.url ?? '')}
            />
            {/* TODO: temporarily hidden */}
            {/* <BenefitBox
              label={t('chic.website.carePlusView.freeEngraving')}
              image={FileFromViews.CarePlusFreeEngraver}
              onButtonClick={(): void => redirect(getRouteDetailsByName(RouteNameEnum.FreeEngraver)?.url ?? '', undefined)}
            /> */}
            <BenefitBox
              label={t('chic.website.carePlusView.offers')}
              image={FileFromViews.CarePlusOffers}
              onButtonClick={(): void => redirect(getRouteDetailsByName(RouteNameEnum.Offers)?.url ?? '')}
            />
            <BenefitBox
              label={t('chic.website.carePlusView.notificationsHistory')}
              image={FileFromViews.CarePlusMessages}
              onButtonClick={(): void => redirect(getRouteDetailsByName(RouteNameEnum.NotificationsHistory)?.url ?? '')}
            />
            <BatteriesImage src={FileFromViews.CarePlusDevices} />
          </BenefitBoxes>
        </InnerBenefitsSectionContainer>
      </BenefitsSection>
    </Container>
  );
};
