import { IsDefined, IsEmail, IsEnum, IsNumber, IsString, Matches, ValidateIf } from 'class-validator';

import { phoneRegexp, postalCodeRegexp } from '@chic/shared/constants';
import { SubscriptionDeliveryCode } from '@chic/shared/enums';

export class UpdateSubscriptionDestinationRequest {
  @IsDefined()
  @IsEnum(SubscriptionDeliveryCode)
  public deliveryType: SubscriptionDeliveryCode;

  @IsNumber()
  @ValidateIf((object: unknown, value: unknown): boolean => value !== null)
  public inpostMachineId: number | null;

  @IsNumber()
  @ValidateIf((object: unknown, value: unknown): boolean => value !== null)
  public pickupPointId: number | null;

  @IsDefined()
  @IsString()
  public name: string;

  @IsDefined()
  @IsString()
  public address: string;

  @IsDefined()
  @IsString()
  public city: string;

  @IsDefined()
  @Matches(postalCodeRegexp)
  public postalCode: string;

  @IsDefined()
  @IsEmail()
  public email: string;

  @IsDefined()
  @Matches(phoneRegexp)
  public phone: string;

  constructor(
    deliveryType: SubscriptionDeliveryCode,
    inpostMachineId: number | null,
    pickupPointId: number | null,
    name: string,
    address: string,
    city: string,
    postalCode: string,
    email: string,
    phone: string,
  ) {
    this.deliveryType = deliveryType;
    this.inpostMachineId = inpostMachineId;
    this.pickupPointId = pickupPointId;
    this.name = name;
    this.address = address;
    this.city = city;
    this.postalCode = postalCode;
    this.email = email;
    this.phone = phone;
  }
}
