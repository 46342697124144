import { IsDefined, IsString } from 'class-validator';

export class SetPasswordAfterRecoveryRequest {
  @IsDefined()
  @IsString()
  public token: string;

  @IsDefined()
  @IsString()
  public password: string;

  constructor(
    token: string,
    password: string,
  ) {
    this.token = token;
    this.password = password;
  }
}
