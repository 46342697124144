import {
  PageHeader,
  UseState,
  UseNotifications,
  useNotifications,
  ValidationBar,
  ValidationBarTheme,
  ComponentColorTheme,
  SectionTitleSize,
} from '@chic-loyalty/ui';
import { QueryClient, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { getMessages, removeMessage, setMessageReadStatus } from '@chic/frontend/api/requests';
import { QueryKey } from '@chic/frontend/enums';
import { FrontendApiError } from '@chic/frontend/models';
import { RouteNameEnum } from '@chic/shared/enums';
import { Message, MessagesListPagination } from '@chic/shared/interfaces';
import { getRouteDetailsByName } from '@chic/shared/utils';

import { Container, Content, Wrapper, StyledPagination, StyledSectionTitle, StyledSmsBox } from './notificationsHistory.styled';

export const NotificationsHistoryView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const navigate: NavigateFunction = useNavigate();
  const { addToast }: UseNotifications = useNotifications();
  const [messages, setMessages]: UseState<Message[]> = useState<Message[]>([]);
  const [activePage, setActivePage]: UseState<number> = useState<number>(0);
  const [pagesCount, setPagesCount]: UseState<number> = useState<number>(0);
  const queryClient: QueryClient = useQueryClient();

  const { isFetching, isSuccess, data: queryData }: UseQueryResult<MessagesListPagination<Message>> = useQuery(
    [QueryKey.Messages, activePage],
    (): Promise<MessagesListPagination<Message>> => getMessages({ limit: 50, offset: activePage * 50 }),
  );

  useEffect(
    (): void => {
      if (queryData && isSuccess) {
        setMessages(queryData.list);
        setPagesCount(Math.ceil(queryData.amount / queryData.limit));
      }
    },
    [queryData]
  );

  const removeMessageAction: (messageId: number) => void = (messageId: number): void => {
    const wasDeletedMessageRead: boolean = !!messages.find((item: Message): boolean => item.id === messageId)?.isRead;
    removeMessage(messageId)
      .then((): void => {
        addToast({ content: t('chic.website.notificationsHistoryView.removeMessageSuccess') });
        if (queryData) {
          const modifiedData: MessagesListPagination<Message> = {
            ...queryData,
            unreadAmount: wasDeletedMessageRead
              ? queryData.unreadAmount
              : queryData.unreadAmount - 1,
            list: messages.filter((message: Message): boolean => message.id !== messageId)
          };
          queryClient.setQueryData<MessagesListPagination<Message>>([QueryKey.Messages, activePage], modifiedData);
          queryClient.setQueryData<MessagesListPagination<Message>>([QueryKey.Messages], modifiedData);
        }
      })
      .catch((error: FrontendApiError): void => addToast({ content: t(error.message) }));
  };

  const setMessageReadStatusAction: (messageId: number, readed: boolean) => void = (messageId: number, readed: boolean): void => {
    setMessageReadStatus(messageId, { readed })
      .then((): void => {
        if (queryData) {
          const modifiedData: MessagesListPagination<Message> = {
            ...queryData,
            unreadAmount: queryData.unreadAmount + (readed ? -1 : 1),
            list: queryData.list.map((item: Message): Message => (
              item.id === messageId
                ? { ...item, isRead: readed }
                : item
            ))
          };
          queryClient.setQueryData<MessagesListPagination<Message>>([QueryKey.Messages, activePage], modifiedData);
          queryClient.setQueryData<MessagesListPagination<Message>>([QueryKey.Messages], modifiedData);
        }
      })
      .catch((error: FrontendApiError): void => addToast({ content: t(error.message) }));
  };

  return (
    <Container>
      <PageHeader
        header={t('chic.website.meta.notificationsHistory.title')}
        subheader={t('chic.website.notificationsHistoryView.subheader')}
        onArrowButtonAction={(): void => navigate(getRouteDetailsByName(RouteNameEnum.CarePlus)?.url ?? '/')}
      />
      <Content>
        <Wrapper>
          <StyledSectionTitle
            title={t('chic.website.notificationsHistoryView.listTitle')}
            size={SectionTitleSize.Small}
          />
          {messages.map((message: Message): JSX.Element => (
            <StyledSmsBox
              key={message.id}
              messageId={message.id}
              content={message.body}
              timestamp={new Date(message.datetime).getTime()}
              removeAction={(): void => removeMessageAction(message.id)}
              changeReadState={(isRead: boolean): void => setMessageReadStatusAction(message.id, isRead)}
              isRead={message.isRead}
            />
          ))}
          {!isFetching && !messages.length && (
            <ValidationBar
              message={t('chic.website.notificationsHistoryView.emptyList')}
              barTheme={ValidationBarTheme.Yellow}
            />
          )}
          {(pagesCount > 1) && (
            <StyledPagination
              activePage={activePage}
              pagesCount={pagesCount}
              onActivePageChange={setActivePage}
              colorTheme={ComponentColorTheme.Dark}
            />
          )}
        </Wrapper>
      </Content>
    </Container>
  );
};
