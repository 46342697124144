import {
  ComponentColorTheme,
  ElementsTableRowData, 
  PageHeader,
  Pagination, 
  PaginationDetails, 
  UseFormatDate, 
  UseState,
  getPathWithParams,
  useFormatDate, 
  usePagination, 
  ElementsTableColumn,
  PointsIcon
} from '@chic-loyalty/ui';
import { QueryObserverResult, useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { getTransactionsList } from '@chic/frontend/api/requests';
import { FileFromViews, QueryKey } from '@chic/frontend/enums';
import { RouteNameEnum } from '@chic/shared/enums';
import { ListPaginationInterface, TransactionListElement } from '@chic/shared/interfaces';
import { getRouteDetailsByName } from '@chic/shared/utils';

import { tableColumns } from './transactionsList.const';
import { 
  Container, 
  EmptyStateContainer, 
  EmptyStateImg, 
  EmptyStateMessage, 
  StyledElementsTable, 
  TableWrapper,
  TableDateWrapper,
  TableDate,
  TableStore,
  TablePoints,
} from './transactionsList.styled';

export const TransactionsListView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { formatDate }: UseFormatDate = useFormatDate();
  const { setMaxItems, setPage, itemsPerPage, offset, page, pagesCount }: PaginationDetails = usePagination();
  const [transactionsList, setTransactionsList]: UseState<ElementsTableRowData[]> = useState<ElementsTableRowData[]>([]);

  const { isLoading }: QueryObserverResult = useQuery(
    [QueryKey.TransactionsList, offset],
    (): Promise<ListPaginationInterface<TransactionListElement>> => getTransactionsList({ limit: itemsPerPage, offset }),
    {
      onSuccess: (transactions: ListPaginationInterface<TransactionListElement>): void => {
        setMaxItems(transactions.amount);
        setTransactionsList(transactions.list.map((transaction: TransactionListElement, index: number): ElementsTableRowData => ({
          nodes: [
            String((index + 1) + offset),
            <TableDateWrapper>
              <TableDate>{formatDate(new Date(transaction.datetime).getTime(), 'DD.MM.YYYY, HH:mm:ss')}</TableDate>
              <TableStore>{transaction.store?.name ?? '-'}</TableStore>
            </TableDateWrapper>,
            transaction.store?.name ?? '-',
            transaction.typeLabel,
            <TablePoints>
              <PointsIcon />
              {String(transaction?.points ?? 0)}
            </TablePoints>,
          ],
          linkData: {
            internalPath: getPathWithParams(getRouteDetailsByName(RouteNameEnum.TransactionDetails)?.url ?? '', { id: `${transaction.id}` })
          }
        })));
      },
      // TODO: add logger
      onError: (): void => undefined
    }
  );

  return (
    <Container>
      <PageHeader header={t('chic.website.transactionsListView.pageHeader.header')} withoutMobileBorderBottom />
      {!isLoading && (
        transactionsList.length ? (
          <TableWrapper>
            <StyledElementsTable 
              columns={tableColumns.map((column: ElementsTableColumn): ElementsTableColumn => ({
                ...column,
                label: column.label && t(column.label),
              }))} 
              rows={ transactionsList }
              mobileHideHeader
            />
            <Pagination activePage={page} pagesCount={pagesCount} onActivePageChange={setPage} colorTheme={ComponentColorTheme.Dark} />
          </TableWrapper>
        ) : (
          <EmptyStateContainer>
            <EmptyStateImg src={FileFromViews.TransactionsListEmptyState} />
            <EmptyStateMessage>
              {t('chic.website.transactionsListView.emptyStateMessage')}
            </EmptyStateMessage>
          </EmptyStateContainer>
        )
      )}
    </Container>
  );
};
