import { ActionBox, Breakpoint, Color, Grid, StyledComponent } from '@chic-loyalty/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  width: calc(100% - 80px);
  min-height: calc(100vh - 219px);
  margin-left: auto;
  background-color: ${Color.AlmostBlack};

  @media ${Breakpoint.Mobile} {
    width: 100%;
  }
`;

export const ContentContainer: StyledComponent<'div'> = styled.div`
  width: 100%;
  padding: 50px 20px 100px;
  max-width: calc(${Grid.Desktop} + 120px);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${Breakpoint.Mobile} {
    padding: 50px 20px;
  }
`;

export const TopSection: StyledComponent<'div'> = styled.div`
  display: flex;
  column-gap: 24px;
  width: 100%;
  padding-bottom: 20px;

  @media ${Breakpoint.Tablet} {
    flex-direction: column;
  }
`;

export const Image: StyledComponent<'img'> = styled.img`
  max-width: 622px;
  border-radius: 12px;
  max-height: 100%;
  width: 50%;
  object-fit: cover;

  @media ${Breakpoint.Tablet} {
    max-width: 100%;
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const RightSection: StyledComponent<'div'> = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  min-width: 400px;

  @media ${Breakpoint.Tablet} {
    width: 100%;
    min-width: unset;
  }
`;

export const StyledActionBox: StyledComponent<typeof ActionBox> = styled(ActionBox)`
  max-height: none;
`;
