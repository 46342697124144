import { IsBoolean, IsDefined } from 'class-validator';

export class SetMessageReadStatusRequest {
  @IsBoolean()
  @IsDefined()
  public readed: boolean;

  constructor(
    readed: boolean,
  ) {
    this.readed = readed;
  }
}
