import { DropdownOption } from '@chic-loyalty/ui';

import { FormRow } from '@chic/frontend/interfaces';
import { PreferredLanguage } from '@chic/shared/enums';

import { RegistrationField } from './registration.enums';
import { RegisterRequestWithRepatedPassword } from './registration.types';

export const marketingAgreementPrefixRegex: RegExp = /^(?<before>.*)(\[prefix\])(?<prefixContent>.*)(\[\/prefix\])(?<after>.*)$/;

export const availableLanguages: DropdownOption[] = [
  { name: PreferredLanguage.Pl, label: 'Polski' },
  { name: PreferredLanguage.En, label: 'English' },
  { name: PreferredLanguage.Ua, label: 'Українська' },
  { name: PreferredLanguage.Cz, label: 'český' },
  { name: PreferredLanguage.De, label: 'Deutsch' },
  { name: PreferredLanguage.Sk, label: 'slovenský' },
  { name: PreferredLanguage.Lt, label: 'lietuvių' },
  { name: PreferredLanguage.No, label: 'norsk' },
  { name: PreferredLanguage.Es, label: 'español' },
];

export const registrationFields: FormRow<RegistrationField>[] = [
  {
    fields: [
      {
        name: RegistrationField.Phone,
        label: 'chic.website.registrationView.form.phone.label',
        placeholder: 'chic.website.registrationView.form.phone.placeholder',
        type: 'text',
        hasPhonePrefix: true,
        required: true,
      },
    ]
  },
  {
    fields: [
      {
        name: RegistrationField.FirstName,
        label: 'chic.website.registrationView.form.firstName.label',
        placeholder: 'chic.website.registrationView.form.firstName.placeholder',
        type: 'text',
        required: true,
      },
      {
        name: RegistrationField.LastName,
        label: 'chic.website.registrationView.form.lastName.label',
        placeholder: 'chic.website.registrationView.form.lastName.placeholder',
        type: 'text',
        required: true,
      },
    ]
  },
  {
    fields: [
      {
        name: RegistrationField.PostalCode,
        label: 'chic.website.registrationView.form.postalCode.label',
        placeholder: 'XX-XXX',
        type: 'text',
        numericMask: '##-###',
        required: false,
      },
    ]
  },
  {
    fields: [
      {
        name: RegistrationField.Birthday,
        label: 'chic.website.registrationView.form.birthday.label',
        placeholder: 'DD-MM-RRRR',
        type: 'text',
        numericMask: '##-##-####',
        placeholderMask: ['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y'],
        required: true,
        isDateInput: true,
      },
    ]
  },
  {
    fields: [
      {
        name: RegistrationField.Email,
        label: 'chic.website.registrationView.form.email.label',
        placeholder: 'chic.website.registrationView.form.email.placeholder',
        type: 'text',
        required: true,
      },
    ]
  },
  {
    fields: [
      {
        name: RegistrationField.Password,
        label: 'chic.website.registrationView.form.password.label',
        placeholder: 'chic.website.registrationView.form.password.placeholder',
        type: 'password',
        required: true,
      },
      {
        name: RegistrationField.RepeatedPassword,
        label: 'chic.website.global.repeatedPassword',
        placeholder: 'chic.website.registrationView.form.repeatPassword.placeholder',
        type: 'password',
        required: true,
      },
    ]
  },
  {
    fields: [
      {
        name: RegistrationField.PreferredLanguage,
        label: 'chic.website.registrationView.form.preferredLanguage.label',
        placeholder: 'chic.website.registrationView.form.preferredLanguage.placeholder',
        type: 'dropdown',
        options: availableLanguages,
        initialValue: availableLanguages.find((item: DropdownOption) => item.name === PreferredLanguage.Pl)
      }
    ]
  }
];

export const initialValues: RegisterRequestWithRepatedPassword = {
  declarationOfAge: false,
  personalDataAgreement: false,
  preferredLanguage: PreferredLanguage.Pl,
} as RegisterRequestWithRepatedPassword;
