import { Breakpoint, Color, FontWeight, Grid, ProductBox, StyledComponent } from '@chic-loyalty/ui';
import styled from 'styled-components';

import { CategoryImageWrapperProps, CategoryLabelProps } from './products.types';

export const Container: StyledComponent<'div'> = styled.div`
  min-height: calc(100vh - 293px);
  width: calc(100% - 80px);
  background-color: ${Color.Level1};
  margin-left: auto;

  @media ${Breakpoint.Mobile} {
    width: 100%;
  }
`;

export const Content: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  gap: 70px;
  max-width: ${Grid.Desktop};
  padding: 70px 20px;
  margin: 0 auto;

  @media ${Breakpoint.Mobile} {
    gap: 50px;
    padding: 35px 20px;
  }
`;

export const CategorySlider: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const CategoryLabel: StyledComponent<'span'> = styled.span`
  font-size: 14px;
  line-height: 22px;
  font-weight: ${FontWeight.Medium};
  color: ${Color.SolidHighEmphasis};
`;

export const CategoryBox: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 90px;
`;

export const CategoryImageWrapper: StyledComponent<'div', CategoryImageWrapperProps> = styled.div<CategoryImageWrapperProps>`
  height: 90px;
  width: 90px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;

  ::after {
    content: '';
    display: block;
    border-width: 1.5px;
    border-style: solid;
    border-color: ${({ $isActive }: CategoryImageWrapperProps): string => $isActive ? Color.Tangerine : Color.Transparent};
    border-radius: 50%;
    position: absolute;
    inset: 0;
  }
`;

export const CategoryImage: StyledComponent<'img'> = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

export const CategoryName: StyledComponent<'span', CategoryLabelProps> = styled.span<CategoryLabelProps>`
  font-size: 10px;
  line-height: 14px;
  font-weight: ${FontWeight.Bold};
  color: ${({ $isActive }: CategoryLabelProps): string => $isActive ? Color.Tangerine : Color.SolidLowEmphasis};
  text-transform: uppercase;
  text-align: center;
`;

export const ProductsList: StyledComponent<'div'> = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 15px;

  @media ${Breakpoint.Tablet} {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media ${Breakpoint.Mobile} {
    grid-template-columns: 1fr 1fr;
  }
  
  @media ${Breakpoint.SmallMobile} {
    grid-gap: 10px;
  }
`;

export const StyledProductBox: StyledComponent<typeof ProductBox> = styled(ProductBox)`
  width: 100%;
`;

export const SearchOverlay: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  gap: 70px;
  min-height: calc(100vh - 293px);
  background-color: ${Color.AlmostBlack};
`;

export const SearchHeader: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 230px;
  width: 100%;
  max-width: ${Grid.Desktop};
  padding: 120px 20px 0 100px;
  margin: 0 auto;

  @media ${Breakpoint.Mobile} {
    padding: 35px 20px;
  }

  ::after {
    content: '';
    display: block;
    border-bottom: 1px solid ${Color.SolidLowestEmphasis};
  }
`;

export const SearchInputWrapper: StyledComponent<'div'> = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;

export const SearchInput: StyledComponent<'input'> = styled.input`
  width: 100%;
  font-size: 31px;
  line-height: 40px;
  color: ${Color.SolidHighEmphasis};
  padding: 0;

  ::placeholder {
    color: ${Color.SolidHighEmphasis};
  }

  @media ${Breakpoint.Mobile} {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const CloseButton: StyledComponent<'button'> = styled.button.attrs({ type: 'button' })`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${Color.Tangerine};
  padding: 13px;
`;

export const SearchContent: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
  width: 100%;
  max-width: ${Grid.Desktop};
  padding: 0 20px 70px 100px;
  margin: 0 auto;

  @media ${Breakpoint.Mobile} {
    padding: 35px 20px;
  }
`;

export const SearchResults: StyledComponent<'div'> = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 15px;
`;

export const SearchQuantity: StyledComponent<'p'> = styled.p`
  color: ${Color.SolidMediumEmphasis};
`;
