import {
  Breakpoint,
  Checkbox,
  IconName,
  InputTheme,
  PageHeader,
  Country,
  UseFormikForm,
  useFormikForm,
  UseState,
  UseNotifications,
  useNotifications,
  UseRedirect,
  useRedirect,
} from '@chic-loyalty/ui';
import { Formik, FormikProps } from 'formik';
import { stringify } from 'query-string';
import React, { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import * as Yup from 'yup';

import { loginRequest } from '@chic/frontend/api/requests';
import { FullscreenAlertAssets } from '@chic/frontend/enums';
import { useAuth } from '@chic/frontend/hooks';
import { UseAuth } from '@chic/frontend/interfaces';
import { FrontendApiError } from '@chic/frontend/models';
import { LoginErrorType, PasswordErrorType, RouteNameEnum } from '@chic/shared/enums';
import { LoginEmailErrorData, UserWithToken } from '@chic/shared/interfaces';
import { LoginByPassword } from '@chic/shared/models';
import { LoginErrorData } from '@chic/shared/types';
import { getPathWithParams, getRouteDetailsByName } from '@chic/shared/utils';

import { SignInFormTabName } from './signIn.enums';
import { useSignInValidation } from './signIn.hooks';
import {
  AdditionalControls,
  Container,
  FormikForm,
  RemindPasswordLink,
  StyledButton,
  StyledContentBox,
  StyledInput,
  StyledTabSwitch,
  StyledValidationBar
} from './signIn.styled';

export const SignInView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const isTablet: boolean = useMediaQuery({ query: Breakpoint.Tablet });
  const [isLoading, setIsLoading]: UseState<boolean> = useState<boolean>(false);
  const [loginError, setLoginError]: UseState<string> = useState<string>('');
  const [activeTab, setActiveTab]: UseState<SignInFormTabName> = useState<SignInFormTabName>(SignInFormTabName.EmailOrEan);
  const [phonePrefixCountry, setPhonePrefixCountry]: UseState<Country> = useState<Country>(Country.Poland);
  const SignInFormValidationSchema: Yup.SchemaOf<LoginByPassword> = useSignInValidation(
    activeTab === SignInFormTabName.PhoneNumber, phonePrefixCountry
  );
  const { setFormSubmitted, isFormSubmitted }: UseFormikForm = useFormikForm();
  const { signIn }: UseAuth = useAuth();
  const { redirect }: UseRedirect = useRedirect();
  const { showFullscreenAlert, hideFullscreenAlert }: UseNotifications = useNotifications();

  const loginAction: (data: LoginByPassword) => void = (data: LoginByPassword): void => {
    setLoginError('');
    setIsLoading(true);
    loginRequest(data)
      .then((response: UserWithToken): void => {
        const { contextToken, ...user }: UserWithToken = response;
        signIn(user, contextToken);
        setIsLoading(false);
      })
      .catch((error: FrontendApiError<LoginErrorData>): void => {
        setLoginError(t(error.message));
        setIsLoading(false);

        if (error.data.type === LoginErrorType.EmailVerificationRequired) {
          showFullscreenAlert({
            title: t('chic.website.signInView.unverifiedAccountPopup.title'),
            description: t('chic.website.signInView.unverifiedAccountPopup.description'),
            iconImage: FullscreenAlertAssets.ErrorIcon,
            acceptButtonSettings: {
              label: t('chic.website.signInView.unverifiedAccountPopup.acceptButtonLabel'),
              action: (): void => {
                hideFullscreenAlert();
                const viewUrl: string = getRouteDetailsByName(RouteNameEnum.ChangeUnverifiedUserEmail)?.url ?? '';
                redirect(`${viewUrl}?${stringify(
                  {
                    email: (error.data as LoginEmailErrorData).email,
                    token: error.data.token
                  },
                  { skipEmptyString: true }
                )}`);
              }
            },
            cancelButtonSettings: {
              label: t('chic.website.signInView.unverifiedAccountPopup.cancelButtonLabel'),
              action: hideFullscreenAlert,
            }
          });
        }
        if ([PasswordErrorType.PasswordExpired, PasswordErrorType.PasswordNotValid].includes(error.data.type as PasswordErrorType)) {
          showFullscreenAlert({
            title: t('chic.website.signInView.passwordErrorPopup.title'),
            description: error.message,
            iconImage: FullscreenAlertAssets.ErrorIcon,
            acceptButtonSettings: {
              label: t('chic.website.signInView.passwordErrorPopup.acceptButtonLabel'),
              action: (): void => {
                hideFullscreenAlert();
                redirect(getPathWithParams(getRouteDetailsByName(RouteNameEnum.SetPassword)?.url ?? '', { token: error.data.token }));
              }
            },
            cancelButtonSettings: {
              label: t('chic.website.signInView.passwordErrorPopup.cancelButtonLabel'),
              action: hideFullscreenAlert,
            }
          });
        }
      });
  };

  return (
    <Container>
      <PageHeader
        header={t('chic.website.global.logIn')}
        onArrowButtonAction={(): void => redirect(getRouteDetailsByName(RouteNameEnum.Home)?.url ?? '/')}
      />
      <StyledContentBox>
        <StyledTabSwitch
          header={t('chic.website.signInView.loginMethod')}
          tabs={[
            { name: SignInFormTabName.EmailOrEan, labels: [t('chic.website.signInView.emailOrEan')], icon: IconName.SmallEmail },
            { name: SignInFormTabName.PhoneNumber, labels: [t('chic.website.signInView.phoneNumber')], icon: IconName.SmallForm }
          ]}
          initialTabName={SignInFormTabName.EmailOrEan}
          onTabChange={(tabName: string): void => setActiveTab(tabName as SignInFormTabName)}
        />
        <Formik
          initialValues={{ login: '', password: '' }}
          onSubmit={loginAction}
          validationSchema={SignInFormValidationSchema}
          validateOnChange={isFormSubmitted}
          validateOnBlur={isFormSubmitted}
        >
          {({ handleSubmit, setFieldValue, errors, values }: FormikProps<LoginByPassword>) => (
            <FormikForm onSubmit={handleSubmit}>
              <StyledInput
                label={t('chic.website.signInView.id.label')}
                placeholder={activeTab === SignInFormTabName.EmailOrEan
                  ? t('chic.website.signInView.id.placeholder')
                  : t('chic.website.signInView.phone.placeholder')
                }
                onChange={(value: string): void => setFieldValue('login', value)}
                value={values.login}
                message={errors.login ?? ''}
                inputTheme={errors.login ? InputTheme.Error : InputTheme.Standard}
                hasPhonePrefix={activeTab === SignInFormTabName.PhoneNumber}
                onChangePhonePrefix={setPhonePrefixCountry}
              />
              <StyledInput
                label={t('chic.website.global.password')}
                placeholder={t('chic.website.signInView.password.placeholder')}
                type='password'
                onChange={(value: string): void => setFieldValue('password', value)}
                onBlur={(value: string): void => setFieldValue('password', value)}
                message={errors.password ?? ''}
                inputTheme={errors.password ? InputTheme.Error : InputTheme.Standard}
              />
              <AdditionalControls>
                <Checkbox
                  id='rememberMe'
                  label={t('chic.website.signInView.rememberMe')}
                  onChange={(value: boolean): void => setFieldValue('rememberMe', value)}
                />
                <RemindPasswordLink to={getRouteDetailsByName(RouteNameEnum.RemindPassword)?.url ?? ''}>
                  {t('chic.website.signInView.forgotMyPassword')}
                </RemindPasswordLink>
              </AdditionalControls>
              <StyledButton
                label={t('chic.website.global.logIn')}
                isLoading={isLoading}
                type='submit'
                onClick={setFormSubmitted}
                fullWidth={isTablet}
              />
            </FormikForm>
          )}
        </Formik>
        <StyledValidationBar message={loginError} />
      </StyledContentBox>
    </Container>
  );
};
