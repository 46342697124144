import {
  UseFormikForm,
  UseNotifications,
  UseRedirect,
  UseState,
  useFormikForm,
  useNotifications,
  useRedirect
} from '@chic-loyalty/ui';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { getSubscriptionOrderDetails, updateSubscriptionOrderDestination } from '@chic/frontend/api/requests';
import { FullscreenAlertAssets, QueryKey } from '@chic/frontend/enums';
import { FrontendApiError } from '@chic/frontend/models';
import { RouteNameEnum } from '@chic/shared/enums';
import { SubscriptionDeliveryCost, SubscriptionOrderInfo } from '@chic/shared/interfaces';
import { UpdateSubscriptionOrderDestinationRequest } from '@chic/shared/models';
import { getRouteDetailsByName } from '@chic/shared/utils';

import { UseSubscriptionNotAuthorizedDeliveryUpdate } from '../subscriptionDeliveryNotAuthorizedUpdate.types';

export const useUpdateSubscriptionDelivery: (
  token: string | undefined,
  orderId: string | undefined,
) => UseSubscriptionNotAuthorizedDeliveryUpdate = (
  token: string | undefined,
  orderId: string | undefined,
): UseSubscriptionNotAuthorizedDeliveryUpdate => {
  const { t }: TransProps<never> = useTranslation();
  const { showFullscreenAlert, hideFullscreenAlert }: UseNotifications = useNotifications();
  const { redirect }: UseRedirect = useRedirect();
  const [orderDetails, setOrderDetails]: UseState<SubscriptionOrderInfo | null> = useState<SubscriptionOrderInfo | null>(null);
  const {
    setApiRequestStart,
    setApiRequestEnd,
    isApiRequestInProgress,
  }: UseFormikForm = useFormikForm();
  
  useQuery(
    [QueryKey.SubscriptionOrderInfo],
    (): Promise<SubscriptionOrderInfo> => getSubscriptionOrderDetails(Number(orderId), String(token)),
    {
      onSuccess: (data: SubscriptionOrderInfo): void => {
        setOrderDetails(data);
      },
      enabled: !!orderId && !!token,
      onError: (error: FrontendApiError): void => {
        showFullscreenAlert({
          description: error.message,
          iconImage: FullscreenAlertAssets.ErrorIcon,
          acceptButtonSettings: {
            label: t('chic.website.global.ok'),
            action: (): void => {
              hideFullscreenAlert();
              goHome();
            }
          },
        });
      },
    }
  );
  
  const getLabelFromDeliveries: (
    orderType: string,
    deliveries: SubscriptionDeliveryCost[]
  ) => string = (
    orderType: string,
    deliveries: SubscriptionDeliveryCost[]
  ): string => {
    const foundDelivery: SubscriptionDeliveryCost | undefined = deliveries.find(
      (delivery: SubscriptionDeliveryCost): boolean => delivery.code === orderType
    );

    return foundDelivery?.label ?? '';
  };

  const startFormApiRequest: (
    requestData: UpdateSubscriptionOrderDestinationRequest
  ) => void = (
    requestData: UpdateSubscriptionOrderDestinationRequest
  ): void => {
    setApiRequestStart();
    updateSubscriptionOrderDestination(requestData)
      .then((): void => {
        showFullscreenAlert({
          description: t('chic.website.global.success'),
          iconImage: FullscreenAlertAssets.SuccessIcon,
          acceptButtonSettings: {
            label: t('chic.website.global.ok'),
            action: (): void => {
              hideFullscreenAlert();
              goHome();
            }
          },
        });
      })
      .catch((error: FrontendApiError): void => {
        showFullscreenAlert({
          description: error.message,
          iconImage: FullscreenAlertAssets.ErrorIcon,
          acceptButtonSettings: {
            label: t('chic.website.global.ok'),
            action: (): void => {
              hideFullscreenAlert();
            }
          },
        });
      })
      .finally((): void => setApiRequestEnd());
  };

  const goHome: () => void = (): void => {
    redirect(getRouteDetailsByName(RouteNameEnum.Home)?.url ?? '/');
  };

  return {
    orderDetails,
    getLabelFromDeliveries,
    startFormApiRequest,
    isApiRequestInProgress,
    goHome,
  };
};
