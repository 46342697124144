import {
  PageHeader,
  PageHeaderPosition,
  OptionsSwitch,
  UseState,
  IconName,
  OptionSwitchOption,
  Button,
  ButtonTheme,
  InputTheme,
  useFormikForm,
  UseFormikForm,
  Input,
  useNotifications,
  UseNotifications,
  Breakpoint,
  MailToUsTheme,
} from '@chic-loyalty/ui';
import { useQuery } from '@tanstack/react-query';
import { Formik, FormikProps } from 'formik';
import React, { useState, useEffect, useMemo } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useParams, useSearchParams } from 'react-router-dom';

import {
  getParcelLockerDetailsById,
  getPickupPointDetails,
  getSubscriptionDetails,
  updateDeliveryDestination
} from '@chic/frontend/api/requests';
import { QueryKey } from '@chic/frontend/enums';
import { useConfirmContactData, useDeliveriesTransformation } from '@chic/frontend/hooks';
import {
  ConfirmContactDataParams,
  FormField,
  FormRow,
  UseConfirmContactData,
  UseDeliveriesTransformation
} from '@chic/frontend/interfaces';
import { FrontendApiError } from '@chic/frontend/models';
import { UseSearchParams } from '@chic/frontend/types';
import { CommonBusinessErrorType, FrontendUrlsParams, RouteNameEnum, SubscriptionDeliveryCode } from '@chic/shared/enums';
import {
  CommonBusinessError,
  ParcelLocker,
  PickupPoint,
  SubscriptionDetails
} from '@chic/shared/interfaces';
import { UpdateSubscriptionDestinationRequest } from '@chic/shared/models';
import { getRouteDetailsByName } from '@chic/shared/utils';

import { useSubscriptionsEditDelivery, useSubscriptionDeliveryValidations } from './hooks';
import { subscriptionDeliveryFields } from './subscriptionEditDelivery.constants';
import { SubscriptionDeliveryField } from './subscriptionEditDelivery.enums';
import {
  ButtonsContainer,
  Container,
  FormikForm,
  ParcelAddress,
  ParcelData,
  SectionTitle,
  SectionTitleLine,
  StyledButton,
  StyledContentBox,
  StyledFieldsRow,
  StyledInput,
  StyledMailToUs
} from './subscriptionEditDelivery.styled';
import {
  UpdateSubscriptionDestinationBasicData,
  UseSubscriptionDeliveryValidations,
  UseSubscriptionsEditDelivery
} from './subscriptionEditDelivery.types';

export const SubscriptionEditDeliveryView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { id }: Readonly<Record<string, string | undefined>> = useParams();
  const {
    setFormSubmitted,
    isFormSubmitted,
    setApiRequestStart,
    setApiRequestEnd,
    isApiRequestInProgress
  }: UseFormikForm = useFormikForm();
  const { getParcelLockerAddress, getPickupPointAddress }: UseDeliveriesTransformation = useDeliveriesTransformation();
  const [selectedDelivery, setSelectedDelivery]: UseState<SubscriptionDeliveryCode>
    = useState<SubscriptionDeliveryCode>(SubscriptionDeliveryCode.InpostMachine);
  const {
    hideSmsCodeView,
    isSmsButtonLoading,
    setShouldShowSmsCode,
    shouldShowSmsCode
  }: UseConfirmContactData = useConfirmContactData();
  const { transformSubscriptionDeliveryCostToOptionSwitchOption }: UseDeliveriesTransformation = useDeliveriesTransformation();
  const { addToast }: UseNotifications = useNotifications();
  const [searchParams]: UseSearchParams = useSearchParams();
  const [subscriptionDetails, setSubscriptionDetails]: UseState<SubscriptionDetails | null> = useState<SubscriptionDetails| null>(null);
  const [parcelLockerData, setParcelLockerData]: UseState<ParcelLocker | null> = useState<ParcelLocker | null>(null);
  const [pickupPointData, setPickupPointData]: UseState<PickupPoint | null> = useState<PickupPoint | null>(null);
  const deliveryType: string = useMemo((): string => (searchParams.get(FrontendUrlsParams.DeliveryType) ?? ''), [searchParams]);
  const isTablet: boolean = useMediaQuery({ query: Breakpoint.Tablet });
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });
  const pointId: string | null = useMemo(
    (): string | null => {
      if (selectedDelivery === subscriptionDetails?.deliveryType.code) {
        if (subscriptionDetails.deliveryType.code === SubscriptionDeliveryCode.InpostMachine) {
          return subscriptionDetails.deliveryDestination.inpostId ?? '';
        } else if (subscriptionDetails.deliveryType.code === SubscriptionDeliveryCode.PickupPoint) {
          return subscriptionDetails.deliveryDestination.pickupPointId?.toString() ?? '';
        }
      }
      return null;
    },
    [subscriptionDetails?.deliveryType, selectedDelivery, subscriptionDetails?.deliveryDestination]
  );

  const {
    goBack,
    onSubmitConfirmContactData,
    redirectToChooseDeliveryPoint,
  }: UseSubscriptionsEditDelivery = useSubscriptionsEditDelivery();
  const { smsFormSchema, deliveryUpdateFormSchema }: UseSubscriptionDeliveryValidations = useSubscriptionDeliveryValidations();

  const parcelMachineId: string = useMemo(
    (): string => deliveryType === SubscriptionDeliveryCode.InpostMachine
      ? searchParams.get(FrontendUrlsParams.PointId) ?? ''
      : subscriptionDetails?.deliveryDestination.inpostId ?? '',
    [searchParams, subscriptionDetails?.deliveryDestination.inpostId, deliveryType]
  );

  const pickupPointId: string = useMemo(
    (): string => deliveryType === SubscriptionDeliveryCode.PickupPoint
      ? searchParams.get(FrontendUrlsParams.PointId) ?? ''
      : subscriptionDetails?.deliveryDestination.pickupPointId?.toString() ?? '',
    [searchParams, subscriptionDetails?.deliveryDestination.pickupPointId, deliveryType]
  );

  useQuery(
    [QueryKey.SubscriptionDetails],
    (): Promise<SubscriptionDetails> => getSubscriptionDetails(parseInt(id ?? '', 10)),
    {
      enabled: !!id,
      onSuccess: (data: SubscriptionDetails): void => setSubscriptionDetails(data),
    }
  );

  useQuery(
    [QueryKey.ParcelLockerDetails, parcelMachineId],
    (): Promise<ParcelLocker> => getParcelLockerDetailsById(parcelMachineId ? parseInt(parcelMachineId, 10) : 0),
    {
      enabled: !!parcelMachineId && selectedDelivery === SubscriptionDeliveryCode.InpostMachine,
      onSuccess: (data: ParcelLocker): void => setParcelLockerData(data),
    }
  );

  useQuery(
    [QueryKey.PickupPointDetails, pickupPointId],
    (): Promise<PickupPoint> => getPickupPointDetails(pickupPointId ? parseInt(pickupPointId, 10) : 0),
    {
      enabled: !!pickupPointId && selectedDelivery === SubscriptionDeliveryCode.PickupPoint,
      onSuccess: (data: PickupPoint): void =>  setPickupPointData(data),
    }
  );

  useEffect(
    (): void => {
      if (deliveryType === SubscriptionDeliveryCode.InpostMachine) {
        setSelectedDelivery(SubscriptionDeliveryCode.InpostMachine);
      } else if (deliveryType === SubscriptionDeliveryCode.PickupPoint) {
        setSelectedDelivery(SubscriptionDeliveryCode.PickupPoint);
      } else if (subscriptionDetails?.deliveryType.code) {
        setSelectedDelivery(subscriptionDetails?.deliveryType.code);
      }
    },
    [subscriptionDetails?.deliveryType.code, deliveryType]
  );

  const onSubmit: (data: UpdateSubscriptionDestinationBasicData) => void = (data: UpdateSubscriptionDestinationBasicData) => {
    const requestData: UpdateSubscriptionDestinationRequest = new UpdateSubscriptionDestinationRequest(
      selectedDelivery,
      selectedDelivery === SubscriptionDeliveryCode.InpostMachine
        ? parseInt(parcelMachineId, 10)
        : null,
      selectedDelivery === SubscriptionDeliveryCode.PickupPoint
        ? parseInt(pickupPointId, 10)
        : null,
      data.name,
      data.address,
      data.city,
      data.postalCode,
      data.email,
      data.phone
    );
    setApiRequestStart();
    updateDeliveryDestination(parseInt(id ?? '', 10), requestData)
      .then((): void => {
        addToast({ content: t('chic.website.global.successMessage') });
        goBack(id);
      })
      .catch((error: FrontendApiError<CommonBusinessError>): void => {
        if (error.data.type === CommonBusinessErrorType.RequireSMSConfirmation) {
          setShouldShowSmsCode(true);
        } else {
          addToast({ content: t(error.message) });
        }
      })
      .finally((): void => setApiRequestEnd());
  };

  const mappedDeliveriesToOptionSwitch: OptionSwitchOption[] = useMemo(
    (): OptionSwitchOption[] => subscriptionDetails?.plan.deliveries.map(transformSubscriptionDeliveryCostToOptionSwitchOption) ?? [],
    [subscriptionDetails?.plan.deliveries]
  );

  return (
    <Container>
      {shouldShowSmsCode ? (
        <>
          <PageHeader header={t('chic.website.subscriptionEditDeliveryView.smsCodeHeader')} onArrowButtonAction={hideSmsCodeView} />
          <StyledContentBox>
            <Formik
              initialValues={{ smsCode: '' }}
              onSubmit={({ smsCode }: ConfirmContactDataParams): void => onSubmitConfirmContactData({ smsCode }, id)}
              validationSchema={smsFormSchema}
              validateOnChange={false}
              validateOnBlur={isFormSubmitted}
            >
              {({ handleSubmit, setFieldValue, errors }: FormikProps<ConfirmContactDataParams>): JSX.Element => (
                <FormikForm onSubmit={handleSubmit}>
                  <Input
                    label={t('chic.website.subscriptionEditDeliveryView.smsCode')}
                    placeholder={t('chic.website.subscriptionEditDeliveryView.enterSmsCode')}
                    type='text'
                    onChange={(value: string): void => setFieldValue('smsCode', value)}
                    message={t(errors.smsCode ?? '')}
                    inputTheme={errors.smsCode ? InputTheme.Error : InputTheme.Standard}
                  />
                  <ButtonsContainer>
                    <StyledButton
                      label={t('chic.website.global.save')}
                      type='submit'
                      onClick={setFormSubmitted}
                      fullWidth={isMobile}
                      isLoading={isSmsButtonLoading}
                    />
                    <StyledButton
                      label={t('chic.website.global.cancel')}
                      onClick={hideSmsCodeView}
                      buttonTheme={ButtonTheme.TextWhite}
                      fullWidth={isMobile}
                    />
                  </ButtonsContainer>
                </FormikForm>
              )}
            </Formik>
          </StyledContentBox>
        </>
      ) : (
        <>
          <PageHeader
            header={t('chic.website.meta.subscriptionEditDelivery.title')}
            headerPosition={PageHeaderPosition.Center}
            onArrowButtonAction={(): void => goBack(id)}
          />
          <StyledContentBox>
            <SectionTitle>{t('chic.website.subscriptionEditDeliveryView.deliveryMethod')}</SectionTitle>
            <OptionsSwitch
              options={mappedDeliveriesToOptionSwitch}
              onOptionChange={(name: string): void => setSelectedDelivery(name as SubscriptionDeliveryCode)}
              activeOptionName={selectedDelivery}
            />
          </StyledContentBox>
          {[SubscriptionDeliveryCode.InpostMachine, SubscriptionDeliveryCode.PickupPoint].includes(selectedDelivery) && (
            <StyledContentBox>
              <SectionTitle>
                {t('chic.website.subscriptionEditDeliveryView.deliveryAddress')}
                <Button
                  label={selectedDelivery !== subscriptionDetails?.deliveryType.code
                    ? t('chic.website.subscriptionEditDeliveryView.addDelivery')
                    : t('chic.website.subscriptionEditDeliveryView.changeDelivery')}
                  icon={IconName.Edit}
                  buttonTheme={ButtonTheme.TextOrange}
                  onClick={(): void => redirectToChooseDeliveryPoint(id ?? '', selectedDelivery, pointId)}
                />
              </SectionTitle>
              <SectionTitleLine />
              <ParcelData>
                {(parcelLockerData && selectedDelivery === SubscriptionDeliveryCode.InpostMachine) && (
                  getParcelLockerAddress(parcelLockerData).map((address: string, index: number): JSX.Element => (
                    <ParcelAddress key={index}>{address}</ParcelAddress>
                  ))
                )}
                {(pickupPointData && selectedDelivery === SubscriptionDeliveryCode.PickupPoint) && (
                  getPickupPointAddress(pickupPointData).map((address: string, index: number): JSX.Element => (
                    <ParcelAddress key={index}>{address}</ParcelAddress>
                  ))
                )}
              </ParcelData>

            </StyledContentBox>
          )}
          <StyledContentBox>
            <Formik
              initialValues={{
                name: subscriptionDetails?.deliveryDestination.name ?? '',
                address: subscriptionDetails?.deliveryDestination.address ?? '',
                postalCode: subscriptionDetails?.deliveryDestination.postalCode ?? '',
                city: subscriptionDetails?.deliveryDestination.city ?? '',
                email: subscriptionDetails?.email ?? '',
                phone: subscriptionDetails?.phone ?? '',
              }}
              onSubmit={onSubmit}
              validationSchema={deliveryUpdateFormSchema}
              validateOnChange={isFormSubmitted}
              validateOnBlur={isFormSubmitted}
              enableReinitialize
            >
              {({ handleSubmit, setFieldValue, errors, values }: FormikProps<UpdateSubscriptionDestinationBasicData>): JSX.Element => (
                <FormikForm onSubmit={handleSubmit}>
                  <SectionTitle>
                    {[SubscriptionDeliveryCode.InpostMachine, SubscriptionDeliveryCode.PickupPoint].includes(selectedDelivery)
                      ? t('chic.website.subscriptionEditDeliveryView.billingAddress')
                      : t('chic.website.subscriptionEditDeliveryView.deliveryAddress')
                    }
                  </SectionTitle>
                  {subscriptionDeliveryFields.map((row: FormRow<SubscriptionDeliveryField>, index: number): JSX.Element => (
                    <>
                      {row.title && <SectionTitle>{t(row.title)}</SectionTitle>}
                      <StyledFieldsRow key={index}>
                        {row.fields.map((field: FormField<SubscriptionDeliveryField>): JSX.Element => (
                          <StyledInput
                            label={t(field.label)}
                            type='text'
                            onChange={(value: string): void => setFieldValue(field.name, value)}
                            value={values[field.name]}
                            inputTheme={errors[field.name] ? InputTheme.Error : InputTheme.Standard}
                            message={errors[field.name]}
                            description={field.description ? t(field.description) : undefined}
                            hasPhonePrefix={field.hasPhonePrefix}
                          />
                        ))}
                      </StyledFieldsRow>
                    </>
                  ))}
                  <ButtonsContainer>
                    <StyledButton
                      type='submit'
                      onClick={setFormSubmitted}
                      label={t('chic.website.subscriptionEditDeliveryView.save')}
                      buttonTheme={ButtonTheme.PrimaryOrange}
                      isLoading={isApiRequestInProgress}
                      fullWidth={isTablet}
                      disabled={(
                        selectedDelivery === SubscriptionDeliveryCode.InpostMachine
                        && !parcelMachineId
                        && !subscriptionDetails?.deliveryDestination.inpostId
                      ) || (
                        selectedDelivery === SubscriptionDeliveryCode.PickupPoint
                        && !pickupPointId
                        && !subscriptionDetails?.deliveryDestination.pickupPointId
                      )}
                    />
                    <StyledButton
                      label={t('chic.website.subscriptionEditDeliveryView.cancel')}
                      buttonTheme={ButtonTheme.TextWhite}
                      onClick={(): void => goBack(id)}
                      fullWidth={isTablet}
                    />
                  </ButtonsContainer>
                </FormikForm>
              )}
            </Formik>
          </StyledContentBox>
        </>
      )}
      <StyledMailToUs
        title={t('chic.website.global.mailToUs.title')}
        actionLabel={t('chic.website.global.mailToUs.actionLabel')}
        actionSettings={{ internalPath: getRouteDetailsByName(RouteNameEnum.SubscriptionContact)?.url ?? '' }}
        mailToUsTheme={MailToUsTheme.Gray}
      />
    </Container>
  );
};
