import { AuthReducerAction } from '@chic/frontend/enums';
import { AuthReducerActions } from '@chic/frontend/types';
import { AuthContextState } from '@chic/shared/interfaces';

export const authReducer = (state: AuthContextState, action: AuthReducerActions): AuthContextState => {
  switch (action.type) {
    case AuthReducerAction.SignIn:
      return { ...state, ...action.payload };
    case AuthReducerAction.SignOut:
      return { token: null, userData: null };
    case AuthReducerAction.UpdateUserData:
      return {
        ...state,
        userData: {
          ...state.userData!,
          ...action.payload
        }
      };
    default:
      return state;
  }
};
