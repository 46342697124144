import { StyledComponent, ContentBox, Input, Dropdown, Button, ValidationBar, Color, Breakpoint } from '@chic-loyalty/ui';
import { Form } from 'formik';
import styled from 'styled-components';

import { StyledButtonProps } from './yourData.types';

export const Container: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: calc(100% - 80px);
  background-color: ${Color.AlmostBlack};
  margin-left: auto;

  @media ${Breakpoint.Mobile} {
    width: 100%;
  }
`;

export const ContentWrapper: StyledComponent<'div'> = styled.div`
  min-height: 100vh;
  width: 100%;
  padding: 0 20px;

  @media ${Breakpoint.Tablet} {
    padding: 0;
  }

  @media ${Breakpoint.Mobile} {
    min-height: auto;
  }
`;

export const StyledContentBox: StyledComponent<typeof ContentBox> = styled(ContentBox)`
  max-width: 900px;
  margin: 70px auto;

  @media ${Breakpoint.Tablet} {
    background: ${Color.Transparent};
    margin: 0;
  }
`;

export const FormikForm: StyledComponent<typeof Form> = styled(Form)`
  width: 100%;
`;

export const StyledInput: StyledComponent<typeof Input> = styled(Input)`
  & + & {
    margin-top: 10px;
  }
`;

export const StyledDropdown: StyledComponent<typeof Dropdown> = styled(Dropdown)`
  & + &,
  & + ${StyledInput},
  ${StyledInput} + & {
    margin-top: 10px;
  }
`;

export const StyledButton: StyledComponent<typeof Button, StyledButtonProps> = styled(Button)`
  & + & {
    margin-left: 10px;

    @media ${Breakpoint.Mobile} {
      margin-top: 10px;
      margin-left: 0;
    }
  }
`;

export const SmsBox: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 712px;
  margin: 50px auto 70px;

  @media ${Breakpoint.Tablet} {
    padding: 0 20px;
  }

  @media ${Breakpoint.Mobile} {
    padding: 0 20px;
  }
`;

export const ButtonsContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  margin-top: 35px;

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
    margin-top: 30px;
  }
`;

export const StyledValidationBar: StyledComponent<typeof ValidationBar> = styled(ValidationBar)`
  margin-top: 30px;
`;
