import {
  Breakpoint,
  Country,
  IconName,
  Input,
  InputTheme,
  PageHeader,
  UseFormikForm,
  useFormikForm,
  useNotifications,
  UseNotifications,
  UseState,
} from '@chic-loyalty/ui';
import { Formik, FormikProps } from 'formik';
import React, { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { initPasswordRecoveryRequest } from '@chic/frontend/api/requests';
import { FullscreenAlertAssets } from '@chic/frontend/enums';
import { RouteNameEnum, UserContactDataType } from '@chic/shared/enums';
import { getRouteDetailsByName } from '@chic/shared/utils';

import { RemindPasswordFormTabName } from './remindPassword.enums';
import { useRemindPasswordValidation } from './remindPassword.hooks';
import {
  Container,
  FormikForm,
  StyledButton,
  StyledContentBox,
  StyledTabSwitch,
} from './remindPassword.styled';
import { PasswordRecoveryData } from './remindPassword.types';

export const RemindPasswordView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const isTablet: boolean = useMediaQuery({ query: Breakpoint.Tablet });
  const [phonePrefixCountry, setPhonePrefixCountry]: UseState<Country> = useState<Country>(Country.Poland);
  const [activeTab, setActiveTab]: UseState<RemindPasswordFormTabName> = useState<RemindPasswordFormTabName>(
    RemindPasswordFormTabName.Email
  );
  const RemindPasswordValidationSchema: Yup.SchemaOf<PasswordRecoveryData> = useRemindPasswordValidation(
    activeTab === RemindPasswordFormTabName.PhoneNumber, phonePrefixCountry
  );
  const { 
    setFormSubmitted, 
    isFormSubmitted, 
    isApiRequestInProgress, 
    setApiRequestStart, 
    setApiRequestEnd 
  }: UseFormikForm = useFormikForm();
  const navigate: NavigateFunction = useNavigate();
  const { showFullscreenAlert, hideFullscreenAlert }: UseNotifications = useNotifications();

  const initPasswordRecoveryAction: (data: PasswordRecoveryData) => void = (data: PasswordRecoveryData): void => {
    setApiRequestStart();
    initPasswordRecoveryRequest({
      type: activeTab === RemindPasswordFormTabName.Email ? UserContactDataType.Email : UserContactDataType.Mobile,
      value: data.contact
    })
      .then((): void => {
        showFullscreenAlert({
          title: t('chic.website.remindPasswordView.success.title'),
          description: t('chic.website.remindPasswordView.success.description'),
          image: FullscreenAlertAssets.WomanWithPhone,
          acceptButtonSettings: {
            label: t('chic.website.remindPasswordView.success.buttonLabel'),
            action: (): void => {
              hideFullscreenAlert();
              navigate(getRouteDetailsByName(RouteNameEnum.Home)?.url ?? '/');
            }
          },
        });
      })
      // TODO: add logger
      .catch((): void => undefined)
      .finally((): void => setApiRequestEnd());
  };

  return (
    <Container>
      <PageHeader
        header={t('chic.website.remindPasswordView.resetYourPassword')}
        subheader={t('chic.website.remindPasswordView.subheader')}
        onArrowButtonAction={(): void => navigate(getRouteDetailsByName(RouteNameEnum.SignIn)?.url ?? '/')}
      />
      <StyledContentBox>
        <StyledTabSwitch
          header={t('chic.website.remindPasswordView.resetMethod')}
          tabs={[
            {
              name: RemindPasswordFormTabName.Email,
              labels: [t('chic.website.remindPasswordView.email')],
              icon: IconName.SmallEmail
            },
            {
              name: RemindPasswordFormTabName.PhoneNumber,
              labels: [t('chic.website.remindPasswordView.phoneNumber')],
              icon: IconName.SmallForm
            },
          ]}
          initialTabName={RemindPasswordFormTabName.Email}
          onTabChange={(tabName: string): void => setActiveTab(tabName as RemindPasswordFormTabName)}
        />
        <Formik
          initialValues={{ contact: '' }}
          onSubmit={initPasswordRecoveryAction}
          validationSchema={RemindPasswordValidationSchema}
          validateOnChange={isFormSubmitted}
          validateOnBlur={isFormSubmitted}
        >
          {({ handleSubmit, setFieldValue, errors }: FormikProps<PasswordRecoveryData>) => (
            <FormikForm onSubmit={handleSubmit}>
              <Input
                label={activeTab === RemindPasswordFormTabName.Email
                  ? t('chic.website.remindPasswordView.email')
                  : t('chic.website.remindPasswordView.phoneNumber')
                }
                placeholder={activeTab === RemindPasswordFormTabName.Email
                  ? t('chic.website.remindPasswordView.emailPlaceholder')
                  : t('chic.website.remindPasswordView.phoneNumberPlaceholder')
                }
                onChange={(value: string): void => setFieldValue('contact', value)}
                onBlur={(value: string): void => setFieldValue('contact', value)}
                hasPhonePrefix={activeTab === RemindPasswordFormTabName.PhoneNumber}
                onChangePhonePrefix={setPhonePrefixCountry}
                message={t(errors.contact ?? '')}
                inputTheme={errors.contact ? InputTheme.Error : InputTheme.Standard}
              />
              <StyledButton
                label={t('chic.website.remindPasswordView.resetYourPassword')}
                type='submit'
                onClick={setFormSubmitted}
                fullWidth={isTablet}
                isLoading={isApiRequestInProgress}
              />
            </FormikForm>
          )}
        </Formik>
      </StyledContentBox>
    </Container>
  );
};
