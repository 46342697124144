import { Breakpoint, Button, Color, ContentBox, Input, StyledComponent } from '@chic-loyalty/ui';
import styled from 'styled-components';

import { ContainerProps } from './setPassword.types';

export const Container: StyledComponent<'div', ContainerProps> = styled.div<ContainerProps>`
  background-color: ${Color.AlmostBlack};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${({ $withLeftPadding }: ContainerProps): string | false => $withLeftPadding && 'padding-left: 80px'};

  @media ${Breakpoint.Mobile} {
    padding-left: 0;
  }
`;

export const FormikForm: StyledComponent<'form'> = styled.form`
  width: 100%;
`;

export const StyledButton: StyledComponent<typeof Button> = styled(Button)`
  margin-top: 50px;
`;

export const StyledContentBox: StyledComponent<typeof ContentBox> = styled(ContentBox)`
  margin: 70px auto;

  @media ${Breakpoint.Tablet} {
    margin: 0 auto;
  }
`;

export const StyledInput: StyledComponent<typeof Input> = styled(Input)`
  & + & {
    margin-top: 10px;
  }
`;
