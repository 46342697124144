export enum ErrorTypeEnum {
  BadRequest = 'badRequest',
  InternalError = 'internalError',
  ValidationError = 'validationError',
  LoginRequired = 'loginRequired',
  AccessDenied = 'accessDenied',
  AuthorizationFailed = 'authorizationFailed',
  NotFound = 'notFound',
  NotImplemented = 'notImplemented',
  Conflict = 'conflict'
}
