import { Color, StyledComponent, TopHeader } from '@chic-loyalty/ui';
import styled from 'styled-components';

import { ChildrenContainerProps, StyledTopHeaderProps } from './appWrapper.types';

export const Container: StyledComponent<'div'> = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${Color.AlmostBlack};
`;

export const ChildrenContainer: StyledComponent<'div', ChildrenContainerProps> = styled.div<ChildrenContainerProps>`
  margin-top: ${({ $isHeaderTransparent }: ChildrenContainerProps): string => $isHeaderTransparent ? '-74px' : '0'};
  padding-top: 74px;
`;

export const StyledTopHeader: StyledComponent<typeof TopHeader, StyledTopHeaderProps> = styled(TopHeader)<StyledTopHeaderProps>`
  position: fixed;
  top: ${({ $top }: StyledTopHeaderProps): number => $top}px;
  left: 0;
  right: 0;
  transition: 0.3s;
`;
