import { AxiosResponse } from 'axios';

import { FrontendApiError } from '@chic/frontend/models';
import { ApiReject, ApiResolve } from '@chic/frontend/types';
import { SendContactFormRequest } from '@chic/shared/models';

import { api } from '../api';

export const generalContactFormRequest: (data: SendContactFormRequest) => Promise<void> = (
  data: SendContactFormRequest
): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.post('/contact/general', data).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const subscriptionContactFormRequest: (data: SendContactFormRequest) => Promise<void> = (
  data: SendContactFormRequest
): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.post('/contact/subscription', data).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);
