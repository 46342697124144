import { ContentBoxTheme, PageHeader, SimpleTable } from '@chic-loyalty/ui';
import React, { useEffect, useMemo, useRef } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { useAuth } from '@chic/frontend/hooks';
import { UseAuth } from '@chic/frontend/interfaces';
import { UseSearchParams } from '@chic/frontend/types';
import { FrontendUrlsParams } from '@chic/shared/enums';

import { useCookiesPolicy } from './cookiesPolicy.hooks';
import { 
  CheckboxesContainer,
  Container, 
  ContentBoxesContainer, 
  ContentText, 
  ContentWrapper, 
  OptionsContainer, 
  SimpleTableContainer, 
  StyledAccordionItem, 
  StyledCheckbox, 
  StyledContentBox, 
  Text, 
  TextSection 
} from './cookiesPolicy.styled';
import { UseCookiesPolicy } from './cookiesPolicy.types';

export const CookiesPolicyView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { 
    cookiesTable, 
    localStorageTable, 
    cookiesPolicyAccepted, 
    changeCookiesPolicyAcceptedAction,
    topContentTextsConfig,
    bottomContentTextsConfig
  }: UseCookiesPolicy = useCookiesPolicy();
  const { userData }: UseAuth = useAuth();
  const [searchParams]: UseSearchParams = useSearchParams();
  const optionsRef: React.Ref<HTMLDivElement> = useRef<HTMLDivElement>(null);

  const slideToOptions: boolean = useMemo(
    (): boolean => {
      return searchParams.get(FrontendUrlsParams.SlideToOptions) === 'true';
    },
    [searchParams]
  );

  useEffect(
    (): void => {
      if (optionsRef && slideToOptions) {
        setTimeout((): void => optionsRef.current?.scrollIntoView({ behavior: 'smooth' }), 500);
      }
    },
    [slideToOptions, optionsRef]
  );
 
  return (
    <Container $withLeftPadding={!!userData}>
      <PageHeader header={t('chic.website.cookiesPolicyView.pageHeader')} />
      <ContentWrapper>
        <ContentBoxesContainer>
          {topContentTextsConfig.map((text: string, index: number): JSX.Element => (
            <StyledContentBox contentBoxTheme={ContentBoxTheme.Small} key={`${text.slice(0, 15)}-${index}`}>
              <ContentText text={t(text)} />
            </StyledContentBox>
          ))}
        </ContentBoxesContainer>
        <TextSection>
          <Text text={t('chic.website.cookiesPolicyView.textSection.first')} />
        </TextSection>
        <SimpleTableContainer>
          <SimpleTable rows={cookiesTable} />
        </SimpleTableContainer>
        <TextSection>
          <Text text={t('chic.website.cookiesPolicyView.textSection.second')} />
        </TextSection>
        <SimpleTableContainer>
          <SimpleTable rows={localStorageTable} />
        </SimpleTableContainer>
        <ContentBoxesContainer>
          {bottomContentTextsConfig.map((text: string, index: number): JSX.Element => (
            <StyledContentBox contentBoxTheme={ContentBoxTheme.Small} key={`${text.slice(0, 15)}-${index}`}>
              <ContentText text={t(text)} />
            </StyledContentBox>
          ))}
        </ContentBoxesContainer>
        <OptionsContainer ref={optionsRef}>
          <StyledAccordionItem 
            label={t('chic.website.cookiesPolicyView.accordionItem.label')} 
            children={
              <CheckboxesContainer>
                <StyledCheckbox 
                  id='marketing'
                  onChange={changeCookiesPolicyAcceptedAction}
                  checked={cookiesPolicyAccepted}
                  label={t('chic.website.cookiesPolicyView.checkboxesContainer.marketing.label')} 
                  description={t('chic.website.cookiesPolicyView.checkboxesContainer.marketing.content')}
                />
                <StyledCheckbox 
                  id='technical'
                  disabled
                  checked
                  label={t('chic.website.cookiesPolicyView.checkboxesContainer.technical.label')} 
                  description={t('chic.website.cookiesPolicyView.checkboxesContainer.technical.content')}
                />
              </CheckboxesContainer>
            }
            isOpen={slideToOptions}
          />
        </OptionsContainer>
      </ContentWrapper>
    </Container>
  );
};
