import { AxiosResponse } from 'axios';
import { stringify } from 'query-string';

import { FrontendApiError } from '@chic/frontend/models';
import { ApiReject, ApiResolve } from '@chic/frontend/types';
import { PayuCreditCardOrderStatus } from '@chic/shared/enums';
import {
  CreditCard,
  CreditCardAddRequestResponse,
  ListPaginationInterface,
  ListPaginationParams,
  PaymentInfo
} from '@chic/shared/interfaces';
import { AddNewCreditCardRequest } from '@chic/shared/models';

import { api } from '../api';

export const getUserPaymentsHistory: (params: ListPaginationParams) => Promise<ListPaginationInterface<PaymentInfo>> = (
  params: ListPaginationParams
): Promise<ListPaginationInterface<PaymentInfo>> => new Promise(
  (resolve: ApiResolve<ListPaginationInterface<PaymentInfo>>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get(`/payments/history?${stringify(params, { skipEmptyString: true })}`).then(
      (response: AxiosResponse<ListPaginationInterface<PaymentInfo>>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getUserCreditCards: () => Promise<CreditCard[]> = (): Promise<CreditCard[]> => new Promise(
  (resolve: ApiResolve<CreditCard[]>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get('/payments/cards').then(
      (response: AxiosResponse<CreditCard[]>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getPayuCardAddRequestStatus: (cardId: number) => Promise<PayuCreditCardOrderStatus> = (
  cardId: number
): Promise<PayuCreditCardOrderStatus> => new Promise(
  (resolve: ApiResolve<PayuCreditCardOrderStatus>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get(`/payments/cards/${cardId}/add-request-info`).then(
      (response: AxiosResponse<PayuCreditCardOrderStatus>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const addNewCreditCard: (data: AddNewCreditCardRequest) => Promise<CreditCardAddRequestResponse> = (
  data: AddNewCreditCardRequest
): Promise<CreditCardAddRequestResponse> => new Promise(
  (resolve: ApiResolve<CreditCardAddRequestResponse>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.post('/payments/cards', data).then(
      (response: AxiosResponse<CreditCardAddRequestResponse>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const removeCreditCard: (cardId: number) => Promise<CreditCard[]> = (
  cardId: number
): Promise<CreditCard[]> => new Promise(
  (resolve: ApiResolve<CreditCard[]>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.delete(`/payments/cards/${cardId}`).then(
      (response: AxiosResponse<CreditCard[]>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);
