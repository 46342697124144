import { Color, IconName } from '@chic-loyalty/ui';
import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { FileFromViews } from '@chic/frontend/enums';
import { useAuth } from '@chic/frontend/hooks';
import { NotFoundMenuPositions, UseAuth } from '@chic/frontend/interfaces';

import { menuPositions } from './configs/menuPositions.config';
import { 
  BannerSection, 
  Container, 
  Navigation, 
  Title, 
  Image,
  ImageContainer, 
  TabButton, 
  NavigationText,
  StyledIcon,
  TabsWrapper, 
} from './notFound.styled';

export const NotFoundView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { isUserLoggedIn }: UseAuth = useAuth();

  return (
    <Container $isUserLoggedIn={isUserLoggedIn()}>
      <BannerSection>
        <ImageContainer>
          <Image src={FileFromViews.NotFound404} />
        </ImageContainer>
        <Title>{t('chic.website.notFoundView.notFound')}</Title>
      </BannerSection>
      <Navigation>
        <TabsWrapper>
          <NavigationText>{t('chic.website.notFoundView.navigationText')}</NavigationText>
          {menuPositions
            .filter((position: NotFoundMenuPositions): boolean => isUserLoggedIn() 
              ? !position.shouldDisplayOnlyForGuests 
              : !!position.shouldDisplayAlways || !!position.shouldDisplayOnlyForGuests)
            .map((position: NotFoundMenuPositions, index: number): JSX.Element => (
              <TabButton key={`${position.label ?? ''}-${index}`} {...position}>
                <StyledIcon name={position.icon ?? IconName.Home} size={24} color={Color.SolidLowEmphasis} />
                {t(position.label ?? '')}
              </TabButton>
            ))
          }
        </TabsWrapper>
      </Navigation>
    </Container>
  );
};
