import { HeroSlide, UseRedirect, useRedirect } from '@chic-loyalty/ui';

import { FileFromViews } from '@chic/frontend/enums';
import { useAltercloudRedirect, useRequiredActions } from '@chic/frontend/hooks';
import { UseAltercloudRedirect, UseRequiredActions } from '@chic/frontend/interfaces';
import { RouteNameEnum } from '@chic/shared/enums';
import { getRouteDetailsByName } from '@chic/shared/utils';

export const useDashboardHeroSlides: () => HeroSlide[] = (): HeroSlide[] => {
  const { handleAltercloudRedirect }: UseAltercloudRedirect = useAltercloudRedirect();
  const { redirect }: UseRedirect = useRedirect();
  const { migrationAction }: UseRequiredActions = useRequiredActions();

  return [
    {
      image: FileFromViews.DashboardHeroSlide1,
      mobileImage: FileFromViews.DashboardHeroSlide1Mobile,
      actionData: { action: migrationAction }
    },
    {
      image: FileFromViews.DashboardHeroSlide2,
      mobileImage: FileFromViews.DashboardHeroSlide2Mobile,
      actionData: {
        action: (): void => redirect(getRouteDetailsByName(RouteNameEnum.SinglePost)?.url ?? '', { id: 23 }),
      }
    },
    {
      image: FileFromViews.DashboardHeroSlide3,
      mobileImage: FileFromViews.DashboardHeroSlide3Mobile,
    },
    {
      image: FileFromViews.DashboardHeroSlide4,
      mobileImage: FileFromViews.DashboardHeroSlide4Mobile,
      actionData: {
        action: (): void => handleAltercloudRedirect()
      }
    },
  ];
};
