import { AxiosResponse } from 'axios';
import { stringify } from 'query-string';

import { FrontendApiError } from '@chic/frontend/models';
import { ApiReject, ApiResolve } from '@chic/frontend/types';
import { GetParcelLockersParams, ParcelLocker } from '@chic/shared/interfaces';

import { api } from '../api';

export const getParcelLockerDetailsById: (id: number) => Promise<ParcelLocker>= (
  id: number
): Promise<ParcelLocker> => new Promise(
  (resolve: ApiResolve<ParcelLocker>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get(`/inpost/parcellockers-by-id/${id}`).then(
      (response: AxiosResponse<ParcelLocker>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getParcelLockerDetailsByExternalId: (id: string) => Promise<ParcelLocker>= (
  id: string
): Promise<ParcelLocker> => new Promise(
  (resolve: ApiResolve<ParcelLocker>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get(`/inpost/parcellockers-by-external/${id}`).then(
      (response: AxiosResponse<ParcelLocker>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getParcelLockersList: (params: Partial<GetParcelLockersParams>) => Promise<ParcelLocker[]>= (
  params: Partial<GetParcelLockersParams>
): Promise<ParcelLocker[]> => new Promise(
  (resolve: ApiResolve<ParcelLocker[]>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get(`/inpost?${stringify(params, { skipEmptyString: true })}`).then(
      (response: AxiosResponse<ParcelLocker[]>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);
