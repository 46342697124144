import { IsDefined, IsEmail, IsEnum, IsNumber, IsOptional, IsString, Matches } from 'class-validator';

import { postalCodeRegexp } from '@chic/shared/constants';
import { PreferredLanguage } from '@chic/shared/enums';

export class RegisterWithoutPasswordRequest<AgreementsType = number> {
  @IsDefined()
  @IsString()
  public firstName: string;

  @IsDefined()
  @IsString()
  public lastName: string;

  @IsDefined()
  @IsString()
  public phone: string;

  @IsDefined()
  @IsEmail()
  public email: string;

  @IsDefined()
  @IsString()
  public birthday: string;

  @IsDefined()
  @IsEnum(PreferredLanguage)
  public preferredLanguage: PreferredLanguage;

  // agreement - value in number because we are sending versions, not decision (below the same)
  @IsDefined()
  @IsNumber()
  public declarationOfAge: AgreementsType;

  @IsDefined()
  @IsNumber()
  public personalDataAgreement: AgreementsType;

  @IsOptional()
  @IsString()
  public smsCode?: string;

  @IsOptional()
  @IsNumber()
  public marketingAgreement?: AgreementsType;

  @IsOptional()
  @IsNumber()
  public marketingAdditionalAgreement?: AgreementsType;

  @IsOptional()
  @IsNumber()
  public marketingSocialAgreement?: AgreementsType;

  @IsOptional()
  @IsNumber()
  public phoneSurveyAgreement?: AgreementsType;

  @IsOptional()
  @IsString()
  public mgmCode?: string;

  @IsOptional()
  @Matches(postalCodeRegexp)
  public postalCode?: string;

  constructor(
    firstName: string,
    lastName: string,
    phone: string,
    email: string,
    birthday: string,
    preferredLanguage: PreferredLanguage,
    declarationOfAge: AgreementsType,
    personalDataAgreement: AgreementsType,
    smsCode?: string,
    marketingAgreement?: AgreementsType,
    marketingAdditionalAgreement?: AgreementsType,
    marketingSocialAgreement?: AgreementsType,
    phoneSurveyAgreement?: AgreementsType,
    mgmCode?: string,
    postalCode?: string,
  ) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.phone = phone;
    this.email = email;
    this.birthday = birthday;
    this.preferredLanguage = preferredLanguage;
    this.declarationOfAge = declarationOfAge;
    this.personalDataAgreement = personalDataAgreement;
    this.smsCode = smsCode;
    this.marketingAgreement = marketingAgreement;
    this.marketingAdditionalAgreement = marketingAdditionalAgreement;
    this.marketingSocialAgreement = marketingSocialAgreement;
    this.phoneSurveyAgreement = phoneSurveyAgreement;
    this.mgmCode = mgmCode;
    this.postalCode = postalCode;
  }
}
