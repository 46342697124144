import { IsString, IsBoolean, IsOptional } from 'class-validator';

export class UpdateSubscriptionDeliveryDateRequest {
  @IsOptional()
  @IsString()
  public deliveryDate?: string;

  @IsOptional()
  @IsBoolean()
  public createPayment?: boolean;

  constructor(
    deliveryDate: string,
    createPayment: boolean,
  ) {
    this.deliveryDate = deliveryDate;
    this.createPayment = createPayment;
  }
}
