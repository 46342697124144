import {
  AdPopup,
  AgeConfirmationScreen,
  CookiesInformationBox,
  Footer,
  HealthWarning,
  MenuPositionData,
  SlideMenu,
  SlideMenuPosition,
  TopHeaderTheme,
  UseLocalStorage,
  useLocalStorage,
  useNotifications,
  UseNotifications,
  UseRedirect,
  useRedirect,
  UseState
} from '@chic-loyalty/ui';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { Location } from 'history';
import React, { useEffect, useMemo, useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { TransProps, useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';
import Cookies from 'universal-cookie';

import { getConfig, getMessages, sendStatsForMessages } from '@chic/frontend/api/requests';
import { appConfig } from '@chic/frontend/app.config';
import { pathsAllowedInEscLimitedMode } from '@chic/frontend/constans';
import { LocalStorageKey, QueryKey } from '@chic/frontend/enums';
import { useAuth, useFirebase, useRequiredActions, useServiceWorker } from '@chic/frontend/hooks';
import { UseAuth, UseFirebase, UseRequiredActions, UseServiceWorker } from '@chic/frontend/interfaces';
import { UseSearchParams } from '@chic/frontend/types';
import { CookiesName, FrontendUrlsParams, RequiredActionType, RouteNameEnum } from '@chic/shared/enums';
import {
  SubscriptionMigrationData,
  InjectedWindowValues,
  Message,
  MessagesListPagination,
  RequiredAction,
  RouteInfo,
  SubscriptionOutOfStockData,
} from '@chic/shared/interfaces';
import { RequiredActionTypes, SystemConfig } from '@chic/shared/types';
import { getRouteDetailsByName, getRouteDetailsByUrl } from '@chic/shared/utils';

import { ChildrenContainer, Container, StyledTopHeader } from './appWrapper.styled';
import { AppWrapperProps, UseAddPopupsConfigs } from './appWrapper.types';
import {
  footerMenuPositions,
  pathsWithHeaderMenuElevationLevel1Theme,
  pathsWithHeaderMenuTransparentTheme,
  pathsWithoutAddPopup,
} from './configs';
import { signedUserFooterMenuPositions } from './configs/footerMenuPositions.config';
import { useAddPopupsConfigs, useSlideMenuPositions } from './hooks';

declare const window: InjectedWindowValues;

export const AppWrapper: React.FC<AppWrapperProps> = (props: AppWrapperProps): JSX.Element => {
  const { children }: AppWrapperProps = props;
  const { t }: TransProps<never> = useTranslation();
  const location: Location = useLocation();
  const pathObject: RouteInfo | undefined = getRouteDetailsByUrl(location.pathname);
  const { userData, token, signOut, isUserLoggedIn }: UseAuth = useAuth();
  const { adPopupAdvertisements, isAdPopupVisible, closeAdPopup }: UseAddPopupsConfigs = useAddPopupsConfigs();
  const [cookiesAccepted, setCookiesAccepted]: UseLocalStorage<boolean> = useLocalStorage<boolean>(
    LocalStorageKey.CookiesPolicyAccepted, false, true
  );
  const [ageVerifyConfirmed, setAgeVerifyConfirmed]: UseLocalStorage<boolean> = useLocalStorage<boolean>(
    LocalStorageKey.AgeVerifyConfirmed, false, true
  );
  const [topHeaderTopOffset, setTopHeaderTopOffset]: UseState<number> = useState<number>(0);
  const [topHeaderTheme, setTopHeaderTheme]: UseState<TopHeaderTheme | undefined> = useState<TopHeaderTheme | undefined>(undefined);
  const scrollY: React.MutableRefObject<number> = React.useRef<number>(0);
  const { redirect }: UseRedirect = useRedirect();
  const {
    subscriptionOutOfStockAction,
    agreementsUpdateRequiredAction,
    upgradeSubscriptionPlanAction,
    migrationToIcPopupRequiredAction,
  }: UseRequiredActions = useRequiredActions();
  const cookies: Cookies = useMemo((): Cookies => new Cookies(), []);
  const { initializeFirebaseOnMessage }: UseFirebase = useFirebase();
  const { register }: UseServiceWorker = useServiceWorker();
  const [searchParams, setSearchParams]: UseSearchParams = useSearchParams();
  const slideMenuPositions: SlideMenuPosition[] = useSlideMenuPositions();
  const { addToast }: UseNotifications = useNotifications();

  const { data: messagesData }: UseQueryResult<MessagesListPagination<Message>> = useQuery(
    [QueryKey.Messages],
    (): Promise<MessagesListPagination<Message>> => getMessages({ limit: 50, offset: 0 }),
    {
      enabled: !!userData,
    }
  );

  const { data: systemConfig, isFetched: systemConfigIsReady }: UseQueryResult<SystemConfig> = useQuery(
    [QueryKey.Config],
    (): Promise<SystemConfig> => getConfig(),
  );

  const visibleFooterPositions: MenuPositionData[] = useMemo((): MenuPositionData[] => {
    return [...footerMenuPositions, ...(token ? signedUserFooterMenuPositions : [])]
      .filter((position: MenuPositionData): boolean => {
        if (systemConfig?.escLoyaltyProgramLimited) {
          return pathsAllowedInEscLimitedMode.includes(position?.name as RouteNameEnum);
        }

        return true;
      })
      .map((position: MenuPositionData): MenuPositionData => ({
        ...position,
        label: t(position.label ?? '')
      }));
  }, [token, systemConfig]);

  useEffect(
    (): void => {
      initializeFirebaseOnMessage(isUserLoggedIn());
      register();
    },
    []
  );

  useEffect(
    (): void => {
      const notificationId: string | null = searchParams.get(FrontendUrlsParams.NotificationId);
      searchParams.delete(FrontendUrlsParams.NotificationId);
      setSearchParams(searchParams);
      if (notificationId && !isNaN(Number(notificationId))) {
        void sendStatsForMessages({ messageId: Number(notificationId), clicked: true, readed: true });
      }
    },
    [searchParams]
  );

  useEffect(
    (): void => {
      window.scrollTo(0, 0);

      if (pathObject) {
        document.title = `${t(pathObject.title)} | ${appConfig.applicationName}`;
      }
    },
    [location.pathname]
  );

  useEffect(
    (): () => void => {
      if (window.scrollY > 120) {
        setTopHeaderTopOffset(-74);
      }

      if (pathObject && pathsWithHeaderMenuTransparentTheme.includes(pathObject.name)) {
        setTopHeaderTheme(TopHeaderTheme.Transparent);
      } else if (pathObject && pathsWithHeaderMenuElevationLevel1Theme.includes(pathObject.name)) {
        setTopHeaderTheme(TopHeaderTheme.ElevationLevel1);
      } else {
        setTopHeaderTheme(TopHeaderTheme.AlmostBlack);
      }

      window.addEventListener('scroll', changeMenuTheme);
      return (): void => window.removeEventListener('scroll', changeMenuTheme);
    },
    [pathObject]
  );

  useEffect(
    (): void => {
      if (!systemConfigIsReady) {
        return;
      }
      if (systemConfigIsReady && (systemConfig?.escLoyaltyProgramLimited || systemConfig?.escLoyaltyProgramDisabled)) {
        if (systemConfig?.escLoyaltyProgramDisabled) {
          signOut();
        }

        return;
      }

      const subscriptionOutOfStockActionDefinition: RequiredAction<RequiredActionTypes> | undefined = userData?.requiredActions
        .find((action: RequiredAction): boolean => action.type === RequiredActionType.SubscriptionOutOfStock);
      if (subscriptionOutOfStockActionDefinition) {
        subscriptionOutOfStockAction(subscriptionOutOfStockActionDefinition as RequiredAction<SubscriptionOutOfStockData[]>);
      }

      const subscriptionUpgradeRequiredDefinition: RequiredAction<RequiredActionTypes> | undefined = userData?.requiredActions
        .find((action: RequiredAction): boolean => action.type === RequiredActionType.SubscriptionUpgradeRequired);
      if (subscriptionUpgradeRequiredDefinition) {
        upgradeSubscriptionPlanAction(subscriptionUpgradeRequiredDefinition as RequiredAction<SubscriptionMigrationData>);
      }

      const isAgreementsUpdateRequired: boolean = !!userData?.requiredActions
        .find((action: RequiredAction): boolean => action.type === RequiredActionType.AgreementsUpdateRequired);
      if (isAgreementsUpdateRequired) {
        agreementsUpdateRequiredAction();
      }

      const isMigrationToIcRequired: boolean = !!userData?.requiredActions
        .find((action: RequiredAction): boolean => action.type === RequiredActionType.InspirationClubMigration);
      if (isMigrationToIcRequired) {
        migrationToIcPopupRequiredAction();
      }
    },
    [userData, systemConfigIsReady]
  );

  const changeMenuTheme: () => void = (): void => {
    if (window.scrollY < 74) {
      setTopHeaderTopOffset(0);
      if (pathObject && !!pathsWithHeaderMenuTransparentTheme.includes(pathObject.name)) {
        setTopHeaderTheme(TopHeaderTheme.Transparent);
      }
    } else if (window.scrollY < 120) {
      setTopHeaderTopOffset(0);
    } else if (scrollY.current > window.scrollY) {
      setTopHeaderTopOffset(0);
      setTopHeaderTheme(TopHeaderTheme.AlmostBlack);
    } else {
      setTopHeaderTopOffset(-74);
      setTopHeaderTheme(TopHeaderTheme.AlmostBlack);
    }
    scrollY.current = window.scrollY;
  };

  const acceptAllCookies: () => void = (): void => {
    cookies.set(CookiesName.DisableGoogleAnalytics, false, { path: '/' });
    setCookiesAccepted(true);
    window[`ga-disable-${window.GA_ID}`] = false;
  };

  const acceptNecessaryCookies: () => void = (): void => setCookiesAccepted(true);

  const redirectToCookiesOptions: () => void = (): void => redirect(
    `${getRouteDetailsByName(RouteNameEnum.CookiesPolicy)?.url ?? ''}?slideToOptions=true`,
    undefined,
    true
  );

  const onUserButtonClick: () => void = (): void => {
    if (systemConfig?.escLoyaltyProgramLimited) {
      addToast({ content: t('chic.website.appWrapper.userProfileInaccessible') });

      return;
    }

    redirect(getRouteDetailsByName(RouteNameEnum.ProfileYourData)?.url ?? '');
  };

  return (
    <GoogleReCaptchaProvider reCaptchaKey={appConfig.recaptchaSiteKey}>
      <Container>
        <StyledTopHeader
          name={userData?.name}
          cardEan={userData?.card}
          points={userData?.points}
          topHeaderTheme={topHeaderTheme}
          $top={topHeaderTopOffset}
          onLogoutButtonClick={signOut}
          onMessagesButtonClick={!systemConfig?.escLoyaltyProgramLimited
            ? (): void => redirect(getRouteDetailsByName(RouteNameEnum.NotificationsHistory)?.url ?? '')
            : undefined
          }
          onUserButtonClick={onUserButtonClick}
          messagesAmount={messagesData?.unreadAmount}
          hasUserContext={!!token}
        />
        <ChildrenContainer $isHeaderTransparent={pathObject && pathsWithHeaderMenuTransparentTheme.includes(pathObject.name)}>
          {children}
          <div>{/* TODO: temporary fix for SSR */}
            <HealthWarning withBottomSpaceOnMobile={!!token} />
          </div>
          {!ageVerifyConfirmed && (
            <div>{/* TODO: temporary fix for SSR */}
              <AgeConfirmationScreen
                onAcceptClick={(): void => setAgeVerifyConfirmed(true)}
                onCancelClick={(): void => redirect('https://google.com', undefined, true)}
              />
            </div>
          )}
          {!cookiesAccepted && (
            <div>{/* TODO: temporary fix for SSR */}
              <CookiesInformationBox
                onMoreOptionsClick={redirectToCookiesOptions}
                onAcceptClick={acceptAllCookies}
                cookiesPolicyUrl={getRouteDetailsByName(RouteNameEnum.CookiesPolicy)?.url ?? ''}
                onAcceptNecessary={acceptNecessaryCookies}
              />
            </div>
          )}
          {!!(
            isAdPopupVisible
            && adPopupAdvertisements.length
            && pathObject
            && !pathsWithoutAddPopup.includes(pathObject.name)
          ) && (
            <div>{/* TODO: temporary fix for SSR */}
              <AdPopup
                advertisements={adPopupAdvertisements}
                onClose={closeAdPopup}
              />
            </div>
          )}
        </ChildrenContainer>
        <Footer
          withGridSize={!!token}
          menuPositions={visibleFooterPositions}
        />
        {!!token && systemConfigIsReady && (
          <SlideMenu
            activePositionName={pathObject?.name ?? RouteNameEnum.Home}
            onLogoutClick={signOut}
            positions={slideMenuPositions}
          />
        )}
      </Container>
    </GoogleReCaptchaProvider>
  );
};
