import { Color, StyledComponent } from '@chic-loyalty/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  width: 100%;
  min-height: calc(100vh - 223px);
  background-color: ${Color.AlmostBlack};
  display: flex;
  align-items: center;
  justify-content: center;
`;
