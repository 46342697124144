import { MapPoint, UseNotifications, UseState, useNotifications } from '@chic-loyalty/ui';
import { useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { Store } from '@chic/shared/interfaces';

import { getStores, getFavouritesStores, likeStore, unlikeStore } from '../api/requests';
import { QueryKey } from '../enums';
import { UseAuth, UseStoreMap } from '../interfaces';
import { FrontendApiError } from '../models';

import { useAuth } from './useAuth.hook';

export const useStoreMap: () => UseStoreMap = (): UseStoreMap => {
  const { t }: TransProps<never> = useTranslation();
  const [stores, setStores]: UseState<Store[]> = useState<Store[]>([]);
  const [favouriteStores, setFavouriteStores]: UseState<Store[]> = useState<Store[]>([]);
  const [favouriteStoresIds, setFavouriteStoresIds]: UseState<number[]> = useState<number[]>([]);
  const { addToast }: UseNotifications = useNotifications();
  const { userData, isUserLoggedIn }: UseAuth = useAuth();

  const mapPoints: MapPoint[] = useMemo(
    (): MapPoint[] => stores.map((store: Store): MapPoint => ({
      ...store,
      isFavorite: isUserLoggedIn() ? favouriteStoresIds.includes(store.id) : undefined
    })),
    [stores, favouriteStoresIds, userData]
  );

  useQuery(
    [QueryKey.Stores],
    (): Promise<Store[]> => getStores(),
    {
      onSuccess: (data: Store[]): void => setStores(data),
      // TODO: add logger
      onError: (): void => undefined
    }
  );

  useQuery(
    [QueryKey.FavouriteStores],
    (): Promise<Store[]> => getFavouritesStores(),
    {
      enabled: isUserLoggedIn(),
      onSuccess: (data: Store[]): void => {
        setFavouriteStores(data);
        setFavouriteStoresIds(data.map((store: Store): number => store.id));
      },
      // TODO: add logger
      onError: (): void => undefined
    }
  );

  const changeLikeStoreStateAction: (storeId: number, shouldLike: boolean) => void = (storeId: number, shouldLike: boolean): void => {
    const storeObject: Store | undefined = stores.find((store: Store): boolean => store.id === storeId);

    if (storeObject) {
      if (shouldLike) {
        likeStore(storeId)
          .then((): void => {
            addToast({ content: t('chic.website.useStoreMap.likeStoreAction.success', { storeName: storeObject.name }) });
            setFavouriteStoresIds([ ...favouriteStoresIds, storeId ]);
            setFavouriteStores([ ...favouriteStores, storeObject ]);
          })
          .catch((error: FrontendApiError): void => addToast({ content: t(error.message) }));
      } else {
        unlikeStore(storeId)
          .then((): void => {
            addToast({ content: t('chic.website.useStoreMap.unlikeStoreAction.success', { storeName: storeObject.name }) });
            setFavouriteStoresIds([ ...favouriteStoresIds.filter((id: number): boolean => id !== storeId) ]);
            setFavouriteStores([ ...favouriteStores.filter((store: Store): boolean => store.id !== storeId) ]);
          })
          .catch((error: FrontendApiError): void => addToast({ content: t(error.message) }));
      }
    }
  };
  
  return {
    changeLikeStoreStateAction,
    mapPoints,
    stores,
    favouriteStores
  };
};
