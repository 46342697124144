import { UseLocalStorage, useLocalStorage, UseState } from '@chic-loyalty/ui';
import { useMemo, useState } from 'react';

import { LocalStorageKey } from '@chic/frontend/enums';
import { AdPopupWatchStatus } from '@chic/shared/interfaces';

import { AdvertisementData, UseAddPopupsConfigs } from '../appWrapper.types';

export const useAddPopupsConfigs: () => UseAddPopupsConfigs = (): UseAddPopupsConfigs => {
  const [adPopupsVisibilityState, setAdPopupsVisibilityState]: UseLocalStorage<AdPopupWatchStatus[]>
    = useLocalStorage<AdPopupWatchStatus[]>(LocalStorageKey.AdPopups, [], []);
  const [isAdPopupVisible, setIsAdPopupVisible]: UseState<boolean> = useState<boolean>(true);

  const adPopupsShown: string[] = useMemo(
    (): string[] => adPopupsVisibilityState
      .filter((item: AdPopupWatchStatus): boolean => item.isWatched)
      .map((item: AdPopupWatchStatus): string => item.name),
    [adPopupsVisibilityState]
  );

  const adPopupsList: AdvertisementData[] = [];

  const closeAdPopup: () => void = (): void => {
    setAdPopupsVisibilityState(adPopupsList.map((item: AdvertisementData): AdPopupWatchStatus => ({
      name: item.name, isWatched: true,
    })));
    setIsAdPopupVisible(false);
  };
  
  return {
    isAdPopupVisible,
    closeAdPopup,
    adPopupAdvertisements: adPopupsList.filter((item: AdvertisementData): boolean => !adPopupsShown.includes(item.name)),
  };
};
