import { AxiosResponse } from 'axios';

import { FrontendApiError } from '@chic/frontend/models';
import { ApiReject, ApiResolve } from '@chic/frontend/types';
import { ListPaginationInterface, ListPaginationParams, TransactionListElement, TransactionWithProducts } from '@chic/shared/interfaces';

import { api } from '../api';

export const getTransactionsList: (params: ListPaginationParams) => Promise<ListPaginationInterface<TransactionListElement>> = (
  params: ListPaginationParams
): Promise<ListPaginationInterface<TransactionListElement>> => new Promise(
  (resolve: ApiResolve<ListPaginationInterface<TransactionListElement>>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get('/transactions', { params }).then(
      (response: AxiosResponse<ListPaginationInterface<TransactionListElement>>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getTransactionDetails: (transactionId: number) => Promise<TransactionWithProducts> = (
  transactionId: number
): Promise<TransactionWithProducts> => new Promise(
  (resolve: ApiResolve<TransactionWithProducts>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get(`/transactions/${transactionId}`).then(
      (response: AxiosResponse<TransactionWithProducts>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);
