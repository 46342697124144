import { AxiosResponse } from 'axios';

import { FrontendApiError } from '@chic/frontend/models';
import { ApiReject, ApiResolve } from '@chic/frontend/types';
import { Store } from '@chic/shared/interfaces';

import { api } from '../api';

export const getStores: () => Promise<Store[]> = (): Promise<Store[]> => new Promise(
  (resolve: ApiResolve<Store[]>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get('/stores').then(
      (response: AxiosResponse<Store[]>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getFavouritesStores: () => Promise<Store[]> = (): Promise<Store[]> => new Promise(
  (resolve: ApiResolve<Store[]>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get('/stores/favourites').then(
      (response: AxiosResponse<Store[]>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const likeStore: (storeId: number) => Promise<void> = (storeId: number): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.put(`/stores/${storeId}/favourite`).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const unlikeStore: (storeId: number) => Promise<void> = (storeId: number): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.delete(`/stores/${storeId}/favourite`).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);
