import {
  Button,
  DetailsInfoSettings,
  MailToUs,
  MailToUsTheme,
  PageHeader,
  UseNotifications,
  UseRedirect,
  UseState,
  useNotifications,
  useRedirect,
} from '@chic-loyalty/ui';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useMemo, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { getSubscriptionPaymentInfo } from '@chic/frontend/api/requests';
import { FullscreenAlertAssets, QueryKey } from '@chic/frontend/enums';
import { FrontendApiError } from '@chic/frontend/models';
import { PaymentStatusCode, RouteNameEnum } from '@chic/shared/enums';
import { SubscriptionPaymentStatus } from '@chic/shared/interfaces';
import { getRouteDetailsByName } from '@chic/shared/utils';

import { usePaymentDetails } from './paymentDetails.hooks';
import {
  Container,
  ContentContainer,
  DetailsBox,
  StyledDetailsInfoGroup,
} from './paymentDetails.styled';
import { UsePaymentDetails } from './paymentDetails.types';

export const PaymentDetailsView: React.FC = (): JSX.Element => {
  const { id }: Readonly<Record<string, string | undefined>> = useParams();
  const { showFullscreenAlert, hideFullscreenAlert }: UseNotifications = useNotifications();
  const { redirect }: UseRedirect = useRedirect();
  const { t }: TransProps<never> = useTranslation();
  const [subscriptionPaymentInfo, setSubscriptionPaymentInfo]: UseState<SubscriptionPaymentStatus | null> =
    useState<SubscriptionPaymentStatus | null>(null);
  const { getDetailsTableData }: UsePaymentDetails = usePaymentDetails();
  const detailsTableData: DetailsInfoSettings[] = subscriptionPaymentInfo ? getDetailsTableData(subscriptionPaymentInfo) : [];
  const isIdValid: boolean = useMemo((): boolean => !!id && /^\d+$/.test(id), [id]);
  const shouldShowGoToPayment: boolean = useMemo((): boolean => {
    return subscriptionPaymentInfo?.status.name !== PaymentStatusCode.Success;
  }, [subscriptionPaymentInfo]);

  useEffect(() => {
    if (!isIdValid) {
      showFullscreenAlert({
        description: t('chic.website.paymentDetailsView.invalidPaymentId'),
        acceptButtonSettings: {
          label: t('chic.website.global.ok'),
          action: (): void => {
            hideFullscreenAlert();
            redirect(getRouteDetailsByName(RouteNameEnum.Subscriptions)?.url ?? '/');
          }
        },
      });
    }
  }, [isIdValid]);

  useQuery(
    [QueryKey.SubscriptionPaymentInfo],
    (): Promise<SubscriptionPaymentStatus> => getSubscriptionPaymentInfo(Number(id)),
    {
      enabled: isIdValid,
      onSuccess: setSubscriptionPaymentInfo,
      // TODO: add logger
      onError: (error: FrontendApiError): void => {
        showFullscreenAlert({
          description: error.message,
          iconImage: FullscreenAlertAssets.ErrorIcon,
          acceptButtonSettings: {
            label: t('chic.website.global.ok'),
            action: (): void => {
              hideFullscreenAlert();
              redirect(getRouteDetailsByName(RouteNameEnum.Subscriptions)?.url ?? '/');
            }
          },
        });
      }
    }
  );

  const goToSubscriptionPayment: () => void = (): void => {
    redirect(subscriptionPaymentInfo?.url ?? '', undefined, true);
  };

  if (!subscriptionPaymentInfo) {
    return <></>;
  }

  return (
    <Container>
      <PageHeader header={t('chic.website.paymentDetailsView.paymentDetails')} />
      <ContentContainer>
        <DetailsBox>
          <StyledDetailsInfoGroup items={detailsTableData} />
          {shouldShowGoToPayment && (
            <Button
              label={t('chic.website.paymentDetailsView.goToPayment')}
              onClick={goToSubscriptionPayment}
            />
          )}
        </DetailsBox>
        <MailToUs
          title={t('chic.website.global.mailToUs.title')}
          actionLabel={t('chic.website.global.mailToUs.actionLabel')}
          actionSettings={{ internalPath: getRouteDetailsByName(RouteNameEnum.SubscriptionContact)?.url ?? '' }}
          mailToUsTheme={MailToUsTheme.Orange}
        />
      </ContentContainer>
    </Container>
  );
};
