import { Breakpoint, Color, FontWeight, Markdown, StyledComponent } from '@chic-loyalty/ui';
import styled from 'styled-components';

import { ContentWrapperProps } from './icMigrationLanding.types';

export const Container: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: stretch;
  width: 100%;
  min-height: calc(100vh - 74px - 242px);
  background-color: ${Color.AlmostBlack};

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
    background-color: ${Color.Level1};
  }
`;

export const ContentWrapper: StyledComponent<'div', ContentWrapperProps> = styled.div<ContentWrapperProps>`
  width: 58.75%;
  padding: ${({ $withLeftSpacing }: ContentWrapperProps): string => $withLeftSpacing ? '155px 20px 60px 100px' : '155px 20px 60px'};

  @media ${Breakpoint.Mobile} {
    order: 2;
    width: 100%;
    padding: 50px 20px 60px;
  }
`;

export const Content: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 100%;
  max-width: 622px;
  margin: 0 auto;
`;

export const HeadingWrapper: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const Heading: StyledComponent<'h1'> = styled.h1`
  font-size: 39px;
  line-height: 47px;
  letter-spacing: -1.17px;
  font-weight: ${FontWeight.Bold};
  color: ${Color.SolidHighEmphasis};
`;

export const HeadingText: StyledComponent<typeof Markdown> = styled(Markdown)`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: ${Color.SolidHighEmphasis};
`;

export const ImageWrapper: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: stretch;
  width: 41.25%;

  @media ${Breakpoint.Mobile} {
    order: 1;
    max-height: clamp(324px, 70vw, 500px);
    width: 100%;
    padding-top: 74px;
  }
`;

export const Image: StyledComponent<'img'> = styled.img`
  width: 100%;
  object-fit: cover;
  object-position: top;

  @media ${Breakpoint.Mobile} {
    object-position: 0 -74px;
  }
`;
