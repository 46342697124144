import { IsDefined, IsString } from 'class-validator';

import { PreferredLanguage } from '@chic/shared/enums';

import { RegisterWithoutPasswordRequest } from './registerWithoutPasswordRequest.model';

export class RegisterWithClientBrowserRequest extends RegisterWithoutPasswordRequest {
  @IsDefined()
  @IsString()
  public clientIp: string;

  @IsDefined()
  @IsString()
  public clientBrowser: string;

  constructor(
    firstName: string,
    lastName: string,
    phone: string,
    email: string,
    birthday: string,
    preferredLanguage: PreferredLanguage,
    clientIp: string,
    clientBrowser: string,
    declarationOfAge: number,
    personalDataAgreement: number,
    smsCode?: string,
    marketingAgreement?: number,
    marketingAdditionalAgreement?: number,
    marketingSocialAgreement?: number,
    phoneSurveyAgreement?: number,
    mgmCode?: string,
    postalCode?: string,
  ) {
    super(
      firstName,
      lastName,
      phone,
      email,
      birthday,
      preferredLanguage,
      declarationOfAge,
      personalDataAgreement,
      smsCode,
      marketingAgreement,
      marketingAdditionalAgreement,
      marketingSocialAgreement,
      phoneSurveyAgreement,
      mgmCode,
      postalCode,
    );
    this.clientIp = clientIp;
    this.clientBrowser = clientBrowser;
  }
}
