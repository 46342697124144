import { IsBoolean, IsDefined, IsNumber, IsOptional } from 'class-validator';

export class SendStatsForMessagesRequest {
  @IsDefined()
  @IsNumber()
  public messageId: number;

  @IsOptional()
  @IsBoolean()
  public readed?: boolean;

  @IsOptional()
  @IsBoolean()
  public clicked?: boolean;

  constructor(
    messageId: number,
    readed?: boolean,
    clicked?: boolean,
  ) {
    this.messageId = messageId;
    this.readed = readed;
    this.clicked = clicked;
  }
}
