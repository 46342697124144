import { eanOrEmailRegexp, getRegexForPhone, Country } from '@chic-loyalty/ui';
import { TransProps, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { LoginByPassword } from '@chic/shared/models';

export const useSignInValidation: (
  enablePhoneValidation: boolean, country: Country
) => Yup.SchemaOf<LoginByPassword> = (
  enablePhoneValidation: boolean, country: Country
): Yup.SchemaOf<LoginByPassword> => {
  const { t }: TransProps<never> = useTranslation();
  const loginRegexp: RegExp = enablePhoneValidation
    ? getRegexForPhone(country)
    : eanOrEmailRegexp;

  return Yup.object().shape({
    login: Yup.string()
      .required(t('chic.website.global.requiredField'))
      .matches(loginRegexp, t('chic.website.global.wrongValue')),
    password: Yup.string()
      .required(t('chic.website.global.requiredField')),
    rememberMe: Yup.boolean()
      .optional(),
  });
};
