import { MapPointDelivery, UseState } from '@chic-loyalty/ui';
import { useQuery } from '@tanstack/react-query';
import { RefObject, useEffect, useMemo, useState } from 'react';
import { DebouncedState, useDebounce } from 'use-debounce';

import { SubscriptionDeliveryCode } from '@chic/shared/enums';
import { GetParcelLockersParams, GetPickupPointsParams, ParcelLocker, PickupPoint, PickupPointWithDistance } from '@chic/shared/interfaces';

import { getParcelLockersList, getPickupPointsList } from '../api/requests';
import { QueryKey } from '../enums';
import { UseDeliveriesTransformation, UseDeliveryMap } from '../interfaces';

import { useDeliveriesTransformation } from './useDeliveriesTranformation.hook';

export const useDeliveryMap: (
  listRef: RefObject<HTMLDivElement>,
  selectedDelivery?: SubscriptionDeliveryCode | string, 
  pointId?: string,
) => UseDeliveryMap = (
  listRef: RefObject<HTMLDivElement>,
  selectedDelivery?: SubscriptionDeliveryCode | string, 
  pointId: string = '',
): UseDeliveryMap => {
  const [mapPoints, setMapPoints]: UseState<MapPointDelivery[]> = useState<MapPointDelivery[]>([]);
  const [shouldFlyToPoint, setShouldFlyToPoint]: UseState<boolean> = useState<boolean>(true);
  const [inputValue, setInputValue]: UseState<string> = useState<string>('');
  const [activePointId, setActivePointId]: UseState<number | null> = useState<number | null>(null);
  const [currentPosition, setCurrentPosition]: UseState<[number, number] | null> = useState<[number, number] | null>(null);
  const [pointPosition, setPointPosition]: UseState<[number, number] | null> = useState<[number, number] | null>(null);
  const [debouncedInputValue]: [string, DebouncedState<(value: string) => void>] = useDebounce(inputValue, 500);
  const activePoint: MapPointDelivery | undefined = useMemo(
    (): MapPointDelivery | undefined => (
      mapPoints.find((point: MapPointDelivery): boolean => point.id === activePointId)
    ),
    [activePointId, mapPoints]
  );
  const requestParams: Partial<GetPickupPointsParams | GetParcelLockersParams> = useMemo(
    (): Partial<GetPickupPointsParams | GetParcelLockersParams> => (
      {
        latitude: pointPosition ? pointPosition[0] : undefined,
        longitude: pointPosition ? pointPosition[1] : undefined,
        searchValue: !pointPosition ? debouncedInputValue : undefined
      }
    ),
    [pointPosition, debouncedInputValue]
  );
  const {
    transformParcelLockerToMapPointDelivery,
    transformPickupPointWithDistanceToMapPointDelivery,
  }: UseDeliveriesTransformation = useDeliveriesTransformation();

  useQuery(
    [QueryKey.ParcelLockersList, requestParams, selectedDelivery, pointId],
    (): Promise<ParcelLocker[]> => getParcelLockersList(requestParams),
    {
      onSuccess: (data: ParcelLocker[]): void => (
        setMapPoints(data
          .map(transformParcelLockerToMapPointDelivery)
          .map((transferredLocker: MapPointDelivery): MapPointDelivery => ({
            ...transferredLocker,
            distance: currentPosition === pointPosition
              ? transferredLocker.distance
              : undefined
          }))
        )
      ),
      enabled: selectedDelivery === SubscriptionDeliveryCode.InpostMachine,
      // TODO: add logger
      onError: (): void => undefined
    }
  );

  useQuery(
    [QueryKey.PickupPointsList, requestParams, selectedDelivery],
    (): Promise<PickupPoint[]> => getPickupPointsList(requestParams),
    {
      onSuccess: (data: PickupPointWithDistance[]): void => (
        setMapPoints(data
          .map(transformPickupPointWithDistanceToMapPointDelivery)
          .map((transferredPickupPoint: MapPointDelivery): MapPointDelivery => ({
            ...transferredPickupPoint,
            distance: currentPosition === pointPosition
              ? transferredPickupPoint.distance
              : undefined
          }))
        )
      ),
      enabled: selectedDelivery === SubscriptionDeliveryCode.PickupPoint,
      // TODO: add logger
      onError: (): void => undefined
    }
  );

  const onLocationButtonClick: () => void = (): void => {
    if (currentPosition) {
      setShouldFlyToPoint(true);
      setActivePointId(null);
      setPointPosition(currentPosition);
    }
  };

  const onPositionChange: (position: [number, number] | null) => void = (position: [number, number] | null): void => {
    if (position !== currentPosition) {
      setActivePointId(null);
    }
    setShouldFlyToPoint(true);
    setCurrentPosition(position);
    setPointPosition(position);
  };

  const onSelectDeliveryPoint: (selectedPointId: number) => void = (selectedPointId: number): void => {
    setActivePointId(selectedPointId);
    setShouldFlyToPoint(false);
  };

  const onSearchInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setInputValue(e.target.value);
    setPointPosition(null);
    setActivePointId(null);
  };

  useEffect(
    (): void => {
      if (activePoint) {
        setPointPosition([activePoint?.lat, activePoint?.lng]);
        listRef.current?.scroll({
          top: 0,
          behavior: 'smooth'
        });
      }
    },
    [activePoint]
  );

  return {
    inputValue,
    mapPoints,
    activePointId,
    pointPosition,
    shouldFlyToPoint,
    onSearchInputChange,
    onLocationButtonClick,
    onSelectDeliveryPoint,
    onPositionChange,
    setInputValue,
    setShouldFlyToPoint,
    setActivePointId,
    setPointPosition,
    setCurrentPosition,
  };
};
