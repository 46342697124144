import { 
  PageHeader, 
  BoxesSlider,  
  UseRedirect, 
  useRedirect, 
  UseState, 
  Icon, 
  Color, 
  IconName, 
} from '@chic-loyalty/ui';
import { InfiniteData, useInfiniteQuery, UseInfiniteQueryResult, useQuery } from '@tanstack/react-query';
import { debounce } from 'lodash';
import React, { ChangeEvent, ChangeEventHandler, RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { getProducts, getProductsCategories } from '@chic/frontend/api/requests';
import { FileFromViews, QueryKey } from '@chic/frontend/enums';
import { UseSearchParams } from '@chic/frontend/types';
import { RouteNameEnum } from '@chic/shared/enums';
import { Product, ProductCategory } from '@chic/shared/interfaces';
import { getRouteDetailsByName } from '@chic/shared/utils';

import { 
  Container, 
  Content, 
  CategoryLabel,
  CategoryBox, 
  CategoryImage,
  CategorySlider,
  CategoryName, 
  CategoryImageWrapper, 
  CloseButton,
  ProductsList, 
  SearchOverlay, 
  SearchHeader,
  SearchInputWrapper,
  SearchInput,
  SearchContent,
  SearchQuantity,
  SearchResults,
  StyledProductBox,
} from './products.styled';

export const ProductsView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { redirect }: UseRedirect = useRedirect();
  const [searchParams, setSearchParams]: UseSearchParams = useSearchParams();
  const [productsCategories, setProductsCategories]: UseState<ProductCategory[]> = useState<ProductCategory[]>([]);
  const [products, setProducts]: UseState<Product[]> = useState<Product[]>([]);
  const [searchedProducts, setSearchedProducts]: UseState<Product[]> = useState<Product[]>([]);
  const [searchValue, setSearchValue]: UseState<string | undefined> = useState<string | undefined>();
  const [searchTempValue, setSearchTempValue]: UseState<string> = useState<string>('');
  const [currentCategory, setCurrentCategory]: UseState<string | null> = useState<string | null>(null);
  const [isSearchOpen, setIsSearchOpen]: UseState<boolean> = useState<boolean>(false);
  const searchInputRef: RefObject<HTMLInputElement> = useRef(null);
  const productsListRef: RefObject<HTMLDivElement> = useRef(null);

  useQuery(
    [QueryKey.ProductsCategories],
    (): Promise<ProductCategory[]> => getProductsCategories(),
    {
      onSuccess: setProductsCategories,
      onError: (): void => undefined
    }
  );

  const { fetchNextPage }: UseInfiniteQueryResult<Product[], unknown> = useInfiniteQuery(
    [QueryKey.Products, searchValue, currentCategory],
    ({ pageParam = 0 }: { pageParam?: number }): Promise<Product[]> => getProducts(
      {
        limit: searchValue ? 64 : 50,
        offset: pageParam * 50,
        category: (!searchValue && currentCategory) ? currentCategory : undefined,
        search: searchValue || undefined,
      }
    ),
    {
      onSuccess: (data: InfiniteData<Product[]>) => {
        if (searchValue && isSearchOpen) {
          setSearchedProducts(data.pages[0]);
        } else {
          const mergedData: Product[] = data.pages.reduce<Product[]>((acc: Product[], page: Product[]) => [...acc, ...page], []);
          setProducts(mergedData);
        }
      },
      onError: (): void => undefined,
      getNextPageParam: (lastPage: Product[], allPages: Product[][]) => {
        if (lastPage.length < 50) {
          return undefined;
        }
        return allPages.length;
      },
      enabled: !!productsCategories.length && !!currentCategory
    },
  );

  const handleCategorySelect: (id: string) => void = (id: string): void => {
    setSearchParams({ category: id });
    setCurrentCategory(id);
  };

  const handleSearch: () => void = debounce((): void => {
    const inputValue: string | undefined = searchInputRef.current?.value;
    if (inputValue && inputValue.length > 2) {
      setSearchValue(inputValue);
      setSearchParams({ search: inputValue });
    } else {
      setSearchValue(undefined);
    }
  }, 500);

  const setSearchTempValueAndHandleSearch: ChangeEventHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchTempValue(event.target.value);
    handleSearch();
  };

  const openSearch: () => void = (): void => {
    setIsSearchOpen(true);
    setTimeout((): void => searchInputRef.current?.focus(), 0);
  };

  const closeSearch: () => void = (): void => {
    setIsSearchOpen(false);
    setSearchValue(undefined);
    setSearchTempValue('');
    if (currentCategory) {
      setSearchParams({ category: currentCategory });
    }
  };

  const itemsQuantityText: string = useMemo(
    (): string => {
      switch (searchedProducts.length) {
        case 0:
          return t('chic.website.productsView.lotOfItemsFound', { value: searchedProducts.length });
        case 1:
          return t('chic.website.productsView.oneItemFound', { value: searchedProducts.length });
        case 2:
        case 3:
        case 4:
          return t('chic.website.productsView.fewItemsFound', { value: searchedProducts.length });
        default:
          return t('chic.website.productsView.lotOfItemsFound', { value: searchedProducts.length });
      }
    }, 
    [searchedProducts.length]
  );

  const onScroll = async (): Promise<void> => {
    if (!productsListRef.current) {
      return;
    }

    const { top, height }: DOMRect = productsListRef.current.getBoundingClientRect();
    const { clientHeight }: HTMLElement = document.documentElement;

    if (top + height <= clientHeight) {
      void fetchNextPage();
    }
  };

  useEffect(
    (): (() => void) => {
      window.addEventListener('scroll', onScroll);
    
      return (): void => {
        window.removeEventListener('scroll', onScroll);
      };
    }, 
    []
  );

  useEffect(
    (): void => {
      const categoryFromParams: string | null = searchParams.get('category');
      const searchValueFromParams: string | null = searchParams.get('search');

      if (categoryFromParams && productsCategories.find((category: ProductCategory): boolean => category.id === categoryFromParams)) {
        setCurrentCategory(categoryFromParams);
      } else {
        setCurrentCategory(productsCategories[0]?.id);
      }

      const allowedProductNameRegex: RegExp = /[A-Za-z0-9\ \,\.\(\)]/;
      if (searchValueFromParams?.match(allowedProductNameRegex)) {
        setSearchValue(searchValueFromParams);
        setSearchTempValue(searchValueFromParams);
        setIsSearchOpen(true);
      }
    }, 
    [productsCategories.length]
  );
  
  return (
    <>
      {!isSearchOpen ? (
        <Container>
          <PageHeader
            header={t('chic.website.meta.products.title')}
            onArrowButtonAction={(): void => redirect(getRouteDetailsByName(RouteNameEnum.Home)?.url ?? '/')}
            searchButtonAction={openSearch}
          />
          <Content>
            {!!productsCategories.length && (
              <CategorySlider>
                <CategoryLabel>{t('chic.website.productsView.categoryLabel')}</CategoryLabel>
                <BoxesSlider cursorLabel={t('chic.website.global.more')}>
                  {productsCategories.map((productCategory: ProductCategory, index: number): JSX.Element => (
                    <CategoryBox key={index} onClick={(): void => handleCategorySelect(productCategory.id)}>
                      <CategoryImageWrapper 
                        $isActive={currentCategory 
                          ? productCategory.id === currentCategory 
                          : index === 0
                        }
                      >
                        <CategoryImage src={productCategory.cover ?? FileFromViews.DefaultProductCategory} alt={productCategory.name} />
                      </CategoryImageWrapper>
                      <CategoryName 
                        $isActive={currentCategory 
                          ? productCategory.id === currentCategory 
                          : index === 0
                        }
                      >
                        {productCategory.name}
                      </CategoryName>
                    </CategoryBox>
                  ))}
                </BoxesSlider>
              </CategorySlider>
            )}
            {!!products.length && (
              <ProductsList ref={productsListRef}>
                {products.map((product: Product): JSX.Element => (
                  <StyledProductBox
                    key={product.id}
                    label={product.name}
                    image={product.photos.length ? product.photos[0] : undefined}
                    points={product.rewardPoints}
                    onButtonClick={
                      (): void => redirect(getRouteDetailsByName(RouteNameEnum.ProductDetails)?.url ?? '', { id: `${product.id}` })
                    }
                  />
                ))}
              </ProductsList>
            )}
          </Content>
        </Container>
      ) : (
        <SearchOverlay>
          <SearchHeader>
            <SearchInputWrapper>
              <SearchInput
                placeholder={t('chic.website.productsView.searchPlaceholder')}
                onChange={setSearchTempValueAndHandleSearch}
                ref={searchInputRef}
                value={searchTempValue}
              />
              <CloseButton onClick={closeSearch}>
                <Icon
                  name={IconName.Close}
                  size={12}
                  color={Color.White}
                />
              </CloseButton>
            </SearchInputWrapper>
          </SearchHeader>
          {searchValue && !!searchedProducts.length && (
            <SearchContent>
              <SearchQuantity>
                {itemsQuantityText}
              </SearchQuantity>
              <SearchResults>
                {searchedProducts.map((product: Product): JSX.Element => (
                  <StyledProductBox
                    key={product.id}
                    label={product.name}
                    image={product.photos.length ? product.photos[0] : undefined}
                    points={product.rewardPoints}
                    onButtonClick={
                      (): void => redirect(getRouteDetailsByName(RouteNameEnum.ProductDetails)?.url ?? '', { id: `${product.id}` })
                    }
                  />
                ))}
              </SearchResults>
            </SearchContent>
          )}
        </SearchOverlay>
      )}
    </>
  );
};
