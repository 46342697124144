import { Breakpoint, Color, ElementsTable, FontWeight, Grid, StyledComponent } from '@chic-loyalty/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  width: 100%;
  background-color: ${Color.AlmostBlack};
  min-height: calc(100vh - 74px);
  display: flex;
  flex-direction: column;
`;

export const TableWrapper: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 25px;
  padding: 50px 20px 100px 100px;
  width: 100%;
  max-width: ${Grid.Desktop};
  margin: 0 auto;

  @media ${Breakpoint.Mobile} {
    padding: 0px 0px 50px;
  }
`;

export const StyledElementsTable: StyledComponent<typeof ElementsTable> = styled(ElementsTable)`
  max-width: ${Grid.Desktop};
  width: 100%;
`;

export const TableDateWrapper: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TableDate: StyledComponent<'span'> = styled.span`
  color: ${Color.SolidHighEmphasis};
`;

export const TableStore: StyledComponent<'span'> = styled.span`
  display: none;
  font-size: 12px;
  line-height: 20px;
  
  @media ${Breakpoint.Mobile} {
    display: inline;
  }
`;

export const TablePoints: StyledComponent<'span'> = styled.span`
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 14px; 
  line-height: 22px; 
  letter-spacing: 0.4px;
  color: ${Color.SolidHighEmphasis};
`;

export const EmptyStateContainer: StyledComponent<'div'> = styled.div`
  padding: 50px 20px 50px 100px;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;

  @media ${Breakpoint.Mobile} {
    padding: 20px;
  }
`;

export const EmptyStateImg: StyledComponent<'img'> = styled.img`
  max-height: 140px;
  width: auto;

  @media ${Breakpoint.Mobile} {
    max-height: 120px;
  }
`;

export const EmptyStateMessage: StyledComponent<'div'> = styled.div`
  font-weight: ${FontWeight.Medium};
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: ${Color.SolidLowEmphasis};
  margin-top: 50px;
`;
