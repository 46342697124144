import { 
  UseParsers, 
  useParsers, 
  UseState, 
  DetailsInfoSettings, 
  OrderDetailsSettings,  
  UseRedirect,
  useRedirect,
  AdBoxSettings,
  Color,
  OrdersHistoryButtonTheme,
  MenuPositionData,
  UseNotifications,
  useNotifications
} from '@chic-loyalty/ui';
import { QueryObserverResult, useQuery } from '@tanstack/react-query';
import { useState, useMemo } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { getParcelLockerDetailsByExternalId, getPickupPointDetails, getSubscriptionDetails } from '@chic/frontend/api/requests';
import { subscriptionOrderStatus } from '@chic/frontend/constans';
import { FullscreenAlertAssets, QueryKey } from '@chic/frontend/enums';
import { useAuth, useDeliveriesTransformation, useSubscriptionsActions } from '@chic/frontend/hooks';
import { UseAuth, UseDeliveriesTransformation, UseSubscriptionsActions } from '@chic/frontend/interfaces';
import { FrontendApiError } from '@chic/frontend/models';
import { getSubscriptionPermissionByType, getUserPermissionByType } from '@chic/frontend/utils';
import { 
  PaymentStatusCode,
  PaymentType,
  RouteNameEnum, 
  SubscriptionDeliveryCode, 
  SubscriptionExtraFieldType, 
  SubscriptionExtraFieldValue, 
  SubscriptionPermissionType, 
  UserPermission 
} from '@chic/shared/enums';
import {  
  ParcelLocker,
  PickupPoint,
  SubscriptionDetails, 
  SubscriptionExtraField, 
  SubscriptionOrder 
} from '@chic/shared/interfaces';
import { getRouteDetailsByName } from '@chic/shared/utils';

import { UseSubscriptionDetails } from './subscriptionDetails.types';

export const useSubscriptionDetails: (id: string) => UseSubscriptionDetails = (id: string): UseSubscriptionDetails => {
  const { t }: TransProps<never> = useTranslation();
  const { parsePrice }: UseParsers = useParsers();
  const { redirect }: UseRedirect = useRedirect();
  const { userData }: UseAuth = useAuth();
  const { showFullscreenAlert, hideFullscreenAlert }: UseNotifications = useNotifications();
  const { changeDeliveryDateAction }: UseSubscriptionsActions = useSubscriptionsActions();
  const { getDeliveryAddress, getParcelLockerAddress, getPickupPointAddress }: UseDeliveriesTransformation = useDeliveriesTransformation();
  const [subscriptionDetails, setSubscriptionDetails]: UseState<SubscriptionDetails | null> = useState<SubscriptionDetails | null>(null);
  const [parcelLocker, setParcelLocker]: UseState<ParcelLocker | null> = useState<ParcelLocker | null>(null);
  const [pickupPoint, setPickupPoint]: UseState<PickupPoint | null> = useState<PickupPoint | null>(null);
  const currency: string = 'zł';

  useQuery(
    [QueryKey.SubscriptionDetails],
    (): Promise<SubscriptionDetails> => getSubscriptionDetails(Number(id)),
    {
      onSuccess: (data: SubscriptionDetails): void => setSubscriptionDetails(data),
      // TODO: add logger
      onError: (error: FrontendApiError): void => {
        if ([404, 409].includes(error.responseCode)) {
          showFullscreenAlert({
            description: t('chic.website.useSubscriptionDetails.subscriptionNotFound'),
            iconImage: FullscreenAlertAssets.ErrorIcon,
            acceptButtonSettings: {
              label: t('chic.website.global.ok'),
              action: (): void => {
                hideFullscreenAlert();
                redirect(getRouteDetailsByName(RouteNameEnum.Subscriptions)?.url ?? '/');
              }
            },
          });
        }
      }
    }
  );

  // TODO: add this to subscription details endpoint
  const parcelLockerQuery: QueryObserverResult = useQuery(
    [QueryKey.ParcelLockerDetails],
    (): Promise<ParcelLocker> => getParcelLockerDetailsByExternalId(subscriptionDetails?.deliveryDestination.inpostExternalId ?? ''),
    {
      enabled: !!subscriptionDetails?.deliveryDestination.inpostExternalId,
      onSuccess: (data: ParcelLocker): void => setParcelLocker(data),
      // TODO: add logger
      onError: (): void => undefined
    }
  );

  // TODO: add this to subscription details endpoint
  const pickupPointQuery: QueryObserverResult = useQuery(
    [QueryKey.PickupPointDetails],
    (): Promise<PickupPoint> => getPickupPointDetails(subscriptionDetails?.deliveryDestination.pickupPointId ?? 0),
    {
      enabled: !!subscriptionDetails?.deliveryDestination.pickupPointId,
      onSuccess: (data: PickupPoint): void => setPickupPoint(data),
      // TODO: add logger
      onError: (): void => undefined
    }
  );

  const canEditProducts: boolean = useMemo(
    (): boolean => {
      if (!subscriptionDetails?.permissions) {
        return false;
      }

      return getSubscriptionPermissionByType(subscriptionDetails.permissions, SubscriptionPermissionType.CanEditSubscriptionProducts);
    },
    [subscriptionDetails?.permissions]
  );

  const canEditDeliveryDate: boolean = useMemo(
    (): boolean => {
      if (!subscriptionDetails?.permissions) {
        return false;
      }

      return getSubscriptionPermissionByType(subscriptionDetails.permissions, SubscriptionPermissionType.CanEditSubscriptionDeliveryDate);
    },
    [subscriptionDetails?.permissions]
  );

  const canChangePaymentMethod: boolean = useMemo(
    (): boolean => {
      if (!userData?.permissions) {
        return false;
      }

      return getUserPermissionByType(userData.permissions, UserPermission.CanChangePaymentMethod);
    },
    [userData?.permissions]
  );

  const touchpointValue: SubscriptionExtraFieldValue | undefined = useMemo(
    (): SubscriptionExtraFieldValue | undefined => {
      if (!subscriptionDetails?.extraFields.length) {
        return;
      }

      return subscriptionDetails.extraFields
        .find((field: SubscriptionExtraField): boolean => field.type === SubscriptionExtraFieldType.Touchpoint)?.value;
    },
    [subscriptionDetails?.extraFields]
  );

  const detailsInfoSettings: DetailsInfoSettings[] = useMemo(
    (): DetailsInfoSettings[] => {
      if (!subscriptionDetails) {
        return [];
      }

      return [
        { 
          label: t('chic.website.useSubscriptionDetails.detailsInfoSettings.status.label'), 
          value: t(`chic.website.useSubscriptionDetails.detailsInfoSettings.status.${subscriptionDetails.active ? 'active' : 'inactive'}`),
          valueColor: subscriptionDetails.active ? Color.Tangerine : undefined
        },
        { 
          label: t('chic.website.useSubscriptionDetails.detailsInfoSettings.createDateTime.label'), 
          value: subscriptionDetails.createDatetime 
        },
        ...(subscriptionDetails.validityDate ? [{ 
          label: t('chic.website.useSubscriptionDetails.detailsInfoSettings.validityDate.label'), 
          value: subscriptionDetails.validityDate 
        }] : []),
        ...(subscriptionDetails?.store ? [{ 
          label: t('chic.website.useSubscriptionDetails.detailsInfoSettings.storeName.label'), 
          value: subscriptionDetails.store.name
        }] : []),
        ...(!subscriptionDetails?.store && touchpointValue && [
          SubscriptionExtraFieldValue.BrandMaster, SubscriptionExtraFieldValue.Hostess
        ].includes(touchpointValue) ? [{ 
            label: t('chic.website.useSubscriptionDetails.detailsInfoSettings.touchpoint.label'), 
            value: touchpointValue === SubscriptionExtraFieldValue.BrandMaster 
              ? t('chic.website.useSubscriptionDetails.detailsInfoSettings.touchpoint.brandmaster')
              : t('chic.website.useSubscriptionDetails.detailsInfoSettings.touchpoint.hostess')
          }] : []),
        { 
          label: t('chic.website.useSubscriptionDetails.detailsInfoSettings.price.label'), 
          value: `${parsePrice(subscriptionDetails.price)} ${currency}` 
        },
        { 
          label: t('chic.website.useSubscriptionDetails.detailsInfoSettings.escPoints.label'), 
          value: String(subscriptionDetails.plan.loyaltyPoints), 
          pointsIcon: true 
        },
        ...(subscriptionDetails.paymentUrlValidity 
          ? [{ 
            label: t('chic.website.useSubscriptionDetails.detailsInfoSettings.paymentUrlValidity.label'), 
            value: subscriptionDetails.paymentUrlValidity
          }] 
          : subscriptionDetails.nextPaymentDate 
            ? [{ 
              label: t('chic.website.useSubscriptionDetails.detailsInfoSettings.waitsForPaymentUrl.label'), 
              value: subscriptionDetails.nextPaymentDate
            }] 
            : []
        )
      ];
    },
    [subscriptionDetails]
  );

  const tableData: OrderDetailsSettings[] = useMemo(
    (): OrderDetailsSettings[] => {
      if (!subscriptionDetails) {
        return [];
      }

      return subscriptionDetails.orders.map((order: SubscriptionOrder): OrderDetailsSettings => {
        const lastPaymentCode: PaymentStatusCode | undefined = order.payments.length 
          ? order.payments[order.payments.length - 1].status.code
          : undefined;
        let orderActionSettings: MenuPositionData | undefined;

        if (subscriptionDetails.active && lastPaymentCode !== PaymentStatusCode.Success) {
          if (subscriptionDetails.paymentUrl) {
            orderActionSettings = {
              label: t('chic.website.useSubscriptionDetails.tableData.paymentButtonSettings.label.payment'),
              action: (): void => redirect(subscriptionDetails.paymentUrl, undefined, true)
            }; 
          } else if (canEditDeliveryDate) {
            orderActionSettings = {
              label: t('chic.website.useSubscriptionDetails.tableData.paymentButtonSettings.label.changeDeliveryDateAction'),
              action: (): void => changeDeliveryDateAction(subscriptionDetails?.plan.name, subscriptionDetails?.id),
            };
          }
        } else if (lastPaymentCode === PaymentStatusCode.Success) {
          if (order.deliveries[0]?.trackingUrl && order.deliveries[0]?.trackingNumber) {
            orderActionSettings = {
              label: t('chic.website.useSubscriptionDetails.tableData.paymentButtonSettings.label.tracking'),
              action: (): void => redirect(order.deliveries[0].trackingUrl ?? '', undefined, true)
            }; 
          }
        }

        return ({
          date: order.deliveryDate ?? '-',
          escPoints: subscriptionDetails.plan.loyaltyPoints,
          orderStatus: subscriptionOrderStatus[order.status.code] ? {
            ...subscriptionOrderStatus[order.status.code],
            label: order.status.name,
          } : {
            label: order.status.name,
            background: Color.AlmostWhite,
            color: Color.AlmostBlack,
            dotColor: Color.Tangerine
          },
          actionButtonSettings: orderActionSettings,
          actionButtonTheme: lastPaymentCode === PaymentStatusCode.Success 
            ? OrdersHistoryButtonTheme.White 
            : OrdersHistoryButtonTheme.Gray,
          actionStatusLabel: lastPaymentCode === PaymentStatusCode.Success && !order.deliveries[0]?.trackingUrl
            ? t('chic.website.global.paid') 
            : undefined
        });
      });
    },
    [subscriptionDetails]
  );

  const deliveryAddress: string[] = useMemo(
    (): string[] => {
      if (!subscriptionDetails && pickupPointQuery.isLoading && parcelLockerQuery.isLoading) {
        return [];
      }
      
      if (subscriptionDetails?.deliveryType.code === SubscriptionDeliveryCode.InpostMachine 
        && parcelLocker) {
        return getParcelLockerAddress(parcelLocker);
      } else if (subscriptionDetails?.deliveryType.code === SubscriptionDeliveryCode.PickupPoint 
        && pickupPoint) {
        return getPickupPointAddress(pickupPoint);
      } else if (subscriptionDetails?.deliveryDestination) {
        return getDeliveryAddress(subscriptionDetails.deliveryDestination);
      } else {
        return [];
      }
    },
    [subscriptionDetails, parcelLockerQuery.isLoading, pickupPointQuery.isLoading]
  );

  const adBoxSettingsList: AdBoxSettings[] = useMemo(
    (): AdBoxSettings[] => {
      if (!subscriptionDetails) {
        return [];
      }
      
      return [
        {
          title: t('chic.website.useSubscriptionDetails.adBoxSettingsList.title.first'),
          buttonSettings: {
            label: t('chic.website.useSubscriptionDetails.adBoxSettingsList.title.button.label.first'),
            action: (): void => redirect(getRouteDetailsByName(RouteNameEnum.SubscriptionContact)?.url ?? '/')
          }
        },
        ...(subscriptionDetails.active ? [{
          title: t('chic.website.useSubscriptionDetails.adBoxSettingsList.title.second'),
          description: canEditDeliveryDate ? t('chic.website.useSubscriptionDetails.adBoxSettingsList.description.second') : undefined,
          buttonSettings: canEditDeliveryDate && subscriptionDetails?.id && subscriptionDetails?.plan.name
            ? {
              label: t('chic.website.useSubscriptionDetails.adBoxSettingsList.title.button.label.second'),
              action: (): void => changeDeliveryDateAction(subscriptionDetails?.plan.name, subscriptionDetails?.id)
            } 
            : undefined,
          validationBarMessage: !canEditDeliveryDate 
            ? t('chic.website.useSubscriptionDetails.adBoxSettingsList.validationBarMessage')
            : undefined
        }] : [])
      ];
    },
    [subscriptionDetails]
  );

  const paymentMethod: string = useMemo(
    (): string => {
      if (!subscriptionDetails?.paymentMethod) {
        return '';
      }
      switch (subscriptionDetails.paymentMethod) {
        case PaymentType.Payu:
          return t('chic.website.globals.payu');
        case PaymentType.Przelewy24:
          return t('chic.website.globals.przelewy24');
        case PaymentType.BankTransfer:
          return t('chic.website.globals.bankTransfer');
        case PaymentType.CashOnDelivery:
          return t('chic.website.globals.cashOnDelivery');
        case PaymentType.CreditCard:
          return t('chic.website.globals.creditCard');
        default:
          return subscriptionDetails.paymentMethod;
      }
    },
    [subscriptionDetails?.paymentMethod]
  );

  return {
    detailsInfoSettings,
    subscriptionDetails,
    tableData,
    canEditProducts,
    deliveryAddress,
    adBoxSettingsList,
    canChangePaymentMethod,
    paymentMethod
  };
};
