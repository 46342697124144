import {
  UseRedirect,
  useRedirect,
  UseNotifications,
  useNotifications,
  UseLocalStorage,
  useLocalStorage,
  PopupImagePosition,
} from '@chic-loyalty/ui';
import { TransProps, useTranslation } from 'react-i18next';

import { FullscreenAlertAssets, LocalStorageKey, PopupId, PopupImage } from '@chic/frontend/enums';
import { UseAuth, UseRequiredActions } from '@chic/frontend/interfaces';
import { RequiredActionType, RouteNameEnum } from '@chic/shared/enums';
import {
  SubscriptionMigrationData,
  RequiredAction,
  SubscriptionOutOfStockData,
} from '@chic/shared/interfaces';
import { getRouteDetailsByName } from '@chic/shared/utils';

import { cancelSubscription, getUrlForEscUserMigrateToIc } from '../api/requests';
import { FrontendApiError } from '../models';

import { useAuth } from './useAuth.hook';

export const useRequiredActions: () => UseRequiredActions = (): UseRequiredActions => {
  const { t }: TransProps<never> = useTranslation();
  const { redirect }: UseRedirect = useRedirect();
  const { showPopup, hidePopup, showFullscreenAlert, hideFullscreenAlert, addToast }: UseNotifications = useNotifications();
  const { userData, signOut }: UseAuth = useAuth();
  const [requiredActionsPopupsState, storeRequiredActionsPopupsState]: UseLocalStorage<Partial<Record<RequiredActionType, boolean>> | null>
    = useLocalStorage<Partial<Record<RequiredActionType, boolean>> | null>(LocalStorageKey.RequiredActionsPopups, null);

  const subscriptionOutOfStockAction: (actionDefinition: RequiredAction<SubscriptionOutOfStockData[]>) => void = (
    actionDefinition: RequiredAction<SubscriptionOutOfStockData[]>
  ): void => {
    if (requiredActionsPopupsState?.[RequiredActionType.SubscriptionOutOfStock]) {
      return;
    }

    const subscriptionsNames: string = actionDefinition.value
      .map((value: SubscriptionOutOfStockData): string => value.planName)
      .join(', ');
    const firstSubscriptionId: number = actionDefinition.value[0].subscriptionId;
    showPopup({
      id: PopupId.SubscriptionOutOfStock,
      image: PopupImage.SubscriptionOutOfStockBg,
      title: t('chic.website.requiredActions.subscriptionOutOfStock.title'),
      description: t('chic.website.requiredActions.subscriptionOutOfStock.description', { names: subscriptionsNames }),
      acceptButtonSettings: {
        label: t('chic.website.requiredActions.subscriptionOutOfStock.acceptLabel'),
        action: (): void => {
          storeRequiredActionsPopupsState({ ...requiredActionsPopupsState, [RequiredActionType.SubscriptionOutOfStock]: true });
          redirect(getRouteDetailsByName(RouteNameEnum.SubscriptionEditProducts)?.url ?? '', { subscriptionId: firstSubscriptionId });
          hidePopup({ id: PopupId.SubscriptionOutOfStock });
        },
      },
      cancelButtonSettings: {
        label: t('chic.website.requiredActions.subscriptionOutOfStock.cancelLabel'),
        action: (): void => {
          storeRequiredActionsPopupsState({ ...requiredActionsPopupsState, [RequiredActionType.SubscriptionOutOfStock]: true });
          hidePopup({ id: PopupId.SubscriptionOutOfStock });
        },
      },
      isActive: true,
      isLoading: false,
    });
  };

  const agreementsUpdateRequiredAction: () => void = (): void => {
    if (requiredActionsPopupsState?.[RequiredActionType.AgreementsUpdateRequired]) {
      return;
    }

    showPopup({
      id: PopupId.AgreementsUpdateRequired,
      image: PopupImage.AgreementsUpdateRequiredBg,
      descriptionHeader: t('chic.website.requiredActions.agreementsUpdateRequired.descriptionHeader'),
      description: t('chic.website.requiredActions.agreementsUpdateRequired.description'),
      acceptButtonSettings: {
        label: t('chic.website.requiredActions.agreementsUpdateRequired.acceptLabel'),
        action: (): void => {
          storeRequiredActionsPopupsState({ ...requiredActionsPopupsState, [RequiredActionType.AgreementsUpdateRequired]: true });
          redirect(getRouteDetailsByName(RouteNameEnum.ProfileAgreements)?.url ?? '');
          hidePopup({ id: PopupId.AgreementsUpdateRequired });
        },
      },
      isActive: true,
      isLoading: false,
    });
  };

  const upgradeSubscriptionPlanAction: (migrationData: RequiredAction<SubscriptionMigrationData>) => void = (
    migrationData: RequiredAction<SubscriptionMigrationData>
  ): void => {
    if (requiredActionsPopupsState?.[RequiredActionType.SubscriptionUpgradeRequired]) {
      return;
    }
    const planName: string = migrationData.value.name.toLowerCase().replace(' ', '_');
    const planType: string = migrationData.value.type.toLowerCase().replace(' ', '_');

    showPopup({
      id: PopupId.UpgradeSubscriptionPlan,
      image: PopupImage.UpgradeSubscriptionPlanPopupBg,
      imagePosition: PopupImagePosition.RightSideForeground,
      descriptionHeader: t('chic.website.requiredActions.upgradeSubscriptionPlan.descriptionHeader', { name: userData?.name }),
      description: t(`chic.website.requiredActions.upgradeSubscriptionPlan.${planName}_${planType}.description`),
      acceptButtonSettings: {
        label: t('chic.website.requiredActions.upgradeSubscriptionPlan.accept'),
        action: (): void => {
          redirect(
            getRouteDetailsByName(RouteNameEnum.SubscriptionPlanUpgradeConditions)?.url ?? '',
            { subscriptionId: migrationData.value.id ?? '' }
          );
          storeRequiredActionsPopupsState({ ...requiredActionsPopupsState, [RequiredActionType.SubscriptionUpgradeRequired]: true });
          hidePopup({ id: PopupId.UpgradeSubscriptionPlan });
        },
      },
      cancelButtonSettings: {
        label: t('chic.website.requiredActions.upgradeSubscriptionPlan.cancel'),
        action: (): void => {
          cancelSubscription(migrationData.value.id)
            .then((): void => {
              addToast({
                content: t('chic.website.requiredActions.upgradeSubscriptionPlan.cancel.success')
              });
            })
            .catch((): void => undefined);
          storeRequiredActionsPopupsState({ ...requiredActionsPopupsState, [RequiredActionType.SubscriptionUpgradeRequired]: true });
          hidePopup({ id: PopupId.UpgradeSubscriptionPlan });
        }
      },
      closeIconAction: (): void => {
        hidePopup({ id: PopupId.UpgradeSubscriptionPlan });
      },
      isActive: true,
      isLoading: false,
    });
  };

  const migrationAction: () => void = () => {
    getUrlForEscUserMigrateToIc()
      .then((path: string): void => {
        signOut(undefined, false);
        redirect(path, undefined, true);
      })
      .catch((error: FrontendApiError): void => {
        showFullscreenAlert({
          title: t('chic.website.global.error'),
          description: error.message,
          image: FullscreenAlertAssets.Woman,
          acceptButtonSettings: {
            label: t('chic.website.global.ok'),
            action: hideFullscreenAlert,
          },
        });
      });
    hidePopup({ id: PopupId.MigrationPopupRequired });
  };

  const migrationToIcPopupRequiredAction: () => void = (): void => {
    if (requiredActionsPopupsState?.[RequiredActionType.InspirationClubMigration]) {
      return;
    }

    showPopup({
      id: PopupId.MigrationPopupRequired,
      image: PopupImage.MigrationPopupRequiredBg,
      imagePosition: PopupImagePosition.RightSideForeground,
      annotation: t('chic.website.requiredActions.migrationToIcPopupRequired.annotation'),
      descriptionHeader: t('chic.website.requiredActions.migrationToIcPopupRequired.descriptionHeader'),
      description: t('chic.website.requiredActions.migrationToIcPopupRequired.description'),
      acceptButtonSettings: {
        label: t('chic.website.requiredActions.migrationToIcPopupRequired.buttonLabel'),
        action: migrationAction
      },
      closeIconAction: (): void => {
        hidePopup({ id: PopupId.MigrationPopupRequired });
      },
      isLoading: false,
    });
  };

  return {
    subscriptionOutOfStockAction,
    agreementsUpdateRequiredAction,
    upgradeSubscriptionPlanAction,
    migrationToIcPopupRequiredAction,
    migrationAction,
  };
};
