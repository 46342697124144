import { DropdownOption } from '@chic-loyalty/ui';

import { FormField } from '@chic/frontend/interfaces';
import { PreferredLanguage } from '@chic/shared/enums';

import { YourDataField } from './yourData.enums';

export const availableLanguages: DropdownOption[] = [
  { name: PreferredLanguage.Pl, label: 'Polski' },
  { name: PreferredLanguage.En, label: 'English' },
  { name: PreferredLanguage.Ua, label: 'Українська' },
  { name: PreferredLanguage.Cz, label: 'český' },
  { name: PreferredLanguage.De, label: 'Deutsch' },
  { name: PreferredLanguage.Sk, label: 'slovenský' },
  { name: PreferredLanguage.Lt, label: 'lietuvių' },
  { name: PreferredLanguage.No, label: 'norsk' },
  { name: PreferredLanguage.Es, label: 'español' },
];

export const yourDataFields: FormField<YourDataField>[] = [
  {
    name: YourDataField.FirstName,
    label: 'chic.website.yourDataView.firstName',
    placeholder: 'chic.website.yourDataView.firstName',
    type: 'text',
    required: true,
  },
  {
    name: YourDataField.LastName,
    label: 'chic.website.yourDataView.lastName',
    placeholder: 'chic.website.yourDataView.lastName',
    type: 'text',
    required: true,
  },
  {
    name: YourDataField.Email,
    label: 'chic.website.yourDataView.email',
    placeholder: 'chic.website.yourDataView.email',
    type: 'text',
    required: true,
  },
  {
    name: YourDataField.Phone,
    label: 'chic.website.yourDataView.phone',
    placeholder: 'chic.website.yourDataView.phone',
    type: 'text',
    hasPhonePrefix: true,
    required: true,
    description: 'chic.website.yourDataView.phoneDescription',
  },
  {
    name: YourDataField.PreferredLanguage,
    label: 'chic.website.yourDataView.preferredLanguage',
    placeholder: 'chic.website.yourDataView.preferredLanguage',
    type: 'dropdown',
    options: availableLanguages,
    initialValue: availableLanguages.find((item: DropdownOption): boolean => item.name === PreferredLanguage.Pl)
  }
];
