import { IsBoolean, IsDefined, IsNumber } from 'class-validator';

export class UpdateFavouritesStoresRequest {
  @IsDefined()
  @IsNumber()
  public storeId: number;

  @IsDefined()
  @IsBoolean()
  public selected: boolean;

  constructor(
    storeId: number,
    selected: boolean,
  ) {
    this.storeId = storeId;
    this.selected = selected;
  }
}
