import { Breakpoint, Color, StyledComponent } from '@chic-loyalty/ui';
import styled from 'styled-components';

import { ContainerProps } from './regulations.types';

export const Container: StyledComponent<'div', ContainerProps> = styled.div<ContainerProps>`
  background-color: ${Color.Level1};
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ $withLeftPadding }: ContainerProps): string | false => $withLeftPadding && 'padding-left: 80px'};

  @media ${Breakpoint.Mobile} {
    padding-left: 0;
  }
`;
