import { injectedInternalUrlsRegexp } from '@chic/shared/constants';
import { RouteNameEnum } from '@chic/shared/enums';
import { RouteInfo } from '@chic/shared/interfaces';

import { getRouteDetailsByName } from './getRouteDetails.utils';

export function injectInternalUrlToMarkdown(content: string): string {
  const urlsKeysToReplace: string[] | null = content.match(injectedInternalUrlsRegexp);
  
  if (!urlsKeysToReplace) {
    return content;
  }
  
  let replacedContent: string = content;
  urlsKeysToReplace.forEach((urlKey: string): void => {
    const routeNameMatch: RegExpMatchArray | null = urlKey.match(/internal\-(?<routeName>[a-zA-Z]+)/);
    const routePath: RouteInfo | undefined = getRouteDetailsByName((routeNameMatch?.groups?.['routeName'] ?? '') as RouteNameEnum);
    replacedContent = replacedContent.replace(urlKey, routePath?.url ?? '');
  });

  return replacedContent;
}
