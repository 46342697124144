import { StyledComponent, Color, FontWeight, Markdown } from '@chic-loyalty/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div``;

export const CollapsedAnnotationWrapper: StyledComponent<'div'> = styled.div``;

export const Annotation: StyledComponent<typeof Markdown> = styled(Markdown)`
  font-weight: ${FontWeight.Medium};
  font-size: 10px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: ${Color.SolidMediumEmphasis};
  margin-bottom: 20px;

  a {
    color: ${Color.Tangerine};
  }
`;

export const ShowMore: StyledComponent<'button'> = styled.button.attrs({ type: 'button' })`
  font-weight: ${FontWeight.Medium};
  font-size: 10px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: ${Color.Tangerine};
  border-bottom: 1px solid ${Color.Tangerine};
`;
