import {
  Breakpoint,
  Button,
  Checkbox,
  Color,
  ContentBox,
  Dropdown,
  FieldsRow,
  FontWeight,
  Input,
  Markdown,
  StyledComponent,
  ValidationBar,
} from '@chic-loyalty/ui';
import styled from 'styled-components';

import { StyledContentBoxProps } from './registration.types';

export const Container: StyledComponent<'div'> = styled.div`
  width: 100%;
  background-color: ${Color.AlmostBlack};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const FormikForm: StyledComponent<'form'> = styled.form`
  width: 100%;
`;

export const RefWrapper: StyledComponent<'div'> = styled.div``;

export const SmsBoxBottomRef: StyledComponent<'div'> = styled.div`
  scroll-margin-bottom: 155px;
`;

export const StyledButton: StyledComponent<typeof Button> = styled(Button)`
  margin-top: 50px;
`;

export const StyledContentBox: StyledComponent<typeof ContentBox, StyledContentBoxProps> = styled(ContentBox)<StyledContentBoxProps>`
  margin: 70px auto ${({ $withBottomMargin }: StyledContentBoxProps): string => $withBottomMargin ? '70px' : '0'};

  & + & {
    margin-top: 10px;
  }

  @media ${Breakpoint.Tablet} {
    margin: 0 auto;
  }
`;

export const StyledInput: StyledComponent<typeof Input> = styled(Input)``;

export const StyledFieldsRow: StyledComponent<typeof FieldsRow> = styled(FieldsRow)`
  & + & {
    margin-top: 10px;
  }
`;

export const StyledDropdown: StyledComponent<typeof Dropdown> = styled(Dropdown)`
  & + &,
  & + ${StyledInput},
  ${StyledInput} + & {
    margin-top: 10px;
  }
`;

export const StyledCheckbox: StyledComponent<typeof Checkbox> = styled(Checkbox)`
  width: 100%;

  & + & {
    padding-top: 15px;
  }
`;

export const AgreementsSmallHeader: StyledComponent<'div'> = styled.div`
  font-weight: ${FontWeight.SemiBold};
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.4px;
  color: ${Color.SolidMediumEmphasis};
  margin-bottom: 10px;
`;

export const MarketingAgreementsPrefix: StyledComponent<'div'> = styled.div`
  font-size: 12px;
  line-height: 19px;
  color: ${Color.SolidMediumEmphasis};
  margin-bottom: 17px;
`;

export const MarketingDiscountWarning: StyledComponent<'div'> = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${Color.Validation};
  padding: 0 0 20px;
`;

export const PrivacyPolicyHeader: StyledComponent<'button'> = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  width: 100%;
  padding: 20px 0;
  border-top: 1px solid ${Color.SolidLowestEmphasis};
  border-bottom: 1px solid ${Color.SolidLowestEmphasis};
`;

export const PrivacyPolicyHeaderText: StyledComponent<'div'> = styled.div`
  font-weight: ${FontWeight.Medium};
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.4px;
  color: ${Color.SolidHighEmphasis};
  max-width: 634px;
  margin-right: 20px;
`;

export const PrivacyPolicyContent: StyledComponent<'div'> = styled.div``;

export const SeparatingLine: StyledComponent<'div'> = styled.div`
  border-top: 1px solid ${Color.SolidLowestEmphasis};
  width: 100%;
  height: 1px;
  margin: 20px 0;
`;

export const Annotation: StyledComponent<'div'> = styled.div`
  font-weight: ${FontWeight.Medium};
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: ${Color.SolidLowEmphasis};
  margin: 10px 0 0 27px;
`;

export const PrivacyPolicyContentWrapper: StyledComponent<'div'> = styled.div`
  background: ${Color.Level2};
  padding: 36px 40px;
`;

export const PrivacyPolicyContentText: StyledComponent<typeof Markdown> = styled(Markdown)`
  font-size: 12px;
  line-height: 19px;
  color: ${Color.SolidMediumEmphasis};

  p, a {
    color: ${Color.SolidMediumEmphasis};
    text-decoration: none;
  }

  p + p {
    margin-top: 25px;
  }
`;

export const RequiredFieldsLabel: StyledComponent<'div'> = styled.div`
  font-size: 12px;
  line-height: 160%;
  color: ${Color.SolidLowEmphasis};
  padding: 15px 0 50px;
`;

export const EscRegulationInfo: StyledComponent<typeof Markdown> = styled(Markdown)`
  font-size: 14px;
  line-height: 22px;
  color: ${Color.SolidHighEmphasis};
  display: block;

  a {
    color: ${Color.Tangerine};
    text-decoration: none;
  }
`;

export const SmsCodeHeader: StyledComponent<'div'> = styled.div`
  font-weight: ${FontWeight.Medium};
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.4px;
  color: ${Color.OpacityHighEmphasis};
  margin-bottom: 20px;
`;

export const StyledValidationBar: StyledComponent<typeof ValidationBar> = styled(ValidationBar)`
  margin-top: 45px;

  @media ${Breakpoint.Tablet} {
    margin-top: 25px;
  }
`;
