import { IsDefined } from 'class-validator';

export class LoginByExternalVendor {
  @IsDefined()
  public type: string;

  @IsDefined()
  public value: string;

  constructor(
    type: string,
    value: string,
  ) {
    this.type = type;
    this.value = value;
  }
}
