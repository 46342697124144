import { IsDefined, IsString, Matches } from 'class-validator';

import { passwordRegexp } from '@chic/shared/constants';

export class SetPasswordRequest {
  @IsDefined()
  @IsString()
  @Matches(passwordRegexp)
  public password: string;

  constructor(
    password: string,
  ) {
    this.password = password;
  }
}
