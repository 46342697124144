import { ElementsTableColumn, TextAlign } from '@chic-loyalty/ui';

export const tableColumns: ElementsTableColumn[] = [
  { 
    name: 'no', 
    label: 'chic.website.transactionsListView.elementsTable.no', 
    options: { 
      align: TextAlign.Right,
      mobileHide: true,
    } 
  },
  { 
    name: 'datetime', 
    label: 'chic.website.transactionsListView.elementsTable.dateTime',
  },
  { 
    name: 'storeName', 
    label: 'chic.website.transactionsListView.elementsTable.storeName',
    options: {
      mobileHide: true,
    }
  },
  { 
    name: 'type', 
    label: 'chic.website.transactionsListView.elementsTable.type',
    options: {
      mobileHide: true,
    }
  },
  { 
    name: 'points', 
    label: 'chic.website.transactionsListView.elementsTable.points', 
  },
];
