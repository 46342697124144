import { IsDefined, IsIP } from 'class-validator';

import { SendStatsForMessagesRequest } from './sendStatsForMessagesRequest.model';

export class SendStatsForMessagesCoreRequest extends SendStatsForMessagesRequest {
  @IsDefined()
  @IsIP()
  public ip: string;

  constructor(
    messageId: number,
    readed: boolean,
    clicked: boolean,
    ip: string,
  ) {
    super(messageId, readed, clicked);
    this.ip = ip;
  }
}
