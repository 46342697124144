import { Breakpoint, Color, FontWeight, Grid, StyledComponent } from '@chic-loyalty/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  background-color: ${Color.AlmostBlack};
  min-height: 100vh;
  width: calc(100% - 80px);
  margin-left: auto;

  @media ${Breakpoint.Mobile} {
    width: 100%;
  }
`;

export const TopSection: StyledComponent<'div'> = styled.div`
  background-color: ${Color.Level1};
  padding: 0 20px;
  overflow: hidden;

  @media ${Breakpoint.Mobile} {
    padding: 0;
  }
`;

export const InnerTopSectionContainer: StyledComponent<'div'> = styled.div`
  align-items: center;
  margin: 0 auto;
  max-width: ${Grid.Desktop};
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 50px 0 100px;
  
  @media ${Breakpoint.Mobile} {
    padding: 0;
    grid-template-columns: 1fr;
  }
`;

export const TopSectionLeft: StyledComponent<'div'> = styled.div`
  @media ${Breakpoint.Mobile} {
    padding: 50px 20px 35px;
  }
`;

export const LogoImage: StyledComponent<'img'> = styled.img`
  height: 100px;
  margin-bottom: 40px;

  @media ${Breakpoint.Mobile} {
    width: 285px;
    height: auto;
  }
`;

export const WomanImage: StyledComponent<'img'> = styled.img`
  @media ${Breakpoint.Mobile} {
    width: 100%;
  }
`;

export const TopSectionTitle: StyledComponent<'h1'> = styled.h1`
  font-weight: ${FontWeight.Medium};
  font-size: 31px;
  line-height: 40px;
  letter-spacing: -0.02em;
  color: ${Color.SolidHighEmphasis};
`;

export const TopSectionText: StyledComponent<'p'> = styled.p`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: ${Color.SolidMediumEmphasis};
`;

export const BenefitsSection: StyledComponent<'div'> = styled.div`
  padding: 100px 20px;
`;

export const InnerBenefitsSectionContainer: StyledComponent<'div'> = styled.div`
  max-width: ${Grid.Desktop};
  margin: 0 auto;
`;

export const BenefitsTitle: StyledComponent<'h2'> = styled.h2`
  font-weight: ${FontWeight.Medium};
  font-size: 25px;
  line-height: 35px;
  letter-spacing: -0.01em;
  margin-bottom: 45px;
  color: ${Color.SolidHighEmphasis};
  text-align: center;
`;

export const BenefitBoxes: StyledComponent<'div'> = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;

  @media ${Breakpoint.Mobile} {
    grid-template-columns: 1fr 1fr;
  }
`;

export const BatteriesImage: StyledComponent<'img'> = styled.img`
  grid-column: 2 / span 2;
  max-width: 100%;
  border-radius: 12px;
  object-fit: cover;
  height: 100%;

  @media ${Breakpoint.Mobile} {
    grid-column: 1 / span 2;
  }
`;
