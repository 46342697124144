import { 
  Breakpoint,
  Button, 
  ButtonTheme, 
  Checkbox, 
  Color, 
  ComponentColorTheme, 
  DeliveryPointMap, 
  DetailsInfoGroup, 
  FieldsRow, 
  Icon, 
  IconName, 
  Input, 
  InputTheme, 
  InputWithLocation, 
  MapPointDelivery, 
  OptionsSwitch, 
  OptionSwitchOption, 
  PageHeader,
  UseState,
} from '@chic-loyalty/ui';
import { Formik, FormikProps } from 'formik';
import React, { useState, RefObject, useRef, useMemo } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Params, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { FileFromViews } from '@chic/frontend/enums';
import { useAuth, useDeliveriesTransformation } from '@chic/frontend/hooks';
import { useDeliveryMap } from '@chic/frontend/hooks/useDeliveryMap.hook';
import { 
  UseAuth, 
  UseDeliveriesTransformation, 
  UseDeliveryMap,  
} from '@chic/frontend/interfaces';
import { SubscriptionDeliveryCode } from '@chic/shared/enums';
import { UpdateSubscriptionOrderDestinationRequest } from '@chic/shared/models';

import { useUpdateSubscriptionDelivery, useSubscriptionDeliveryNotAuthorizedValidations } from './hooks';
import { 
  Container, 
  ContentWrapper, 
  DetailsWrapper, 
  DetailsBox,
  DetailsBoxHeader,
  DetailsBoxDescription,
  ImageBox, 
  Image, 
  ContentBox,
  ContentTitle,
  BottomBox, 
  ButtonsWrapper,
  ListContainerWrapper,
  ListContainer,
  StyledSearchDeliveryListItem,
  MapContainer,
  DeliveryFormWrapper,
  FormikForm,
  DeliveryImage,
} from './subscriptionDeliveryNotAuthorizedUpdate.styled';
import { 
  UpdateSubscriptionNotAuthorizedDestinationBasicData, 
  UseSubscriptionNotAuthorizedDeliveryUpdate 
} from './subscriptionDeliveryNotAuthorizedUpdate.types';

export const SubscriptionDeliveryNotAuthorizedUpdateView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { userData }: UseAuth = useAuth();
  const [selectedDelivery, setSelectedDelivery]: UseState<SubscriptionDeliveryCode | undefined>
    = useState<SubscriptionDeliveryCode | undefined>(undefined);
  const { token, orderId }: Readonly<Params<string>> = useParams();
  const listRef: RefObject<HTMLDivElement> = useRef(null);
  const { transformSubscriptionDeliveryCostToOptionSwitchOption }: UseDeliveriesTransformation = useDeliveriesTransformation();
  const {
    inputValue,
    mapPoints,
    activePointId,
    pointPosition,
    shouldFlyToPoint,
    onSearchInputChange,
    onLocationButtonClick,
    onSelectDeliveryPoint,
    onPositionChange,
    setShouldFlyToPoint,
    setActivePointId,
    setPointPosition,
    setCurrentPosition,
    setInputValue,
  }: UseDeliveryMap = useDeliveryMap(listRef, selectedDelivery);
  const { 
    orderDetails,
    getLabelFromDeliveries,
    startFormApiRequest,
    isApiRequestInProgress,
    goHome
  }: UseSubscriptionNotAuthorizedDeliveryUpdate = useUpdateSubscriptionDelivery(token, orderId);
  const subscriptionDeliveryNotAuthorizedValidations: 
    Yup.SchemaOf<UpdateSubscriptionNotAuthorizedDestinationBasicData> = useSubscriptionDeliveryNotAuthorizedValidations();
  const [resendDelivery, setResendDelivery]: UseState<boolean> = useState<boolean>(false);
  const [formikValues, setFormikValues]: UseState<UpdateSubscriptionNotAuthorizedDestinationBasicData | null> 
    = useState<UpdateSubscriptionNotAuthorizedDestinationBasicData | null>(null);
  const [isFormValid, setIsFormValid]: UseState<boolean> = useState<boolean>(true);
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });
  
  const onDeliveryChange: (name: string) => void = (name: string): void => {
    setActivePointId(null);
    setPointPosition(null);
    setCurrentPosition(null);
    setInputValue('');
    setSelectedDelivery(name as SubscriptionDeliveryCode);
    setSelectedDelivery(name as SubscriptionDeliveryCode);
  };

  const handleFormSubmit: () => void = (): void => {
    if (!!orderDetails && !!token && (!!selectedDelivery || resendDelivery)) {
      const requestData: UpdateSubscriptionOrderDestinationRequest = new UpdateSubscriptionOrderDestinationRequest(
        orderDetails.orderId,
        token,
        resendDelivery ? orderDetails.type : selectedDelivery as SubscriptionDeliveryCode,
        resendDelivery 
          ? orderDetails.type === SubscriptionDeliveryCode.InpostMachine
            ? orderDetails.inpostId
            : null
          : selectedDelivery === SubscriptionDeliveryCode.InpostMachine
            ? activePointId
            : null,
        !resendDelivery && selectedDelivery === SubscriptionDeliveryCode.PickupPoint
          ? activePointId
          : null,
        formikValues?.name ?? orderDetails.deliveryName ?? '',
        formikValues?.address ?? orderDetails.deliveryAddress ?? '',
        formikValues?.city ?? orderDetails.deliveryCity ?? '',
        formikValues?.postalCode ?? orderDetails.deliveryPostalCode ?? '',
      );
      startFormApiRequest(requestData);
    }
  };

  const isDisabled: boolean = useMemo(
    (): boolean => {
      if (orderDetails?.hasBeenSent && resendDelivery) {
        return false;
      }

      if (!orderDetails || !selectedDelivery) {
        return true;
      }

      if (isFormValid 
        && selectedDelivery !== SubscriptionDeliveryCode.InpostMachine 
        && selectedDelivery !== SubscriptionDeliveryCode.PickupPoint
      ) {
        return false;
      }

      if (activePointId) {
        return false;
      }

      return true;
    },
    [orderDetails, resendDelivery, selectedDelivery, activePointId, isFormValid]
  );

  const mappedDeliveriesToOptionSwitch: OptionSwitchOption[] = useMemo(
    (): OptionSwitchOption[] => orderDetails?.plan?.deliveries.map(transformSubscriptionDeliveryCostToOptionSwitchOption) ?? [],
    [orderDetails?.plan?.deliveries]
  );
  
  return (
    <Container $withLeftPadding={!!userData}>
      <PageHeader 
        header={orderDetails?.hasBeenSent 
          ? t('chic.website.subscriptionDeliveryNotAuthorizedUpdateView.unclaimedPackage') 
          : t('chic.website.subscriptionDeliveryNotAuthorizedUpdateView.inactiveInpostMachine')
        } 
      />
      {orderDetails && (
        <ContentWrapper>
          <DetailsWrapper>
            <DetailsBox>
              <DetailsBoxHeader>
                {orderDetails.hasBeenSent 
                  ? t('chic.website.subscriptionDeliveryNotAuthorizedUpdateView.unclaimedParcelReturned')
                  : t('chic.website.subscriptionDeliveryNotAuthorizedUpdateView.inactiveParcelLocker')
                }
              </DetailsBoxHeader>
              <DetailsBoxDescription>
                {orderDetails.hasBeenSent 
                  ? t('chic.website.subscriptionDeliveryNotAuthorizedUpdateView.formResubmission')
                  : t('chic.website.subscriptionDeliveryNotAuthorizedUpdateView.selectAnotherOneToResend')
                }  
              </DetailsBoxDescription>
              <DetailsInfoGroup labelSize={9} valueSize={12} items={[
                {
                  label: t('chic.website.global.orderID'),
                  value: String(orderDetails.orderId),
                },
                {
                  label: t('chic.website.global.emailAddress'),
                  value: orderDetails.email,
                  wrap: true,
                },
                {
                  label: getLabelFromDeliveries(orderDetails.type, orderDetails.plan?.deliveries ?? []),
                  value: orderDetails.type === SubscriptionDeliveryCode.InpostMachine 
                    ? String(orderDetails.externalId)
                    : `${orderDetails.deliveryName ?? ''} ${orderDetails.deliveryAddress ?? ''} 
                    ${orderDetails.deliveryPostalCode ?? ''} ${orderDetails.deliveryCity ?? ''}`,
                },
              ]} />
            </DetailsBox>
            <ImageBox>
              <Image src={FileFromViews.SubscriptionsManualWorker} />
            </ImageBox>
          </DetailsWrapper>
          {orderDetails?.hasBeenSent && (
            <ContentBox>
              <ContentTitle>{t('chic.website.subscriptionDeliveryNotAuthorizedUpdateView.resendToPreviousAddress')}</ContentTitle>
              <Checkbox 
                id='resendDelivery'
                label={orderDetails.type === SubscriptionDeliveryCode.InpostMachine 
                  ? t('chic.website.subscriptionDeliveryNotAuthorizedUpdateView.resendOrderToPreviousParcelLocker')
                  : t('chic.website.subscriptionDeliveryNotAuthorizedUpdateView.resendOrderAgainToPreviousAddress')
                } 
                onChange={(): void => setResendDelivery(!resendDelivery)}
              />
            </ContentBox>
          )}
          {!resendDelivery && orderDetails?.canBeChangedDeliveryType && orderDetails.plan?.deliveries && (
            <ContentBox>
              <ContentTitle>{t('chic.website.global.deliveryMethod')}</ContentTitle>
              <OptionsSwitch 
                options={mappedDeliveriesToOptionSwitch}
                onOptionChange={onDeliveryChange}
                activeOptionName={selectedDelivery}
              />
            </ContentBox>
          )}
          {!resendDelivery &&
            selectedDelivery &&
            [SubscriptionDeliveryCode.InpostMachine, SubscriptionDeliveryCode.PickupPoint].includes(selectedDelivery) &&
            (
              <DetailsWrapper>
                <ContentBox>
                  <ContentTitle>
                    {
                      orderDetails.type === SubscriptionDeliveryCode.InpostMachine
                        ? t('chic.website.subscriptionDeliveryNotAuthorizedUpdateView.chooseNewParcelLocker')
                        : t('chic.website.subscriptionDeliveryNotAuthorizedUpdateView.chooseNewDeliveryPoint')
                    }
                  </ContentTitle>
                  <InputWithLocation
                    inputValue={inputValue} 
                    placeholder={t('chic.website.global.search')} 
                    onChange={onSearchInputChange} 
                    onLocationButtonClick={onLocationButtonClick} 
                    onPositionChange={onPositionChange}
                    colorTheme={ComponentColorTheme.Dark}
                  />
                  <ListContainerWrapper>
                    <ListContainer ref={listRef}>
                      {mapPoints.map((point: MapPointDelivery): JSX.Element => (
                        <StyledSearchDeliveryListItem
                          data={point}
                          onChoose={(itemId: string): void => {
                            setActivePointId(parseInt(itemId, 10));
                            setShouldFlyToPoint(true);
                          }}
                          radioButtonName='points'
                          checked={point.id === activePointId}
                          key={point.id}
                        />
                      ))}
                    </ListContainer>
                  </ListContainerWrapper>
                </ContentBox>
                <MapContainer>
                  <DeliveryPointMap
                    points={mapPoints}
                    onSelectDeliveryPoint={onSelectDeliveryPoint}
                    enable
                    currentPosition={pointPosition}
                    shouldFlyToPosition={shouldFlyToPoint}
                    onCoordinatesChange={(lat: number, lng: number): void => {
                      setActivePointId(null);
                      setPointPosition([lat, lng]);
                      setShouldFlyToPoint(false);
                    }}
                  />
                </MapContainer>
              </DetailsWrapper>
            )
          }
          {!resendDelivery && selectedDelivery &&
            ![SubscriptionDeliveryCode.InpostMachine, SubscriptionDeliveryCode.PickupPoint]
              .includes(selectedDelivery) &&
            (
              <DetailsWrapper>
                <ContentBox>
                  <ContentTitle>{t('chic.website.global.editDeliveryAddress')}</ContentTitle>
                  <DeliveryFormWrapper>
                    <Formik
                      initialValues={{
                        name: orderDetails?.deliveryName ?? '',
                        address: orderDetails?.deliveryAddress ?? '',
                        postalCode: orderDetails?.deliveryPostalCode ?? '',
                        city: orderDetails?.deliveryCity ?? '',
                      }}
                      innerRef={(formikActions: FormikProps<UpdateSubscriptionNotAuthorizedDestinationBasicData> | null) => {
                        setFormikValues(formikActions?.values ?? null);
                        setIsFormValid(formikActions?.isValid ?? false);
                      }}
                      onSubmit={() => undefined}
                      validationSchema={subscriptionDeliveryNotAuthorizedValidations}
                      validateOnChange={true}
                      validateOnBlur={true}
                      enableReinitialize
                    >
                      {({ 
                        handleSubmit, setFieldValue, errors, values
                      }: FormikProps<UpdateSubscriptionNotAuthorizedDestinationBasicData>): JSX.Element => (
                        <FormikForm onSubmit={handleSubmit}>
                          <Input
                            label={t('chic.website.global.recipientName')}
                            type='text'
                            onChange={(value: string): void => setFieldValue('name', value)}
                            value={values.name}
                            message={errors.name}
                            inputTheme={errors.name ? InputTheme.Error : InputTheme.Standard}
                          />
                          <Input
                            label={t('chic.website.global.address')}
                            placeholder={t('chic.website.global.address')}
                            type='text'
                            value={values.address}
                            onChange={(value: string): void => setFieldValue('address', value)}
                            message={errors.address}
                            inputTheme={errors.address ? InputTheme.Error : InputTheme.Standard}
                          />
                          <FieldsRow>
                            <Input
                              label={t('chic.website.global.postalCode')}
                              placeholder={t('chic.website.global.postalCode')}
                              type='text'
                              numericMask='##-###'
                              value={values.postalCode}
                              onChange={(value: string): void => setFieldValue('postalCode', value)}
                              message={errors.postalCode}
                              inputTheme={errors.postalCode ? InputTheme.Error : InputTheme.Standard}
                            />
                            <Input
                              label={t('chic.website.global.city')}
                              placeholder={t('chic.website.global.city')}
                              type='text'
                              value={values.city}
                              onChange={(value: string): void => setFieldValue('city', value)}
                              message={errors.city}
                              inputTheme={errors.city ? InputTheme.Error : InputTheme.Standard}
                            />
                          </FieldsRow>
                        </FormikForm>
                      )}
                    </Formik>
                    <DeliveryImage>
                      <Icon name={IconName.Truck} size={128} color={Color.SolidHighEmphasis} />
                    </DeliveryImage>
                  </DeliveryFormWrapper>
                </ContentBox>
              </DetailsWrapper>
            )
          }
        </ContentWrapper>
      )}
      <BottomBox>
        <ButtonsWrapper>
          <Button
            label={t('chic.website.global.confirm')}
            onClick={handleFormSubmit}
            isLoading={isApiRequestInProgress} 
            disabled={isDisabled}
            fullWidth={isMobile}
          />
          <Button label={t('chic.website.global.cancel')} onClick={goHome} buttonTheme={ButtonTheme.SecondaryWhite} fullWidth={isMobile} />
        </ButtonsWrapper>
      </BottomBox>
    </Container>
  );
};
