import { IsDefined, IsEmail, IsEnum, IsString } from 'class-validator';

import { PreferredLanguage } from '@chic/shared/enums';

export class UpdateProfileRequest {
  @IsDefined()
  @IsString()
  public phone: string;

  @IsDefined()
  @IsEmail()
  public email: string;

  @IsDefined()
  @IsString()
  public firstName: string;

  @IsDefined()
  @IsString()
  public lastName: string;

  @IsDefined()
  @IsEnum(PreferredLanguage)
  public preferredLanguage: PreferredLanguage;

  @IsDefined()
  @IsString()
  public hash: string;

  constructor(
    phone: string,
    email: string,
    firstName: string,
    lastName: string,
    preferredLanguage: PreferredLanguage,
    hash: string,
  ) {
    this.phone = phone;
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.preferredLanguage = preferredLanguage;
    this.hash = hash;
  }
}
