import { TransProps, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { phoneRegexp, nameRegexp } from '@chic/shared/constants';
import { PreferredLanguage } from '@chic/shared/enums';
import { UpdateProfileRequest } from '@chic/shared/models';

export const useUpdateProfileValidation: () => Yup.SchemaOf<UpdateProfileRequest> = (): Yup.SchemaOf<UpdateProfileRequest> => {
  const { t }: TransProps<never> = useTranslation();

  return Yup.object().shape({
    phone: Yup.string()
      .transform((value: string | null | undefined) => value && value.replace(/\s+/g, ''))
      .matches(phoneRegexp, t('chic.website.global.validations.invalidPhone'))
      .required(t('chic.website.global.requiredField')),
    email: Yup.string()
      .email(t('chic.website.global.wrongValue'))
      .required(t('chic.website.global.requiredField')),
    firstName: Yup.string()
      .matches(nameRegexp, t('chic.website.global.wrongValue'))
      .min(2, t('chic.website.global.wrongValue'))
      .max(64, t('chic.website.global.wrongValue'))
      .required(t('chic.website.global.requiredField')),
    lastName: Yup.string()
      .matches(nameRegexp, t('chic.website.global.wrongValue'))
      .min(2, t('chic.website.global.wrongValue'))
      .max(128, t('chic.website.global.wrongValue'))
      .required(t('chic.website.global.requiredField')),
    preferredLanguage: Yup.mixed<PreferredLanguage>()
      .oneOf(Object.values(PreferredLanguage))
      .required(t('chic.website.global.requiredField')),
    hash: Yup.string().required('')
  });
};
