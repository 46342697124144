import { AxiosResponse } from 'axios';

import { FrontendApiError } from '@chic/frontend/models';
import { ApiReject, ApiResolve } from '@chic/frontend/types';
import { Post, PostDetails } from '@chic/shared/interfaces';

import { api } from '../api';

export const getPostDetails: (id: string) => Promise<PostDetails> = (id: string): Promise<PostDetails> => new Promise(
  (resolve: ApiResolve<PostDetails>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get(`/posts/${id}`).then(
      (response: AxiosResponse<PostDetails>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getPosts: () => Promise<Post[]> = (): Promise<Post[]> => new Promise(
  (resolve: ApiResolve<Post[]>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get('/posts').then(
      (response: AxiosResponse<Post[]>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);
