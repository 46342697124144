import { useLocalStorage, UseLocalStorage, UseNotifications, useNotifications, useRedirect, UseRedirect } from '@chic-loyalty/ui';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { getMessaging, getToken, MessagePayload, Messaging, onMessage, isSupported } from 'firebase/messaging';
import { TransProps, useTranslation } from 'react-i18next';

import { registerMessagesToken, sendStatsForMessages } from '@chic/frontend/api/requests';
import { appConfig } from '@chic/frontend/app.config';
import { LocalStorageKey, PopupId, PopupImage } from '@chic/frontend/enums';
import { MessagesLocalSettings, UseFirebase } from '@chic/frontend/interfaces';
import { FrontendApiError } from '@chic/frontend/models';
import { RouteNameEnum } from '@chic/shared/enums';
import { getPathWithParams, getRouteDetailsByName } from '@chic/shared/utils';

export const useFirebase: () => UseFirebase = (): UseFirebase => {
  const { addPush, addToast, showPopup, hidePopup }: UseNotifications = useNotifications();
  const { t }: TransProps<never> = useTranslation();
  const { redirect }: UseRedirect = useRedirect();
  const [messagesLocalSettings, storeMessagesLocalSettings]: UseLocalStorage<MessagesLocalSettings | null>
    = useLocalStorage<MessagesLocalSettings | null>(LocalStorageKey.MessagesLocalSettings, null);
  const oneDayInMs: number = 60*60*1000 * 24;

  const onMessageHandler: (payload: MessagePayload) => void = (payload: MessagePayload): void => {
    addPush({
      pushId: payload.messageId,
      title: payload.data?.title ?? t('chic.website.useFirebase.defaultTitle'),
      category: t(`chic.website.notifications.categories.${payload.data?.category ?? 'default'}`),
      description: payload.data?.body ?? '',
      onSettingsClick: (): void => {
        redirect(getRouteDetailsByName(RouteNameEnum.ProfileNotificationsSettings)?.url ?? '');
      },
      ...((payload.data?.link && payload.data?.messageId)
        ? {
          primaryButtonSettings: {
            label: t('chic.website.useFirebase.go'),
            action: (): void => {
              const messageId: number = Number(payload.data?.messageId);
              if (!isNaN(messageId)) {
                void sendStatsForMessages({ messageId, clicked: true, readed: false });
                redirect(getPathWithParams(payload.data?.link ?? '', { nId: String(messageId) }));
              }
            },
          }
        }
        : {}
      )
    });
  };

  const initializeFirebaseMessages: () => void = (): void => {
    if (
      !('Notification' in window)
      || Notification.permission !== 'default'
      || (
        messagesLocalSettings?.status === 'denied'
        && ((messagesLocalSettings.statusUpdateTimestamp + (oneDayInMs * 7)) > new Date().getTime())
      )
    ) {
      return;
    }

    void isSupported().then((messagesSupported: boolean): void => {
      if (!messagesSupported) {
        return;
      }

      showPopup({
        id: PopupId.NotificationsFirebase,
        image: PopupImage.NotificationsBg,
        descriptionHeader: t('chic.website.useFirebase.initialPopup.title'),
        description: t('chic.website.useFirebase.initialPopup.description'),
        acceptButtonSettings: {
          label: t('chic.website.useFirebase.initialPopup.acceptLabel'),
          action: (): void => {
            storeMessagesLocalSettings({ status: 'granted', statusUpdateTimestamp: new Date().getTime() });
            initializeFirebaseMessagesAction();
            hidePopup({ id: PopupId.NotificationsFirebase });
          },
        },
        cancelButtonSettings: {
          label: t('chic.website.useFirebase.initialPopup.cancelLabel'),
          action: (): void => {
            storeMessagesLocalSettings({ status: 'denied', statusUpdateTimestamp: new Date().getTime() });
            hidePopup({ id: PopupId.NotificationsFirebase });
          },
        },
      });
    });
  };

  const initializeFirebaseMessagesAction: () => void = async (): Promise<void> => {
    const app: FirebaseApp = initializeApp(appConfig.firebaseConfig);
    const messaging: Messaging = getMessaging(app);

    navigator.serviceWorker.getRegistration('/service-worker.js')
      .then((serviceWorkerRegistration: ServiceWorkerRegistration | undefined): void => {
        getToken(messaging, { serviceWorkerRegistration })
          .then((currentToken: string): void => {
            if (currentToken) {
              registerMessagesToken({ token: currentToken, expiration: new Date().getTime() + (oneDayInMs * 365) })
                .then((): void => addToast({ content: t('chic.website.useFirebase.registerMessagesTokenSuccess') }))
                .catch((error: FrontendApiError): void => addToast({ content: t(error.message) }));
              onMessage(messaging, onMessageHandler);
            }
          })
          // TODO: add logger
          .catch((e: Error): void => {
          // eslint-disable-next-line no-console
            console.error('Firebase get token action error: ', e);
          });
      })
      // TODO: add logger
      .catch((e: Error): void => {
      // eslint-disable-next-line no-console
        console.error('Firebase get SW action error: ', e);
      });
  };

  const initializeFirebaseOnMessage: (shouldRegisterToken: boolean) => void = (shouldRegisterToken: boolean): void => {
    if (!('Notification' in window) || Notification.permission !== 'granted') {
      return;
    }

    void isSupported().then(async (messagesSupported: boolean): Promise<void> => {
      if (messagesSupported) {
        const app: FirebaseApp = initializeApp(appConfig.firebaseConfig);
        const messaging: Messaging = getMessaging(app);

        navigator.serviceWorker.getRegistration('/service-worker.js')
          .then((serviceWorkerRegistration: ServiceWorkerRegistration | undefined): void => {
            getToken(messaging, { serviceWorkerRegistration })
              .then((currentToken: string): void => {
                if (currentToken && shouldRegisterToken) {
                  void registerMessagesToken({ token: currentToken, expiration: new Date().getTime() + (oneDayInMs * 365) });
                  onMessage(messaging, onMessageHandler);
                }
              })
              // TODO: add logger
              .catch((e: Error): void => {
              // eslint-disable-next-line no-console
                console.error('Firebase get token action error: ', e);
              });
          })
          // TODO: add logger
          .catch((e: Error): void => {
          // eslint-disable-next-line no-console
            console.error('Firebase get SW action error: ', e);
          });
      }
    });
  };

  return { initializeFirebaseMessages, initializeFirebaseOnMessage };
};
