import {
  PageHeader,
  InputTheme,
  UseState,
  UseFormikForm,
  useFormikForm,
  Breakpoint,
  DropdownOption,
  PageHeaderPosition,
  ButtonTheme,
  UseNotifications,
  useNotifications,
  MenuPositionData,
  ValidationBarTheme,
  Input
} from '@chic-loyalty/ui';
import { Formik, FormikProps } from 'formik';
import React, { useState, useMemo } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import * as Yup from 'yup';

import { setProfileData } from '@chic/frontend/api/requests';
import { useAuth, useConfirmContactData } from '@chic/frontend/hooks';
import { ConfirmContactDataParams, FormField, UseAuth, UseConfirmContactData } from '@chic/frontend/interfaces';
import { FrontendApiError } from '@chic/frontend/models';
import { PreferredLanguage } from '@chic/shared/enums';
import { UserWithToken, User } from '@chic/shared/interfaces';
import { UpdateProfileRequest } from '@chic/shared/models';

import { useProfileTabs } from '../profile.hooks';

import { useUpdateProfileValidation, useSmsCodeValidation } from './hooks';
import { yourDataFields, availableLanguages } from './yourData.constants';
import { YourDataField } from './yourData.enums';
import {
  Container,
  ContentWrapper,
  StyledContentBox,
  FormikForm,
  StyledInput,
  StyledDropdown,
  StyledButton,
  SmsBox,
  ButtonsContainer,
  StyledValidationBar
} from './yourData.styled';

export const YourDataView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { userData, updateUserData }: UseAuth = useAuth();
  const { addToast }: UseNotifications = useNotifications();
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });
  const { setFormSubmitted, isFormSubmitted }: UseFormikForm = useFormikForm();
  const updateProfileValidationSchema: Yup.SchemaOf<UpdateProfileRequest> = useUpdateProfileValidation();
  const SmsCodeValidationSchema: Yup.SchemaOf<ConfirmContactDataParams> = useSmsCodeValidation();
  const profileTabs: MenuPositionData[] = useProfileTabs();
  const [apiSuccess, setApiSuccess]: UseState<string> = useState<string>('');
  const [oldUserDataCached, setOldUserDataCached]: UseState<boolean> = useState<boolean>(false);
  const [isProfileButtonLoading, setProfileButtonIsLoading]: UseState<boolean> = useState<boolean>(false);
  const {
    submitConfirmContactData,
    hideSmsCodeView,
    isSmsButtonLoading,
    setShouldShowSmsCode,
    shouldShowSmsCode
  }: UseConfirmContactData = useConfirmContactData();

  const showSmsCodeView: () => void = (): void => {
    setShouldShowSmsCode(true);
    window.scrollTo(0, 0);
  };

  const oldUserData: User | null = useMemo((): User | null => {
    if (!userData || oldUserDataCached) {
      return null;
    }

    setOldUserDataCached(true);

    return userData;
  }, [userData]);

  const onSubmit: (data: UpdateProfileRequest) => void = (data: UpdateProfileRequest): void => {
    if (!userData) return;

    setProfileButtonIsLoading(true);
    const clearUnformattedPhone: string = data.phone.replace(/\s+/g, '');
    const requestData: UpdateProfileRequest = new UpdateProfileRequest(
      clearUnformattedPhone,
      data.email,
      data.firstName,
      data.lastName,
      data.preferredLanguage,
      userData.hash
    );

    setProfileData(requestData)
      .then((response: UserWithToken): void => {
        const { contextToken, ...user }: UserWithToken = response;
        if (clearUnformattedPhone !== oldUserData?.phone) {
          showSmsCodeView();
        } else {
          setApiSuccess(t('chic.website.yourDataView.success'));
        }
        setOldUserDataCached(false);
        updateUserData(user);
      })
      .catch((error: FrontendApiError): void => addToast({ content: t(error.message) }))
      .finally((): void => setProfileButtonIsLoading(false));
  };

  const onSubmitWithSmsCode: ({ smsCode }: ConfirmContactDataParams) => void = ({ smsCode }: ConfirmContactDataParams): void => {
    submitConfirmContactData({ smsCode })
      .then((): void => setApiSuccess(t('chic.website.yourDataView.success')))
      .catch((): void => undefined);
  };

  return (
    <Container>
      {!shouldShowSmsCode ? (
        <>
          <PageHeader
            header={t('chic.website.meta.profile.title')}
            headerPosition={PageHeaderPosition.CenterLeft}
            tabs={profileTabs}
            activeTabName='yourData'
          />
          <ContentWrapper>
            <StyledContentBox>
              <Formik
                initialValues={{
                  phone: userData?.phone ?? '',
                  email: userData?.email ?? '',
                  firstName: userData?.name ?? '',
                  lastName: userData?.surname ?? '',
                  preferredLanguage: userData?.preferredLanguage ?? ('' as PreferredLanguage),
                  hash: userData?.hash ?? '',
                }}
                onSubmit={onSubmit}
                validationSchema={updateProfileValidationSchema}
                validateOnChange={isFormSubmitted}
                validateOnBlur={isFormSubmitted}
                enableReinitialize
              >
                {({ handleSubmit, setFieldValue, errors, values }: FormikProps<UpdateProfileRequest>) => (
                  <FormikForm onSubmit={handleSubmit}>
                    {yourDataFields.map((field: FormField<YourDataField>): JSX.Element => field.type !== 'dropdown' ? (
                      <StyledInput
                        key={field.name}
                        label={t(field.label)}
                        type={field.type}
                        onChange={(value: string): void => setFieldValue(field.name, value)}
                        value={values[field.name]}
                        description={field.description ? t(field.description) : undefined}
                        message={field.name !== YourDataField.Email
                          ? t(errors[field.name] as string ?? '')
                          : (errors[field.name]
                            ? t(errors[field.name] as string)
                            : userData?.hasVerifiedEmail
                              ? t('chic.website.changePasswordView.emailInfoMessage')
                              : t('chic.website.changePasswordView.emailUpdateMessage')
                          )
                        }
                        inputTheme={errors[field.name] ? InputTheme.Error : InputTheme.Standard}
                        hasPhonePrefix={field.hasPhonePrefix}
                      />
                    ) : (
                      <StyledDropdown
                        key={field.name}
                        label={t(field.label)}
                        options={field?.options ?? []}
                        onChooseAnswer={(value: DropdownOption): void => setFieldValue(field.name, value.name)}
                        message={t(errors[field.name] as string ?? '')}
                        invalid={!!errors[field.name]}
                        initialValue={availableLanguages.find(
                          (item: DropdownOption) => item.name === userData?.preferredLanguage
                        )}
                      />
                    ))}
                    <ButtonsContainer>
                      <StyledButton
                        label={t('chic.website.yourDataView.save')}
                        type='submit'
                        onClick={setFormSubmitted}
                        fullWidth={isMobile}
                        isLoading={isProfileButtonLoading}
                      />
                    </ButtonsContainer>
                  </FormikForm>
                )}
              </Formik>
              <StyledValidationBar
                message={apiSuccess}
                barTheme={ValidationBarTheme.Green}
              />
            </StyledContentBox>
          </ContentWrapper>
        </>
      ) : (
        <>
          <PageHeader
            header={t('chic.website.yourDataView.enterSmsCode')}
            onArrowButtonAction={hideSmsCodeView}
          />
          <ContentWrapper>
            <SmsBox>
              <Formik
                initialValues={{ smsCode: '' }}
                onSubmit={onSubmitWithSmsCode}
                validationSchema={SmsCodeValidationSchema}
                validateOnChange={false}
                validateOnBlur={isFormSubmitted}
              >
                {({ handleSubmit, setFieldValue, errors }: FormikProps<ConfirmContactDataParams>) => (
                  <FormikForm onSubmit={handleSubmit}>
                    <Input
                      label={t('chic.website.yourDataView.smsCode')}
                      placeholder={t('chic.website.yourDataView.enterSmsCode')}
                      type='text'
                      onChange={(value: string): void => setFieldValue('smsCode', value)}
                      onBlur={(value: string): void => setFieldValue('smsCode', value)}
                      message={t(errors.smsCode ?? '')}
                      inputTheme={errors.smsCode ? InputTheme.Error : InputTheme.Standard}
                    />
                    <ButtonsContainer>
                      <StyledButton
                        label={t('chic.website.yourDataView.save')}
                        type='submit'
                        onClick={setFormSubmitted}
                        fullWidth={isMobile}
                        isLoading={isSmsButtonLoading}
                        $withAdditionalMargin
                      />
                      <StyledButton
                        label={t('chic.website.yourDataView.cancel')}
                        onClick={hideSmsCodeView}
                        buttonTheme={ButtonTheme.SecondaryWhite}
                        fullWidth={isMobile}
                        $withAdditionalMargin
                      />
                    </ButtonsContainer>
                  </FormikForm>
                )}
              </Formik>
            </SmsBox>
          </ContentWrapper>
        </>
      )}
    </Container>
  );
};
