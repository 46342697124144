import { IsNumber, IsOptional } from 'class-validator';

export class UpdateUserAgreementsRequest {
  @IsOptional()
  @IsNumber()
  public marketingAgreement?: number | null;

  @IsOptional()
  @IsNumber()
  public marketingAdditionalAgreement?: number | null;

  @IsOptional()
  @IsNumber()
  public marketingSocialAgreement?: number | null;

  @IsOptional()
  @IsNumber()
  public phoneSurveyAgreement?: number | null;

  constructor(
    marketingAgreement?: number | null,
    marketingAdditionalAgreement?: number | null,
    marketingSocialAgreement?: number | null,
    phoneSurveyAgreement?: number | null,
  ) {
    this.marketingAgreement = marketingAgreement;
    this.marketingAdditionalAgreement = marketingAdditionalAgreement;
    this.marketingSocialAgreement = marketingSocialAgreement;
    this.phoneSurveyAgreement = phoneSurveyAgreement;
  }
}
