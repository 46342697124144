import { IsDefined, IsEmail, IsString } from 'class-validator';

export class ChangeUnverifiedUserEmailRequest {
  @IsDefined()
  @IsString()
  @IsEmail()
  public email: string;

  @IsDefined()
  @IsString()
  public token: string;

  constructor(
    email: string,
    token: string,
  ) {
    this.email = email;
    this.token = token;
  }
}
