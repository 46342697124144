import { Color, Breakpoint, StyledComponent, SmsBox, Grid, SectionTitle, Pagination } from '@chic-loyalty/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  width: 100%;
  background-color: ${Color.AlmostBlack};
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - 273px);
  width: calc(100% - 80px);
  margin-left: auto;

  @media ${Breakpoint.Mobile} {
    min-height: unset;
    margin-left: 0;
    width: auto;
  }
`;

export const StyledSectionTitle: StyledComponent<typeof SectionTitle> = styled(SectionTitle)`
  margin-bottom: 20px;
`;

export const StyledSmsBox: StyledComponent<typeof SmsBox> = styled(SmsBox)`
  & + & {
    margin-top: 2px;
  }
`;

export const StyledPagination: StyledComponent<typeof Pagination> = styled(Pagination)`
  margin-top: 20px;
`;

export const Content: StyledComponent<'div'> = styled.div`
  padding: 50px 20px 100px;
  width: 100%;
`;

export const Wrapper: StyledComponent<'div'> = styled.div`
  max-width: ${Grid.Desktop};
  margin: 0 auto;
`;
