import { UseNotifications, UseState, useNotifications } from '@chic-loyalty/ui';
import { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { confirmContactData } from '@chic/frontend/api/requests';
import { useAuth } from '@chic/frontend/hooks';
import { ConfirmContactDataParams, UseAuth, UseConfirmContactData } from '@chic/frontend/interfaces';
import { FrontendApiError } from '@chic/frontend/models';
import { UserContactDataType, CustomerStatusType } from '@chic/shared/enums';
import { CustomerStatusWithUserData } from '@chic/shared/interfaces';
import { ConfirmContactDataRequest } from '@chic/shared/models';

import { FullscreenAlertAssets } from '../enums';

export const useConfirmContactData: () => UseConfirmContactData = (): UseConfirmContactData => {
  const { t }: TransProps<never> = useTranslation();
  const { userData, updateUserData }: UseAuth = useAuth();
  const { addToast, showFullscreenAlert, hideFullscreenAlert }: UseNotifications = useNotifications();
  const [isSmsButtonLoading, setIsSmsButtonLoading]: UseState<boolean> = useState<boolean>(false);
  const [shouldShowSmsCode, setShouldShowSmsCode]: UseState<boolean> = useState<boolean>(false);

  const hideSmsCodeView: () => void = (): void => setShouldShowSmsCode(false);

  const submitConfirmContactData: ({ smsCode }: ConfirmContactDataParams) => Promise<void> = (
    { smsCode }: ConfirmContactDataParams
  ): Promise<void> => {
    return new Promise<void>((resolve: (() => void), reject: (() => void)) => {
      if (!userData) {
        reject();
        return;
      }

      setIsSmsButtonLoading(true);
      confirmContactData(new ConfirmContactDataRequest(userData.card, UserContactDataType.Mobile, smsCode))
        .then((response: CustomerStatusWithUserData): void => {
          if (response.status === CustomerStatusType.Completed) {
            if (response.user) {
              updateUserData(response.user);
            }
            showFullscreenAlert({
              title: t('chic.website.global.success'),
              description: t('chic.website.useConfirmContactData.confirmContactDataSuccessMessage'),
              image: FullscreenAlertAssets.WomanWithPhoneAndComputer,
              acceptButtonSettings: {
                label: t('chic.website.global.close'),
                action: hideFullscreenAlert,
              },
            });
            hideSmsCodeView();
            resolve();
          }
        })
        .catch((error: FrontendApiError): void => {
          addToast({ content: t(error.message) });
          reject();
        })
        .finally((): void => {
          setIsSmsButtonLoading(false);
        });
    });
  };

  return {
    submitConfirmContactData,
    hideSmsCodeView,
    isSmsButtonLoading,
    setShouldShowSmsCode,
    shouldShowSmsCode
  };
};
