import {
  ActionBoxButtonType,
  ButtonTheme,
  DataBox,
  IconName,
  PageHeader,
  UseParsers,
  UseRedirect,
  UseTransformations,
  getPathWithParams,
  useParsers,
  useRedirect,
  useTransformations
} from '@chic-loyalty/ui';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { getNewPlanForSubscriptionUpgrade, getSubscriptionDetails } from '@chic/frontend/api/requests';
import { QueryKey } from '@chic/frontend/enums';
import { RouteNameEnum } from '@chic/shared/enums';
import { SubscriptionDetails, SubscriptionPlan, SubscriptionPlanGroup } from '@chic/shared/interfaces';
import { getRouteDetailsByName } from '@chic/shared/utils';

import {
  Container,
  ContentContainer,
  Image,
  RightSection,
  StyledActionBox,
  TopSection,
} from './subscriptionPlanUpgradeConditions.styled';

export const SubscriptionPlanUpgradeConditionsView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { redirect }: UseRedirect = useRedirect();
  const currency: string = 'zł';
  const { parsePrice }: UseParsers = useParsers();
  const { subscriptionId }: Readonly<Record<string, string | undefined>> = useParams();
  const { getSubscriptionAssetByType }: UseTransformations = useTransformations();
  const url: string = getRouteDetailsByName(RouteNameEnum.SubscriptionPlanUpgradeEditProducts)?.url ?? '';
  const { data: newSubscriptionPlan }: UseQueryResult<SubscriptionPlan> = useQuery(
    [QueryKey.SubscriptionPlanDetails],
    (): Promise<SubscriptionPlan> => getNewPlanForSubscriptionUpgrade(parseInt(subscriptionId ?? '', 10)),
    {
      enabled: !!subscriptionId,
    }
  );

  const { data: subscriptionDetails }: UseQueryResult<SubscriptionDetails> = useQuery(
    [QueryKey.SubscriptionDetails],
    (): Promise<SubscriptionDetails> => getSubscriptionDetails(parseInt(subscriptionId ?? '', 10)),
    {
      enabled: !!subscriptionId,
    }
  );

  const newSubscriptionPlanGroups: string = useMemo(
    (): string => {
      if (!newSubscriptionPlan?.groups) {
        return '';
      }

      return newSubscriptionPlan.groups.map((group: SubscriptionPlanGroup): string => `${group.amount} ${group.name}`)
        .join(', ');
    },
    [newSubscriptionPlan]
  );

  const oldSubscriptionPlanGroups: string = useMemo(
    (): string => {
      if (!subscriptionDetails?.plan.groups) {
        return '';
      }

      return subscriptionDetails.plan.groups.map((group: SubscriptionPlanGroup): string => `${group.amount} ${group.name}`)
        .join(', ');
    },
    [subscriptionDetails]
  );

  return (
    <Container>
      <PageHeader
        header={t('chic.website.subscriptionPlanUpgradeConditionsView.title')}
        onArrowButtonAction={(): void => redirect(getRouteDetailsByName(RouteNameEnum.Subscriptions)?.url ?? '/')}
      />
      <ContentContainer>
        <TopSection>
          {newSubscriptionPlan && <Image src={getSubscriptionAssetByType(newSubscriptionPlan?.assets, 'packshot', 'ic')} />}
          <RightSection>
            <DataBox
              label={t('chic.website.subscriptionPlanUpgradeConditionsView.newSubscriptionPlan.label')}
              data={[
                {
                  label: t('chic.website.subscriptionPlanUpgradeConditionsView.subscriptionPlan.name.label'),
                  value: newSubscriptionPlan?.name ?? ''
                },
                {
                  label: t('chic.website.subscriptionPlanUpgradeConditionsView.subscriptionPlan.content.label'),
                  value: newSubscriptionPlanGroups
                },
                {
                  label: t('chic.website.subscriptionPlanUpgradeConditionsView.subscriptionPlan.price.label'),
                  value: `${parsePrice(newSubscriptionPlan?.price.current ?? 0)} ${currency}`,
                  isHighlighted: true,
                },
              ]}
            />
            <DataBox
              label={t('chic.website.subscriptionPlanUpgradeConditionsView.oldSubscriptionPlan.label')}
              data={[
                {
                  label: t('chic.website.subscriptionPlanUpgradeConditionsView.subscriptionPlan.name.label'),
                  value: subscriptionDetails?.plan.name ?? ''
                },
                {
                  label: t('chic.website.subscriptionPlanUpgradeConditionsView.subscriptionPlan.content.label'),
                  value: oldSubscriptionPlanGroups
                },
                {
                  label: t('chic.website.subscriptionPlanUpgradeConditionsView.subscriptionPlan.price.label'),
                  value: `${parsePrice(subscriptionDetails?.plan.price.current ?? 0)} ${currency}`,
                  isHighlighted: true,
                },
              ]}
            />
          </RightSection>
        </TopSection>
        <StyledActionBox
          iconName={IconName.Layers}
          text={t('chic.website.subscriptionPlanUpgradeConditionsView.actionBox.text')}
          buttonSettings={{
            label: t('chic.website.subscriptionPlanUpgradeConditionsView.actionBox.buttonLabel'),
            buttonTheme: ButtonTheme.PrimaryWhite,
            internalPath: getPathWithParams(
              `${url}?planId=${newSubscriptionPlan?.id ?? ''}`,
              { subscriptionId: subscriptionId ?? '' }
            ),
          }}
          buttonType={ActionBoxButtonType.Simple}
        />
      </ContentContainer>
    </Container>
  );
};
