import { PageHeader, TopSection, useRedirect, UseRedirect } from '@chic-loyalty/ui';
import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { FileFromViews } from '@chic/frontend/enums';
import { RouteNameEnum } from '@chic/shared/enums';
import { getRouteDetailsByName } from '@chic/shared/utils';

import {
  BonusesSection,
  BonusesSectionInner,
  Container,
  GiftBoxImage,
  LightText,
  StyledBonusBox,
  TopSectionText,
} from './birthdayBonus.styled';

export const BirthdayBonusView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { redirect }: UseRedirect = useRedirect();

  return (
    <Container>
      <PageHeader
        header={t('chic.website.meta.birthdayBonus.title')}
        onArrowButtonAction={(): void => redirect(getRouteDetailsByName(RouteNameEnum.CarePlus)?.url ?? '')}
      />
      <TopSection image={FileFromViews.BirthdayBonusFriends}>
        <GiftBoxImage src={FileFromViews.BirthdayBonusGiftBox} />
        <TopSectionText>{t('chic.website.birthdayBonusView.topSectionTitle')}</TopSectionText>
      </TopSection>
      <BonusesSection>
        <BonusesSectionInner>
          <StyledBonusBox
            title={t('chic.website.birthdayBonusView.customerBirthdayBonus')}
            image={FileFromViews.BirthdayBonusVuseLiquid}
            upperMarkdownText={t('chic.website.birthdayBonusView.onceAYearBenefits')}
            lowerText={t('chic.website.birthdayBonusView.secondLiquidRequirements')}
            regulationsUrl='https://static.esmokingclub.pl/regulations/2022/rocznica_w_esc_luty2022.pdf'
          />
          <StyledBonusBox
            title={t('chic.website.birthdayBonusView.membershipAnniversaryBonus')}
            image={FileFromViews.BirthdayBonusVuseDevice}
            upperMarkdownText={t('chic.website.birthdayBonusView.anniversaryBonusDescription')}
            lowerText={t('chic.website.birthdayBonusView.anniversaryBonusDuration')}
            regulationsUrl='https://static.esmokingclub.pl/regulations/2022/urodziny_w_esc_luty2022.pdf'
          />
          <LightText>
            *{t('chic.website.birthdayBonusView.annotation')}
          </LightText>
        </BonusesSectionInner>
      </BonusesSection>
    </Container>
  );
};
