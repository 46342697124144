import { RouteNameEnum } from '@chic/shared/enums';

export const pathsAllowedInEscLimitedMode: RouteNameEnum[] = [   
  RouteNameEnum.PaymentRedirectLanding,
  RouteNameEnum.ContactDataVerification,
  RouteNameEnum.ChangeUnverifiedUserEmail,
  RouteNameEnum.Payment,
  RouteNameEnum.SignInIntegration,
  RouteNameEnum.TechnicalConfirmEmail,
  RouteNameEnum.TechnicalPayuCvvCodeAdd,
  RouteNameEnum.TechnicalIcMigrationLanding,
  RouteNameEnum.SubscriptionDeliveryNotAuthorizedUpdate,
  RouteNameEnum.SurveyAuthorized,
  RouteNameEnum.Survey,
  RouteNameEnum.DevicesAuthorizationReset,
  RouteNameEnum.DashboardForEscProgramLimited,
  RouteNameEnum.CookiesPolicy,
  RouteNameEnum.PrivacyPolicy,
  RouteNameEnum.Contact,
  RouteNameEnum.Regulations,
];
