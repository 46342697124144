import { AppConfig } from '@chic/frontend/interfaces';
import { InjectedWindowValues } from '@chic/shared/interfaces';

declare const window: InjectedWindowValues;

export const appConfig: AppConfig = {
  api: process.env.REACT_APP_API_URL || '/api',
  websocket: process.env.REACT_APP_WEBSOCKET_URL || '/',
  applicationName: 'eSmokingClub',
  angularAssetsBasePath: process.env.REACT_APP_ANGULAR_ASSETS_BASE_PATH || '',
  payuPosId: (typeof window !== 'undefined')
    ? window.PAYU_POS_ID || ''
    : '',
  recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY || '',
  firebaseConfig: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY
      ? process.env.REACT_APP_FIREBASE_API_KEY
      : '',
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID
      ? process.env.REACT_APP_FIREBASE_PROJECT_ID
      : '',
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID
      ? process.env.REACT_APP_FIREBASE_SENDER_ID
      : '',
    appId: process.env.REACT_APP_FIREBASE_APP_ID
      ? process.env.REACT_APP_FIREBASE_APP_ID
      : '',
  },
};
