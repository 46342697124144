import { IsString, IsDefined, IsNumber } from 'class-validator';

export class SurveyAnswerToSave {
  @IsDefined()
  @IsNumber()
  public questionId: number;

  @IsDefined()
  @IsNumber()
  public answerId: number;

  @IsDefined()
  @IsString()
  public value: string;

  constructor(
    questionId: number,
    answerId: number,
    value: string,
  ) {
    this.questionId = questionId;
    this.answerId = answerId;
    this.value = value;
  }
}
