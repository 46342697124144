import {
  Breakpoint,
  InputTheme,
  PageHeader,
  UseFormikForm,
  useFormikForm,
  UseNotifications,
  useNotifications
} from '@chic-loyalty/ui';
import { Formik, FormikProps } from 'formik';
import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { NavigateFunction, Params, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { setPasswordAfterRecovery } from '@chic/frontend/api/requests';
import { FullscreenAlertAssets } from '@chic/frontend/enums';
import { useAuth } from '@chic/frontend/hooks';
import { UseAuth } from '@chic/frontend/interfaces';
import { FrontendApiError } from '@chic/frontend/models';
import { RouteNameEnum } from '@chic/shared/enums';
import { UserWithToken } from '@chic/shared/interfaces';
import { getRouteDetailsByName } from '@chic/shared/utils';

import { useSetPasswordValidation } from './setPassword.hooks';
import { Container, FormikForm, StyledButton, StyledContentBox, StyledInput } from './setPassword.styled';
import { SetPasswordFormData } from './setPassword.types';

export const SetPasswordView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const isTablet: boolean = useMediaQuery({ query: Breakpoint.Tablet });
  const SetPasswordValidationSchema: Yup.SchemaOf<SetPasswordFormData> = useSetPasswordValidation();
  const navigate: NavigateFunction = useNavigate();
  const { token }: Readonly<Params<string>> = useParams();
  const { addToast, showFullscreenAlert, hideFullscreenAlert }: UseNotifications = useNotifications();
  const { userData, signIn }: UseAuth = useAuth();
  const {
    setFormSubmitted,
    isFormSubmitted,
    setApiRequestStart,
    setApiRequestEnd,
    isApiRequestInProgress
  }: UseFormikForm = useFormikForm();

  const setPassword: (data: SetPasswordFormData) => void = (data: SetPasswordFormData): void => {
    if (token) {
      setApiRequestStart();
      setPasswordAfterRecovery({ token, password: data.password })
        .then((response: UserWithToken): void => {
          setApiRequestEnd();
          showFullscreenAlert({
            title: t('chic.website.setPasswordView.success.title'),
            description: t('chic.website.setPasswordView.success.description'),
            image: FullscreenAlertAssets.ManWithGlasses,
            acceptButtonSettings: {
              label: t('chic.website.global.home'),
              action: (): void => {
                const { contextToken, ...user }: UserWithToken = response;
                hideFullscreenAlert();
                signIn(user, contextToken);
              }
            },
          });
        })
        .catch((error: FrontendApiError): void => {
          setApiRequestEnd();
          addToast({ content: t(error.message) });
        });
    } else {
      navigate(getRouteDetailsByName(RouteNameEnum.Home)?.url ?? '/');
    }
  };

  return (
    <Container $withLeftPadding={!!userData}>
      <PageHeader
        header={t('chic.website.setPasswordView.header')}
        subheader={t('chic.website.setPasswordView.subheader')}
      />
      <StyledContentBox>
        <Formik
          initialValues={{ password: '', repeatedPassword: '' }}
          onSubmit={setPassword}
          validationSchema={SetPasswordValidationSchema}
          validateOnChange={isFormSubmitted}
          validateOnBlur={isFormSubmitted}
        >
          {({ handleSubmit, setFieldValue, errors }: FormikProps<SetPasswordFormData>) => (
            <FormikForm onSubmit={handleSubmit}>
              <StyledInput
                label={t('chic.website.global.password')}
                placeholder={t('chic.website.setPasswordView.password.placeholder')}
                onChange={(value: string): void => setFieldValue('password', value)}
                onBlur={(value: string): void => setFieldValue('password', value)}
                message={t(errors.password ?? '')}
                inputTheme={errors.password ? InputTheme.Error : InputTheme.Standard}
                type='password'
              />
              <StyledInput
                label={t('chic.website.global.repeatedPassword')}
                placeholder={t('chic.website.setPasswordView.password.placeholder')}
                onChange={(value: string): void => setFieldValue('repeatedPassword', value)}
                onBlur={(value: string): void => setFieldValue('repeatedPassword', value)}
                message={t(errors.repeatedPassword ?? '')}
                inputTheme={errors.repeatedPassword ? InputTheme.Error : InputTheme.Standard}
                type='password'
              />
              <StyledButton
                label={t('chic.website.setPasswordView.setYourPassword')}
                type='submit'
                onClick={setFormSubmitted}
                isLoading={isApiRequestInProgress}
                fullWidth={isTablet}
              />
            </FormikForm>
          )}
        </Formik>
      </StyledContentBox>
    </Container>
  );
};
