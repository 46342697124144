import { RouteNameEnum, UserPermission } from '@chic/shared/enums';
import { getRouteDetailsByName } from '@chic/shared/utils';

import { MenuPositionDataWithPermissions } from './profile.types';

export const profileTabsMenu: MenuPositionDataWithPermissions[] = [
  {
    name: 'yourData',
    label: 'chic.website.meta.yourData.title',
    internalPath: getRouteDetailsByName(RouteNameEnum.ProfileYourData)?.url,
    permissions: []
  },
  {
    name: 'changePassword',
    label: 'chic.website.meta.changePassword.title',
    internalPath: getRouteDetailsByName(RouteNameEnum.ProfileChangePassword)?.url,
    permissions: []
  },
  {
    name: 'agreements',
    label: 'chic.website.meta.agreements.title',
    internalPath: getRouteDetailsByName(RouteNameEnum.ProfileAgreements)?.url,
    permissions: []
  },
  {
    name: 'notificationsSettings',
    label: 'chic.website.meta.notificationsSettings.title',
    internalPath: getRouteDetailsByName(RouteNameEnum.ProfileNotificationsSettings)?.url,
    permissions: []
  },
  {
    name: 'paymentSettings',
    label: 'chic.website.meta.paymentSettings.title',
    internalPath: getRouteDetailsByName(RouteNameEnum.ProfilePaymentsSettings)?.url,
    permissions: [UserPermission.CanChangePaymentMethod]
  },
  {
    name: 'paymentsHistory',
    label: 'chic.website.meta.paymentHistory.title',
    internalPath: getRouteDetailsByName(RouteNameEnum.ProfilePaymentsHistory)?.url,
    permissions: [UserPermission.CanChangePaymentMethod]
  }
];
