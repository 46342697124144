import { AccordionItem, AccordionItemData } from '@chic-loyalty/ui';
import { TransProps, useTranslation } from 'react-i18next';

import { FileFromViews } from '@chic/frontend/enums';

import { faqConfig } from './faq.config';
import { Container, ContentWrapper, Header, TopContent, Image, BottomContent } from './faq.styled';

export const FaqView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();

  return (
    <Container>
      <ContentWrapper>
        <TopContent>
          <Image src={FileFromViews.Help} />
          <Header>{t('chic.website.faqView.header')}</Header>
        </TopContent>
        <BottomContent>
          {faqConfig.map((faqItem: AccordionItemData): JSX.Element => (
            <AccordionItem
              key={faqItem.label}
              label={t(faqItem.label ?? '')}
              content={t(faqItem.content ?? '')}
            />
          ))}
        </BottomContent>
      </ContentWrapper>
    </Container>
  );
};
