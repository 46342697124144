import { TopSection, Button, ButtonTheme, UseRedirect, useRedirect, PageHeader, Breakpoint } from '@chic-loyalty/ui';
import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { FileFromViews } from '@chic/frontend/enums';
import { RouteNameEnum } from '@chic/shared/enums';
import { getRouteDetailsByName } from '@chic/shared/utils';

import {
  BigText,
  Text,
  Container,
  BottomSection,
  WarningBox,
  WarningBoxTitle,
  WarningBoxText,
  DevicesImage,
  BatteryImage,
  BottomSectionInner,
} from './freeService.styled';

export const FreeServiceView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { redirect }: UseRedirect = useRedirect();
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });
  const isTablet: boolean = useMediaQuery({ query: Breakpoint.Tablet });

  return (
    <Container>
      <PageHeader
        header={t('chic.website.meta.freeService.title')}
        onArrowButtonAction={(): void => redirect(getRouteDetailsByName(RouteNameEnum.CarePlus)?.url ?? '')}
      />
      <TopSection image={FileFromViews.FreeServiceEswPoint}>
        <BigText>{t('chic.website.freeServiceView.havingDeviceProblem')}</BigText>
        <Text>{t('chic.website.freeServiceView.useOurService')}</Text>
        <Button
          buttonTheme={ButtonTheme.SecondaryOrange}
          label={t('chic.website.freeServiceView.mapOfPoints')}
          onClick={() => redirect(getRouteDetailsByName(RouteNameEnum.FindUs)?.url ?? '')}
          fullWidth={isMobile}
        />
      </TopSection>
      <BottomSection>
        <BottomSectionInner>
          <WarningBox>
            <WarningBoxTitle>{t('chic.website.freeServiceView.remember')}</WarningBoxTitle>
            <WarningBoxText>{t('chic.website.freeServiceView.unchargedBatteryRequirement')}</WarningBoxText>
            <WarningBoxText>{t('chic.website.freeServiceView.serviceDuration')}</WarningBoxText>
            <BatteryImage src={FileFromViews.FreeServiceNoBattery} />
          </WarningBox>
          <DevicesImage src={isMobile
            ? FileFromViews.FreeServiceVuseDevicesMobile
            : isTablet
              ? FileFromViews.FreeServiceVuseDevicesTablet
              : FileFromViews.FreeServiceVuseDevices
          }/>
        </BottomSectionInner>
      </BottomSection>
    </Container>
  );
};
