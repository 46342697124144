import { useMemo } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';

import { LocalStorageKey } from '@chic/frontend/enums';
import { CookiesName } from '@chic/shared/enums';
import { InjectedWindowValues } from '@chic/shared/interfaces';

import { UseCookiesPolicy } from './cookiesPolicy.types';
declare const window: InjectedWindowValues;

export const useCookiesPolicy: () => UseCookiesPolicy = (): UseCookiesPolicy => {
  const { t }: TransProps<never> = useTranslation();
  const cookies: Cookies = new Cookies();
  
  const cookiesPolicyAccepted: boolean = useMemo(
    (): boolean => {
      return cookies.get(CookiesName.DisableGoogleAnalytics) === 'false';
    },
    []
  );
  
  const changeCookiesPolicyAcceptedAction: (value: boolean) => void = (value: boolean): void => {
    cookies.set(
      CookiesName.DisableGoogleAnalytics,
      !value,
      { path: '/' }
    );
    window[`ga-disable-${window.GA_ID}`] = !value;
  };

  const topContentTextsConfig: string[] = [
    'chic.website.cookiesPolicyView.topContentTextsConfig.firstSection',
    'chic.website.cookiesPolicyView.topContentTextsConfig.secondSection'
  ];

  const bottomContentTextsConfig: string[] = [
    'chic.website.cookiesPolicyView.bottomContentTextsConfig.firstSection',
    'chic.website.cookiesPolicyView.bottomContentTextsConfig.secondSection'
  ];
  
  const cookiesTable: string[][] = [
    [
      t('chic.website.useCookiesPolicy.cookiesTable.header.name'),
      t('chic.website.useCookiesPolicy.cookiesTable.header.time'),
      t('chic.website.useCookiesPolicy.cookiesTable.header.goal'),
      t('chic.website.useCookiesPolicy.cookiesTable.header.category'),
      t('chic.website.useCookiesPolicy.cookiesTable.header.origin'),
    ],
    [
      t('chic.website.useCookiesPolicy.cookiesTable.ga.name'),
      t('chic.website.useCookiesPolicy.cookiesTable.ga.time'),
      t('chic.website.useCookiesPolicy.cookiesTable.ga.goal'),
      t('chic.website.useCookiesPolicy.cookiesTable.ga.category'),
      t('chic.website.useCookiesPolicy.cookiesTable.ga.origin'),
    ],
    [
      t('chic.website.useCookiesPolicy.cookiesTable.disablega.name'),
      t('chic.website.useCookiesPolicy.cookiesTable.disablega.time'),
      t('chic.website.useCookiesPolicy.cookiesTable.disablega.goal'),
      t('chic.website.useCookiesPolicy.cookiesTable.disablega.category'),
      t('chic.website.useCookiesPolicy.cookiesTable.disablega.origin'),
    ]
  ];

  const localStorageTable: string[][] = [
    [
      t('chic.website.useCookiesPolicy.localStorageTable.header.name'),
      t('chic.website.useCookiesPolicy.localStorageTable.header.goal'),
      t('chic.website.useCookiesPolicy.localStorageTable.header.category'),
      t('chic.website.useCookiesPolicy.localStorageTable.header.origin'),
    ],
    ...(Object.entries(LocalStorageKey).map(([key, value]: [string, LocalStorageKey]): string[] => (
      [
        value,
        t(`chic.website.useCookiesPolicy.localStorageTable.${key}.goal` ?? '-'),
        t(`chic.website.useCookiesPolicy.localStorageTable.${key}.category` ?? '-'),
        t(`chic.website.useCookiesPolicy.localStorageTable.${key}.origin` ?? '-'),
      ]
    )))
  ];
  
  return {
    cookiesTable, 
    localStorageTable,
    cookiesPolicyAccepted,
    changeCookiesPolicyAcceptedAction,
    topContentTextsConfig,
    bottomContentTextsConfig
  };
};
