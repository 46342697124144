import { StyledComponent, Color, Breakpoint, ValidationBar, Pagination, SectionTitle } from '@chic-loyalty/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: calc(100% - 80px);
  background-color: ${Color.AlmostBlack};
  margin-left: auto;

  @media ${Breakpoint.Mobile} {
    width: 100%;
  }
`;

export const ContentWrapper: StyledComponent<'div'> = styled.div`
  min-height: 100vh;
  width: 100%;
  padding: 50px 20px 100px;
  max-width: 940px;

  @media ${Breakpoint.Tablet} {
    padding: 50px 20px 100px;
  }

  @media ${Breakpoint.Mobile} {
    padding: 35px 0 50px;
    min-height: auto;
  }
`;

export const StyledValidationBar: StyledComponent<typeof ValidationBar> = styled(ValidationBar)`
  margin-top: 20px;
`;

export const StyledPagination: StyledComponent<typeof Pagination> = styled(Pagination)`
  margin-top: 25px;
`;

export const StyledSectionTitle: StyledComponent<typeof SectionTitle> = styled(SectionTitle)`
  margin-bottom: 35px;

  @media ${Breakpoint.Mobile} {
    margin: 0 20px 35px;
  }
`;
