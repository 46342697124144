import { StyledComponent, Color, FontWeight, Breakpoint } from '@chic-loyalty/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 80px);
  background-color: ${Color.Level1};
  margin-left: auto;

  @media ${Breakpoint.Mobile} {
    width: 100%;
  }
`;

export const ContentWrapper: StyledComponent<'div'> = styled.div`
  max-width: 900px;
  width: 100%;
  height: 100%;
`;

export const TopContent: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 0 65px;
`;

export const BottomContent: StyledComponent<'div'> = styled.div`
  padding-bottom: 100px;

  @media ${Breakpoint.Tablet} {
    padding: 0 45px 100px;
  }

  @media ${Breakpoint.Mobile} {
    padding: 0 20px 70px;
  }
`;

export const Header: StyledComponent<'div'> = styled.div`
  font-weight: ${FontWeight.Medium};
  font-size: 31px;
  letter-spacing: -0.6px;
  margin-top: 40px;
  color: ${Color.SolidHighEmphasis};
  text-align: center;

  @media ${Breakpoint.Mobile} {
    padding: 0 20px;
  }
`;

export const Image: StyledComponent<'img'> = styled.img`
  max-width: 100%;
`;
