import { TransProps, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { phoneRegexp, postalCodeRegexp } from '@chic/shared/constants';

import { UseSubscriptionDeliveryValidations } from '../subscriptionEditDelivery.types';

export const useSubscriptionDeliveryValidations: () => UseSubscriptionDeliveryValidations = (): UseSubscriptionDeliveryValidations => {
  const { t }: TransProps<never> = useTranslation();

  return {
    smsFormSchema: Yup.object().shape({
      smsCode: Yup.string()
        .required(t('chic.website.global.requiredField')),
    }),
    deliveryUpdateFormSchema: Yup.object().shape({
      name: Yup.string()
        .min(2, t('chic.website.global.validations.name'))
        .max(64, t('chic.website.global.maxLength'))
        .required(t('chic.website.global.requiredField')),
      address: Yup.string()
        .min(2, t('chic.website.global.validations.address'))
        .max(128, t('chic.website.global.maxLength'))
        .required(t('chic.website.global.requiredField')),
      postalCode: Yup.string()
        .matches(postalCodeRegexp, t('chic.website.global.wrongValue'))
        .required(t('chic.website.global.requiredField')),
      city: Yup.string()
        .min(2, t('chic.website.global.validations.city'))
        .max(64, t('chic.website.global.maxLength'))
        .required(t('chic.website.global.requiredField')),
      email: Yup.string()
        .email(t('chic.website.global.wrongValue'))
        .required(t('chic.website.global.requiredField')),
      phone: Yup.string()
        .transform((value: string | null | undefined): string => value ? value.replace(/\s+/g, '') : '')
        .matches(phoneRegexp, t('chic.website.global.validations.invalidPhone'))
        .required(t('chic.website.global.requiredField')),
    }),
  };
};
