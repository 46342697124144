export enum BackendTranslationKey {
  BadRequest = 'chic.website.errors.400.message',
  LoginRequired = 'chic.website.errors.401.message',
  AccessDenied = 'chic.website.errors.403.message',
  NotFound = 'chic.website.errors.404.message',
  Conflict = 'chic.website.errors.409.message',
  InternalError = 'chic.website.errors.500.message',
  // custom error message
  ProductNotFound = 'chic.website.errors.custom.productNotFound',
  ParcelLockerNotFound = 'chic.website.errors.custom.parcelLockerNotFound',
  ProfileNotFound = 'chic.website.errors.custom.profileNotFound',
  UnauthorizedIp = 'chic.website.errors.custom.unauthorizedUser',
  PickupPointNotFound = 'chic.website.errors.custom.pickupPointNotFound',
  PostNotFound = 'chic.website.errors.custom.postNotFound',
  PromotionNotFound = 'chic.website.errors.custom.promotionNotFound',
  WrongRecaptcha = 'chic.website.errors.custom.wrongRecaptcha',
  WrongLoyaltyProgramIc = 'chic.website.errors.custom.wrongLoyaltyProgram.InspirationClub',
  WrongLoyaltyProgramEsc = 'chic.website.errors.custom.wrongLoyaltyProgram.eSmokingClub',
  EscLoyaltyProgramDisabled = 'chic.website.errors.custom.escLoyaltyProgramDisabled',
}
