import { TransProps, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { smsCodeRegexp } from '@chic/shared/constants';

import { RegistrationField } from '../registration.enums';

export const useSmsCodeFormValidation: () => Record<RegistrationField.SmsCode, Yup.BaseSchema>
= (): Record<RegistrationField.SmsCode, Yup.BaseSchema> => {
  const { t }: TransProps<never> = useTranslation();

  return {
    [RegistrationField.SmsCode]: Yup.string()
      .matches(smsCodeRegexp, t('chic.website.registration.smsValidationError'))
      .required(t('chic.website.global.requiredField')),
  };
};
