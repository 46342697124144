import React from 'react';
import useCollapse from 'react-collapsed';
import { UseCollapseOutput } from 'react-collapsed/dist/types';
import { TransProps, useTranslation } from 'react-i18next';

import { Annotation, CollapsedAnnotationWrapper, Container, ShowMore } from './payuAnnotations.styled';
import { PayuAnnotationsProps } from './payuAnnotations.types';

export const PayuAnnotations: React.FC<PayuAnnotationsProps> = (props: PayuAnnotationsProps): JSX.Element => {
  const { className }: PayuAnnotationsProps = props;
  const { t }: TransProps<never> = useTranslation();
  const { getCollapseProps, setExpanded, isExpanded }: UseCollapseOutput = useCollapse();

  return (
    <Container className={className}>
      <Annotation text={t('chic.website.payuAnnotations.paymentOrder')} />
      <Annotation text={t('chic.website.payuAnnotations.paymentRules')} />
      <Annotation text={t('chic.website.payuAnnotations.personalDataProtection')} />
      <CollapsedAnnotationWrapper {...getCollapseProps()}>
        <Annotation text={t('chic.website.payuAnnotations.personalDataProtectionDescription')} />
      </CollapsedAnnotationWrapper>
      <ShowMore onClick={(): void => setExpanded(!isExpanded)}>
        {t(`chic.website.payuAnnotations.${isExpanded ? 'showLess' : 'showMore'}`)}
      </ShowMore>
    </Container>
  );
};
