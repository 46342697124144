import { PageHeader, SmallListElement, UseState, ContentBox, ContentBoxTheme } from '@chic-loyalty/ui';
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { getRegulations } from '@chic/frontend/api/requests';
import { QueryKey } from '@chic/frontend/enums';
import { useAuth } from '@chic/frontend/hooks';
import { UseAuth } from '@chic/frontend/interfaces';
import { Regulation } from '@chic/shared/interfaces';

import { Container } from './regulations.styled';

export const RegulationsView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { userData }: UseAuth = useAuth();
  const [regulations, setRegulations]: UseState<Regulation[]> = useState<Regulation[]>([]);

  useQuery(
    [QueryKey.Regulations],
    (): Promise<Regulation[]> => getRegulations(),
    {
      onSuccess: (data: Regulation[]): void => setRegulations(data),
      // TODO: add logger
      onError: (): void => undefined
    }
  );

  // TODO: use useRedirect here
  const openLink = (link: string): void => {
    const win: Window | null = window.open(link, '_blank');
    win?.focus();
  };

  return (
    <Container $withLeftPadding={!!userData}>
      <PageHeader header={t('chic.website.regulationsView.regulations')} />
      <ContentBox contentBoxTheme={ContentBoxTheme.Medium2}>
        {regulations.map((regulation: Regulation): JSX.Element => (
          <SmallListElement
            key={regulation.id}
            onClick={(): void => openLink(regulation.path)}
            title={regulation.name}
          />
        ))}
      </ContentBox>
    </Container>
  );
};
