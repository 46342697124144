import { AxiosResponse } from 'axios';
import { stringify } from 'query-string';

import { FrontendApiError } from '@chic/frontend/models';
import { ApiReject, ApiResolve } from '@chic/frontend/types';
import {
  Subscription,
  SubscriptionDetails,
  SubscriptionPaymentStatus,
  SubscriptionOrderInfo,
  SubscriptionPlan,
  SubscriptionPlanCategoryWithImg
} from '@chic/shared/interfaces';
import {
  UpdateSubscriptionDeliveryDateRequest,
  UpdateSubscriptionDestinationRequest,
  UpdateSubscriptionOrderDestinationRequest,
  UpdateSubscriptionPaymentMethodRequest,
  UpdateSubscriptionProductsRequest,
} from '@chic/shared/models';

import { api } from '../api';

export const getSubscriptions: () => Promise<Subscription[]> = (): Promise<Subscription[]> => new Promise(
  (resolve: ApiResolve<Subscription[]>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get('/subscriptions').then(
      (response: AxiosResponse<Subscription[]>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getSubscriptionDetails: (id: number) => Promise<SubscriptionDetails> = (
  id: number
): Promise<SubscriptionDetails> => new Promise(
  (resolve: ApiResolve<SubscriptionDetails>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get(`/subscriptions/${id}`).then(
      (response: AxiosResponse<SubscriptionDetails>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getSubscriptionPaymentInfo: (id: number) => Promise<SubscriptionPaymentStatus> = (
  id: number
): Promise<SubscriptionPaymentStatus> => new Promise(
  (resolve: ApiResolve<SubscriptionPaymentStatus>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get(`/subscriptions/payments/${id}`).then(
      (response: AxiosResponse<SubscriptionPaymentStatus>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const cancelSubscription: (id: number) => Promise<SubscriptionDetails> = (
  id: number
): Promise<SubscriptionDetails> => new Promise(
  (resolve: ApiResolve<SubscriptionDetails>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.delete(`/subscriptions/${id}`).then(
      (response: AxiosResponse<SubscriptionDetails>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getSubscriptionsPlans: () => Promise<SubscriptionPlan[]> = (): Promise<SubscriptionPlan[]> => new Promise(
  (resolve: ApiResolve<SubscriptionPlan[]>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get('/subscriptions/plans').then(
      (response: AxiosResponse<SubscriptionPlan[]>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getSubscriptionsPlansCategories: () => Promise<SubscriptionPlanCategoryWithImg[]>
  = (): Promise<SubscriptionPlanCategoryWithImg[]> => new Promise(
    (resolve: ApiResolve<SubscriptionPlanCategoryWithImg[]>, reject: ApiReject<FrontendApiError>): Promise<void> => (
      api.get('/subscriptions/plans-categories').then(
        (response: AxiosResponse<SubscriptionPlanCategoryWithImg[]>): void => { resolve(response?.data); }
      ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
    )
  );

export const changeSubscriptionNextDeliveryDate: (
  subscriptionId: number, data?: UpdateSubscriptionDeliveryDateRequest
) => Promise<SubscriptionDetails> = (
  subscriptionId: number, data: UpdateSubscriptionDeliveryDateRequest = {}
): Promise<SubscriptionDetails> => new Promise(
  (resolve: ApiResolve<SubscriptionDetails>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.post(`/subscriptions/${subscriptionId}/delivery-date`, data).then(
      (response: AxiosResponse<SubscriptionDetails>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const updateSubscriptionPaymentMethod: (
  subscriptionId: number, data: UpdateSubscriptionPaymentMethodRequest
) => Promise<SubscriptionDetails> = (
  subscriptionId: number, data: UpdateSubscriptionPaymentMethodRequest
): Promise<SubscriptionDetails> => new Promise(
  (resolve: ApiResolve<SubscriptionDetails>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.post(`/subscriptions/${subscriptionId}/payment-method`, data).then(
      (response: AxiosResponse<SubscriptionDetails>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const updateDeliveryDestination: (
  subscriptionId: number, data: UpdateSubscriptionDestinationRequest
) => Promise<SubscriptionDetails> = (
  subscriptionId: number, data: UpdateSubscriptionDestinationRequest
): Promise<SubscriptionDetails> => new Promise(
  (resolve: ApiResolve<SubscriptionDetails>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.post(`/subscriptions/${subscriptionId}/destination`, data).then(
      (response: AxiosResponse<SubscriptionDetails>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getSubscriptionPaymentProviderUrl: (orderId: string, paymentId: string) => Promise<string> = (
  orderId: string, paymentId: string
): Promise<string> => new Promise(
  (resolve: ApiResolve<string>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get(`/subscriptions/orders/${orderId}/${paymentId}`).then(
      (response: AxiosResponse<string>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const updateSubscriptionProducts: (
  subscriptionId: number, data: UpdateSubscriptionProductsRequest
) => Promise<SubscriptionDetails> = (
  subscriptionId: number, data: UpdateSubscriptionProductsRequest
): Promise<SubscriptionDetails> => new Promise(
  (resolve: ApiResolve<SubscriptionDetails>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.post(`/subscriptions/${subscriptionId}/products`, data).then(
      (response: AxiosResponse<SubscriptionDetails>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getSubscriptionOrderDetails: (orderId: number, token: string) => Promise<SubscriptionOrderInfo> = (
  orderId: number,
  token: string,
): Promise<SubscriptionOrderInfo> => new Promise(
  (resolve: ApiResolve<SubscriptionOrderInfo>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get(`/subscriptions/orders/${orderId}?${stringify({ token }, { skipEmptyString: true })}`).then(
      (response: AxiosResponse<SubscriptionOrderInfo>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const updateSubscriptionOrderDestination: (
  data: UpdateSubscriptionOrderDestinationRequest
) => Promise<SubscriptionDetails> = (
  data: UpdateSubscriptionOrderDestinationRequest
): Promise<SubscriptionDetails> => new Promise(
  (resolve: ApiResolve<SubscriptionDetails>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.post('/subscriptions/update-order-destination', data).then(
      (response: AxiosResponse<SubscriptionDetails>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const upgradeSubscriptionPlan: (
  subscriptionId: number, data: UpdateSubscriptionProductsRequest
) => Promise<SubscriptionDetails> = (
  subscriptionId: number, data: UpdateSubscriptionProductsRequest
): Promise<SubscriptionDetails> => new Promise(
  (resolve: ApiResolve<SubscriptionDetails>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.post(`/subscriptions/${subscriptionId}/upgrade`, data).then(
      (response: AxiosResponse<SubscriptionDetails>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getNewPlanForSubscriptionUpgrade: (subscriptionId: number) => Promise<SubscriptionPlan> = (
  subscriptionId: number
): Promise<SubscriptionPlan> => new Promise(
  (resolve: ApiResolve<SubscriptionPlan>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get(`/subscriptions/${subscriptionId}/new-plan-for-upgrade`).then(
      (response: AxiosResponse<SubscriptionPlan>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);
