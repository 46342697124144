import { useContext } from 'react';

import { OnlineStateContext } from '@chic/frontend/contexts';
import { UseOnlineState } from '@chic/frontend/interfaces';

export const useOnlineState: () => UseOnlineState = (): UseOnlineState => {
  const isAppOnline: boolean = useContext(OnlineStateContext);

  return { isAppOnline };
};
