import { IsDefined, IsEnum } from 'class-validator';

import { SubscriptionPaymentMethod } from '@chic/shared/enums';

export class UpdateSubscriptionPaymentMethodRequest {
  @IsDefined()
  @IsEnum(SubscriptionPaymentMethod)
  public method: SubscriptionPaymentMethod;

  constructor(
    method: SubscriptionPaymentMethod,
  ) {
    this.method = method;
  }
}
