/* eslint-disable no-console */
import { UseNotifications, useNotifications } from '@chic-loyalty/ui';
import FingerprintJS, { Agent, GetResult } from '@fingerprintjs/fingerprintjs';
import { useMemo } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { sendStatsForInitPwa } from '@chic/frontend/api/requests';
import { PopupId, PopupImage } from '@chic/frontend/enums';
import { UseServiceWorker } from '@chic/frontend/interfaces';

export const useServiceWorker: () => UseServiceWorker = (): UseServiceWorker => {
  const { addToast, showPopup, hidePopup }: UseNotifications = useNotifications();
  const { t }: TransProps<never> = useTranslation();

  const isLocalhost: boolean = useMemo(
    (): boolean => {
      const localhostIpRegex: RegExp = /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/;
      return Boolean(
        window.location.hostname === 'localhost'
        || window.location.hostname === '[::1]'
        || window.location.hostname.match(localhostIpRegex)
      );
    },
    []
  );

  const register: () => void = (): void => {
    if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
      const publicUrl: URL = new URL(process.env.PUBLIC_URL ?? '', window.location.href);
      if (publicUrl.origin !== window.location.origin) {
        return;
      }
  
      window.addEventListener('appinstalled', (): void => {
        FingerprintJS.load({ monitoring: false })
          .then((agent: Agent): Promise<GetResult> => agent.get())
          .then((fingerPrintResult: GetResult): void => {
            void sendStatsForInitPwa({ fingerPrint: fingerPrintResult.visitorId });
            addToast({ content: t('chic.website.useServiceWorker.pwaInstallationSuccess') });
          })
          .catch((): void => undefined);
      });
  
      window.addEventListener('load', (): void => {
        const swUrl: string = `${process.env.PUBLIC_URL}/service-worker.js`;
        if (isLocalhost) {
          checkValidServiceWorker(swUrl);
        } else {
          registerValidSW(swUrl);
        }
      });
    }
  };
  
  const registerValidSW: (swUrl: string) => void = (swUrl: string): void => {
    navigator.serviceWorker
      .register(swUrl)
      .then((registration: ServiceWorkerRegistration): void => {
        registration.onupdatefound = (): void => {
          const installingWorker: ServiceWorker | null = registration.installing;
          if (installingWorker === null) {
            return;
          }
          installingWorker.onstatechange = (): void => {
            if (installingWorker.state === 'installed') {
              if (navigator.serviceWorker.controller) {
                // At this point, the updated precached content has been fetched,
                // but the previous service worker will still serve the older
                // content until all client tabs are closed.
                showPopup({
                  id: PopupId.ServiceWorkerNewVersionAvailable,
                  image: PopupImage.NotificationsBg,
                  description:  t('chic.website.useServiceWorker.serviceWorkerUpdatePopup.description'),
                  acceptButtonSettings: {
                    label:  t('chic.website.useServiceWorker.serviceWorkerUpdatePopup.acceptLabel'),
                    action: (): void => {
                      hidePopup({ id: PopupId.ServiceWorkerNewVersionAvailable });
                      window.location.reload();
                    },
                  },
                });

                console.log(
                  'New content is available and will be used when all ' +
                    'tabs for this page are closed. See https://cra.link/PWA.'
                );
              } else {
                // At this point, everything has been precached.
                // It's the perfect time to display a
                // "Content is cached for offline use." message.
                console.log('Content is cached for offline use.');
              }
            }
          };
        };
      })
      .catch((error: Error): void => {
        console.error('Error during service worker registration:', error);
      });
  };
  
  const checkValidServiceWorker: (swUrl: string) => void = (swUrl: string): void => {
    fetch(swUrl, { headers: { 'Service-Worker': 'script' } })
      .then((response: Response): void => {
        // Ensure service worker exists, and that we really are getting a JS file.
        const contentType: string | null = response.headers.get('content-type');
        if (response.status === 404 || (contentType !== null && contentType.indexOf('javascript') === -1)) {
          // No service worker found. Probably a different app. Reload the page.
          void navigator.serviceWorker.ready.then((registration: ServiceWorkerRegistration): void => {
            void registration.unregister().then((): void => {
              window.location.reload();
            });
          });
        } else {
          registerValidSW(swUrl);
        }
      })
      .catch((): void => {
        console.error('No internet connection found. App is running in offline mode.');
      });
  };
  
  const unregister: () => void = (): void => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready
        .then((registration: ServiceWorkerRegistration): Promise<boolean> => registration.unregister())
        .catch((error: Error) => {
          console.error(error.message);
        });
    }
  };

  return { register, unregister };
};
