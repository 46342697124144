import { IsDefined, IsString } from 'class-validator';

import { ConfirmUnverifiedEmailCoreRequest } from './confirmUnverifiedEmailCoreRequest.model';

export class ConfirmUnverifiedEmailRequest extends ConfirmUnverifiedEmailCoreRequest {
  @IsDefined()
  @IsString()
  public userHash: string;

  @IsDefined()
  @IsString()
  public authHash: string;

  constructor(
    value: string,
    userHash: string,
    authHash: string,
  ) {
    super(value);
    this.userHash = userHash;
    this.authHash = authHash;
  }
}
