import { 
  DetailsInfoGroup,
  DetailsInfoSettings,
  PageHeader,
  TransactionProductBox,
  UseFormatDate,
  UseNotifications,
  UseParsers,
  UseRedirect,
  UseState,
  useFormatDate,
  useNotifications,
  useParsers,
  useRedirect,
} from '@chic-loyalty/ui';
import { QueryObserverResult, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { Params, useParams } from 'react-router-dom';

import { getTransactionDetails } from '@chic/frontend/api/requests';
import { FullscreenAlertAssets, QueryKey } from '@chic/frontend/enums';
import { FrontendApiError } from '@chic/frontend/models';
import { RouteNameEnum, TransactionType } from '@chic/shared/enums';
import { ProductQuantityPrice, TransactionWithProducts } from '@chic/shared/interfaces';
import { getRouteDetailsByName } from '@chic/shared/utils';

import { 
  Container, 
  DetailsInfoSection,
  DetailsInfoWrapper, 
  ProductsSection, 
  ProductsWrapper, 
  StyledLoader, 
  TransactionDetailsBoxWrapper 
} from './transactionDetails.styled';

export const TransactionDetailsView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { showFullscreenAlert, hideFullscreenAlert }: UseNotifications = useNotifications();
  const { redirect }: UseRedirect = useRedirect();
  const { id }: Readonly<Params<string>> = useParams();
  const { formatDate }: UseFormatDate = useFormatDate();
  const { parsePrice }: UseParsers = useParsers();
  const [transactionData, setTransactionData]: UseState<TransactionWithProducts | null> = useState<TransactionWithProducts | null>(null);
  const [detailsInfoItems, setDetailsInfoItems]: UseState<DetailsInfoSettings[]> = useState<DetailsInfoSettings[]>([]);

  const { isFetched }: QueryObserverResult = useQuery<TransactionWithProducts, FrontendApiError>(
    [QueryKey.TransactionDetails],
    (): Promise<TransactionWithProducts> => getTransactionDetails(Number(id)),
    {
      onSuccess: (details: TransactionWithProducts): void => {
        setTransactionData(details);
        setDetailsInfoItems([
          { 
            label: t('chic.website.transactionDetailsView.detailsInfoItems.datetime'), 
            value: formatDate(new Date(details.datetime).getTime(), 'DD.MM.YYYY, HH:mm:ss') 
          },
          { label: t('chic.website.transactionDetailsView.detailsInfoItems.storeName'), value: details?.store.name ?? '-' },
          { 
            label: t('chic.website.transactionDetailsView.detailsInfoItems.type'), 
            value: details.typeLabel
          },
          ...(details?.typeName && [TransactionType.Refund, TransactionType.Sale].includes(details.typeName) ? [{
            label: t('chic.website.transactionDetailsView.detailsInfoItems.price'),
            value: String(`${parsePrice(details.totalPrice ?? 0)} zł`)
          }] : []),
          { 
            label: t('chic.website.transactionDetailsView.detailsInfoItems.points'), 
            value: String(details?.points ?? 0), 
            pointsIcon: true 
          },
        ]);
      },
      onError: (error: FrontendApiError): void => {
        if (error.responseCode === 404) {
          showFullscreenAlert({
            description: t('chic.website.transactionDetailsView.transactionNotFound'),
            iconImage: FullscreenAlertAssets.ErrorIcon,
            acceptButtonSettings: {
              label: t('chic.website.global.ok'),
              action: (): void => {
                hideFullscreenAlert();
                redirect(getRouteDetailsByName(RouteNameEnum.TransactionsList)?.url ?? '/');
              }
            },
          });
        }
      },
      retry: false,
    }
  );
  
  return (
    <Container>
      <PageHeader 
        header={t('chic.website.transactionDetailsView.pageHeader.header')} 
        onArrowButtonAction={(): void => redirect(getRouteDetailsByName(RouteNameEnum.TransactionsList)?.url ?? '/')} 
      />
      <DetailsInfoSection>
        <DetailsInfoWrapper>
          <DetailsInfoGroup items={detailsInfoItems} />
        </DetailsInfoWrapper>
      </DetailsInfoSection>
      {!transactionData && !isFetched && (
        <StyledLoader />
      )}
      {!!transactionData?.products.length && (
        <ProductsSection>
          <ProductsWrapper>
            {transactionData.products.map((product: ProductQuantityPrice): JSX.Element => (
              <TransactionDetailsBoxWrapper key={product.id}>
                <TransactionProductBox 
                  name={product.productDetails?.name ?? '-'} 
                  ean={product.productDetails?.ean ?? '-'}  
                  amount={product.quantity}
                  image={product.productDetails?.photos[0]}
                />
              </TransactionDetailsBoxWrapper>
            ))}
          </ProductsWrapper>
        </ProductsSection>
      )}
    </Container>
  );
};
