import { Color, FontWeight, Markdown, StyledComponent } from '@chic-loyalty/ui';
import styled from 'styled-components';

export const Wrapper: StyledComponent<'div'> = styled.div``;

export const AngularCoverWrapper: StyledComponent<'div'> = styled.div`
  min-height: calc(100vh - 293px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px 20px;
`;

export const Header: StyledComponent<'div'> = styled.div`
  font-size: 31px;
  font-weight: ${FontWeight.Medium};
  line-height: 40.3px;
  letter-spacing: -0.62px;
  color: ${Color.AlmostWhite};
  margin-bottom: 20px;
`;

export const Description: StyledComponent<typeof Markdown> = styled(Markdown)`
  font-size: 22px;
  font-weight: ${FontWeight.Light};
  line-height: 40.3px;
  letter-spacing: -0.62px;
  color: ${Color.SolidMediumEmphasis};
  margin-bottom: 20px;
  text-align: center;

  a {
    color: ${Color.SolidMediumEmphasis};
    font-weight: ${FontWeight.Regular};
  }
`;
