import { IsDefined, IsEnum, IsString, Matches } from 'class-validator';

import { eanRegexp } from '@chic/shared/constants';
import { UserContactDataType } from '@chic/shared/enums';

export class ConfirmContactDataRequest {
  @IsDefined()
  @IsString()
  @Matches(eanRegexp)
  public ean: string;

  @IsDefined()
  @IsEnum(UserContactDataType)
  public type: UserContactDataType;

  @IsDefined()
  @IsString()
  public token: string;

  constructor(
    ean: string,
    type: UserContactDataType,
    token: string,
  ) {
    this.ean = ean;
    this.type = type;
    this.token = token;
  }
}
