import { 
  UseState, 
  PageHeader, 
  PageHeaderPosition, 
  ProductDetailsBox,
  UseRedirect,
  useRedirect,
  Icon,
  IconName,
  Color,
  UseNotifications,
  useNotifications,
} from '@chic-loyalty/ui';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { getProductDetails } from '@chic/frontend/api/requests';
import { FullscreenAlertAssets, QueryKey } from '@chic/frontend/enums';
import { useAuth } from '@chic/frontend/hooks';
import { UseAuth } from '@chic/frontend/interfaces';
import { FrontendApiError } from '@chic/frontend/models';
import { RouteNameEnum } from '@chic/shared/enums';
import { Product } from '@chic/shared/interfaces';
import { getRouteDetailsByName } from '@chic/shared/utils';

import { 
  Container, 
  HeaderWrapper,
  ContentWrapper, 
  Content, 
  ImageBox,
  Image,
  StyledLoader,
} from './productDetails.styled';

export const ProductDetailsView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { showFullscreenAlert, hideFullscreenAlert }: UseNotifications = useNotifications();
  const [productDetails, setProductDetails]: UseState<Product | null> = useState<Product | null>(null);
  const { redirect }: UseRedirect = useRedirect();
  const { id: productId }: Readonly<Record<string, string | undefined>> = useParams();
  const { userData }: UseAuth = useAuth();

  const { isLoading }: UseQueryResult = useQuery<Product, FrontendApiError>(
    [QueryKey.ProductDetails],
    (): Promise<Product> => getProductDetails(productId || ''),
    {
      onSuccess: setProductDetails,
      onError: (error: FrontendApiError): void => {
        if (error.responseCode === 404) {
          showFullscreenAlert({
            description: t('chic.website.productDetailsView.productNotFound'),
            iconImage: FullscreenAlertAssets.ErrorIcon,
            acceptButtonSettings: {
              label: t('chic.website.global.ok'),
              action: (): void => {
                hideFullscreenAlert();
                redirect(getRouteDetailsByName(RouteNameEnum.Products)?.url ?? '/');
              }
            },
          });
        }
      },
    }
  );

  return (
    <Container>
      {!productDetails && isLoading && (
        <StyledLoader />
      )}
      {!!productDetails && userData && (
        <>
          <HeaderWrapper>
            <PageHeader
              header={productDetails.name}
              subheader={t('chic.website.productDetailsView.ean', { value: productDetails.ean })}
              headerPosition={PageHeaderPosition.Left}
              onArrowButtonAction={(): void => redirect(getRouteDetailsByName(RouteNameEnum.Products)?.url ?? '/')}
            />
          </HeaderWrapper>
          <ContentWrapper>        
            <Content>
              <ImageBox>
                {productDetails.photos[0] ? (
                  <Image src={productDetails.photos[0]} alt={productDetails.name} />
                ) : (
                  <Icon name={IconName.Photo} size={44} color={Color.Whisper} />
                )} 
              </ImageBox>
              <ProductDetailsBox 
                points={productDetails.rewardPoints} 
                description={productDetails.description ?? ''} 
                canUnlock={true}
                numberOfPointsNedded={productDetails.rewardPoints - userData.points}
              />
            </Content>
          </ContentWrapper>
        </>
      )}
    </Container>
  );
};
