import { IsDefined, IsString } from 'class-validator';

import { PayuCreditCardOrderStatus } from '@chic/shared/enums';

export class PayuOrder {
  @IsDefined()
  @IsString()
  public orderId: string;

  @IsDefined()
  @IsString()
  public extOrderId: string;

  @IsDefined()
  @IsString()
  public orderCreateDate: string;

  @IsDefined()
  @IsString()
  public notifyUrl: string;

  @IsDefined()
  @IsString()
  public customerIp: string;

  @IsDefined()
  @IsString()
  public merchantPosId: string;

  @IsDefined()
  @IsString()
  public description: string;

  @IsDefined()
  @IsString()
  public currencyCode: string;

  @IsDefined()
  @IsString()
  public totalAmount: string;

  @IsDefined()
  @IsString()
  public status: PayuCreditCardOrderStatus;

  constructor(
    orderId: string,
    extOrderId: string,
    orderCreateDate: string,
    notifyUrl: string,
    customerIp: string,
    merchantPosId: string,
    description: string,
    currencyCode: string,
    totalAmount: string,
    status: PayuCreditCardOrderStatus,
  ) {
    this.orderId = orderId;
    this.extOrderId = extOrderId;
    this.orderCreateDate = orderCreateDate;
    this.notifyUrl = notifyUrl;
    this.customerIp = customerIp;
    this.merchantPosId = merchantPosId;
    this.description = description;
    this.currencyCode = currencyCode;
    this.totalAmount = totalAmount;
    this.status = status;
  }
}
