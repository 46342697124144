import {
  PageHeader,
  UseState,
  PageHeaderPosition,
  UseNotifications,
  useNotifications,
  MenuPositionData,
  ValidationBarTheme,
  ContentBoxTheme,
} from '@chic-loyalty/ui';
import { useQuery } from '@tanstack/react-query';
import { Form, Formik, FormikProps } from 'formik';
import React, { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { getMessagesCategories, subscribeMessageCategory } from '@chic/frontend/api/requests';
import { QueryKey } from '@chic/frontend/enums';
import { FrontendApiError } from '@chic/frontend/models';
import { MessageCategoryDetails } from '@chic/shared/interfaces';
import { SubscribeMessageCategoryRequestItem } from '@chic/shared/models';

import { useProfileTabs } from '../profile.hooks';

import {
  Container,
  ContentWrapper,
  StyledButton,
  StyledCheckbox,
  StyledContentBox,
  StyledValidationBar
} from './notificationsSettings.styled';

export const NotificationsSettingsView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { addToast }: UseNotifications = useNotifications();
  const profileTabs: MenuPositionData[] = useProfileTabs();
  const [messageCategories, setMessageCategories]: UseState<MessageCategoryDetails[]> = useState<MessageCategoryDetails[]>([]);
  const [readOnlyMessageCategoriesNames, setReadOnlyMessageCategoriesNames]: UseState<string[]> = useState<string[]>([]);
  const [initialSelectedMessageCategories, setInitialSelectedMessageCategories]: UseState<Record<string, boolean>>
    = useState<Record<string, boolean>>({});
  const [isLoading, setIsLoading]: UseState<boolean> = useState<boolean>(false);

  useQuery(
    [QueryKey.MessageCategory],
    (): Promise<MessageCategoryDetails[]> => getMessagesCategories(),
    {
      onSuccess: (data: MessageCategoryDetails[]): void => {
        setInitialSelectedMessageCategories(data.reduce(
          (acc: Record<string, boolean>, item: MessageCategoryDetails): Record<string, boolean> => ({ ...acc, [item.name]: item.active }),
          {}
        ));
        setMessageCategories(data);
        setReadOnlyMessageCategoriesNames(data
          .filter((category: MessageCategoryDetails): boolean => category.readOnly)
          .map((category: MessageCategoryDetails): string => category.name)
        );
      },
      // TODO: add logger
      onError: (): void => undefined
    }
  );

  const updateMessageCategories: (data: Record<string, boolean>) => void = (data: Record<string, boolean>): void => {
    setIsLoading(true);
    const requestData: SubscribeMessageCategoryRequestItem[] = Object.entries(data)
      .filter(([name]: [name: string, value: boolean]): boolean => !readOnlyMessageCategoriesNames.includes(name))
      .map(([name, value]: [name: string, value: boolean]): SubscribeMessageCategoryRequestItem => ({
        name,
        active: value,
      }));
    subscribeMessageCategory(requestData)
      .then((): void => addToast({ content: t('chic.website.notificationsSettingsView.topicsUpdated') }))
      .catch((error: FrontendApiError): void => addToast({ content: t(error.message) }))
      .finally((): void => setIsLoading(false));
  };

  return (
    <Container>
      <PageHeader
        header={t('chic.website.meta.profile.title')}
        headerPosition={PageHeaderPosition.CenterLeft}
        tabs={profileTabs}
        activeTabName='notificationsSettings'
      />
      <ContentWrapper>
        <StyledContentBox contentBoxTheme={ContentBoxTheme.Medium1}>
          <StyledValidationBar
            message={t('chic.website.notificationsSettingsView.message')}
            barTheme={ValidationBarTheme.Gray}
          />
          <Formik
            initialValues={initialSelectedMessageCategories}
            onSubmit={updateMessageCategories}
            enableReinitialize
          >
            {({ handleSubmit, setFieldValue, values }: FormikProps<Record<string, boolean>>): JSX.Element => (
              <Form onSubmit={handleSubmit}>
                {messageCategories.map((messageCategory: MessageCategoryDetails): JSX.Element => (
                  <StyledCheckbox
                    label={messageCategory.label}
                    key={messageCategory.name}
                    id={messageCategory.name}
                    checked={!!values[messageCategory.name]}
                    disabled={messageCategory.readOnly}
                    onChange={(value: boolean): void => setFieldValue(messageCategory.name, value)}
                  />
                ))}
                <StyledButton
                  type='submit'
                  label={t('chic.website.global.save')}
                  isLoading={isLoading}
                />
              </Form>
            )}
          </Formik>
        </StyledContentBox>
      </ContentWrapper>
    </Container>
  );
};
