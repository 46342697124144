import { 
  AccordionItem, 
  Breakpoint, 
  Checkbox, 
  Color, 
  ContentBox, 
  FontWeight, 
  Grid, 
  Markdown, 
  StyledComponent 
} from '@chic-loyalty/ui';
import styled from 'styled-components';

import { ContainerProps } from './cookiesPolicy.types';

export const Container: StyledComponent<'div', ContainerProps> = styled.div<ContainerProps>`
  background-color: ${Color.AlmostBlack};
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ $withLeftPadding }: ContainerProps): string | false => $withLeftPadding && 'padding-left: 80px'};

  @media ${Breakpoint.Mobile} {
    padding-left: 0;
  }
`;

export const ContentWrapper: StyledComponent<'div'> = styled.div`
  padding: 0 20px 100px;
  max-width: 100%;
`;

export const ContentBoxesContainer: StyledComponent<'div'> = styled.div`
  margin-top: 50px;
`;

export const StyledContentBox: StyledComponent<typeof ContentBox> = styled(ContentBox)`
  & + & {
    margin-top: 2px;
  }
`;

export const ContentText: StyledComponent<typeof Markdown> = styled(Markdown)`
  font-size: 14px;
  line-height: 20px;
  color: ${Color.SolidLowEmphasis};

  p + p {
    margin-top: 20px;
  }

  a {
    color: ${Color.Tangerine};
  }

  ul {
    margin-top: 15px;
    list-style-type: disc;
    list-style-position: inside;

    li::marker {
      color: ${Color.Tangerine};
    }

    li + li {
      margin-top: 12px;
    }
  }
`;

export const TextSection: StyledComponent<'div'> = styled.div`
  max-width: ${Grid.Tablet};
  margin: 50px 0 35px;
`;

export const Text: StyledComponent<typeof Markdown> = styled(Markdown)`
  font-weight: ${FontWeight.Medium};
  font-size: 16px;
  line-height: 22px;
  color: ${Color.SolidHighEmphasis};

  a {
    color: ${Color.Tangerine};
  }
`;

export const SimpleTableContainer: StyledComponent<'div'> = styled.div`
  max-width: ${Grid.Tablet};
  width: 100%;

  & + & {
    margin-bottom: 50px;
  }
`;

export const CheckboxesContainer: StyledComponent<'div'> = styled.div``;

export const StyledAccordionItem: StyledComponent<typeof AccordionItem> = styled(AccordionItem)`
  max-width: ${Grid.Tablet};
`;

export const StyledCheckbox: StyledComponent<typeof Checkbox> = styled(Checkbox)`
  & + & {
    margin-top: 20px;
  }
`;

export const OptionsContainer: StyledComponent<'div'> = styled.div``;
