import { IsDefined, IsString, Matches } from 'class-validator';

import { passwordRegexp } from '@chic/shared/constants';
import { PreferredLanguage } from '@chic/shared/enums';

import { RegisterWithoutPasswordRequest } from './registerWithoutPasswordRequest.model';

export class RegisterRequest<AgreementsType = number> extends RegisterWithoutPasswordRequest<AgreementsType> {
  @IsDefined()
  @IsString()
  @Matches(passwordRegexp)
  public password: string;

  @IsDefined()
  @IsString()
  public recaptchaToken: string;

  constructor(
    firstName: string,
    lastName: string,
    phone: string,
    email: string,
    birthday: string,
    preferredLanguage: PreferredLanguage,
    password: string,
    declarationOfAge: AgreementsType,
    personalDataAgreement: AgreementsType,
    recaptchaToken: string,
    smsCode?: string,
    marketingAgreement?: AgreementsType,
    marketingAdditionalAgreement?: AgreementsType,
    marketingSocialAgreement?: AgreementsType,
    phoneSurveyAgreement?: AgreementsType,
    mgmCode?: string,
    postalCode?: string,
  ) {
    super(
      firstName,
      lastName,
      phone,
      email,
      birthday,
      preferredLanguage,
      declarationOfAge,
      personalDataAgreement,
      smsCode,
      marketingAgreement,
      marketingAdditionalAgreement,
      marketingSocialAgreement,
      phoneSurveyAgreement,
      mgmCode,
      postalCode,
    );
    this.password = password;
    this.recaptchaToken = recaptchaToken;
  }
}
