import { UserPermission } from '@chic/shared/enums';
import { IdValueObject } from '@chic/shared/models';

export function getUserPermissionByType(
  permissions: IdValueObject<UserPermission, boolean>[], type: UserPermission
): boolean {
  return !!permissions.find((permission: IdValueObject<UserPermission, boolean>): boolean => (
    permission.id === type
  ))?.value;
}
