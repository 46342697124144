import {
  Breakpoint,
  Button,
  Color,
  ContentBox,
  FontWeight,
  hexToRgba,
  Input,
  StyledComponent,
  TabSwitch,
  ValidationBar
} from '@chic-loyalty/ui';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  width: 100%;
  background-color: ${Color.AlmostBlack};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media ${Breakpoint.Mobile} {
    width: 100%;
  }
`;

export const FormikForm: StyledComponent<'form'> = styled.form`
  width: 100%;
`;

export const StyledButton: StyledComponent<typeof Button> = styled(Button)`
  margin-top: 35px;
`;

export const AdditionalControls: StyledComponent<'div'> = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const RemindPasswordLink: StyledComponent<typeof Link> = styled(Link)`
  font-weight: ${FontWeight.SemiBold};
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: ${hexToRgba(Color.White, 0.4)};
`;

export const StyledTabSwitch: StyledComponent<typeof TabSwitch> = styled(TabSwitch)`
  margin-bottom: 20px;
`;

export const StyledContentBox: StyledComponent<typeof ContentBox> = styled(ContentBox)`
  margin: 70px auto;

  @media ${Breakpoint.Tablet} {
    margin: 0 auto;
  }
`;

export const StyledInput: StyledComponent<typeof Input> = styled(Input)`
  & + & {
    margin-top: 10px;
  }
`;

export const StyledValidationBar: StyledComponent<typeof ValidationBar> = styled(ValidationBar)`
  margin-top: 45px;

  @media ${Breakpoint.Tablet} {
    margin-top: 25px;
  }
`;
