import { StyledComponent, Color, FontWeight, Breakpoint, ZIndex, Button } from '@chic-loyalty/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  display: flex;
  background-color: ${Color.Black};
  min-height: 620px;
  height: 100vh;

  @media ${Breakpoint.Tablet} {
    flex-direction: column-reverse;
    height: auto;
  }
`;

export const LeftSection: StyledComponent<'div'> = styled.div`
  flex: 1;
  padding-top: 150px;
  padding-bottom: 60px;
  padding-left: 168px;
  overflow: visible;
  z-index: ${ZIndex.Min};
  display: flex;
  flex-direction: column;

  @media ${Breakpoint.Tablet} {
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 0;
    position: relative;
    top: -170px;
  }

  @media ${Breakpoint.Mobile} {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    top: -130px;
    margin-bottom: -100px;
  }
`;

export const WelcomeText: StyledComponent<'p'> = styled.p`
  font-size: 20px;
  line-height: 28px;
  font-weight: ${FontWeight.Medium};
  color: ${Color.SolidMediumEmphasis};
  white-space: nowrap;
`;

export const Title: StyledComponent<'p'> = styled.p`
  margin-top: 25px;
  color: ${Color.SolidHighEmphasis};
  font-size: 48px;
  line-height: 57px;
  letter-spacing: -0.03em;
  font-weight: ${FontWeight.Medium};
  width: 200%;
  max-width: 760px;

  @media ${Breakpoint.Tablet} {
    width: 100%;
    max-width: 600px;
    font-size: 39px;
    line-height: 46px;
  }
`;

export const StyledButton: StyledComponent<typeof Button> = styled(Button)`
  margin-top: 25px;
`;

export const RightSection: StyledComponent<'div'> = styled.div`
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
  height: 100vh;
  min-height: 620px;
  
  @media ${Breakpoint.Tablet} {
    min-height: 0;
    max-height: 60vh;
    opacity: 0.6;
    align-items: flex-start;
  }

  @media ${Breakpoint.Mobile} {
    max-height: 40vh;
  }
`;

export const ManImg: StyledComponent<'img'> = styled.img`
  min-height: 100%;
  min-width: 100%;
  flex-shrink: 0;
  transition: 0.3s;

  @media ${Breakpoint.Mobile} {
    object-fit: cover;
    height: 40vh;
    object-position: 50% 0%;
  }

  :hover {
    transform: scale(1.1);
  }
`;

export const DevicesImg: StyledComponent<'img'> = styled.img`
  position: absolute;
  bottom: 50px;
  right: 50px;

  @media ${Breakpoint.Tablet} {
    display: none;
  }
`;
