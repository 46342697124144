export enum PreferredLanguage {
  Pl = 'pl',
  En = 'en',
  Ua = 'ua',
  Cz = 'cz',
  De = 'de',
  Sk = 'sk',
  Lt = 'lt',
  No = 'no',
  Es = 'es',
}
