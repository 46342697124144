import { Type } from 'class-transformer';
import { IsArray, ValidateNested } from 'class-validator';

import { SurveyAnswerToSave } from './surveyAnswerToSave.model';

export class SaveSurveyAnswerRequest {
  @Type(() => SurveyAnswerToSave)
  @IsArray()
  @ValidateNested()
  public answers: SurveyAnswerToSave[];

  constructor(
    answers: SurveyAnswerToSave[],
  ) {
    this.answers = answers;
  }
}
