import { IsDefined, IsString, Matches } from 'class-validator';

import { passwordRegexp } from '@chic/shared/constants';

export class ChangePasswordRequest {
  @IsDefined()
  @IsString()
  public oldPassword: string;

  @IsDefined()
  @IsString()
  @Matches(passwordRegexp)
  public newPassword: string;

  constructor(
    oldPassword: string,
    newPassword: string,
  ) {
    this.oldPassword = oldPassword;
    this.newPassword = newPassword;
  }
}
