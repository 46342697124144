import { BonusBox, Breakpoint, Color, FontWeight, Grid, StyledComponent } from '@chic-loyalty/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  background-color: ${Color.Level1};
  min-height: 100vh;
  width: calc(100% - 80px);
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${Breakpoint.Mobile} {
    width: 100%;
  }
`;

export const GiftBoxImage: StyledComponent<'img'> = styled.img`
  margin-bottom: 35px;
`;

export const TopSectionText: StyledComponent<'p'> = styled.p`
  font-weight: ${FontWeight.Medium};
  font-size: 31px;
  line-height: 40px;
  letter-spacing: -0.02em;
  color: ${Color.SolidHighEmphasis};

  @media ${Breakpoint.Mobile} {
    font-size: 25px;
    line-height: 35px;
    letter-spacing: -0.01em;
  }
`;

export const BonusesSection: StyledComponent<'div'> = styled.div`
  background-color: ${Color.AlmostBlack};
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const BonusesSectionInner: StyledComponent<'div'> = styled.div`
  margin: 100px 20px;
  max-width: ${Grid.Desktop};

  @media ${Breakpoint.Tablet} {
    margin: 50px 20px;
  }
`;

export const LightText: StyledComponent<'p'> = styled.p`
  font-size: 12px;
  line-height: 19px;
  color: ${Color.SolidLowEmphasis};
`;

export const StyledBonusBox: StyledComponent<typeof BonusBox> = styled(BonusBox)`
  margin-bottom: 10px;
`;
