import { Breakpoint, Button, Color, FontWeight, Grid, StyledComponent, TransactionProductBox } from '@chic-loyalty/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  width: calc(100% - 80px);
  min-height: 100vh;
  margin-left: auto;
  background-color: ${Color.AlmostBlack};

  @media ${Breakpoint.Mobile} {
    width: 100%;
  }
`;

export const ContentContainer: StyledComponent<'div'> = styled.div`
  width: 100%;
  padding: 50px 20px 100px;
  max-width: calc(${Grid.Desktop} + 120px);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${Breakpoint.Mobile} {
    padding: 50px 0;
  }
`;

export const AdBoxWrapper: StyledComponent<'div'> = styled.div`
  margin-bottom: 10px;
  width: 100%;

  @media ${Breakpoint.Mobile} {
    margin-bottom: 0;
    padding: 35px 20px;
  }
`;

export const DetailsBox: StyledComponent<'div'> = styled.div`
  width: 100%;
  padding: 70px 202px;
  background-color: ${Color.Level1};
  box-shadow: 0 12px 20px -8px rgba(0, 0, 0, 0.015993);
  border-radius: 4px;
  margin-bottom: 10px;

  @media ${Breakpoint.Tablet} {
    padding: 70px 102px;
  }

  @media ${Breakpoint.Mobile} {
    padding: 35px 20px;
  }
`;

export const TextContent: StyledComponent<'div'> = styled.div`
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.4px;
  color: ${Color.SolidMediumEmphasis};
`;

export const Subtitle: StyledComponent<'div'> = styled.div`
  font-weight: ${FontWeight.Medium};
  font-size: 16px;
  line-height: 22px;
  color: ${Color.SolidHighEmphasis};
  margin: 50px 0 35px;
`;

export const StyledButton: StyledComponent<typeof Button> = styled(Button)`
  margin-top: 50px;
`;

export const StyledTransactionProductBox: StyledComponent<typeof TransactionProductBox> = styled(TransactionProductBox)`
  padding: 20px 0;
  border-bottom: 1px solid ${Color.SolidLowestEmphasis};
`;

export const OrderHistoryTableWrapper: StyledComponent<'div'> = styled.div`
  max-width: ${Grid.Tablet};
  width: 100%;

  @media ${Breakpoint.Mobile} {
    padding: 0 20px;
  }
`;
