import { 
  Color, 
  Breakpoint, 
  StyledComponent,
  Grid,
  TransactionProductBox,
  ActionBox,
  SubscriptionPlanSummaryBox,
} from '@chic-loyalty/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: calc(100% - 80px);
  background-color: ${Color.AlmostBlack};
  margin-left: auto;

  @media ${Breakpoint.Mobile} {
    width: 100%;
  }
`;

export const ContentWrapper: StyledComponent<'div'> = styled.div`
  width: 100%;
  background-color: ${Color.AlmostBlack};
  display: flex;
  justify-content: center;
`;

export const ContentWrapperStepTwo: StyledComponent<typeof ContentWrapper> = styled(ContentWrapper)`
  padding: 50px 20px 100px;

  @media ${Breakpoint.Tablet} {
    padding: 50px 20px;
  }
`;

export const Content: StyledComponent<'div'> = styled.div`
  max-width: ${Grid.Desktop};
  width: 100%;
`;

export const DetailsBox: StyledComponent<'div'> = styled.div`
  width: 100%;
  padding: 70px 202px;
  background-color: ${Color.Level1};
  box-shadow: 0 12px 20px -8px rgba(0, 0, 0, 0.015993);
  border-radius: 12px;
  margin-bottom: 20px;

  @media ${Breakpoint.Tablet} {
    padding: 70px 102px;
  }

  @media ${Breakpoint.Mobile} {
    padding: 35px 20px;
  }
`;

export const StyledTransactionProductBox: StyledComponent<typeof TransactionProductBox> = styled(TransactionProductBox)`
  padding: 20px 0;

  & + & {
    border-top: 1px solid ${Color.SolidLowestEmphasis};
  }
`;

export const StyledActionBox: StyledComponent<typeof ActionBox> = styled(ActionBox)`
  @media ${Breakpoint.Mobile} {
    height: auto;
  }
`;

export const StyledSubscriptionPlanSummaryBox: StyledComponent<typeof SubscriptionPlanSummaryBox> = styled(SubscriptionPlanSummaryBox)`
  margin-bottom: 20px;
`;
