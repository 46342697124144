import { AgreementFieldId } from '@chic/frontend/enums';
import { AgreementDataExtended, UseAgreements, UseAuth } from '@chic/frontend/interfaces/hooks';
import { AgreementName, PreferredLanguage } from '@chic/shared/enums';
import { AgreementData, UserAgreement } from '@chic/shared/interfaces';

import { useAuth } from './useAuth.hook';

export const useAgreements: () => UseAgreements = (): UseAgreements => {
  const marketingAgreementPrefixRegex: RegExp = /^(?<before>.*)(\[prefix\])(?<prefixContent>.*)(\[\/prefix\])(?<after>.*)$/;
  const { userData }: UseAuth = useAuth();

  const getAgreementFieldIdByAgreementName: (name: AgreementName) => AgreementFieldId = (name: AgreementName): AgreementFieldId => {
    switch (name) {
      case AgreementName.DeclarationOfAge:
        return AgreementFieldId.DeclarationOfAge;
      case AgreementName.PersonalDataAgreement:
        return AgreementFieldId.PersonalDataAgreement;
      case AgreementName.MarketingAgreement:
        return AgreementFieldId.MarketingAgreement;
      case AgreementName.MarketingAdditionalAgreement:
        return AgreementFieldId.MarketingAdditionalAgreement;
      case AgreementName.MarketingSocialAgreement:
        return AgreementFieldId.MarketingSocialAgreement;
      case AgreementName.PhoneSurveyAgreement:
        return AgreementFieldId.PhoneSurveyAgreement;
    }
  };

  const getAgreementsObject: (
    agreements: AgreementData[], active: boolean, language?: PreferredLanguage
  ) => Record<AgreementName, AgreementDataExtended> = (
    agreements: AgreementData[], active: boolean, language: PreferredLanguage = PreferredLanguage.Pl
  ): Record<AgreementName, AgreementDataExtended> => {
    return agreements
      .filter((item: AgreementData): boolean => item.language === language && item.active === active)
      .reduce(
        (
          acc: Record<AgreementName, AgreementDataExtended>, item: AgreementData
        ): Record<AgreementName, AgreementDataExtended> => {
          const matched: RegExpMatchArray | null = item.markdown.match(marketingAgreementPrefixRegex);

          return {
            ...acc,
            [item.agreementName]: {
              ...item,
              fieldId: getAgreementFieldIdByAgreementName(item.agreementName),
              markdown: matched ? matched.groups?.after : item.markdown,
            }
          };
        },
        {} as Record<AgreementName, AgreementDataExtended>
      );
  };

  const getMarketingAgreementPrefix: (agreements: AgreementData[], active: boolean, language?: PreferredLanguage) => string = (
    agreements: AgreementData[], active: boolean, language: PreferredLanguage = PreferredLanguage.Pl
  ): string => {
    const marketingAgreement: AgreementData | undefined = agreements.find((item: AgreementData): boolean => (
      item.agreementName === AgreementName.MarketingAgreement
      && item.language === language
      && item.active === active
    ));
    const matchedPrefix: RegExpMatchArray | undefined | null = marketingAgreement?.markdown?.match(marketingAgreementPrefixRegex);
    
    return matchedPrefix?.groups?.prefixContent ?? '';
  };

  const getAgreementFromUserData: (type: AgreementName) => UserAgreement | undefined = (type: AgreementName): UserAgreement | undefined => {
    return userData?.agreements?.find((agreement: UserAgreement): boolean => agreement.type === type);
  };

  const getSelectedAgreementVersionFromUserData: (type: AgreementName) => number | undefined = (
    type: AgreementName
  ): number | undefined => {
    const agreement: UserAgreement | undefined = getAgreementFromUserData(type);

    return agreement?.value
      ? agreement?.version
      : undefined;
  };

  return {
    getAgreementsObject,
    getMarketingAgreementPrefix,
    getAgreementFromUserData,
    getSelectedAgreementVersionFromUserData,
  };
};
