import { 
  Color, 
  Breakpoint, 
  StyledComponent, 
  Grid, 
  Input, 
  ProductBox,
  MailToUs,
  FontWeight,
  Markdown,
  Button,
} from '@chic-loyalty/ui';
import styled from 'styled-components';

import { IconContainerProps } from './baseViewWithEditProducts.types';

export const Content: StyledComponent<'div'> = styled.div`
  max-width: ${Grid.Desktop};
  width: calc(100% - 80px);
  padding: 50px 20px;

  @media ${Breakpoint.Tablet} {
    width: 100%;
  }
`;

export const StyledInput: StyledComponent<typeof Input> = styled(Input)`
  max-width: 700px;
  width: 100%;

  @media ${Breakpoint.Mobile} {
    max-width: 100%;
  }
`;

export const GroupContainer: StyledComponent<'div'> = styled.div``;

export const ContentTitle: StyledComponent<'div'> = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: ${Color.SolidHighEmphasis};
  text-align: left;
  display: flex;
  align-items: center;
  margin-top: 70px;
`;

export const ProductsList: StyledComponent<'div'> = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(4, minmax(200px, 1fr));
  margin-top: 25px;

  @media ${Breakpoint.Tablet} {
    grid-template-columns: repeat(3, minmax(200px, 1fr));
  }

  @media ${Breakpoint.Mobile} {
    grid-template-columns: repeat(2, minmax(200px, 1fr));
  }

  @media ${Breakpoint.SmallMobile} {
    grid-template-columns: repeat(1, minmax(200px, 1fr));
  }
`;

export const StyledProductBox: StyledComponent<typeof ProductBox> = styled(ProductBox)`
  width: 100%;
`;

export const ItemsCountMessage: StyledComponent<'div'> = styled.div`
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 1px;
  color: ${Color.SolidLowEmphasis};
  margin-top: 10px;
`;

export const StyledMailToUs: StyledComponent<typeof MailToUs> = styled(MailToUs)`
  margin-top: 40px;
`;

export const IconContainer: StyledComponent<'div', IconContainerProps> = styled.div<IconContainerProps>`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ $background }: IconContainerProps): Color => $background};
  margin-left: 10px;
`;

export const MissingGroupsItemsNames: StyledComponent<typeof Markdown> = styled(Markdown)`
  text-align: center;
  font-weight: ${FontWeight.Light};
  font-size: 12px;
  line-height: 16px;
  color: ${Color.SolidMediumEmphasis};

  strong {
    color: ${Color.Tangerine};
  }
`;

export const ButtonsWrapper: StyledComponent<'div'> = styled.div`
  width: 100%;
  padding: 40px 20px 100px;
  background: ${Color.Level2};
  display: flex;
  justify-content: center;

  @media ${Breakpoint.Mobile} {
    padding: 40px 20px;
  }
`;

export const StyledButton: StyledComponent<typeof Button> = styled(Button)`
  & + & {
    margin-left: 10px;
  }

  @media ${Breakpoint.Mobile} {
    width: 100%;

    & + & {
      margin-left: 0;
      margin-top: 10px;
    }
  }
`;

export const ButtonsContainer: StyledComponent<'div'> = styled.div`
  max-width: ${Grid.Desktop};
  width: 100%;
  display: flex;

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
  }
`;

export const OuterContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
