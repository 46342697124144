import { 
  AccordionItemData,
  StepBoxSettings, 
  SubscriptionDetailsSettings, 
  SubscriptionInformations, 
  UseRedirect, 
  UseState, 
  UseTransformations, 
  getPathWithParams,
  useRedirect,
  useTransformations
} from '@chic-loyalty/ui';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { getSubscriptions, getSubscriptionsPlans } from '@chic/frontend/api/requests';
import { FileFromViews, QueryKey } from '@chic/frontend/enums';
import { useSubscriptionsActions } from '@chic/frontend/hooks';
import { UseSubscriptionsActions } from '@chic/frontend/interfaces';
import { RouteNameEnum } from '@chic/shared/enums';
import { Subscription, SubscriptionPlan, SubscriptionPlanAsset } from '@chic/shared/interfaces';
import { getRouteDetailsByName } from '@chic/shared/utils';

import { UseSubscriptions } from './subscriptions.types';

export const useSubscriptions: () => UseSubscriptions = (): UseSubscriptions => {
  const { t }: TransProps<never> = useTranslation();
  const { redirect }: UseRedirect = useRedirect();
  const { getSubscriptionAssetByType }: UseTransformations = useTransformations();
  const { changeDeliveryDateAction }: UseSubscriptionsActions = useSubscriptionsActions();
  const [inactiveSubscriptions, setInactiveSubscriptions]: UseState<SubscriptionInformations[] | null>
    = useState<SubscriptionInformations[] | null>(null);
  const [activeSubscriptions, setActiveSubscriptions]: UseState<SubscriptionDetailsSettings[] | null>
    = useState<SubscriptionDetailsSettings[] | null>(null);
  const [subscriptionPlansCategories, setSubscriptionPlansCategories]: UseState<SubscriptionPlan[][] | null>
    = useState<SubscriptionPlan[][] | null>(null);
  const [activeTab, setActiveTab]: UseState<'active' | 'inactive'> = useState<'active' | 'inactive'>('active');
  const [canScrollToAvailablePlan, setCanScrollToAvailablePlan]: UseState<boolean> = useState<boolean>(false);

  useQuery(
    [QueryKey.SubscriptionPlans],
    (): Promise<SubscriptionPlan[]> => getSubscriptionsPlans(),
    {
      onSuccess: (data: SubscriptionPlan[]): void => {
        const subscriptionPlansCopy: Record<string, SubscriptionPlan[]> = {};
        data.forEach((plan: SubscriptionPlan): void => {
          if (!subscriptionPlansCopy[plan.category.name]) {
            subscriptionPlansCopy[plan.category.name] = [];
          }
          subscriptionPlansCopy[plan.category.name].push(plan);
        });
        setSubscriptionPlansCategories(Object.values(subscriptionPlansCopy));
      },
      // TODO: add logger
      onError: (): void => undefined
    }
  );

  useQuery(
    [QueryKey.Subscriptions],
    (): Promise<Subscription[]> => getSubscriptions(),
    {
      onSuccess: (data: Subscription[]): void => {
        const activeSubs: Subscription[] = data
          .filter((subscription: Subscription): boolean => !!subscription.active);
        const inactiveSubs: Subscription[] = data
          .filter((subscription: Subscription): boolean => subscription.active === false);
        setInactiveSubscriptions(inactiveSubs
          .map((subscription: Subscription): SubscriptionInformations => ({
            label: subscription.plan.name,
            planColor: subscription.plan.color,
            price: subscription.price,
            escPoints: subscription.plan.loyaltyPoints,
            buttonSettings: {
              internalPath: getPathWithParams(
                getRouteDetailsByName(RouteNameEnum.SubscriptionDetails)?.url ?? '', { id: `${subscription.id}` }
              ),
            },
            active: false
          }))
        );
        setActiveSubscriptions(activeSubs
          .map((subscription: Subscription): SubscriptionDetailsSettings => ({
            name: subscription.plan.name,
            image: getSubscriptionAssetByType(subscription.plan.assets, 'packshot', 'dark'),
            planColor: subscription.plan.color,
            planIcon: subscription.plan.assets.find((asset: SubscriptionPlanAsset): boolean => asset.type === 'icon')?.path ?? '',
            price: subscription.price,
            escPoints: subscription.plan.loyaltyPoints,
            changeDeliveryDateAction: (): void => changeDeliveryDateAction(subscription.plan.name, subscription.id),
            editAction: (): void => redirect(
              getPathWithParams(
                getRouteDetailsByName(RouteNameEnum.SubscriptionEditProducts)?.url ?? '', { subscriptionId: `${subscription.id}` }
              ),
            ),
            detailsAction: (): void => redirect(
              getPathWithParams(getRouteDetailsByName(RouteNameEnum.SubscriptionDetails)?.url ?? '', { id: `${subscription.id}` }),
            ),
          }))
        );
        if (activeSubs.length) {
          setActiveTab('active');
        } else if (inactiveSubs.length) {
          setActiveTab('inactive');
        }
      },
      // TODO: add logger
      onError: (): void => undefined
    }
  );

  const faqItems: AccordionItemData[] = useMemo(
    (): AccordionItemData[] => {
      let itemsArray: AccordionItemData[] = [];
      for (let index: number = 1; index <= 12; index++) {
        itemsArray = [ 
          ...itemsArray, {
            label: t(`chic.website.useSubscriptions.faqItems.label.${index}`),
            content: t(`chic.website.useSubscriptions.faqItems.content.${index}`),
          }
        ];
      }
      return itemsArray;
    },
    []
  );
  
  const stepBoxes: StepBoxSettings[] = [
    {
      icon: FileFromViews.SubscriptionFirstStepIcon,
      title: t('chic.website.useSubscriptions.stepBoxes.firstStep.title'),
      details: t('chic.website.useSubscriptions.stepBoxes.firstStep.details')
    },
    {
      icon: FileFromViews.SubscriptionSecondStepIcon,
      title: t('chic.website.useSubscriptions.stepBoxes.secondStep.title'),
      details: t('chic.website.useSubscriptions.stepBoxes.secondStep.details')
    },
    {
      icon: FileFromViews.SubscriptionThirdStepIcon,
      title: t('chic.website.useSubscriptions.stepBoxes.thirdStep.title'),
      details: t('chic.website.useSubscriptions.stepBoxes.thirdStep.details')
    }
  ];

  const onTabChangeAction: (value: string) => void = (value: string): void => {
    if (value === 'active') {
      setActiveTab('active');
    } else if (value === 'inactive') {
      setActiveTab('inactive');
    }
  };

  useEffect(
    (): void => {
      if (![subscriptionPlansCategories, inactiveSubscriptions, activeSubscriptions].includes(null)) {
        setCanScrollToAvailablePlan(true);
      }
    },
    [subscriptionPlansCategories, inactiveSubscriptions, activeSubscriptions]
  );

  return {
    stepBoxes, 
    subscriptionPlansCategories, 
    faqItems, 
    inactiveSubscriptions, 
    activeSubscriptions,
    activeTab,
    onTabChangeAction,
    canScrollToAvailablePlan
  };
};
