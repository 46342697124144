import { MenuPositionData } from '@chic-loyalty/ui';
import { useMemo } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { useAuth } from '@chic/frontend/hooks';
import { UseAuth } from '@chic/frontend/interfaces';
import { UserPermission } from '@chic/shared/enums';
import { IdValueObject } from '@chic/shared/models';

import { profileTabsMenu } from './profile.constants';
import { MenuPositionDataWithPermissions } from './profile.types';

export const useProfileTabs: () => MenuPositionData[] = (): MenuPositionData[] => {
  const { t }: TransProps<never> = useTranslation();
  const { userData }: UseAuth = useAuth();
  const userPermissions: UserPermission[] = useMemo(
    (): UserPermission[] => {
      if (!userData?.permissions) {
        return [];
      }

      return userData.permissions
        .filter((permission: IdValueObject<UserPermission, boolean>): boolean => permission.value)
        .map((grantedPermission: IdValueObject<UserPermission, boolean>) => grantedPermission.id);
    },
    [userData?.permissions]
  );

  return profileTabsMenu
    .filter((tab: MenuPositionDataWithPermissions): boolean => {
      return tab.permissions.every((permission: UserPermission) => userPermissions.includes(permission));
    })
    .map((tab: MenuPositionData): MenuPositionData => ({ ...tab, label: t(tab.label ?? '') }));
};
