import { IsDefined } from 'class-validator';

export class LoginByUidToken {
  @IsDefined()
  public uid: string;

  @IsDefined()
  public token: string;

  constructor(
    uid: string,
    token: string,
  ) {
    this.uid = uid;
    this.token = token;
  }
}
