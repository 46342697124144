import { Breakpoint, Color, FontWeight, Icon, MenuPosition, StyledComponent } from '@chic-loyalty/ui';
import styled from 'styled-components';

import { FileFromViews } from '@chic/frontend/enums';

import { ContainerProps } from './notFound.types';

export const Container: StyledComponent<'div', ContainerProps> = styled.div<ContainerProps>`
  width: ${({ $isUserLoggedIn }: ContainerProps): string => $isUserLoggedIn ? 'calc(100% - 80px)' : '100%'};
  background-color: ${Color.AlmostBlack};
  min-height: calc(100vh - 74px - 242px);
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${Breakpoint.Mobile} {
    width: 100%;
  }
`;

export const BannerSection: StyledComponent<'div'> = styled.div`
  width: 100%;
  padding: 40px;
  flex: 1;
  background-image: url(${FileFromViews.NotFoundBackground});
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ImageContainer: StyledComponent<'div'> = styled.div`
  max-height: 250px;
  height: 100%;
`;

export const Image: StyledComponent<'img'> = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const Title: StyledComponent<'div'> = styled.div`
  font-size: 31px;
  line-height: 40px;
  font-weight: ${FontWeight.Medium};
  letter-spacing: -0.02em;
  text-align: center;
  color: ${Color.AlmostWhite};
  margin-top: 45px;

  @media ${Breakpoint.Mobile} {
    font-size: 24px;
    line-height: 20px;
  }
`;

export const Navigation: StyledComponent<'div'> = styled.div`
  width: 100%;
  padding: 70px 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${Breakpoint.Tablet} {
    display: flex;
    flex-direction: column;
  }
`;

export const NavigationText: StyledComponent<'div'> = styled.div`
  margin-right: 20px;
  font-size: 16px;
  line-height: 22px;
  color: ${Color.Tangerine};
  font-weight: ${FontWeight.Medium};
  white-space: nowrap;

  @media ${Breakpoint.Tablet} {
    margin-right: 0;
    margin-bottom: 20px;
    grid-column: span 2;
    text-align: center;
  }

  @media ${Breakpoint.SmallMobile} {
    grid-column: span 1;
  }
`;

export const TabsWrapper: StyledComponent<'div'> = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media ${Breakpoint.Tablet} {
    display: grid;
    grid-template-columns: repeat(2, minmax(200px, 1fr));
    gap: 20px;
  }

  @media ${Breakpoint.SmallMobile} {
    grid-template-columns: 100%;
  }
`;

export const TabButton: StyledComponent<typeof MenuPosition> = styled(MenuPosition)`
  max-width: 200px;
  width: 100%;
  height: 60px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 1px;
  font-weight: ${FontWeight.Bold};
  color: ${Color.SolidHighEmphasis};
  border: 1px solid ${Color.SolidLowestEmphasis};
  border-radius: 2px;
  gap: 12px;
  white-space: nowrap;

  & + & {
    margin-left: 10px;

    @media ${Breakpoint.Tablet} {
      margin-left: 0;
    }
  }

  @media ${Breakpoint.Tablet} {
    max-width: 100%;
  }
`;

export const StyledIcon: StyledComponent<typeof Icon> = styled(Icon)`
  flex-shrink: 0;
`;
