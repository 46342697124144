import { IconName, SlideMenuPosition } from '@chic-loyalty/ui';

import { RouteNameEnum } from '@chic/shared/enums';
import { getRouteDetailsByName } from '@chic/shared/utils';

export const slideMenuPositions: SlideMenuPosition[] = [
  {
    name: RouteNameEnum.Dashboard,
    label: 'chic.website.meta.dashboard.title',
    labelOnMobile: 'chic.website.meta.dashboardMobile.title',
    icon: IconName.Home,
    iconActive: IconName.HomeActive,
    internalPath: getRouteDetailsByName(RouteNameEnum.Dashboard)?.url,
    onMobileDisplayOnBar: true,
    orderOnMobile: 0,
  },
  {
    name: RouteNameEnum.CarePlus,
    label: 'chic.website.meta.carePlus.title',
    labelOnMobile: 'chic.website.meta.carePlus.title',
    icon: IconName.CarePlus,
    iconActive: IconName.CarePlusActive,
    internalPath: getRouteDetailsByName(RouteNameEnum.CarePlus)?.url,
    onMobileDisplayOnBar: true,
    orderOnMobile: 1,
  },
  {
    name: RouteNameEnum.Offers,
    label: 'chic.website.meta.offers.title',
    icon: IconName.Offers,
    iconActive: IconName.OffersActive,
    internalPath: getRouteDetailsByName(RouteNameEnum.Offers)?.url,
    orderOnMobile: 4,
  },
  {
    name: RouteNameEnum.PostsList,
    label: 'chic.website.meta.postsList.title',
    icon: IconName.News,
    iconActive: IconName.NewsActive,
    internalPath: getRouteDetailsByName(RouteNameEnum.PostsList)?.url,
    orderOnMobile: 5,
  },
  {
    name: RouteNameEnum.Products,
    label: 'chic.website.meta.products.title',
    icon: IconName.Rewards,
    iconActive: IconName.RewardsActive,
    internalPath: getRouteDetailsByName(RouteNameEnum.Products)?.url,
    onMobileDisplayOnBar: true,
    orderOnMobile: 3,
  },
  {
    name: RouteNameEnum.TransactionsList,
    label: 'chic.website.meta.transactions.title',
    icon: IconName.History,
    iconActive: IconName.HistoryActive,
    internalPath: getRouteDetailsByName(RouteNameEnum.TransactionsList)?.url,
    orderOnMobile: 6,
  },
  {
    name: RouteNameEnum.Subscriptions,
    label: 'chic.website.meta.subscriptions.title',
    icon: IconName.Subscription,
    iconActive: IconName.SubscriptionActive,
    internalPath: getRouteDetailsByName(RouteNameEnum.Subscriptions)?.url,
    onMobileDisplayOnBar: true,
    orderOnMobile: 2,
  },
  {
    name: RouteNameEnum.Faq,
    label: 'chic.website.meta.faq.title',
    icon: IconName.Help,
    iconActive: IconName.HelpActive,
    internalPath: getRouteDetailsByName(RouteNameEnum.Faq)?.url,
    displayOnlyOnMobileFullscreenList: true,
    orderOnMobile: 7,
  },
  {
    name: RouteNameEnum.FindUs,
    label: 'chic.website.meta.findUs.title',
    icon: IconName.Map,
    iconActive: IconName.MapActive,
    internalPath: getRouteDetailsByName(RouteNameEnum.FindUs)?.url,
    displayOnlyOnMobileFullscreenList: true,
    orderOnMobile: 8,
  },
  {
    name: RouteNameEnum.Contact,
    label: 'chic.website.meta.contact.title',
    icon: IconName.Email,
    iconActive: IconName.EmailActive,
    externalUrl: getRouteDetailsByName(RouteNameEnum.Contact)?.url,
    displayOnlyOnMobileFullscreenList: true,
    orderOnMobile: 9,
  },
  {
    name: RouteNameEnum.Regulations,
    label: 'chic.website.meta.regulations.title',
    icon: IconName.Files,
    iconActive: IconName.FilesActive,
    internalPath: getRouteDetailsByName(RouteNameEnum.Regulations)?.url,
    displayOnlyOnMobileFullscreenList: true,
    orderOnMobile: 10,
  },
  {
    label: 'chic.website.meta.partners.title',
    icon: IconName.Network,
    iconActive: IconName.NetworkActive,
    externalUrl: 'https://static.esmokingclub.pl/regulations/partnerzy.pdf',
    displayOnlyOnMobileFullscreenList: true,
    orderOnMobile: 11,
  },
  {
    name: RouteNameEnum.ProfileYourData,
    label: 'chic.website.meta.profile.title',
    icon: IconName.Account,
    iconActive: IconName.AccountActive,
    internalPath: getRouteDetailsByName(RouteNameEnum.ProfileYourData)?.url,
    displayOnlyOnMobileFullscreenList: true,
    orderOnMobile: 12,
  },
];
