import { Breakpoint, Color, Grid, StyledComponent } from '@chic-loyalty/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  background-color: ${Color.Level1};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 80px;

  @media ${Breakpoint.Mobile} {
    padding-left: 0;
  }
`;

export const CenterSection: StyledComponent<'div'> = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 80px 20px 160px;
  background-color: ${Color.AlmostBlack};

  @media ${Breakpoint.Tablet} {
    padding: 50px 20px 70px;
  }
`;

export const GridContainer: StyledComponent<'div'> = styled.div`
  max-width: ${Grid.Desktop};
  width: 100%;
`;

export const Row: StyledComponent<'div'> = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${Breakpoint.Tablet} {
    flex-direction: column;
  }

  @media ${Breakpoint.Mobile} {
    padding: 50px 0;
  }
`;
