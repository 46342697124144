import { AxiosResponse } from 'axios';

import { FrontendApiError } from '@chic/frontend/models';
import { ApiReject, ApiResolve } from '@chic/frontend/types';
import { SendStatsForInitPwaRequest, SendStatsForMessagesRequest } from '@chic/shared/models';

import { api } from '../api';

export const sendStatsForInitPwa: (data: SendStatsForInitPwaRequest) => Promise<void> = (
  data: SendStatsForInitPwaRequest
): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.post('/stats/pwa-init', data).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const sendStatsForMessages: (data: SendStatsForMessagesRequest) => Promise<void> = (
  data: SendStatsForMessagesRequest
): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.post('/stats/messages', data).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);
