export enum RegistrationField {
  FirstName = 'firstName',
  LastName = 'lastName',
  Phone = 'phone',
  Email = 'email',
  PostalCode = 'postalCode',
  Birthday = 'birthday',
  PreferredLanguage = 'preferredLanguage',
  Password = 'password',
  RepeatedPassword = 'repeatedPassword',
  SmsCode = 'smsCode',
}
