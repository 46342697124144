import { AccordionItemData } from '@chic-loyalty/ui';

export const faqConfig: AccordionItemData[] = [
  {
    label: 'chic.website.faqView.getPoints.label',
    content: 'chic.website.faqView.getPoints.content'
  },
  {
    label: 'chic.website.faqView.getReward.label',
    content: 'chic.website.faqView.getReward.content'
  },
  {
    label: 'chic.website.faqView.checkAccount.label',
    content: 'chic.website.faqView.checkAccount.content'
  },
  {
    label: 'chic.website.faqView.findLoyaltyCard.label',
    content: 'chic.website.faqView.findLoyaltyCard.content'
  },
  {
    label: 'chic.website.faqView.whatAreOffers.label',
    content: 'chic.website.faqView.whatAreOffers.content'
  },
];
