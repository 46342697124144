import { Color, FontWeight, Breakpoint, StyledComponent, Grid } from '@chic-loyalty/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  background-color: ${Color.Level1};
  width: calc(100% - 80px);
  margin-left: auto;

  @media ${Breakpoint.Mobile} {
    width: 100%;
  }
`;

export const Content: StyledComponent<'div'> = styled.div`
  width: 100%;
`;

export const Wrapper: StyledComponent<'div'> = styled.div`
  width: 100%;
  padding: 0 20px;
`;

export const Header: StyledComponent<'div'> = styled.div`
  display: flex;
  gap: 70px;
  max-width: ${Grid.Desktop};
  padding: 70px 0 100px;
  margin: 0 auto;

  @media ${Breakpoint.Tablet} {
    flex-direction: column;
    align-items: center;
    gap: 35px;
  }

  @media ${Breakpoint.Mobile} {
    padding: 50px 0;
  }
`;

export const HeaderImage: StyledComponent<'div'> = styled.div`
  display: flex;
  height: 270px;
  width: 50%;
  max-width: 515px;
  border-radius: 12px;
  overflow: hidden;

  @media ${Breakpoint.Tablet} {
    height: clamp(175px, 40vw, 230px);
    width: 100%;
  }
`;

export const Image: StyledComponent<'img'> = styled.img`
  height: auto;
  width: 100%;
  object-fit: cover;
`;

export const HeaderContent: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  max-width: 507px;

  @media ${Breakpoint.Tablet} {
    width: 100%;
  }
`;

export const Label: StyledComponent<'div'> = styled.div`
  border: 1px solid ${Color.SolidLowEmphasis};
  border-radius: 6px;
  padding: 5px 9px;
  font-weight: ${FontWeight.Bold};
  font-size: 7px;
  line-height: 9px;
  letter-spacing: 0.44px;
  text-transform: uppercase;
  color: ${Color.SolidMediumEmphasis};
  width: max-content;
  margin-bottom: 15px;
`;

export const HeaderTitle: StyledComponent<'h2'> = styled.h2`
  display: -webkit-box;
  font-size: 31px;
  line-height: 40px;
  font-weight: ${FontWeight.Medium};
  letter-spacing: 0.2px;
  text-overflow: ellipsis;
  color: ${Color.SolidHighEmphasis};
  margin-bottom: 20px;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media ${Breakpoint.Tablet} {
    -webkit-line-clamp: 3;
  }

  @media ${Breakpoint.Mobile} {
    font-size: 25px;
    line-height: 35px;
    letter-spacing: 0.1px;
    -webkit-line-clamp: 4;
  }
`;

export const HeaderText: StyledComponent<'p'> = styled.p`
  display: -webkit-box;
  font-size: 16px;
  line-height: 24px;
  font-weight: ${FontWeight.Regular};
  letter-spacing: 0.4px;
  text-overflow: ellipsis;
  color: ${Color.SolidMediumEmphasis};
  margin-bottom: 25px;
  overflow: hidden;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;

  @media ${Breakpoint.Tablet} {
    -webkit-line-clamp: 6;
  }
`;

export const HeaderButton: StyledComponent<'div'> = styled.div``;

export const Posts: StyledComponent<'div'> = styled.div`
  width: 100%;
  background-color: ${Color.AlmostBlack};
  padding: 0 20px;
`;

export const PostsGrid: StyledComponent<'div'> = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 15px;
  max-width: ${Grid.Desktop};
  padding: 100px 0;
  margin: 0 auto;

  @media ${Breakpoint.Tablet} {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media ${Breakpoint.Mobile} {
    grid-template-columns: 1fr 1fr;
    padding: 50px 20px;
  }

  @media ${Breakpoint.SmallMobile} {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
`;
