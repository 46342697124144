import { UseQueryResult, useQuery } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { Navigate, useLocation, Location, useMatch } from 'react-router-dom';

import { getConfig } from '@chic/frontend/api/requests';
import { pathsAllowedInEscLimitedMode } from '@chic/frontend/constans';
import { QueryKey } from '@chic/frontend/enums';
import { useAuth } from '@chic/frontend/hooks';
import { UseAuth } from '@chic/frontend/interfaces';
import { plRouting } from '@chic/shared/constants';
import { RouteNameEnum } from '@chic/shared/enums';
import { RouteInfo } from '@chic/shared/interfaces';
import { SystemConfig } from '@chic/shared/types';
import { getRouteDetailsByName } from '@chic/shared/utils';

import { AngularCoverWrapper, Description, Header, Wrapper } from './checkAuth.styled';
import { CheckAuthProps } from './checkAuth.types';

export const CheckAuth: React.FC<CheckAuthProps> = (props: CheckAuthProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { children }: CheckAuthProps = props;
  const { token, userData }: UseAuth = useAuth();
  const location: Location = useLocation();
  const pathObject: RouteInfo[] = plRouting.filter(
    (route: RouteInfo): boolean => !!useMatch(route.url) && route.name !== RouteNameEnum.NotFound
  );
  const viewsForbiddenAfterSignIn: RouteNameEnum[] = useMemo(
    (): RouteNameEnum[] => [
      RouteNameEnum.SignIn,
      RouteNameEnum.Home,
      RouteNameEnum.SetPassword,
      RouteNameEnum.RemindPassword,
      RouteNameEnum.ChangeUnverifiedUserEmail,
      RouteNameEnum.TechnicalConfirmEmail,
    ],
    []
  );
  const subscriptionsRoutes: RouteNameEnum[] = useMemo(
    (): RouteNameEnum[] => [
      RouteNameEnum.SubscriptionDetails,
      RouteNameEnum.Subscriptions,
      RouteNameEnum.SubscriptionContact,
      RouteNameEnum.SubscriptionEditDelivery,
      RouteNameEnum.SubscriptionEditProducts,
      RouteNameEnum.SubscriptionChooseDeliveryPoint,
    ],
    []
  );

  const { data: systemConfig }: UseQueryResult<SystemConfig> = useQuery(
    [QueryKey.Config],
    (): Promise<SystemConfig> => getConfig(),
  );

  if (!token && pathObject[0]?.secured) {
    return (
      <Navigate to={getRouteDetailsByName(RouteNameEnum.SignIn)?.url ?? '/'} state={{ from: location }} replace />
    );
  }

  if (
    !!token
    && systemConfig?.escLoyaltyProgramLimited
    && !pathsAllowedInEscLimitedMode.includes(pathObject[0]?.name)
  ) {
    return (
      <Navigate to={getRouteDetailsByName(RouteNameEnum.DashboardForEscProgramLimited)?.url ?? '/'} replace />
    );
  }

  if (
    !!token
    && viewsForbiddenAfterSignIn.includes(pathObject[0]?.name)
  ) {
    return (
      <Navigate to={getRouteDetailsByName(RouteNameEnum.Dashboard)?.url ?? '/'} replace />
    );
  }

  if (pathObject[0]?.inOldFrontend) {
    return (
      <AngularCoverWrapper>
        <Header>{t('chic.website.checkAuth.angularError.header')}</Header>
        <Description text={t('chic.website.checkAuth.angularError.description')} />
      </AngularCoverWrapper>
    );
  }

  if (
    !systemConfig?.subscriptions
    && !userData?.hasActiveSubscriptions
    && subscriptionsRoutes.includes(pathObject[0]?.name)
  ) {
    return (
      <Navigate to={getRouteDetailsByName(RouteNameEnum.NotFound)?.url ?? '/'} replace />
    );
  }

  return (
    <Wrapper>{children}</Wrapper>
  );
};
