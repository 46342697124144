import { Color, StatusSettings } from '@chic-loyalty/ui';

import { SubscriptionOrderCode } from '@chic/shared/enums';

export const subscriptionOrderStatus: Record<SubscriptionOrderCode, StatusSettings> = {
  [SubscriptionOrderCode.Complete]: {
    label: 'chic.website.subscriptionOrderStatus.complete',
    background: Color.Success,
    color: Color.AlmostWhite
  },
  [SubscriptionOrderCode.Failed]: {
    label: 'chic.website.subscriptionOrderStatus.failed',
    background: Color.Validation,
    color: Color.AlmostWhite
  },
  [SubscriptionOrderCode.OnDelivery]: {
    label: 'chic.website.subscriptionOrderStatus.onDelivery',
    background: Color.AlmostWhite,
    color: Color.AlmostBlack,
    dotColor: Color.Tangerine
  },
  [SubscriptionOrderCode.Pending]: {
    label: 'chic.website.subscriptionOrderStatus.pending',
    background: Color.WarningLight,
    color: Color.AlmostBlack
  },
  [SubscriptionOrderCode.WaitingForPayment]: {
    label: 'chic.website.subscriptionOrderStatus.waitingForPayment',
    background: Color.Tangerine,
    color: Color.AlmostWhite
  },
};
