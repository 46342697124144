import { AxiosResponse } from 'axios';
import { stringify } from 'query-string';

import { GetProductOptions } from '@chic/frontend/interfaces';
import { FrontendApiError } from '@chic/frontend/models';
import { ApiReject, ApiResolve } from '@chic/frontend/types';
import { Product, ProductCategory } from '@chic/shared/interfaces';

import { api } from '../api';

export const getProducts: (options?: GetProductOptions) => Promise<Product[]> = (
  options: GetProductOptions = {}
): Promise<Product[]> => {
  return new Promise<Product[]>(
    (resolve: ApiResolve<Product[]>, reject: ApiReject<FrontendApiError>): Promise<void> => (
      api.get(`/products?${stringify(options, { skipEmptyString: true })}`).then(
        (response: AxiosResponse<Product[]>): void => { resolve(response?.data); }
      ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
    )
  );
};

export const getProductsCategories: () => Promise<ProductCategory[]> = (): Promise<ProductCategory[]> => new Promise(
  (resolve: ApiResolve<ProductCategory[]>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get('/products/categories').then(
      (response: AxiosResponse<ProductCategory[]>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getPromotionalProducts: () => Promise<Product[]> = (): Promise<Product[]> => new Promise(
  (resolve: ApiResolve<Product[]>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get('/products/promotional').then(
      (response: AxiosResponse<Product[]>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getProductDetails: (id: string) => Promise<Product> = (id: string): Promise<Product> => new Promise(
  (resolve: ApiResolve<Product>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get(`/products/${id}`).then(
      (response: AxiosResponse<Product>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);
