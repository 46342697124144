import { 
  Breakpoint,
  Color, 
  FontWeight, 
  Grid, 
  Markdown, 
  SectionTitle, 
  SimpleInfoBox, 
  StyledComponent, 
  ActionBox, 
  hexToRgba, 
  SubscriptionDetails,
} from '@chic-loyalty/ui';
import styled from 'styled-components';

import { ContainerProps } from './subscriptions.types';

export const Container: StyledComponent<'div', ContainerProps> = styled.div<ContainerProps>`
  background-color: ${({ $withUserSubscriptions }: ContainerProps): string => $withUserSubscriptions ? Color.AlmostBlack : Color.Level1};
  width: 100%;
`;

export const UserSubscriptionsSectionContainer: StyledComponent<'div'> = styled.div`
  padding: 50px 20px 100px 100px;

  @media ${Breakpoint.Mobile} {
    padding: 50px 20px;
  }
`;

export const UserSubscriptionsSection: StyledComponent<'div'> = styled.div`
  max-width: ${Grid.Desktop};
  margin: 0 auto;
`;

export const MainSectionContainer: StyledComponent<'div'> = styled.div`
  padding: 0 20px 0 100px;
  background-color: ${Color.Level1};

  @media ${Breakpoint.Mobile} {
    padding: 0;
  }
`;

export const MainSection: StyledComponent<'div'> = styled.div`
  max-width: ${Grid.Desktop};
  margin: 0 auto;
`;

export const TopSectionContent: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;

  .orange-text {
    color: ${Color.Tangerine};
  }
`; 

export const MainTopSectionTitle: StyledComponent<'div'> = styled.div`
  font-weight: ${FontWeight.Medium};
  font-size: 25px;
  line-height: 35px;
  letter-spacing: -0.01em;
  color: ${Color.SolidHighEmphasis};
`;

export const SecondTopSectionTitle: StyledComponent<typeof Markdown> = styled(Markdown)`
  font-weight: ${FontWeight.SemiBold};
  font-size: 48px;
  line-height: 58px;
  letter-spacing: -0.01em;
  color: ${Color.SolidHighEmphasis};
`;

export const TopSectionDescription: StyledComponent<typeof Markdown> = styled(Markdown)`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: ${Color.SolidMediumEmphasis};
  margin-top: 20px;
`;

export const StyledSimpleInfoBox: StyledComponent<typeof SimpleInfoBox> = styled(SimpleInfoBox)`
  height: auto;
`;

export const SimpleInfoBoxWrapper: StyledComponent<'div'> = styled.div`
  display: flex;

  ${StyledSimpleInfoBox} + ${StyledSimpleInfoBox} {
    margin-left: 20px;
  }

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
    padding: 50px 20px;

    ${StyledSimpleInfoBox} + ${StyledSimpleInfoBox} {
      margin-left: 0;
      margin-top: 10px;
    }
  }
`;

export const StepBoxesSection: StyledComponent<'div'> = styled.div`
  padding: 100px 0 140px;
  border-top: 1px solid ${hexToRgba(Color.White, 0.12)};
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${Breakpoint.Mobile} {
    padding: 70px 20px 50px;
    border-top: none;
  }
`;

export const StyledSectionTitle: StyledComponent<typeof SectionTitle> = styled(SectionTitle)`
  margin-bottom: 20px;
  padding: 20px 0;
  border-bottom: 1px solid ${Color.Tangerine};
`;

export const SectionHeadTitle: StyledComponent<'h3'> = styled.h3`
  font-weight: ${FontWeight.Medium};
  font-size: 25px;
  line-height: 35px;
  letter-spacing: -0.01em;
  color: ${Color.SolidHighEmphasis};
  text-align: center;
  margin-bottom: 50px;
`;

export const OfferSectionContainer: StyledComponent<'div'> = styled.div`
  width: 100%;
  padding: 100px 20px 150px 100px;
  background-color: ${Color.AlmostBlack};

  @media ${Breakpoint.Mobile} {
    padding: 70px 20px 50px;
  }
`;

export const OfferSection: StyledComponent<'div'> = styled.div`
  max-width: ${Grid.Desktop};
  margin: 0 auto;
`;

export const FaqSectionContainer: StyledComponent<'div'> = styled.div`
  background-color: ${Color.Level1};
  padding: 100px 20px 100px 100px;

  @media ${Breakpoint.Mobile} {
    padding: 70px 20px 50px;
  }
`;

export const FaqSection: StyledComponent<'div'> = styled.div`
  max-width: 956px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AccordionContainer: StyledComponent<'div'> = styled.div`
  width: 100%;
`;

export const FaqIcon: StyledComponent<'img'> = styled.img`
  width: 135px;
  height: auto;
  margin-bottom: 44px;

  @media ${Breakpoint.Mobile} { 
    width: 97px;
  }
`;

export const BottomSectionContainer: StyledComponent<'div'> = styled.div`
  width: 100%;
  padding: 100px 20px 100px 100px;
  background-color: ${Color.AlmostBlack};

  @media ${Breakpoint.Mobile} {
    padding: 50px 20px;
  }
`;

export const BottomSection: StyledComponent<'div'> = styled.div`
  max-width: ${Grid.Desktop};
  margin: 0 auto;
`;

export const StyledActionBox: StyledComponent<typeof ActionBox> = styled(ActionBox)`
  margin-top: 15px;
`;

export const AdBoxContainer: StyledComponent<'div'> = styled.div`
  @media ${Breakpoint.SmallMobile} {
    padding-bottom: 30px;
  }
`;

export const StyledSubscriptionDetails: StyledComponent<typeof SubscriptionDetails> = styled(SubscriptionDetails)`
  & + & {
    margin-top: 10px;
  }
`;

export const SubscriptionPlanBoxWrapper: StyledComponent<'div'> = styled.div``;
