export enum QueryKey {
  Agreements = 'agreements',
  FavouriteStores = 'favouriteStores',
  MessageCategory = 'messageCategory',
  ParcelLockerDetails = 'parcelLockerDetails',
  PickupPointDetails = 'pickupPointDetails',
  Messages = 'messages',
  SubscriptionPaymentInfo = 'subscriptionPaymentInfo',
  IcMigrationPath = 'icMigrationPath',
  PromotionalProducts = 'promotionalProducts',
  Promotions = 'promotions',
  PromotionsDetails = 'promotionsDetails',
  PromotionsForUnlock = 'promotionsForUnlock',
  PostsDetails = 'postsDetails',
  Posts = 'posts',
  Regulations = 'regulations',
  Products = 'products',
  ProductDetails = 'productDetails',
  ProductsCategories = 'productsCategories',
  Stores = 'stores',
  SubscriptionDetails = 'subscriptionDetails',
  Subscriptions = 'subscriptions',
  SubscriptionPlanDetails = 'subscriptionPlanDetails',
  SubscriptionPlans = 'subscriptionsPlans',
  SubscriptionPlansCategories = 'subscriptionsPlansCategories',
  SubscriptionOrderInfo = 'subscriptionOrderInfo',
  TechnicalConfirmEmail = 'technicalConfirmEmail',
  TransactionDetails = 'transactionDetails',
  TransactionsList = 'transactionsList',
  UserCreditCards = 'userCreditCards',
  ParcelMachineId = 'parcelMachineId',
  PickupPointId = 'pickupPointId',
  PickupPointsList = 'pickupPointsList',
  ParcelLockersList = 'parcelLockersList',
  PaymentProviderUrl = 'paymentProviderUrl',
  Config = 'config',
}
