import { StyledComponent, Color, Breakpoint } from '@chic-loyalty/ui';
import styled from 'styled-components';

import { ContainerProps } from './findUs.types';

export const Container: StyledComponent<'div', ContainerProps> = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: ${({ $isUserLoggedIn }: ContainerProps): string => $isUserLoggedIn ? 'calc(100% - 80px)' : '100%'};
  background-color: ${Color.Level1};
  margin-left: auto;
  height: calc(100vh - 220px);

  @media ${Breakpoint.Mobile} {
    width: 100%;
  }
`;

export const MapContainer: StyledComponent<'div'> = styled.div`
  height: 100%;
  width: 100%;
`;
