import { AxiosResponse } from 'axios';

import { FrontendApiError } from '@chic/frontend/models';
import { ApiReject, ApiResolve } from '@chic/frontend/types';
import { UserWithToken } from '@chic/shared/interfaces';

import { api } from '../api';

export const altercloudAuthorization: (token: string) => Promise<UserWithToken> = (
  token: string
): Promise<UserWithToken> => new Promise(
  (resolve: ApiResolve<UserWithToken>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.post(`/integrations/altercloud/${token}`).then(
      (response: AxiosResponse<UserWithToken>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getAltercloudRedirectPath: () => Promise<string> = (): Promise<string> => new Promise(
  (resolve: ApiResolve<string>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get('/integrations/altercloud').then(
      (response: AxiosResponse<string>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);
