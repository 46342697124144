import { UseNotifications, UseState, useNotifications } from '@chic-loyalty/ui';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { Socket, io } from 'socket.io-client';

import { appConfig } from '@chic/frontend/app.config';
import { OnlineStateContext, SocketContext } from '@chic/frontend/contexts';
import { useAuth } from '@chic/frontend/hooks';
import { UseAuth } from '@chic/frontend/interfaces';
import { WebSocketIncomingCommand, WebSocketOutgoingCommand } from '@chic/shared/enums';

import { SocketProviderProps } from './socketProvider.types';

export const SocketProvider: React.FC<SocketProviderProps> = (props: SocketProviderProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { children }: SocketProviderProps = props;
  const [socket, setSocket]: UseState<Socket | undefined> = useState<Socket | undefined>();
  const [isAuthorizedToSocket, setIsAuthorizedToSocket]: UseState<boolean> = useState<boolean>(false);
  const [isAppInitialized, setIsAppInitialized]: UseState<boolean> = useState<boolean>(false);
  const isAppOnline: MutableRefObject<boolean> = useRef<boolean>(true);
  const { addToast }: UseNotifications = useNotifications();
  const { token, signOutCleanup }: UseAuth = useAuth();

  useEffect(
    (): (() => void) => {
      const socketIo: Socket = io(appConfig.websocket, { path: '/ws' });
      socketIo.on('connect', (): void => setSocket(socketIo));
      socketIo.on('disconnect', (): void => setSocket(undefined));
      socketIo.on(WebSocketOutgoingCommand.SignOut, (): void => {
        signOutCleanup();
        setIsAuthorizedToSocket(false);
      });

      return (): void => {
        socketIo.off('connect');
        socketIo.off('disconnect');
        socketIo.off(WebSocketOutgoingCommand.SignOut);
        socketIo.close();
      };
    },
    []
  );

  useEffect(
    (): void => {
      if (token && socket && !isAuthorizedToSocket) {
        socket.emit(WebSocketIncomingCommand.SignIn, { contextToken: token });
        setIsAuthorizedToSocket(true);
      }
    },
    [token, socket]
  );

  useEffect(
    (): void => {
      if (socket?.connected && !isAppInitialized) {
        setIsAppInitialized(true);
      }
      if (isAppInitialized) {
        isAppOnline.current = !!socket?.connected;
        addToast({ content: socket?.connected
          ? t('chic.website.socketProvider.appOnline')
          : t('chic.website.socketProvider.appOffline')
        });
      }
    },
    [socket?.connected]
  );

  return (
    <SocketContext.Provider value={socket}>
      <OnlineStateContext.Provider value={isAppOnline.current}>
        {children}
      </OnlineStateContext.Provider>
    </SocketContext.Provider>
  );
};
