import { Breakpoint, Color, FontWeight, Grid, StyledComponent } from '@chic-loyalty/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  background-color: ${Color.Level1};
  min-height: 100vh;
  width: calc(100% - 80px);
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${Breakpoint.Mobile} {
    width: 100%;
  }
`;

export const BigText: StyledComponent<'h1'> = styled.h1`
  font-weight: ${FontWeight.Medium};
  font-size: 31px;
  line-height: 40px;
  letter-spacing: -0.02em;
  color: ${Color.SolidHighEmphasis};
  margin-bottom: 15px;

  @media ${Breakpoint.Mobile} {
    font-size: 25px;
    line-height: 35px;
    letter-spacing: -0.01em;
  }
`;

export const Text: StyledComponent<'p'> = styled.p`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: ${Color.SolidMediumEmphasis};
  margin-bottom: 35px;
`;

export const BottomSection: StyledComponent<'div'> = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${Color.AlmostBlack};
`;

export const BottomSectionInner: StyledComponent<'div'> = styled.div`
  margin: 100px 20px;
  max-width: ${Grid.Desktop};
  display: flex;
  gap: 15px;

  @media ${Breakpoint.Tablet} {
    margin: 50px 20px;
    flex-direction: column;
  }
`;

export const WarningBox: StyledComponent<'div'> = styled.div`
  padding: 55px;
  background-color: ${Color.Rajah};
  color: ${Color.AlmostBlack};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  position: relative;

  @media ${Breakpoint.Tablet} {
    padding: 50px 20px;
  }
`;

export const WarningBoxTitle: StyledComponent<'h1'> = styled.h1`
  font-weight: ${FontWeight.Medium};
  font-size: 31px;
  line-height: 40px;
  letter-spacing: -0.02em;
  margin-bottom: 30px;
`;

export const WarningBoxText: StyledComponent<'p'> = styled.p`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.4px;

  & + & {
    margin-top: 10px;
  }
`;

export const BatteryImage: StyledComponent<'img'> = styled.img`
  margin: 40px 0 0 auto;

  @media ${Breakpoint.Tablet} {
    position: absolute;
    top: 0;
    right: 14px;
  }
`;

export const DevicesImage: StyledComponent<'img'> = styled.img`
  border-radius: 12px;

  @media ${Breakpoint.Tablet} {
    width: 100%;
  }
`;
