import { IsBoolean, IsDefined, IsString } from 'class-validator';

export class SubscribeMessageCategoryRequestItem {
  @IsDefined()
  @IsString()
  public name: string;

  @IsDefined()
  @IsBoolean()
  public active: boolean;

  constructor(
    name: string,
    active: boolean,
  ) {
    this.name = name;
    this.active = active;
  }
}
