import { AxiosResponse } from 'axios';

import { FrontendApiError } from '@chic/frontend/models';
import { ApiReject, ApiResolve } from '@chic/frontend/types';
import { UserWithToken } from '@chic/shared/interfaces/api';
import { LoginByPassword } from '@chic/shared/models';
import { LoginErrorData } from '@chic/shared/types';

import { api } from '../api';

export const loginRequest: (data: LoginByPassword) => Promise<UserWithToken> = (
  data: LoginByPassword
): Promise<UserWithToken> => new Promise(
  (resolve: ApiResolve<UserWithToken>, reject: ApiReject<FrontendApiError<LoginErrorData>>): Promise<void> => (
    api.post('/authorization/login', data).then(
      (response: AxiosResponse<UserWithToken>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError<LoginErrorData>>): void => reject(error))
  )
);

export const logoutRequest: () => Promise<void> = (): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<void>): Promise<void> => (
    api.post('/authorization/logout').then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<void>): void => reject(error))
  )
);
