import { 
  Color, 
  Breakpoint, 
  StyledComponent, 
  Grid, 
  TransactionProductBox, 
  Button, 
  MailToUs,
} from '@chic-loyalty/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: calc(100% - 80px);
  background-color: ${Color.AlmostBlack};
  margin-left: auto;

  @media ${Breakpoint.Mobile} {
    width: 100%;
  }
`;

export const ContentWrapper: StyledComponent<'div'> = styled.div`
  width: 100%;
  background-color: ${Color.AlmostBlack};
  display: flex;
  justify-content: center;
`;

export const ContentWrapperStepTwo: StyledComponent<typeof ContentWrapper> = styled(ContentWrapper)`
  flex-direction: column;
`;

export const Content: StyledComponent<'div'> = styled.div`
  max-width: ${Grid.Desktop};
  width: 100%;
  margin: 0 auto;
  padding: 50px 20px;
`;

export const ButtonsWrapper: StyledComponent<'div'> = styled.div`
  width: 100%;
  padding: 40px 20px 100px;
  background: ${Color.Level2};
  display: flex;
  justify-content: center;

  @media ${Breakpoint.Mobile} {
    padding: 40px 20px;
  }
`;

export const StyledButton: StyledComponent<typeof Button> = styled(Button)`
  & + & {
    margin-left: 10px;
  }

  @media ${Breakpoint.Mobile} {
    width: 100%;

    & + & {
      margin-left: 0;
      margin-top: 10px;
    }
  }
`;

export const ButtonsContainer: StyledComponent<'div'> = styled.div`
  max-width: ${Grid.Desktop};
  width: 100%;
  display: flex;

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
  }
`;

export const StyledMailToUs: StyledComponent<typeof MailToUs> = styled(MailToUs)`
  margin-top: 40px;
`;

export const DetailsBox: StyledComponent<'div'> = styled.div`
  width: 100%;
  padding: 70px 202px;
  background-color: ${Color.Level1};
  box-shadow: 0 12px 20px -8px rgba(0, 0, 0, 0.015993);
  border-radius: 4px;
  margin-bottom: 10px;

  @media ${Breakpoint.Tablet} {
    padding: 70px 102px;
  }

  @media ${Breakpoint.Mobile} {
    padding: 35px 20px;
  }
`;

export const StyledTransactionProductBox: StyledComponent<typeof TransactionProductBox> = styled(TransactionProductBox)`
  padding: 20px 0;

  & + & {
    border-top: 1px solid ${Color.SolidLowestEmphasis};
  }
`;
