import { StyledComponent, FontWeight, Color, ContentBox, Button, Checkbox, Markdown, Breakpoint, Icon, Tabs } from '@chic-loyalty/ui';
import { Form } from 'formik';
import styled from 'styled-components';

import { AgreementWarningBoxProps } from './agreements.types';

export const Container: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% - 80px);
  background-color: ${Color.AlmostBlack};
  margin-left: auto;

  @media ${Breakpoint.Mobile} {
    width: 100%;
  }
`;

export const ContentWrapper: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  padding: 0 20px;

  @media ${Breakpoint.Tablet} {
    padding: 0;
  }

  @media ${Breakpoint.Mobile} {
    min-height: auto;
  }
`;

export const StyledContentBox: StyledComponent<typeof ContentBox> = styled(ContentBox)`
  max-width: 900px;
  padding: 50px 110px;
  border-radius: 4px;
  /* TODO: move to component */

  & + & {
    margin-top: 2px;
  }

  @media ${Breakpoint.Mobile} {
    padding: 35px 20px;
  }
`;

export const StyledCheckbox: StyledComponent<typeof Checkbox> = styled(Checkbox)`
  width: 100%;

  & + & {
    margin-top: 20px;
  }
`;

export const StyledIcon: StyledComponent<typeof Icon> = styled(Icon)`
  flex-shrink: 0;
`;

export const MarketingAgreementsPrefix: StyledComponent<'div'> = styled.div`
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.4px;
  color: ${Color.SolidMediumEmphasis};
  margin-bottom: 20px;
`;

export const StyledButton: StyledComponent<typeof Button> = styled(Button)`
  align-self: flex-start;
  margin-top: 50px;

  @media ${Breakpoint.Mobile} {
    align-self: center;
    width: calc(100% - 40px);
    margin-top: 35px;
  }
`;

export const FormikForm: StyledComponent<typeof Form> = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px auto 100px;

  @media ${Breakpoint.Tablet} {
    margin: 50px 20px 100px;
  }

  @media ${Breakpoint.Mobile} {
    margin: 35px 0 50px;
  }
`;

export const SeparatingLine: StyledComponent<'div'> = styled.div`
  border-top: 1px solid ${Color.SolidLowestEmphasis};
  width: 100%;
  height: 1px;
  margin: 35px 0;
`;

export const SmallSeparatingLine: StyledComponent<'div'> = styled.div`
  border-top: 1px solid ${Color.SolidLowestEmphasis};
  width: calc(100% - 40px);
  height: 1px;
  margin: 20px 0 20px auto;
`;

export const Annotation: StyledComponent<'div'> = styled.div`
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: ${Color.SolidLowEmphasis};
  padding-left: 40px;
`;

export const AgreementHeader: StyledComponent<'div'> = styled.div`
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  color: ${Color.SolidHighEmphasis};
  margin-bottom: 35px;
`;

export const StyledTabs: StyledComponent<typeof Tabs> = styled(Tabs)`
  margin-bottom: 20px;
`;

export const AgreementWarningBox: StyledComponent<'div', AgreementWarningBoxProps> = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  margin-top: ${({ $withMarginTop }: AgreementWarningBoxProps): string => $withMarginTop ? '35px' : '0'};
`;

export const AgreementWarningMessage: StyledComponent<'div'> = styled.div`
  font-weight: ${FontWeight.SemiBold};
  font-size: 12px;
  line-height: 19.2px;
  letter-spacing: 0.4px;
  color: ${Color.Validation};
`;

export const NewAgreementsInfo: StyledComponent<typeof Markdown> = styled(Markdown)`
  font-weight: ${FontWeight.Medium};
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.4px;
  color: ${Color.SolidHighEmphasis};

  a {
    color: ${Color.Tangerine};
  }
`;

export const PrivacyPolicyHeader: StyledComponent<'button'> = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 0;
  border-bottom: 1px solid ${Color.Tangerine};
  margin-top: 30px;

  @media ${Breakpoint.Mobile} {
    width: calc(100% - 40px);
  }
`;

export const PrivacyPolicyHeaderText: StyledComponent<'div'> = styled.div`
  font-weight: ${FontWeight.Bold};
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.4px;
  color: ${Color.SolidHighEmphasis};
  max-width: 760px;
  margin-right: 20px;
  text-align: left;
`;

export const PrivacyPolicyContent: StyledComponent<'div'> = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;

  @media ${Breakpoint.Mobile} {
    width: calc(100% - 40px);
  }
`;

export const PrivacyPolicyContentWrapper: StyledComponent<'div'> = styled.div`
  background: ${Color.Level2};
  padding: 36px 40px;
`;

export const PrivacyPolicyContentText: StyledComponent<typeof Markdown> = styled(Markdown)`
  font-size: 12px;
  line-height: 19px;
  color: ${Color.SolidMediumEmphasis};

  p, a {
    color: ${Color.SolidMediumEmphasis};
    text-decoration: none;
  }

  p + p {
    margin-top: 25px;
  }
`;
