import { useLocalStorage, UseLocalStorage, useRedirect, UseRedirect } from '@chic-loyalty/ui';
import { Dispatch, useContext } from 'react';
import { useLocation, Location, NavigateFunction, useNavigate } from 'react-router-dom';

import { AuthContext, AuthDispatchContext } from '@chic/frontend/contexts';
import { AuthReducerAction, LocalStorageKey } from '@chic/frontend/enums';
import { UseAuth, UseFirebase } from '@chic/frontend/interfaces';
import { AuthReducerActions } from '@chic/frontend/types';
import { RequiredActionType, RouteNameEnum } from '@chic/shared/enums';
import { AuthContextState, User } from '@chic/shared/interfaces';
import { getRouteDetailsByName } from '@chic/shared/utils';

import { setAuthToken } from '../api/api';
import { logoutRequest } from '../api/requests';

import { useFirebase } from './useFirebase.hook';

export const useAuth: () => UseAuth = (): UseAuth => {
  const state: AuthContextState = useContext(AuthContext);
  const dispatch: Dispatch<AuthReducerActions> = useContext(AuthDispatchContext);
  const [, storeAuthData]: UseLocalStorage<AuthContextState | null> = useLocalStorage<AuthContextState | null>(
    LocalStorageKey.AuthData, null
  );
  const [, storeRequiredActionsPopupsState]: UseLocalStorage<Record<RequiredActionType, boolean> | null>
    = useLocalStorage<Record<RequiredActionType, boolean> | null>(LocalStorageKey.RequiredActionsPopups, null);
  const location: Location = useLocation();
  const navigate: NavigateFunction = useNavigate();
  const { redirect }: UseRedirect = useRedirect();
  // TODO: fix this types
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access,  @typescript-eslint/no-explicit-any
  const pathnameAfterSignIn: string = (location.state as any)?.from?.pathname || getRouteDetailsByName(
    RouteNameEnum.Dashboard
  )?.url;
  const pathnameAfterSignInFromAngularApp: string | null = new URLSearchParams(location.search).get('returnUrl');
  const { initializeFirebaseMessages }: UseFirebase = useFirebase();

  const signIn: (
    userData: User, token: string, pathnameToRedirect?: string
  ) => void = (
    userData: User, token: string, pathnameToRedirect?: string
  ): void => {
    setAuthToken(token);
    dispatch({ type: AuthReducerAction.SignIn, payload: { userData, token } });
    storeAuthData({ userData, token });
    storeRequiredActionsPopupsState(null);
    initializeFirebaseMessages();
    if (pathnameAfterSignInFromAngularApp) {
      redirect(pathnameAfterSignInFromAngularApp, undefined, true);
    } else {
      redirect(pathnameToRedirect ?? pathnameAfterSignIn);
    }
  };

  const signOutCleanup: (redirectPath?: string, withRedirect?: boolean) => void = (
    redirectPath?: string, withRedirect: boolean = true
  ): void => {
    dispatch({ type: AuthReducerAction.SignOut, payload: null });
    storeAuthData(null);
    setAuthToken(null);
    if (withRedirect) {
      navigate(redirectPath ?? getRouteDetailsByName(RouteNameEnum.Home)?.url ?? '/');
    }
    setTimeout((): void => storeRequiredActionsPopupsState(null), 0);
  };

  const signOut: (redirectPath?: string, withRedirect?: boolean) => void = (
    redirectPath?: string, withRedirect: boolean = true
  ): void => {
    void logoutRequest()
      .then((): void => signOutCleanup(redirectPath, withRedirect))
      .catch((): void => signOutCleanup(redirectPath, withRedirect));
  };

  const isUserLoggedIn: () => boolean = (): boolean => {
    return !!state?.userData?.hash && !!state.token;
  };

  const updateUserData: (userData: Partial<User>) => void = (userData: Partial<User>): void => {
    dispatch({ type: AuthReducerAction.UpdateUserData, payload: userData });
    if (state.userData) {
      storeAuthData({ userData: { ...state.userData, ...userData }, token: state.token });
    }
  };

  return {
    signIn,
    signOut,
    signOutCleanup,
    isUserLoggedIn,
    updateUserData,
    token: state?.token ?? null,
    userData: state?.userData ?? null
  };
};
