import { StyledComponent, ContentBox, Input, Button, ValidationBar, Color, FontWeight, Breakpoint } from '@chic-loyalty/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: calc(100% - 80px);
  background-color: ${Color.AlmostBlack};
  margin-left: auto;

  @media ${Breakpoint.Mobile} {
    width: 100%;
  }
`;

export const ContentWrapper: StyledComponent<'div'> = styled.div`
  min-height: 100vh;
  width: 100%;
  padding: 0 20px;

  @media ${Breakpoint.Tablet} {
    padding: 0;
  }

  @media ${Breakpoint.Mobile} {
    min-height: auto;
  }
`;

export const StyledContentBox: StyledComponent<typeof ContentBox> = styled(ContentBox)`
  max-width: 900px;
  margin: 70px auto;

  @media ${Breakpoint.Tablet} {
    background: ${Color.Transparent};
    margin: 0;
  }
`;

export const FormMessageContainer: StyledComponent<'div'> = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;

export const FormMessageTitle: StyledComponent<'div'> = styled.div`
  font-weight: ${FontWeight.Bold};
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 19.2px;
  color: ${Color.SolidMediumEmphasis};
  margin-bottom: 5px;
`;

export const FormMessageContent: StyledComponent<'div'> = styled.div`
  font-size: 12px;
  line-height: 19.2px;
  color: ${Color.SolidLowEmphasis};
`;

export const FormikForm: StyledComponent<'form'> = styled.form`
  width: 100%;
`;

export const StyledInput: StyledComponent<typeof Input> = styled(Input)`
  & + & {
    margin-top: 10px;
  }
`;

export const StyledButton: StyledComponent<typeof Button> = styled(Button)`
  margin-top: 30px;
`;

export const StyledValidationBar: StyledComponent<typeof ValidationBar> = styled(ValidationBar)`
  margin-top: 30px;
`;
