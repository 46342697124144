import { Breakpoint, Color, DetailsInfoGroup, Grid, StyledComponent } from '@chic-loyalty/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 293px);
  width: calc(100% - 80px);
  margin-left: auto;
  background-color: ${Color.AlmostBlack};

  @media ${Breakpoint.Mobile} {
    width: 100%;
  }
`;

export const ContentContainer: StyledComponent<'div'> = styled.div`
  padding: 50px 0 100px;
  max-width: ${Grid.Desktop};
  width: calc(100% - 80px);
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${Breakpoint.Mobile} {
    width: calc(100% - 40px);
    padding: 50px 0;
  }
`;

export const DetailsBox: StyledComponent<'div'> = styled.div`
  width: 100%;
  padding: 70px 202px;
  background-color: ${Color.Level1};
  box-shadow: 0 12px 20px -8px rgba(0, 0, 0, 0.015993);
  border-radius: 4px;
  margin-bottom: 10px;

  @media ${Breakpoint.Tablet} {
    padding: 70px 102px;
  }

  @media ${Breakpoint.Mobile} {
    padding: 35px 20px;
  }
`;

export const StyledDetailsInfoGroup: StyledComponent<typeof DetailsInfoGroup> = styled(DetailsInfoGroup)`
  border-bottom: 1px solid ${Color.SolidLowestEmphasis};
  margin-bottom: 35px;
`;
