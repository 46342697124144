import { RouteNameEnum } from '@chic/shared/enums';
import { RouteInfo } from '@chic/shared/interfaces';

export const plRouting: RouteInfo[] = [
  {
    name: RouteNameEnum.ProfileChangePassword,
    url: '/profil/zmiana-hasla',
    title: 'chic.website.meta.changePassword.title',
    description: 'chic.website.meta.changePassword.description',
    secured: true
  },
  {
    name: RouteNameEnum.Regulations,
    url: '/regulaminy',
    title: 'chic.website.meta.regulations.title',
    description: 'chic.website.meta.regulations.description',
  },
  {
    name: RouteNameEnum.Faq,
    url: '/faq',
    title: 'chic.website.meta.faq.title',
    description: 'chic.website.meta.faq.description',
    secured: true
  },
  {
    name: RouteNameEnum.ProfileYourData,
    url: '/profil/twoje-dane',
    title: 'chic.website.meta.yourData.title',
    description: 'chic.website.meta.yourData.title',
    secured: true
  },
  {
    name: RouteNameEnum.ProfileAgreements,
    url: '/profil/zgody',
    title: 'chic.website.meta.agreements.title',
    description: 'chic.website.meta.agreements.description',
    secured: true
  },
  {
    name: RouteNameEnum.ProfileNotificationsSettings,
    url: '/profil/ustawienia-powiadomien',
    title: 'chic.website.meta.notificationsSettings.title',
    description: 'chic.website.meta.notificationsSettings.description',
    secured: true
  },
  {
    name: RouteNameEnum.ProfilePaymentsSettings,
    url: '/profil/ustawienia-platnosci',
    title: 'chic.website.meta.paymentsSettings.title',
    description: 'chic.website.meta.paymentsSettings.description',
    secured: true
  },
  {
    name: RouteNameEnum.ProfilePaymentsHistory,
    url: '/profil/historia-platnosci',
    title: 'chic.website.meta.profilePaymentsHistory.title',
    description: 'chic.website.meta.profilePaymentsHistory.description',
    secured: true
  },
  {
    name: RouteNameEnum.ProfileAddCreditCard,
    url: '/profil/ustawienia-platnosci/dodaj-nowa-karte',
    title: 'chic.website.meta.profileAddCreditCard.title',
    description: 'chic.website.meta.profileAddCreditCard.description',
    secured: true
  },
  {
    name: RouteNameEnum.Offers,
    url: '/oferty',
    title: 'chic.website.meta.offers.title',
    description: 'chic.website.meta.offers.description',
    secured: true
  },
  {
    name: RouteNameEnum.Products,
    url: '/nagrody',
    title: 'chic.website.meta.products.title',
    description: 'chic.website.meta.products.description',
    secured: true
  },
  {
    name: RouteNameEnum.ProductDetails,
    url: '/nagrody/:id',
    title: 'chic.website.meta.productsDetails.title',
    description: 'chic.website.meta.productsDetails.description',
    secured: true
  },
  {
    name: RouteNameEnum.OfferDetails,
    url: '/oferty/:id',
    title: 'chic.website.meta.offerDetails.title',
    description: 'chic.website.meta.offerDetails.description',
    secured: true
  },
  {
    name: RouteNameEnum.Contact,
    url: '/kontakt',
    title: 'chic.website.meta.contact.title',
    description: 'chic.website.meta.contact.description',
  },
  {
    name: RouteNameEnum.SinglePost,
    url: '/co-nowego/:id',
    title: 'chic.website.meta.singlePost.title',
    description: 'chic.website.meta.singlePost.description',
    secured: true
  },
  {
    name: RouteNameEnum.PaymentRedirectLanding,
    url: '/payment/:orderId/:paymentId',
    title: 'chic.website.meta.paymentRedirectLanding.title',
    description: 'chic.website.meta.paymentRedirectLanding.description',
  },
  {
    name: RouteNameEnum.PostsList,
    url: '/co-nowego',
    title: 'chic.website.meta.postsList.title',
    description: 'chic.website.meta.postsList.description',
    secured: true
  },
  {
    name: RouteNameEnum.ContactDataVerification,
    url: '/n/:ean',
    title: 'chic.website.meta.contactDataVerification.title',
    description: 'chic.website.meta.contactDataVerification.description',
  },
  {
    name: RouteNameEnum.Dashboard,
    url: '/dashboard',
    title: 'chic.website.meta.dashboard.title',
    description: 'chic.website.meta.dashboard.description',
    secured: true
  },
  {
    name: RouteNameEnum.DashboardForEscProgramLimited,
    url: '/zmigruj-sie',
    title: 'chic.website.meta.dashboardForEscProgramLimited.title',
    description: 'chic.website.meta.dashboardForEscProgramLimited.description',
    secured: true
  },
  {
    name: RouteNameEnum.BirthdayBonus,
    url: '/care-plus/urodzinowy-bonus',
    title: 'chic.website.meta.birthdayBonus.title',
    description: 'chic.website.meta.birthdayBonus.description',
    secured: true
  },
  // TODO: temporarily hidden
  // {
  //   name: RouteNameEnum.FreeEngraver,
  //   url: '/care-plus/bezplatny-grawer',
  //   title: 'chic.website.meta.freeEngraver.title',
  //   description: 'chic.website.meta.freeEngraver.description',
  //   secured: true
  // },
  {
    name: RouteNameEnum.CarePlus,
    url: '/care-plus',
    title: 'chic.website.meta.carePlus.title',
    description: 'chic.website.meta.carePlus.description',
    secured: true
  },
  {
    name: RouteNameEnum.ChangeUnverifiedUserEmail,
    url: '/zmien-adres-email',
    title: 'chic.website.meta.changeUnverifiedUserEmail.title',
    description: 'chic.website.meta.changeUnverifiedUserEmail.description',
  },
  {
    name: RouteNameEnum.CookiesPolicy,
    url: '/polityka-cookies',
    title: 'chic.website.meta.cookiesPolicy.title',
    description: 'chic.website.meta.cookiesPolicy.description',
  },
  {
    name: RouteNameEnum.FindUs,
    url: '/znajdz-nas',
    title: 'chic.website.meta.findUs.title',
    description: 'chic.website.meta.findUs.description',
  },
  {
    name: RouteNameEnum.FreeService,
    url: '/care-plus/darmowy-serwis',
    title: 'chic.website.meta.freeService.title',
    description: 'chic.website.meta.freeService.description',
    secured: true
  },
  {
    name: RouteNameEnum.Home,
    url: '/',
    title: 'chic.website.meta.home.title',
    description: 'chic.website.meta.home.description'
  },
  {
    name: RouteNameEnum.Logout,
    url: '/wylogowanie',
    title: 'chic.website.meta.logout.title',
    description: 'chic.website.meta.logout.description'
  },
  {
    name: RouteNameEnum.NotificationsHistory,
    url: '/care-plus/wiadomosci',
    title: 'chic.website.meta.notificationsHistory.title',
    description: 'chic.website.meta.notificationsHistory.description'
  },
  {
    name: RouteNameEnum.Payment,
    url: '/platnosc/:id',
    title: 'chic.website.meta.payment.title',
    description: 'chic.website.meta.payment.description',
    secured: true,
  },
  {
    name: RouteNameEnum.PrivacyPolicy,
    url: 'https://static.esmokingclub.pl/regulations/polityka_prywatnosci_esc_3_0.pdf',
    title: '',
    description: '',
    isNonAppExternalUrl: true,
  },
  {
    name: RouteNameEnum.RemindPassword,
    url: '/przypomnij-haslo',
    title: 'chic.website.meta.remindPassword.title',
    description: 'chic.website.meta.remindPassword.description'
  },
  {
    name: RouteNameEnum.SetPassword,
    url: '/ustaw-haslo/:token',
    title: 'chic.website.meta.setPassword.title',
    description: 'chic.website.meta.setPassword.description'
  },
  {
    name: RouteNameEnum.SignIn,
    url: '/logowanie',
    title: 'chic.website.meta.signIn.title',
    description: 'chic.website.meta.signIn.description'
  },
  {
    name: RouteNameEnum.SignInIntegration,
    url: '/incomming/:token',
    title: 'chic.website.meta.signInIntegration.title',
    description: 'chic.website.meta.signInIntegration.description'
  },
  {
    name: RouteNameEnum.NotFound,
    url: '/nie-znaleziono',
    title: 'chic.website.meta.notFound.title',
    description: 'chic.website.meta.notFound.description'
  },
  {
    name: RouteNameEnum.SubscriptionDetails,
    url: '/subskrypcje/:id',
    title: 'chic.website.meta.subscriptionDetails.title',
    description: 'chic.website.meta.subscriptionDetails.description',
    secured: true
  },
  {
    name: RouteNameEnum.Subscriptions,
    url: '/subskrypcje',
    title: 'chic.website.meta.subscriptions.title',
    description: 'chic.website.meta.subscriptions.description',
    secured: true
  },
  {
    name: RouteNameEnum.SubscriptionPlanUpgradeConditions,
    url: '/subskrypcje/:subscriptionId/kontynuacja/warunki',
    title: 'chic.website.meta.subscriptionPlanUpgradeConditions.title',
    description: 'chic.website.meta.subscriptionPlanUpgradeConditions.description',
    secured: true
  },
  {
    name: RouteNameEnum.SubscriptionPlanUpgradeEditProducts,
    url: '/subskrypcje/:subscriptionId/kontynuacja/wybor-produktow',
    title: 'chic.website.meta.subscriptionPlanUpgradeEditProducts.title',
    description: 'chic.website.meta.subscriptionPlanUpgradeEditProducts.description',
    secured: true
  },
  {
    name: RouteNameEnum.SubscriptionContact,
    url: '/subskrypcje/kontakt',
    title: 'chic.website.meta.subscriptionContact.title',
    description: 'chic.website.meta.subscriptionContact.description',
    secured: true
  },
  {
    name: RouteNameEnum.SubscriptionEditDelivery,
    url: '/subskrypcje/:id/edycja-dostawy',
    title: 'chic.website.meta.subscriptionEditDelivery.title',
    description: 'chic.website.meta.subscriptionEditDelivery.description',
    secured: true,
  },
  {
    name: RouteNameEnum.SubscriptionEditProducts,
    url: '/subskrypcje/:subscriptionId/edytuj-produkty',
    title: 'chic.website.meta.subscriptionEditProducts.title',
    description: 'chic.website.meta.subscriptionEditProducts.description',
    secured: true,
  },
  {
    name: RouteNameEnum.SubscriptionChooseDeliveryPoint,
    url: '/subskrypcje/:id/edycja-dostawy/wybierz-punkt-odbioru',
    title: 'chic.website.meta.subscriptionChooseDelivery.title',
    description: 'chic.website.meta.subscriptionChooseDelivery.description',
    secured: true,
  },
  {
    name: RouteNameEnum.TechnicalConfirmEmail,
    url: '/cm/:authHash/:customerHash',
    title: 'chic.website.meta.technicalConfirmEmail.title',
    description: 'chic.website.meta.technicalConfirmEmail.description',
  },
  {
    name: RouteNameEnum.TechnicalPayuCvvCodeAdd,
    url: '/kontynuacja-platnosci/:creditCardId',
    title: 'chic.website.meta.technicalPayuCvvCodeAdd.title',
    description: 'chic.website.meta.technicalPayuCvvCodeAdd.description',
  },
  {
    name: RouteNameEnum.TechnicalIcMigrationLanding,
    url: '/migracja/:authHash/:customerHash',
    title: 'chic.website.meta.technicalIcMigrationLanding.title',
    description: 'chic.website.meta.technicalIcMigrationLanding.description',
  },
  {
    name: RouteNameEnum.TransactionsList,
    url: '/transakcje',
    title: 'chic.website.meta.transactionsList.title',
    description: 'chic.website.meta.transactionsList.description',
  },
  {
    name: RouteNameEnum.TransactionDetails,
    url: '/transakcje/:id',
    title: 'chic.website.meta.transactionDetails.title',
    description: 'chic.website.meta.transactionDetails.description',
  },
  {
    name: RouteNameEnum.SubscriptionDeliveryNotAuthorizedUpdate,
    url: '/aktualizacja-dostawy/:token/:orderId',
    title: 'chic.website.meta.subscriptionDeliveryNotAuthorizedUpdate.title',
    description: 'chic.website.meta.subscriptionDeliveryNotAuthorizedUpdate.description',
  },
  // old frontend routes
  {
    name: RouteNameEnum.SurveyAuthorized,
    url: '/main/survey/:token/:id',
    title: '',
    description: '',
    inOldFrontend: true,
    secured: true,
  },
  {
    name: RouteNameEnum.Survey,
    url: '/survey/:token/:id',
    title: '',
    description: '',
    inOldFrontend: true,
  },
  {
    name: RouteNameEnum.TicketsAuthorized,
    url: '/main/bilety/:userHash/:authHash',
    title: '',
    description: '',
    inOldFrontend: true,
    secured: true,
  },
  {
    name: RouteNameEnum.Tickets,
    url: '/bilety/:userHash/:authHash',
    title: '',
    description: '',
    inOldFrontend: true,
  },
  {
    name: RouteNameEnum.SubscriptionEdit,
    url: '/main/subscriptions/details/:id/edit-products',
    title: '',
    description: '',
    inOldFrontend: true,
    secured: true,
  },
  {
    name: RouteNameEnum.DevicesAuthorizationReset,
    url: '/reset-autoryzacji',
    title: '',
    description: '',
    inOldFrontend: true,
  },
];
