import { Type } from 'class-transformer';
import { IsArray, IsDefined } from 'class-validator';

import { IdValueObject } from '../../common';

export class UpdateSubscriptionProductsRequest {
  @IsDefined()
  @Type(() => IdValueObject)
  @IsArray()
  public products: IdValueObject[];

  constructor(
    products: IdValueObject[],
  ) {
    this.products = products;
  }
}
