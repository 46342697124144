import { AxiosResponse } from 'axios';
import { stringify } from 'query-string';

import { FrontendApiError } from '@chic/frontend/models';
import { ApiReject, ApiResolve } from '@chic/frontend/types';
import { GetPickupPointsParams, PickupPoint, PickupPointWithDistance } from '@chic/shared/interfaces';

import { api } from '../api';

export const getPickupPointDetails: (id: number) => Promise<PickupPoint> = (
  id: number
): Promise<PickupPoint> => new Promise(
  (resolve: ApiResolve<PickupPoint>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get(`/pickup-points/${id}`).then(
      (response: AxiosResponse<PickupPoint>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getPickupPointsList: (params: Partial<GetPickupPointsParams>) => Promise<PickupPointWithDistance[]>= (
  params: Partial<GetPickupPointsParams>
): Promise<PickupPointWithDistance[]> => new Promise(
  (resolve: ApiResolve<PickupPointWithDistance[]>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get(`/pickup-points?${stringify(params, { skipEmptyString: true })}`).then(
      (response: AxiosResponse<PickupPointWithDistance[]>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);
