import {
  Breakpoint,
  Input,
  InputTheme,
  PageHeader,
  UseFormikForm,
  useFormikForm,
  useNotifications,
  UseNotifications,
  useRedirect,
  UseRedirect,
} from '@chic-loyalty/ui';
import { Formik, FormikProps } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';

import { changeUnverifiedUserEmail } from '@chic/frontend/api/requests';
import { FullscreenAlertAssets } from '@chic/frontend/enums';
import { FrontendApiError } from '@chic/frontend/models';
import { UseSearchParams } from '@chic/frontend/types';
import { RouteNameEnum } from '@chic/shared/enums';
import { ChangeUnverifiedUserEmailRequest } from '@chic/shared/models';
import { getRouteDetailsByName } from '@chic/shared/utils';

import { useChangeUnverifiedUserEmailValidation } from './changeUnverifiedUserEmail.hooks';
import {
  Container,
  FormikForm,
  StyledButton,
  StyledContentBox,
} from './changeUnverifiedUserEmail.styled';

export const ChangeUnverifiedUserEmailView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const isTablet: boolean = useMediaQuery({ query: Breakpoint.Tablet });
  const ChangeUnverifiedUserEmailValidationSchema: Yup.SchemaOf<ChangeUnverifiedUserEmailRequest>
    = useChangeUnverifiedUserEmailValidation();
  const { showFullscreenAlert, hideFullscreenAlert, addToast }: UseNotifications = useNotifications();
  const { redirect }: UseRedirect = useRedirect();
  const [searchParams, setSearchParams]: UseSearchParams = useSearchParams();
  const email: string = useMemo((): string => searchParams.get('email') ?? '', [searchParams]);
  const token: string = useMemo((): string => searchParams.get('token') ?? '', [searchParams]);
  const {
    setFormSubmitted,
    isFormSubmitted,
    setApiRequestStart,
    setApiRequestEnd,
    isApiRequestInProgress
  }: UseFormikForm = useFormikForm();

  useEffect(
    (): void => {
      if (!email || !token) {
        redirect(getRouteDetailsByName(RouteNameEnum.Home)?.url ?? '/');
      }
    },
    []
  );

  const changeUnverifiedUserEmailAction: (data: ChangeUnverifiedUserEmailRequest) => void = (
    data: ChangeUnverifiedUserEmailRequest
  ): void => {
    setApiRequestStart();
    changeUnverifiedUserEmail(data)
      .then((): void => {
        setSearchParams({});
        showFullscreenAlert({
          title: t('chic.website.changeUnverifiedUserEmailView.successPopup.title'),
          description: t('chic.website.changeUnverifiedUserEmailView.successPopup.description', { email: data.email }),
          image: FullscreenAlertAssets.WomanWithPhone,
          acceptButtonSettings: {
            label: t('chic.website.global.home'),
            action: (): void => {
              hideFullscreenAlert();
              redirect(getRouteDetailsByName(RouteNameEnum.Home)?.url ?? '/');
            }
          },
        });
      })
      .catch((error: FrontendApiError): void => addToast({ content: error.message }))
      .finally((): void => setApiRequestEnd());
  };

  return (
    <Container>
      <PageHeader
        header={t('chic.website.changeUnverifiedUserEmailView.header')}
        subheader={t('chic.website.changeUnverifiedUserEmailView.subheader')}
        onArrowButtonAction={(): void => redirect(getRouteDetailsByName(RouteNameEnum.SignIn)?.url ?? '/')}
      />
      <StyledContentBox>
        <Formik
          initialValues={{ email, token }}
          onSubmit={changeUnverifiedUserEmailAction}
          validationSchema={ChangeUnverifiedUserEmailValidationSchema}
          validateOnChange={isFormSubmitted}
          validateOnBlur={isFormSubmitted}
        >
          {({ handleSubmit, setFieldValue, errors, values }: FormikProps<ChangeUnverifiedUserEmailRequest>): JSX.Element => (
            <FormikForm onSubmit={handleSubmit}>
              <Input
                label={t('chic.website.global.email')}
                onChange={(value: string): void => setFieldValue('email', value)}
                value={t(values.email ?? '')}
                message={errors.email ?? ''}
                inputTheme={errors.email ? InputTheme.Error : InputTheme.Standard}
              />
              <StyledButton
                label={t('chic.website.changeUnverifiedUserEmailView.submit')}
                type='submit'
                onClick={setFormSubmitted}
                fullWidth={isTablet}
                isLoading={isApiRequestInProgress}
              />
            </FormikForm>
          )}
        </Formik>
      </StyledContentBox>
    </Container>
  );
};
