import { 
  Breakpoint,
  Button,
  ButtonSize,
  ButtonTheme,
} from '@chic-loyalty/ui';
import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { FileFromViews } from '@chic/frontend/enums';
import { useRequiredActions } from '@chic/frontend/hooks';
import { UseRequiredActions } from '@chic/frontend/interfaces';

import { 
  Container, 
  ContentWrapper, 
  Content, 
  HeadingWrapper, 
  Heading, 
  HeadingText,
  ImageWrapper, 
  Image 
} from './dashboardForEscProgramLimited.styled';

export const DashboardForEscProgramLimitedView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const isSmallMobile: boolean = useMediaQuery({ query: Breakpoint.SmallMobile });
  const { migrationAction }: UseRequiredActions = useRequiredActions();

  return (
    <Container>
      <ContentWrapper>
        <Content>
          <HeadingWrapper>
            <Heading>{t('chic.website.dashboardForEscProgramLimitedView.heading')}</Heading>
            <HeadingText text={t('chic.website.dashboardForEscProgramLimitedView.headingText')} />
          </HeadingWrapper>
          <Button 
            label={t('chic.website.dashboardForEscProgramLimitedView.submit')} 
            buttonTheme={ButtonTheme.PrimaryOrange} 
            size={ButtonSize.Small} 
            type='submit'
            onClick={migrationAction}
            fullWidth={isSmallMobile}
          />
        </Content>
      </ContentWrapper>
      <ImageWrapper>
        <Image src={FileFromViews.DashboardForEscProgramLimitedBackground} />
      </ImageWrapper>
    </Container>
  );
};
