import { Breakpoint, Color, FontWeight, Grid, SearchDeliveryListItem, StyledComponent, disableScroll, hexToRgba } from '@chic-loyalty/ui';
import { Form } from 'formik';
import styled from 'styled-components';

import { ContainerProps } from './subscriptionDeliveryNotAuthorizedUpdate.types';

export const Container: StyledComponent<'div', ContainerProps> = styled.div<ContainerProps>`
  width: 100%;
  background-color: ${Color.AlmostBlack};
  min-height: calc(100vh - 74px);
  display: flex;
  flex-direction: column;

  ${({ $withLeftPadding }: ContainerProps): string | false => $withLeftPadding && 'padding-left: 80px'};

  @media ${Breakpoint.Mobile} {
    padding-left: 0;
  }
`;

export const ContentWrapper: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  max-width: ${Grid.Desktop};
  padding: 50px 0;
  margin: 0 auto;

  @media ${Breakpoint.Mobile} {
    padding: 0 0 50px;
  }
`;

export const DetailsWrapper: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 10px;
  width: 100%;

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
  }
`;

export const DetailsBox: StyledComponent<'div'> = styled.div`
  background-color: ${Color.Level1};
  padding: 50px 55px 33px;
  width: 100%;

  @media ${Breakpoint.Mobile} {
    padding: 37px 20px;
    order: 2;
  }
`;

export const DetailsBoxHeader: StyledComponent<'div'> = styled.div`
  font-size: 14px;
  line-height: 22px;
  font-weight: ${FontWeight.Medium};
  color: ${Color.SolidHighEmphasis};
  margin-bottom: 5px;
`;

export const DetailsBoxDescription: StyledComponent<'div'> = styled.div`
  font-size: 12px;
  line-height: 20px;
  color: ${Color.SolidMediumEmphasis};
  margin-bottom: 20px;
`;

export const ImageBox: StyledComponent<'div'> = styled.div`
  background-color: ${Color.Level1};
  width: 78%;
  max-width: 450px;
  order: 1;

  @media ${Breakpoint.Mobile} {
    width: 100%;
    max-width: none;
  }
`;

export const Image: StyledComponent<'img'> = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const ContentBox: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;
  background-color: ${Color.Level1};
  padding: 50px 55px;

  @media ${Breakpoint.Mobile} {
    padding: 50px 20px;
  }
`;

export const ContentTitle: StyledComponent<'span'> = styled.span`
  font-weight: ${FontWeight.Bold};
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${Color.SolidHighEmphasis};
`;

export const BottomBox: StyledComponent<'div'> = styled.div`
  width: 100%;
  background-color: ${Color.Level1};
`;

export const ButtonsWrapper: StyledComponent<'div'> = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  max-width: ${Grid.Desktop};
  padding: 40px 0 99px;
  margin: 0 auto;

  @media ${Breakpoint.Desktop} {
    padding: 40px 45px 99px;
  }

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
    padding: 40px 20px 99px;
  }
`;

export const ListContainerWrapper: StyledComponent<'div'> = styled.div`
  position: relative;
  max-height: 268px;
  height: 100%;
  display: flex;

  @media ${Breakpoint.Tablet} {
    max-height: 600px;
  }

  @media ${Breakpoint.Mobile} {
    height: 300px;
  }
`;

export const ListContainer: StyledComponent<'div'> = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  ${disableScroll};
`;

export const StyledSearchDeliveryListItem: StyledComponent<typeof SearchDeliveryListItem> = styled(SearchDeliveryListItem)`
  display: flex;
  padding-top: 25px;
  padding-bottom: 21px;

  & + & {
    padding-top: 20px;
    border-top: 1px solid ${hexToRgba(Color.White, 0.12)};
  }
`;

export const MapContainer: StyledComponent<'div'> = styled.div`
  height: 550px;
  width: 100%;
  max-width: 450px;

  @media ${Breakpoint.Mobile} {
    display: none;
  }
`;

export const DeliveryFormWrapper: StyledComponent<'div'> = styled.div`
  display: flex;
  gap: 20px;
`;

export const FormikForm: StyledComponent<typeof Form> = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

export const DeliveryImage: StyledComponent<'div'> = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 385px;
  border: 1px solid ${Color.SolidLowestEmphasis};
  border-radius: 4px;
  padding: 45px;

  @media ${Breakpoint.Mobile} {
    display: none;
  }
`;
