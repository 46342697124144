import { IsBoolean, IsDefined, IsOptional, IsString } from 'class-validator';

export class LoginByPassword {
  @IsDefined()
  @IsString()
  public login: string;

  @IsDefined()
  @IsString()
  public password: string;

  @IsOptional()
  @IsBoolean()
  public rememberMe?: boolean;

  constructor(
    login: string,
    password: string,
    rememberMe: boolean,
  ) {
    this.login = login;
    this.password = password;
    this.rememberMe = rememberMe;
  }
}
