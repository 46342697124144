import { IsDefined, IsNumber } from 'class-validator';

export class UnlockPromotionRequest {
  @IsDefined()
  @IsNumber()
  public promotionId: number;

  constructor(
    promotionId: number,
  ) {
    this.promotionId = promotionId;
  }
}
