import { StyledComponent, ContentBox, Color, Breakpoint, Button, FontWeight } from '@chic-loyalty/ui';
import styled from 'styled-components';

import { PayuAnnotations } from '@chic/frontend/components';

export const Container: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: calc(100% - 80px);
  background-color: ${Color.AlmostBlack};
  margin-left: auto;

  @media ${Breakpoint.Mobile} {
    width: 100%;
  }
`;

export const PayuInputsHeader: StyledComponent<'div'> = styled.div`
  margin-bottom: 25px;
  font-weight: ${FontWeight.Bold};
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${Color.SolidHighEmphasis};
`;

export const PayuInputContainer: StyledComponent<'div'> = styled.div`
  width: 100%;
  border-radius: 6px;
  height: 85px;
  background-color: ${Color.Level2};
  border: 1px solid ${Color.Transparent};
  padding: 20px 20px 0;

  & + & {
    margin-top: 10px;
  }
`;

export const PayuInputLabel: StyledComponent<'label'> = styled.label`
  color: ${Color.SolidLowEmphasis};
  font-weight: ${FontWeight.Bold};
  font-size: 9px;
  line-height: 11px;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding-bottom: 15px;
  display: block;
`;

export const PayuCardForm: StyledComponent<'div'> = styled.div`
  width: 100%;
  height: 29px;
  color: ${Color.SolidHighEmphasis};
`;

export const PayuInputDescription: StyledComponent<'div'> = styled.div`
  font-weight: ${FontWeight.Medium};
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: ${Color.SolidLowEmphasis};
  margin-top: 5px;
`;

export const ContentWrapper: StyledComponent<'div'> = styled.div`
  min-height: 100vh;
  width: 100%;
  padding: 0 20px;

  @media ${Breakpoint.Tablet} {
    padding: 0;
  }

  @media ${Breakpoint.Mobile} {
    min-height: auto;
  }
`;

export const StyledContentBox: StyledComponent<typeof ContentBox> = styled(ContentBox)`
  max-width: 900px;
  margin: 50px auto 100px;
  position: relative;

  @media ${Breakpoint.Tablet} {
    background: ${Color.Transparent};
    margin: 0;
  }
`;

export const StyledButton: StyledComponent<typeof Button> = styled(Button)`
  margin-top: 37px;
`;

export const StyledPayuAnnotations: StyledComponent<typeof PayuAnnotations> = styled(PayuAnnotations)`
  margin-top: 35px;
`;
