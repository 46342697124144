import { SubscriptionPermissionType } from '@chic/shared/enums';
import { NameValueInterface } from '@chic/shared/interfaces';

export function getSubscriptionPermissionByType(
  permissions: NameValueInterface<SubscriptionPermissionType, boolean>[], type: SubscriptionPermissionType
): boolean {
  return !!permissions.find((permission: NameValueInterface<SubscriptionPermissionType, boolean>): boolean => (
    permission.name === type
  ))?.value;
}
