export enum PopupImage {
  // required actions
  SubscriptionOutOfStockBg = '/assets/popupImage/subscriptionOutOfStockBg.svg',
  AgreementsUpdateRequiredBg = '/assets/popupImage/agreementsUpdateRequiredBg.svg',
  InspirationClubPopupRequiredBg = '/assets/popupImage/inspirationClubPopupRequiredBg.jpg',
  MigrationPopupRequiredBg = '/assets/popupImage/migrationPopupRequiredBg.jpg',
  UpgradeSubscriptionPlanPopupBg = '/assets/popupImage/upgradeSubscriptionPlanPopupBg.jpg',
  // notifications
  NotificationsBg = '/assets/popupImage/notificationsBg.svg',
}
