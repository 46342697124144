import { StyledComponent, ContentBox, Color, Breakpoint, Button, ValidationBar, SectionTitle } from '@chic-loyalty/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: calc(100% - 80px);
  background-color: ${Color.AlmostBlack};
  margin-left: auto;
  padding: 0 0 100px;

  @media ${Breakpoint.Mobile} {
    width: 100%;
    padding: 0 0 50px;
  }
`;

export const ContentWrapper: StyledComponent<'div'> = styled.div`
  min-height: 100vh;
  width: 100%;
  padding: 0 20px;

  @media ${Breakpoint.Tablet} {
    padding: 0;
  }

  @media ${Breakpoint.Mobile} {
    min-height: auto;
  }
`;

export const StyledContentBox: StyledComponent<typeof ContentBox> = styled(ContentBox)`
  max-width: 900px;
  margin: 70px auto 50px;
  position: relative;

  @media ${Breakpoint.Tablet} {
    background: ${Color.Transparent};
    margin: 0;
  }
`;

export const StyledValidationBar: StyledComponent<typeof ValidationBar> = styled(ValidationBar)`
  margin-bottom: 10px;
`;

export const StyledButton: StyledComponent<typeof Button> = styled(Button)`
  margin: 35px 0;
`;

export const StyledSectionTitle: StyledComponent<typeof SectionTitle> = styled(SectionTitle)`
  margin-bottom: 35px;

  @media ${Breakpoint.Mobile} {
    margin: 0 20px 35px;
  }
`;

export const SubscriptionsPaymentsWrapper: StyledComponent<'div'> = styled.div`
  max-width: 940px;
  padding: 0 20px;
  margin: 0 auto;

  @media ${Breakpoint.Mobile} {
    padding: 0;
  }
`;
