import {
  PageHeader,
  InputTheme,
  UseState,
  UseFormikForm,
  useFormikForm,
  Breakpoint,
  ValidationBarTheme,
  PageHeaderPosition,
  MenuPositionData
} from '@chic-loyalty/ui';
import { Formik, FormikProps } from 'formik';
import React, { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import * as Yup from 'yup';

import { setNewPassword } from '@chic/frontend/api/requests';
import { FrontendApiError } from '@chic/frontend/models';
import { ChangePasswordRequest } from '@chic/shared/models';

import { useProfileTabs } from '../profile.hooks';

import { initialValues } from './changePassword.constants';
import {
  Container,
  ContentWrapper,
  StyledContentBox,
  FormMessageContainer,
  FormMessageTitle,
  FormMessageContent,
  FormikForm,
  StyledInput,
  StyledButton,
  StyledValidationBar
} from './changePassword.styled';
import { ChangePasswordRequestWithRepatedPassword } from './changePassword.types';
import { useChangePasswordValidation } from './hooks';

export const ChangePasswordView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });
  const { 
    setFormSubmitted, 
    isFormSubmitted, 
    isApiRequestInProgress, 
    setApiRequestStart, 
    setApiRequestEnd 
  }: UseFormikForm = useFormikForm();
  const ChangePasswordValidationSchema: Yup.SchemaOf<ChangePasswordRequestWithRepatedPassword> = useChangePasswordValidation();
  const profileTabs: MenuPositionData[] = useProfileTabs();
  const [apiError, setApiError]: UseState<string> = useState<string>('');
  const [apiSuccess, setApiSuccess]: UseState<string> = useState<string>('');

  const onSubmit: (data: ChangePasswordRequestWithRepatedPassword) => void = (data: ChangePasswordRequestWithRepatedPassword): void => {
    const requestData: ChangePasswordRequest = new ChangePasswordRequest(data.oldPassword, data.newPassword);

    setApiRequestStart();
    setNewPassword(requestData)
      .then((): void => {
        setApiError('');
        setApiSuccess(t('chic.website.changePasswordView.passwordChanged'));
      })
      .catch((error: FrontendApiError): void => {
        setApiSuccess('');
        setApiError(t(error.message));
      })
      .finally((): void => setApiRequestEnd());
  };

  return (
    <Container>
      <PageHeader
        header={t('chic.website.meta.profile.title')}
        headerPosition={PageHeaderPosition.CenterLeft}
        tabs={profileTabs}
        activeTabName='changePassword'
      />
      <ContentWrapper>
        <StyledContentBox>
          <FormMessageContainer>
            <FormMessageTitle>{t('chic.website.changePasswordView.messageTitle')}</FormMessageTitle>
            <FormMessageContent>{t('chic.website.global.validations.passwordFormat')}</FormMessageContent>
          </FormMessageContainer>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={ChangePasswordValidationSchema}
            validateOnChange={isFormSubmitted}
            validateOnBlur={isFormSubmitted}
          >
            {({ handleSubmit, setFieldValue, errors, values }: FormikProps<ChangePasswordRequestWithRepatedPassword>) => (
              <FormikForm onSubmit={handleSubmit}>
                <StyledInput
                  label={t('chic.website.changePasswordView.currentPassword')}
                  placeholder={t('chic.website.changePasswordView.enterPassword')}
                  type='password'
                  onChange={(value: string): void => setFieldValue('oldPassword', value)}
                  onBlur={(value: string): void => setFieldValue('oldPassword', value)}
                  value={values.oldPassword}
                  message={errors.oldPassword ?? ''}
                  inputTheme={errors.oldPassword ? InputTheme.Error : InputTheme.Standard}
                />
                <StyledInput
                  label={t('chic.website.changePasswordView.newPassword')}
                  placeholder={t('chic.website.changePasswordView.enterPassword')}
                  type='password'
                  onChange={(value: string): void => setFieldValue('newPassword', value)}
                  onBlur={(value: string): void => setFieldValue('newPassword', value)}
                  value={values.newPassword}
                  message={errors.newPassword ?? ''}
                  inputTheme={errors.newPassword ? InputTheme.Error : InputTheme.Standard}
                />
                <StyledInput
                  label={t('chic.website.changePasswordView.repeatNewPassword')}
                  placeholder={t('chic.website.changePasswordView.enterPassword')}
                  type='password'
                  onChange={(value: string): void => setFieldValue('repeatedPassword', value)}
                  onBlur={(value: string): void => setFieldValue('repeatedPassword', value)}
                  value={values.repeatedPassword}
                  message={errors.repeatedPassword ?? ''}
                  inputTheme={errors.repeatedPassword ? InputTheme.Error : InputTheme.Standard}
                />
                <StyledButton
                  label={t('chic.website.changePasswordView.save')}
                  type='submit'
                  onClick={setFormSubmitted}
                  fullWidth={isMobile}
                  isLoading={isApiRequestInProgress}
                />
              </FormikForm>
            )}
          </Formik>
          <StyledValidationBar
            message={apiError || apiSuccess}
            barTheme={apiSuccess ? ValidationBarTheme.Green : ValidationBarTheme.Red}
          />
        </StyledContentBox>
      </ContentWrapper>
    </Container>
  );
};
