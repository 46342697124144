import { BaseApiError } from '@chic/shared/interfaces';

export class ApiError<ApiErrorData extends BaseApiError | Record<string, unknown> = Record<string, unknown>> {
  public message: string;
  public data: ApiErrorData;

  constructor(
    message: string,
    data: ApiErrorData,
  ) {
    this.message = message;
    this.data = data;
  }

  public toJson(): { message: string; data: ApiErrorData } {
    return {
      message: this.message,
      data: this.data,
    };
  }
}
