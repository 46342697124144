import { ButtonTheme, IconName, Button } from '@chic-loyalty/ui';
import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { FileFromViews } from '@chic/frontend/enums';
import { RouteNameEnum } from '@chic/shared/enums';
import { getRouteDetailsByName } from '@chic/shared/utils';

import {
  Container,
  LeftSection,
  WelcomeText,
  Title,
  RightSection,
  ManImg,
  DevicesImg,
  StyledButton,
} from './topSection.styled';

export const TopSection: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const navigate: NavigateFunction = useNavigate();

  return (
    <Container>
      <LeftSection>
        <WelcomeText>{t('chic.website.homeView.welcome')}</WelcomeText>
        <Title>{t('chic.website.homeView.title')}</Title>
        <StyledButton
          buttonTheme={ButtonTheme.SecondaryOrange}
          label={t('chic.website.homeView.logIn')}
          icon={IconName.ArrowCenterRight}
          onClick={(): void => navigate(getRouteDetailsByName(RouteNameEnum.SignIn)?.url ?? '/')}
        />
      </LeftSection>
      <RightSection>
        <ManImg src={FileFromViews.HomeMan} />
        <DevicesImg src={FileFromViews.HomeDevices} />
      </RightSection>
    </Container>
  );
};
