import { UseRedirect, useRedirect } from '@chic-loyalty/ui';
import { stringify } from 'query-string';

import { useConfirmContactData } from '@chic/frontend/hooks';
import { ConfirmContactDataParams, UseConfirmContactData } from '@chic/frontend/interfaces';
import { FrontendUrlsParams, RouteNameEnum } from '@chic/shared/enums';
import { getRouteDetailsByName } from '@chic/shared/utils';

import { UseSubscriptionsEditDelivery } from '../subscriptionEditDelivery.types';

export const useSubscriptionsEditDelivery: () => UseSubscriptionsEditDelivery = (): UseSubscriptionsEditDelivery => {
  const { submitConfirmContactData }: UseConfirmContactData = useConfirmContactData();
  const { redirect }: UseRedirect = useRedirect();

  const goBack: (
    id: string | undefined
  ) => void = (
    id: string | undefined
  ): void => redirect(getRouteDetailsByName(RouteNameEnum.SubscriptionDetails)?.url ?? '', { id: id ?? '' });
  
  const onSubmitConfirmContactData: (
    { smsCode }: ConfirmContactDataParams, 
    id: string | undefined
  ) => void = (
    { smsCode }: ConfirmContactDataParams, 
    id: string | undefined
  ): void => {
    submitConfirmContactData({ smsCode })
      .then((): void => goBack(id))
      .catch((): void => undefined);
  };  

  const redirectToChooseDeliveryPoint: (id: string, selectedDelivery: string, pointId: string | null) => void = (
    id: string, selectedDelivery: string, pointId: string | null
  ): void => {
    redirect(
      `${getRouteDetailsByName(RouteNameEnum.SubscriptionChooseDeliveryPoint)?.url ?? ''}?${stringify(
        { [FrontendUrlsParams.PointId]: pointId, [FrontendUrlsParams.DeliveryType]: selectedDelivery },
        { skipEmptyString: true }
      )}`, { id: id ?? '' }
    );
  };

  return {
    goBack,
    onSubmitConfirmContactData,
    redirectToChooseDeliveryPoint,
  };
};
