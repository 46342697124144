import { IsDefined, IsEnum, IsNumber, IsString, ValidateIf } from 'class-validator';

import { SubscriptionDeliveryCode } from '@chic/shared/enums';

export class UpdateSubscriptionOrderDestinationRequest {
  @IsDefined()
  @IsNumber()
  public orderId: number;

  @IsDefined()
  @IsString()
  public token: string;

  @IsDefined()
  @IsEnum(SubscriptionDeliveryCode)
  public deliveryType: SubscriptionDeliveryCode;

  @IsNumber()
  @ValidateIf((object: unknown, value: unknown): boolean => value !== null)
  public inpostMachineId: number | null;

  @IsNumber()
  @ValidateIf((object: unknown, value: unknown): boolean => value !== null)
  public pickupPointId: number | null;

  @IsDefined()
  @IsString()
  public name: string;

  @IsDefined()
  @IsString()
  public address: string;

  @IsDefined()
  @IsString()
  public city: string;

  @IsDefined()
  @IsString()
  public postalCode: string;

  constructor(
    orderId: number,
    token: string,
    deliveryType: SubscriptionDeliveryCode,
    inpostMachineId: number | null,
    pickupPointId: number | null,
    name: string,
    address: string,
    city: string,
    postalCode: string,
  ) {
    this.orderId = orderId;
    this.token = token;
    this.deliveryType = deliveryType;
    this.inpostMachineId = inpostMachineId;
    this.pickupPointId = pickupPointId;
    this.name = name;
    this.address = address;
    this.city = city;
    this.postalCode = postalCode;
  }
}
