import { IsDefined, IsString } from 'class-validator';

// TODO: check after implementation
export class AddNewCreditCardRequest {
  @IsDefined()
  @IsString()
  public cardMask: string;

  @IsDefined()
  @IsString()
  public token: string;

  @IsDefined()
  @IsString()
  public deviceFingerPrint: string;

  constructor(
    cardMask: string,
    token: string,
    deviceFingerPrint: string,
  ) {
    this.cardMask = cardMask;
    this.token = token;
    this.deviceFingerPrint = deviceFingerPrint;
  }
}
