import { Breakpoint, Color, FavoriteStore, Grid, HeroSlider, SectionTitle, StyledComponent } from '@chic-loyalty/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  width: calc(100% - 80px);
  background-color: ${Color.AlmostBlack};
  min-height: 100vh;
  margin-left: auto;

  @media ${Breakpoint.Mobile} {
    width: 100%;
  }
`;

export const StyledSectionTitle: StyledComponent<typeof SectionTitle> = styled(SectionTitle)`
  margin-bottom: 40px;
`;

export const StyledHeroSlider: StyledComponent<typeof HeroSlider> = styled(HeroSlider)`
  & + ${StyledSectionTitle} {
    margin-top: 50px;
  }
`;

export const StyledFavoriteStore: StyledComponent<typeof FavoriteStore> = styled(FavoriteStore)`
  & + & {
    margin-top: 10px;
  }
`;

export const BannerWithPlansSection: StyledComponent<'div'> = styled.div`
  background-color: ${Color.Level1};
  padding: 70px 20px;
`;

export const OffersWithWhatsNewSection: StyledComponent<'div'> = styled.div`
  background-color: ${Color.AlmostBlack};
  padding: 100px 20px 150px;
`;

export const PromotionalProductsSection: StyledComponent<'div'> = styled.div`
  padding: 100px 20px;
  background-color: ${Color.DeepSaffron};
`;

export const StoresSection: StyledComponent<'div'> = styled.div`
  padding: 100px 20px 70px;
  background-color: ${Color.AlmostWhite};
`;

export const InnerGridWrapper: StyledComponent<'div'> = styled.div`
  max-width: ${Grid.Desktop};
  margin: 0 auto;
`;

export const WhatsNewSection: StyledComponent<'div'> = styled.div`
  margin-top: 70px;
  padding-top: 70px;
  border-top: 1px solid ${Color.SolidLowestEmphasis};
`;

export const MapContainer: StyledComponent<'div'> = styled.div`
  height: 700px;

  @media ${Breakpoint.Mobile} {
    height: 585px;
  }
`;
