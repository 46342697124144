import { UseNotifications, UseRedirect, useNotifications, useRedirect } from '@chic-loyalty/ui';
import { QueryClientProvider as QueryClientProviderElement } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { useTranslation, TransProps } from 'react-i18next';

import { FullscreenAlertAssets } from '@chic/frontend/enums';
import { useOnlineState } from '@chic/frontend/hooks';
import { UseOnlineState } from '@chic/frontend/interfaces';
import { RouteNameEnum } from '@chic/shared/enums';
import { getRouteDetailsByName } from '@chic/shared/utils';

import { QueryClientProviderProps } from './queryClientProvider.types';

export const QueryClientProvider: React.FC<QueryClientProviderProps> = (props: QueryClientProviderProps): JSX.Element => {
  const { queryClient, children }: QueryClientProviderProps = props;
  const { t }: TransProps<never> = useTranslation();
  const { showFullscreenAlert, hideFullscreenAlert }: UseNotifications = useNotifications();
  const { isAppOnline }: UseOnlineState = useOnlineState();
  const { redirect }: UseRedirect = useRedirect();

  useEffect(
    (): void => {
      queryClient.setDefaultOptions({
        queries: {
          refetchOnWindowFocus: false,
          networkMode: 'offlineFirst',
          retry: false,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          onSettled: (_: unknown, error: unknown): void => {
            if (!isAppOnline && error) {
              showFullscreenAlert({
                title: t('chic.website.global.error'),
                description: t('chic.website.queryClientProvider.noInternetConnection'),
                iconImage: FullscreenAlertAssets.ErrorIcon,
                acceptButtonSettings: {
                  label: t('chic.website.global.ok'),
                  action: (): void => {
                    redirect(getRouteDetailsByName(RouteNameEnum.Dashboard)?.url ?? '');
                    hideFullscreenAlert();
                  }
                },
              });
            }
          }
        }
      });
    },
    [queryClient, isAppOnline]
  );

  return (
    <QueryClientProviderElement client={queryClient}>
      {children}
    </QueryClientProviderElement>
  );
};
