import { AxiosResponse } from 'axios';
import { stringify } from 'query-string';

import { FrontendApiError } from '@chic/frontend/models';
import { ApiReject, ApiResolve } from '@chic/frontend/types';
import { ListPaginationParams, Message, MessageCategoryDetails, MessagesListPagination } from '@chic/shared/interfaces';
import {
  RegisterMessagesTokenRequest,
  SetMessageReadStatusRequest,
  SubscribeMessageCategoryRequestItem
} from '@chic/shared/models';

import { api } from '../api';

export const getMessages: (params: ListPaginationParams) => Promise<MessagesListPagination<Message>> = (
  params: ListPaginationParams
): Promise<MessagesListPagination<Message>> => new Promise(
  (resolve: ApiResolve<MessagesListPagination<Message>>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get(`/messages?${stringify(params, { skipEmptyString: true })}`).then(
      (response: AxiosResponse<MessagesListPagination<Message>>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getMessagesCategories: () => Promise<MessageCategoryDetails[]> = (): Promise<MessageCategoryDetails[]> => new Promise(
  (resolve: ApiResolve<MessageCategoryDetails[]>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get('/messages/categories').then(
      (response: AxiosResponse<MessageCategoryDetails[]>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const subscribeMessageCategory: (data: SubscribeMessageCategoryRequestItem[]) => Promise<MessageCategoryDetails[]> = (
  data: SubscribeMessageCategoryRequestItem[]
): Promise<MessageCategoryDetails[]> => new Promise(
  (resolve: ApiResolve<MessageCategoryDetails[]>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.post('/messages/categories', data).then(
      (response: AxiosResponse<MessageCategoryDetails[]>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const removeMessage: (messageId: number) => Promise<void> = (
  messageId: number
): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.delete(`/messages/${messageId}`).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const setMessageReadStatus: (messageId: number, data: SetMessageReadStatusRequest) => Promise<void> = (
  messageId: number, data: SetMessageReadStatusRequest
): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.patch(`/messages/${messageId}/change-read-status`, data).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const registerMessagesToken: (data: RegisterMessagesTokenRequest) => Promise<void> = (
  data: RegisterMessagesTokenRequest
): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.post('/messages/register-token', data).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);
