import { Breakpoint, Button, Color, ContentBox, StyledComponent, TabSwitch } from '@chic-loyalty/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  width: 100%;
  background-color: ${Color.AlmostBlack};
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - 223px);
  margin-left: auto;

  @media ${Breakpoint.Mobile} {
    min-height: unset;
  }
`;

export const StyledTabSwitch: StyledComponent<typeof TabSwitch> = styled(TabSwitch)`
  margin-bottom: 20px;
`;

export const FormikForm: StyledComponent<'form'> = styled.form`
  width: 100%;
`;

export const StyledButton: StyledComponent<typeof Button> = styled(Button)`
  margin-top: 35px;
`;

export const StyledContentBox: StyledComponent<typeof ContentBox> = styled(ContentBox)`
  margin: 70px auto;

  @media ${Breakpoint.Mobile} {
    margin: 0 auto;
  }
`;
