import { 
  AccordionItem,
  AccordionItemData,
  AdBox,
  ComponentColorTheme,
  IconName, 
  PageHeader, 
  SectionTitleSize, 
  SimpleInfoBoxTheme, 
  StepBoxesGroup, 
  SubscriptionDetailsSettings, 
  SubscriptionPlanBox, 
  SubscriptionPlanBoxData, 
  SubscriptionPlanGroupSimple, 
  SubscriptionsList, 
  TopSection, 
  UseRedirect,   
  UseTransformations,   
  highlightTextFragments,  
  useRedirect,
  useTransformations,
} from '@chic-loyalty/ui';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import React, { RefObject, useEffect, useRef } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { getConfig } from '@chic/frontend/api/requests';
import { FileFromViews, QueryKey } from '@chic/frontend/enums';
import { UseSearchParams } from '@chic/frontend/types';
import { FrontendUrlsParams, RouteNameEnum } from '@chic/shared/enums';
import { SubscriptionPlan, SubscriptionPlanAsset, SubscriptionPlanGroup } from '@chic/shared/interfaces';
import { SystemConfig } from '@chic/shared/types';
import { getRouteDetailsByName } from '@chic/shared/utils';

import { useSubscriptions } from './subscriptions.hooks';
import { 
  Container, 
  MainSection, 
  TopSectionContent, 
  TopSectionDescription, 
  MainTopSectionTitle, 
  SimpleInfoBoxWrapper, 
  StyledSimpleInfoBox, 
  SecondTopSectionTitle,
  StepBoxesSection,
  SectionHeadTitle,
  OfferSection,
  OfferSectionContainer,
  FaqSection,
  BottomSectionContainer,
  BottomSection,
  FaqIcon,
  StyledActionBox,
  MainSectionContainer,
  AccordionContainer,
  UserSubscriptionsSectionContainer,
  UserSubscriptionsSection,
  FaqSectionContainer,
  StyledSectionTitle,
  StyledSubscriptionDetails,
  AdBoxContainer,
  SubscriptionPlanBoxWrapper
} from './subscriptions.styled';
import { UseSubscriptions } from './subscriptions.types';

export const SubscriptionsView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { redirect }: UseRedirect = useRedirect();
  const { getSubscriptionAssetByType }: UseTransformations = useTransformations();
  const { 
    stepBoxes, 
    subscriptionPlansCategories, 
    faqItems, 
    inactiveSubscriptions, 
    activeSubscriptions,
    activeTab,
    onTabChangeAction,
    canScrollToAvailablePlan
  }: UseSubscriptions = useSubscriptions();
  const [searchParams]: UseSearchParams = useSearchParams();
  const subscriptionPlanBoxRef: RefObject<HTMLDivElement> = useRef(null);

  const { data: systemConfig }: UseQueryResult<SystemConfig> = useQuery(
    [QueryKey.Config],
    (): Promise<SystemConfig> => getConfig(),
  );

  useEffect(
    (): void => {
      if (canScrollToAvailablePlan && !!subscriptionPlanBoxRef) {
        subscriptionPlanBoxRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    },
    [searchParams, subscriptionPlanBoxRef, canScrollToAvailablePlan]
  );
  
  return (
    <Container $withUserSubscriptions={!!activeSubscriptions?.length || !!inactiveSubscriptions?.length}>
      <PageHeader header={t('chic.website.meta.subscriptions.title')} />
      {(!!activeSubscriptions?.length || !!inactiveSubscriptions?.length) && (
        <UserSubscriptionsSectionContainer>
          <UserSubscriptionsSection>
            <StyledSectionTitle 
              title={t('chic.website.subscriptionsView.userSubscriptionHeader.title')}
              size={SectionTitleSize.Small}
              onTabChange={onTabChangeAction}
              activeTab={activeTab}
              tabs={[
                {
                  name: 'active',
                  label: t('chic.website.subscriptionsView.userSubscriptionHeader.button.active'),
                  disabled: !activeSubscriptions?.length
                },
                {
                  name: 'inactive',
                  label: t('chic.website.subscriptionsView.userSubscriptionHeader.button.inactive'),
                  disabled: !inactiveSubscriptions?.length
                }
              ]}
            />
            {activeTab === 'active' && (
              activeSubscriptions?.map((subscription: SubscriptionDetailsSettings, index: number): JSX.Element => (
                <StyledSubscriptionDetails {...subscription} key={`${subscription.name}-${subscription.planColor}-${index}`} />
              ))
            )}
            {activeTab === 'inactive' && !!inactiveSubscriptions?.length && (
              <SubscriptionsList rows={inactiveSubscriptions} />
            )}
          </UserSubscriptionsSection>
        </UserSubscriptionsSectionContainer>
      )}
      <MainSectionContainer>
        <MainSection>
          <TopSection 
            image={FileFromViews.SubscriptionsPackage} 
            children={
              <TopSectionContent>
                <MainTopSectionTitle>{t('chic.website.subscriptionsView.mainTopSectionContent.title')}</MainTopSectionTitle>
                <TopSectionDescription 
                  text={highlightTextFragments(t('chic.website.subscriptionsView.mainTopSectionContent.description'))} 
                />
              </TopSectionContent>
            } 
          />
          <SimpleInfoBoxWrapper>
            <StyledSimpleInfoBox 
              iconName={IconName.Layers} 
              title={t('chic.website.subscriptionsView.simpleInfoBox.subscriptions.title')}
              description={t('chic.website.subscriptionsView.simpleInfoBox.subscriptions.description')}
              boxTheme={SimpleInfoBoxTheme.BigDark}
            />
            <StyledSimpleInfoBox 
              iconName={IconName.DeliveryCourier} 
              title={t('chic.website.subscriptionsView.simpleInfoBox.delivery.title')}
              description={t('chic.website.subscriptionsView.simpleInfoBox.delivery.description')}
              boxTheme={SimpleInfoBoxTheme.BigDark}
            />
          </SimpleInfoBoxWrapper>
          <TopSection 
            image={FileFromViews.SubscriptionsWomanSmartphone} 
            children={
              <TopSectionContent>
                <SecondTopSectionTitle text={highlightTextFragments(t('chic.website.subscriptionsView.secondTopSectionContent.title'))} />
                <TopSectionDescription text={t('chic.website.subscriptionsView.secondTopSectionContent.description')} />
              </TopSectionContent>
            } 
            imagePosition='right'
          />
          {(!inactiveSubscriptions?.length && !activeSubscriptions?.length) && (
            <StepBoxesSection>
              <SectionHeadTitle>{t('chic.website.subscriptionsView.stepBoxesSection.sectionTitle')}</SectionHeadTitle>
              <StepBoxesGroup steps={stepBoxes} colorTheme={ComponentColorTheme.Dark} />
            </StepBoxesSection>
          )}
        </MainSection>
      </MainSectionContainer>
      {systemConfig?.subscriptions && (
        <OfferSectionContainer>
          <OfferSection>
            <SectionHeadTitle>{t('chic.website.subscriptionsView.offerSection.sectionTitle')}</SectionHeadTitle>
            {subscriptionPlansCategories?.map((plan: SubscriptionPlan[], index: number): JSX.Element => (
              <SubscriptionPlanBoxWrapper
                ref={searchParams.get(FrontendUrlsParams.AvailablePlan) === String(plan[0].category.id)
                  ? subscriptionPlanBoxRef
                  : undefined
                }
                key={index}
              >
                <SubscriptionPlanBox
                  planGroup={plan.map((subscriptionPlan: SubscriptionPlan): SubscriptionPlanBoxData => ({
                    colorLabel: subscriptionPlan.color,
                    label: subscriptionPlan.name,
                    category: subscriptionPlan.category.name,
                    price: {
                      regular: subscriptionPlan.price.regular,
                      current: subscriptionPlan.price.current
                    },
                    escPoints: subscriptionPlan.loyaltyPoints,
                    groups: subscriptionPlan.groups.map((group: SubscriptionPlanGroup): SubscriptionPlanGroupSimple => ({
                      label: group.name,
                      amount: group.amount
                    })),
                    planIcon: subscriptionPlan.assets.find((asset: SubscriptionPlanAsset): boolean => asset.type === 'icon')?.path ?? ''
                  }))} 
                  categoryAvatar={getSubscriptionAssetByType(plan[0].assets, 'packshot', 'dark')}
                  isOpen={searchParams.get(FrontendUrlsParams.AvailablePlan) === String(plan[0].category.id)}
                />
              </SubscriptionPlanBoxWrapper>
            ))}
          </OfferSection>
        </OfferSectionContainer>
      )}
      <FaqSectionContainer>
        <FaqSection>
          <FaqIcon src={FileFromViews.SubscriptionsFaqIcon} />
          <SectionHeadTitle>{t('chic.website.subscriptionsView.faqSection.sectionTitle')}</SectionHeadTitle>
          <AccordionContainer>
            {faqItems.map((accordion: AccordionItemData, index: number): JSX.Element => (
              <AccordionItem {...accordion} key={`${accordion.label ?? ''}-${index}`} />
            ))}
          </AccordionContainer>
        </FaqSection>
      </FaqSectionContainer>
      <BottomSectionContainer>
        <BottomSection>
          <AdBoxContainer>
            <AdBox
              adBoxSettingsList={[
                {
                  title: t('chic.website.subscriptionsView.subscriptionBox.title'),
                  description: t('chic.website.subscriptionsView.subscriptionBox.description'),
                  highlightedBoxSettings: {
                    label: t('chic.website.subscriptionsView.subscriptionBox.highlightedLabel'),
                    value: t('chic.website.subscriptionsView.subscriptionBox.highlightedValue'),
                    url: 'tel:+48800610610'
                  },
                  textBeforeButton: t('chic.website.subscriptionsView.subscriptionBox.textBeforeArrowButton'),
                  buttonSettings: { 
                    action: (): void => redirect(getRouteDetailsByName(RouteNameEnum.Contact)?.url ?? '', undefined, true),
                    label: t('chic.website.subscriptionsView.subscriptionBox.arrowButtonLabel')
                  },
                }
              ]}
              img={FileFromViews.SubscriptionsSmartphoneInHands}
            />
          </AdBoxContainer>
          <StyledActionBox 
            text={t('chic.website.subscriptionsView.userActionBox.productComplaint.text')}
            iconName={IconName.LayersCrossed}
            buttonSettings={{
              action: (): void => redirect('https://static.esmokingclub.pl/regulations/product-complaint.pdf', undefined, true),
              label: t('chic.website.subscriptionsView.userActionBox.productComplaint.buttonLabel')
            }}
          />
          <StyledActionBox 
            text={t('chic.website.subscriptionsView.userActionBox.listOfPlaces.text')}
            iconName={IconName.LayersCrossed}
            buttonSettings={{
              action: (): void => redirect(
                'https://static.esmokingclub.pl/regulations/lista_punktow_obslugujacych_subskrypcje.pdf', undefined, true
              ),
              label: t('chic.website.subscriptionsView.userActionBox.listOfPlaces.buttonLabel')
            }}
          />
        </BottomSection>
      </BottomSectionContainer>
    </Container>
  );
};
