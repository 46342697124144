import { UseRedirect, useRedirect, UseNotifications, useNotifications, Color } from '@chic-loyalty/ui';
import { useMemo } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { appConfig } from '@chic/frontend/app.config';
import { FullscreenAlertAssets } from '@chic/frontend/enums';
import { UsePayu } from '@chic/frontend/interfaces';
import { RouteNameEnum } from '@chic/shared/enums';
import { getRouteDetailsByName } from '@chic/shared/utils';

export const usePayu: () => UsePayu = (): UsePayu => {
  const { t }: TransProps<never> = useTranslation();
  const { redirect }: UseRedirect = useRedirect();
  const { showFullscreenAlert, hideFullscreenAlert }: UseNotifications = useNotifications();

  const secureFormOptions: payu.SecureFormOptions = useMemo(
    (): payu.SecureFormOptions => ({
      cardIcon: true,
      style: {
        basic: { fontSize: '16px', fontColor: Color.SolidMediumEmphasis },
        focus: { fontColor: Color.SolidMediumEmphasis }
      },
      placeholder: {
        // eslint-disable-next-line id-denylist
        number: t('chic.website.global.cardNumber.placeholder'),
        date: t('chic.website.global.expirationDate.placeholder'),
        cvv: t('chic.website.global.cvvCode.placeholder'),
      },
      lang: 'pl'
    }),
    []
  );

  const isPayuSdkReady: () => boolean = (): boolean => {
    if (typeof PayU === 'undefined' || !appConfig.payuPosId) {
      showFullscreenAlert({
        title: t('chic.website.usePayu.noPayuSkdAvailablePopup.title'),
        description: t('chic.website.usePayu.noPayuSkdAvailablePopup.description'),
        iconImage: FullscreenAlertAssets.ErrorIcon,
        acceptButtonSettings: {
          label: t('chic.website.global.ok'),
          action: (): void => {
            hideFullscreenAlert();
            redirect(getRouteDetailsByName(RouteNameEnum.ProfilePaymentsSettings)?.url ?? '');
          }
        },
      });

      return false;
    }

    return true;
  };

  const showSuccessPopupAfterPayuOperation: () => void = (): void => {
    showFullscreenAlert({
      title: t('chic.website.usePayu.payuOperationSuccessPopup.title'),
      description: t('chic.website.usePayu.payuOperationSuccessPopup.description'),
      image: FullscreenAlertAssets.WomanWithPhoneAndComputer,
      acceptButtonSettings: {
        label: t('chic.website.global.close'),
        action: (): void => {
          hideFullscreenAlert();
          redirect(getRouteDetailsByName(RouteNameEnum.ProfilePaymentsSettings)?.url ?? '');
        }
      },
    });
  };

  return {
    secureFormOptions,
    isPayuSdkReady,
    showSuccessPopupAfterPayuOperation,
  };
};
