import { Color, StatusSettings } from '@chic-loyalty/ui';

import { PaymentStatusCode } from '@chic/shared/enums';

export const paymentsStatuses: Record<PaymentStatusCode, StatusSettings> = {
  [PaymentStatusCode.Pending]: {
    label: 'chic.website.paymentsStatuses.pending',
    color: Color.AlmostBlack,
    background: Color.AlmostWhite,
    dotColor: Color.Tangerine,
  },
  [PaymentStatusCode.Success]: {
    label: 'chic.website.paymentsStatuses.success',
    color: Color.AlmostWhite,
    background: Color.Success,
  },
  [PaymentStatusCode.Rejected]: {
    label: 'chic.website.paymentsStatuses.rejected',
    color: Color.AlmostWhite,
    background: Color.Validation,
  },
  [PaymentStatusCode.Failed]: {
    label: 'chic.website.paymentsStatuses.failed',
    color: Color.AlmostBlack,
    background: Color.WarningLight,
  },
};
