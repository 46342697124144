import { BaseApiError } from '@chic/shared/interfaces';
import { ApiError } from '@chic/shared/models';

export class FrontendApiError<ApiErrorData extends BaseApiError | Record<string, unknown>
= Record<string, unknown>> extends ApiError<ApiErrorData> {
  public responseCode: number;

  constructor(
    message: string,
    responseCode: number,
    data: ApiErrorData,
  ) {
    super(message, data);
    this.responseCode = responseCode;
  }
}
