import { UseNotifications, UseRedirect, useNotifications, useRedirect } from '@chic-loyalty/ui';
import { TransProps, useTranslation } from 'react-i18next';

import { cancelSubscription, changeSubscriptionNextDeliveryDate } from '@chic/frontend/api/requests';
import { FullscreenAlertAssets } from '@chic/frontend/enums';
import { UseSubscriptionsActions } from '@chic/frontend/interfaces';
import { FrontendApiError } from '@chic/frontend/models';
import { RouteNameEnum } from '@chic/shared/enums';
import { getRouteDetailsByName } from '@chic/shared/utils';

export const useSubscriptionsActions: () => UseSubscriptionsActions = (): UseSubscriptionsActions => {
  const { t }: TransProps<never> = useTranslation();
  const { redirect }: UseRedirect = useRedirect();
  const { showFullscreenAlert, hideFullscreenAlert }: UseNotifications = useNotifications();

  const changeDeliveryDateAction: (name: string, id: number) => void = (name: string, id: number): void => {
    showFullscreenAlert({
      title: t('chic.website.useSubscriptionsActions.showFullscreenAlert.title'),
      description: t('chic.website.useSubscriptionsActions.showFullscreenAlert.description', { value: name }),
      iconImage: FullscreenAlertAssets.SuccessIcon,
      acceptButtonSettings: {
        label: t('chic.website.global.yes'),
        action: (): void => {
          changeSubscriptionNextDeliveryDate(id)
            .then((): void => showFullscreenAlert({
              title: t('chic.website.global.success'),
              description: t(
                'chic.website.useSubscriptionsActions.changeSubscriptionNextDeliveryDate.success'
              ),
              iconImage: FullscreenAlertAssets.SuccessIcon,
              acceptButtonSettings: {
                label: t('chic.website.global.close'),
                action: hideFullscreenAlert
              }
            }))
            .catch((error: FrontendApiError): void => showFullscreenAlert({
              title: t('chic.website.global.error'),
              description: error.message,
              iconImage: FullscreenAlertAssets.ErrorIcon,
              acceptButtonSettings: {
                label: t('chic.website.global.close'),
                action: hideFullscreenAlert
              }
            }));
        }
      },
      cancelButtonSettings: {
        label: t('chic.website.global.no'),
        action: hideFullscreenAlert
      }
    });
  };

  const cancelSubscriptionAction: (id: number) => void = (id: number): void => {
    showFullscreenAlert({
      title: t('chic.website.useSubscriptionDetails.cancelSubscriptionAction.fullscreenAlert.title'),
      description: t('chic.website.useSubscriptionDetails.cancelSubscriptionAction.fullscreenAlert.description'),
      acceptButtonSettings: {
        label: t('chic.website.global.yes'),
        action: (): void => {
          cancelSubscription(id)
            .then((): void => {
              hideFullscreenAlert();
              redirect(getRouteDetailsByName(RouteNameEnum.Subscriptions)?.url ?? '/');
            })
            .catch((error: FrontendApiError): void => showFullscreenAlert({
              title: t('chic.website.global.error'),
              description: error.message,
              iconImage: FullscreenAlertAssets.ErrorIcon,
              acceptButtonSettings: {
                label: t('chic.website.global.close'),
                action: hideFullscreenAlert
              }
            }));
        }
      },
      cancelButtonSettings: {
        label: t('chic.website.global.no'),
        action: (): void => {
          hideFullscreenAlert();
        }
      },
    });
  };

  return {
    changeDeliveryDateAction,
    cancelSubscriptionAction,
  };
};
