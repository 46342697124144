import { FormRow } from '@chic/frontend/interfaces';

import { SubscriptionDeliveryField } from './subscriptionEditDelivery.enums';

export const subscriptionDeliveryFields: FormRow<SubscriptionDeliveryField>[] = [
  {
    fields: [
      {
        name: SubscriptionDeliveryField.Name,
        label: 'chic.website.global.recipientName',
        type: 'text',
        required: true,
      },
    ]
  },
  {
    fields: [
      {
        name: SubscriptionDeliveryField.Address,
        label: 'chic.website.global.address',
        type: 'text',
        required: true,
      },
    ],
  },
  {
    fields: [
      {
        name: SubscriptionDeliveryField.PostalCode,
        label: 'chic.website.global.postalCode',
        type: 'text',
        required: true,
      },
      {
        name: SubscriptionDeliveryField.City,
        label: 'chic.website.global.city',
        type: 'text',
        required: true,
      },
    ],
  },
  {
    title: 'chic.website.subscriptionEditDeliveryView.contactData',
    fields: [
      {
        name: SubscriptionDeliveryField.Phone,
        label: 'chic.website.subscriptionEditDeliveryView.phone.label',
        description: 'chic.website.subscriptionEditDeliveryView.phone.message',
        type: 'text',
        hasPhonePrefix: true,
        required: true,
      },
    ],
  },
  {
    fields: [
      {
        name: SubscriptionDeliveryField.Email,
        label: 'chic.website.subscriptionEditDeliveryView.email.label',
        description: 'chic.website.subscriptionEditDeliveryView.email.message',
        type: 'text',
        required: true,
      },
    ],
  },
];
