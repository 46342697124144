import { StyledComponent, ContentBox, Input, Color, Breakpoint, FontWeight, FieldsRow, MailToUs, Button } from '@chic-loyalty/ui';
import { Form } from 'formik';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: calc(100% - 80px);
  background-color: ${Color.Black};
  margin-left: auto;

  @media ${Breakpoint.Mobile} {
    width: 100%;
  }
`;

export const StyledContentBox: StyledComponent<typeof ContentBox> = styled(ContentBox)`
  max-width: 900px;
  margin: 50px auto 0;
  padding: 70px 102px;

  & + & {
    margin-top: 10px;
  }

  @media ${Breakpoint.Tablet} {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    padding: 50px 20px 35px;

    & + & {
      margin-top: 2px;
    }
  }
`;

export const SectionTitle: StyledComponent<'div'> = styled.div`
  font-weight: ${FontWeight.Bold};
  font-size: 10px;
  letter-spacing: 0.5px;
  line-height: 15px;
  color: ${Color.SolidHighEmphasis};
  margin-bottom: 15px;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
`;

export const SectionTitleLine: StyledComponent<'div'> = styled.div`
  width: 100%;
  margin-top: 15px;
  height: 1px;
  background-color: ${Color.Tangerine};
`;

export const FormikForm: StyledComponent<typeof Form> = styled(Form)`
  width: 100%;
`;

export const StyledInput: StyledComponent<typeof Input> = styled(Input)`
  & + & {
    margin-top: 10px;
  }
`;

export const StyledFieldsRow: StyledComponent<typeof FieldsRow> = styled(FieldsRow)`
  margin: 10px 0;

  ${StyledInput} + ${StyledInput} {
    margin-top: 0;
  }

  & + ${SectionTitle} {
    margin-top: 50px;
  }
`;

export const StyledMailToUs: StyledComponent<typeof MailToUs> = styled(MailToUs)`
  margin: 10px auto 100px;
  max-width: 900px;

  @media ${Breakpoint.Tablet} {
    margin: 35px 20px 50px;
    width: calc(100% - 40px);
  }
`;

export const ButtonsContainer: StyledComponent<'div'> = styled.div`
  width: 100%;
  display: flex;
  padding-top: 40px;

  @media ${Breakpoint.Tablet} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 15px;
  }
`;

export const StyledButton: StyledComponent<typeof Button> = styled(Button)`
  & + & {
    margin-left: 50px;
  }

  @media ${Breakpoint.Tablet} {
    & + & {
      margin: 35px 0 0;
    }
  }
`;

export const ParcelData: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 33px;
`;

export const ParcelAddress: StyledComponent<'div'> = styled.div`
  color: ${Color.SolidMediumEmphasis};
  font-size: 14px;
  line-height: 24px;
  font-weight: ${FontWeight.Light};
`;
