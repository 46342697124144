import { IsDefined, IsEmail, IsString, Matches } from 'class-validator';

import { eanRegexp, phoneRegexp } from '@chic/shared/constants/regexps';

export class SendContactFormRequest {
  @IsDefined()
  @Matches(eanRegexp)
  public ean: string;

  @IsDefined()
  @IsString()
  public name: string;

  @IsDefined()
  @IsEmail()
  public email: string;

  @IsDefined()
  @Matches(phoneRegexp)
  public phone: string;

  @IsDefined()
  @IsString()
  public message: string;

  @IsDefined()
  @IsString()
  public recaptchaToken: string;

  constructor(
    ean: string,
    name: string,
    email: string,
    phone: string,
    message: string,
    recaptchaToken: string,
  ) {
    this.ean = ean;
    this.name = name;
    this.email = email;
    this.phone = phone;
    this.message = message;
    this.recaptchaToken = recaptchaToken;
  }
}
