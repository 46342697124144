import {
  ContactFormSection,
  PageHeader,
  UseNotifications,
  useNotifications,
  ContactFormValues,
  UseState,
} from '@chic-loyalty/ui';
import React, { useState } from 'react';
import { IGoogleReCaptchaConsumerProps, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { TransProps, useTranslation } from 'react-i18next';
import { useNavigate, NavigateFunction } from 'react-router-dom';

import { subscriptionContactFormRequest } from '@chic/frontend/api/requests';
import { FullscreenAlertAssets } from '@chic/frontend/enums';
import { useAuth } from '@chic/frontend/hooks';
import { UseAuth } from '@chic/frontend/interfaces';
import { FrontendApiError } from '@chic/frontend/models';

import { Container, GridContainer, Row, CenterSection } from './subscriptionContact.styled';

export const SubscriptionContactView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { showFullscreenAlert, hideFullscreenAlert }: UseNotifications = useNotifications();
  const navigate: NavigateFunction = useNavigate();
  const { userData }: UseAuth = useAuth();
  const [isLoading, setIsLoading]: UseState<boolean> = useState<boolean>(false);
  const { executeRecaptcha }: IGoogleReCaptchaConsumerProps = useGoogleReCaptcha();

  const onSubmit: (values: ContactFormValues) => Promise<void> = async (values: ContactFormValues): Promise<void> => {
    setIsLoading(true);
    if (!executeRecaptcha) {
      setIsLoading(false);
      return;
    }
    const recaptchaToken: string = await executeRecaptcha();

    subscriptionContactFormRequest({ ...values, recaptchaToken })
      .then((): void => {
        showFullscreenAlert({
          title: t('chic.website.subscriptionContactView.success.title'),
          description: t('chic.website.subscriptionContactView.success.description'),
          acceptButtonSettings: {
            label: t('chic.app.global.close'),
            action: hideFullscreenAlert
          },
        });
      })
      .catch((error: FrontendApiError): void => {
        showFullscreenAlert({
          description: error.message,
          iconImage: FullscreenAlertAssets.ErrorIcon,
          acceptButtonSettings: {
            label: t('chic.website.global.ok'),
            action: hideFullscreenAlert,
          },
        });
      })
      .finally((): void => setIsLoading(false));
  };

  return (
    <Container>
      <PageHeader
        header={t('chic.website.subscriptionContactView.header')}
        onArrowButtonAction={(): void => navigate(-1)} // TODO: make global hook for this 
      />
      <CenterSection>
        <GridContainer>
          <Row>
            <ContactFormSection
              initialValues={userData
                ? {
                  ean: userData.card,
                  name: userData.name,
                  email: userData.email,
                  phone: userData.phone,
                }
                : undefined
              }
              onSubmit={onSubmit}
              title={t('chic.website.subscriptionContactView.contactFormTitle')}
              subtitle={t('chic.website.subscriptionContactView.contactFormSubtitle')}
              isLoading={isLoading}
            />
          </Row>
        </GridContainer>
      </CenterSection>
    </Container>
  );
};
