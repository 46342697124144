export enum RouteNameEnum {
  CookiesPolicy = 'cookiesPolicy',
  Contact = 'contact',
  ContactDataVerification = 'contactDataVerification',
  Home = 'home',
  NotFound = 'notFound',
  SignIn = 'signIn',
  SignInIntegration = 'signInIntegration',
  RemindPassword = 'remindPassword',
  Regulations = 'regulations',
  SetPassword = 'setPassword',
  Dashboard = 'dashboard',
  DashboardForEscProgramLimited = 'dashboardForEscProgramLimited',
  Logout = 'logout',
  BirthdayBonus = 'BirthdayBonus',
  // TODO: temporarily hidden
  // FreeEngraver = 'freeEngraver',
  CarePlus = 'carePlus',
  ChangeUnverifiedUserEmail = 'changeUnverifiedUserEmail',
  FreeService = 'freeService',
  Offers = 'offers',
  OfferDetails = 'offerDetails',
  SinglePost = 'singlePost',
  Payment = 'payment',
  PostsList = 'postsList',
  PrivacyPolicy = 'privacyPolicy',
  Products = 'products',
  ProductDetails = 'productDetails',
  ProfileYourData = 'profileYourData',
  ProfileChangePassword = 'profileChangePassword',
  ProfileAgreements = 'profileAgreements',
  ProfileNotificationsSettings = 'profileNotificationsSettings',
  ProfilePaymentsSettings = 'profilePaymentsSettings',
  ProfilePaymentsHistory = 'profilePaymentsHistory',
  ProfileAddCreditCard = 'profileAddCreditCard',
  NotificationsHistory = 'notificationsHistory',
  SubscriptionDetails = 'subscriptionDetails',
  Subscriptions = 'subscriptions',
  SubscriptionPlanUpgradeConditions = 'subscriptionPlanUpgradeConditions',
  SubscriptionPlanUpgradeEditProducts = 'subscriptionPlanUpgradeEditProducts',
  SubscriptionContact = 'subscriptionContact',
  TechnicalConfirmEmail = 'technicalConfirmEmail',
  TechnicalPayuCvvCodeAdd = 'technicalPayuCvvCodeAdd',
  TechnicalIcMigrationLanding = 'technicalIcMigrationLanding',
  TransactionDetails = 'transactionDetails',
  TransactionsList = 'transactionsList',
  Faq = 'faq',
  SubscriptionEditDelivery = 'subscriptionEditDelivery',
  SubscriptionEditProducts = 'subscriptionEditProducts',
  SubscriptionChooseDeliveryPoint = 'subscriptionChooseDeliveryPoint',
  FindUs = 'findUs',
  // in old frontend
  SubscriptionEdit = 'subscriptionEdit',
  Survey = 'survey',
  SurveyAuthorized = 'surveyAuthorized',
  Tickets = 'tickets',
  TicketsAuthorized = 'ticketsAuthorized',
  DevicesAuthorizationReset = 'devicesAuthorizationReset',
  SubscriptionDeliveryNotAuthorizedUpdate = 'subscriptionDeliveryNotAuthorizedUpdate',
  PaymentRedirectLanding = 'paymentRedirectLanding',
}
