import {
  PageHeader,
  UseState,
  PageHeaderPosition,
  MenuPositionData,
  ValidationBarTheme,
  PaginationDetails,
  usePagination,
  ComponentColorTheme,
  PaymentsHistoryTable,
  PaymentDetails,
  SectionTitleSize,
} from '@chic-loyalty/ui';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { getUserPaymentsHistory } from '@chic/frontend/api/requests';
import { QueryKey } from '@chic/frontend/enums';
import { getCreditCardLogo } from '@chic/frontend/utils';
import { CreditCardProviderType, PaymentDetailsType } from '@chic/shared/enums';
import { ListPaginationInterface, PaymentInfo } from '@chic/shared/interfaces';

import { useProfileTabs } from '../profile.hooks';

import { paymentsStatuses } from './paymentsHistory.constants';
import {
  Container,
  ContentWrapper,
  StyledPagination,
  StyledSectionTitle,
  StyledValidationBar
} from './paymentsHistory.styled';

export const PaymentsHistoryView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const profileTabs: MenuPositionData[] = useProfileTabs();
  const [payments, setPayments]: UseState<PaymentDetails[]> = useState<PaymentDetails[]>([]);
  const { setMaxItems, setPage, itemsPerPage, offset, page, pagesCount }: PaginationDetails = usePagination();

  const { isFetching }: UseQueryResult = useQuery(
    [QueryKey.Subscriptions, offset],
    (): Promise<ListPaginationInterface<PaymentInfo>> => getUserPaymentsHistory({ offset, limit: itemsPerPage }),
    {
      onSuccess: (data: ListPaginationInterface<PaymentInfo>): void => {
        setPayments(data.list.map((payment: PaymentInfo): PaymentDetails => ({
          planName: payment.item.details.categoryName,
          planColor: payment.item.details.color,
          timestamp: payment.timestamp,
          orderNumber: `${payment.orderId}/${payment.subscriptionId}`,
          price: payment.amount,
          paymentTypeLabel: payment.details.label
            ? payment.details.label.replace(/[\*].*\s/g, '････ ')
            : payment.details.provider?.label,
          paymentStatus: {
            ...paymentsStatuses[payment.status.code],
            label: t(paymentsStatuses[payment.status.code].label),
          },
          paymentTypeLogo: payment.details.type === PaymentDetailsType.Card
            ? getCreditCardLogo(payment.details.provider.type as CreditCardProviderType)
            : undefined,
        })));
        setMaxItems(data.amount);
      },
      // TODO: add logger
      onError: (): void => undefined
    }
  );

  return (
    <Container>
      <PageHeader
        header={t('chic.website.meta.profile.title')}
        headerPosition={PageHeaderPosition.CenterLeft}
        tabs={profileTabs}
        activeTabName='paymentsHistory'
      />
      <ContentWrapper>
        <StyledSectionTitle
          title={t('chic.website.paymentsHistoryView.tableTitle')}
          size={SectionTitleSize.Small}
        />
        {(!isFetching && !payments.length) ? (
          <StyledValidationBar
            message={t('chic.website.paymentsHistoryView.noPayments')}
            barTheme={ValidationBarTheme.Gray}
          />
        ) : (
          <>
            {!!payments.length && (
              <PaymentsHistoryTable payments={payments} />
            )}
            {(pagesCount > 1) && (
              <StyledPagination
                activePage={page}
                pagesCount={pagesCount}
                onActivePageChange={setPage}
                colorTheme={ComponentColorTheme.Dark}
              />
            )}
          </>
        )}
      </ContentWrapper>
    </Container>
  );
};
