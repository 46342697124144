import { TransProps, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { passwordRegexp } from '@chic/shared/constants';

import { ChangePasswordRequestWithRepatedPassword } from '../changePassword.types';

export const useChangePasswordValidation: () => Yup.SchemaOf<ChangePasswordRequestWithRepatedPassword>
= (): Yup.SchemaOf<ChangePasswordRequestWithRepatedPassword> => {
  const { t }: TransProps<never> = useTranslation();

  return Yup.object().shape({
    oldPassword: Yup.string()
      .required(t('chic.website.global.requiredField'))
      .matches(passwordRegexp, t('chic.website.global.validations.passwordFormat')),
    newPassword: Yup.string()
      .required(t('chic.website.global.requiredField'))
      .matches(passwordRegexp, t('chic.website.global.validations.passwordFormat'))
      .oneOf([Yup.ref('repeatedPassword'), null], t('chic.website.global.validations.passwordsMustMatch'))
      .notOneOf([Yup.ref('oldPassword'), null], t('chic.website.global.validations.passwordsMustBeDifferentFromOld')),
    repeatedPassword: Yup.string()
      .required(t('chic.website.global.requiredField'))
      .matches(passwordRegexp, t('chic.website.global.validations.passwordFormat'))
      .oneOf([Yup.ref('newPassword'), null], t('chic.website.global.validations.passwordsMustMatch'))
      .notOneOf([Yup.ref('oldPassword'), null], t('chic.website.global.validations.passwordsMustBeDifferentFromOld')),
  });
};
