import { UseNotifications, useNotifications, useRedirect, UseRedirect } from '@chic-loyalty/ui';
import React, { useEffect } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { Params, useParams } from 'react-router-dom';

import { altercloudAuthorization } from '@chic/frontend/api/requests';
import { FullscreenAlertAssets } from '@chic/frontend/enums';
import { useAuth } from '@chic/frontend/hooks';
import { UseAuth } from '@chic/frontend/interfaces';
import { FrontendApiError } from '@chic/frontend/models';
import { RouteNameEnum } from '@chic/shared/enums';
import { UserWithToken } from '@chic/shared/interfaces';
import { getRouteDetailsByName } from '@chic/shared/utils';

import { Container } from './signInIntegration.styled';

export const SignInIntegrationView: React.FC = (): JSX.Element => {
  const { showFullscreenAlert, hideFullscreenAlert }: UseNotifications = useNotifications();
  const { signIn }: UseAuth = useAuth();
  const { t }: TransProps<never> = useTranslation();
  const { token }: Readonly<Params<string>> = useParams();
  const { redirect }: UseRedirect = useRedirect();

  useEffect(
    (): void => {
      if (token) {
        altercloudAuthorization(token)
          .then((response: UserWithToken): void => {
            const { contextToken, ...user }: UserWithToken = response;
            signIn(user, contextToken);
          })
          .catch((error: FrontendApiError): void => {
            showFullscreenAlert({
              title: t('chic.website.signInIntegrationView.error.title'),
              description: error.message,
              iconImage: FullscreenAlertAssets.ErrorIcon,
              acceptButtonSettings: {
                label: t('chic.website.global.ok'),
                action: (): void => {
                  hideFullscreenAlert();
                  redirect(getRouteDetailsByName(RouteNameEnum.Home)?.url ?? '');
                }
              },
            });
          });
      }
    },
    []
  );

  return (
    <Container />
  );
};
