import { GlobalStyle, NotificationsProvider } from '@chic-loyalty/ui';
import { QueryClient } from '@tanstack/react-query';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { AuthContextProvider, CheckAuth, QueryClientProvider, SocketProvider } from '@chic/frontend/components';
import { RoutingContext, UserSSRDataContext } from '@chic/frontend/contexts';
import {
  BirthdayBonusView,
  CarePlusView,
  ContactDataVerificationView,
  DashboardView,
  FaqView,
  FreeServiceView,
  HomeView,
  LogoutView,
  RemindPasswordView,
  SetPasswordView,
  SignInIntegrationView,
  SignInView,
  OffersView,
  ChangePasswordView,
  YourDataView,
  AgreementsView,
  ContactView,
  RegulationsView,
  SubscriptionsView,
  ProductsView,
  NotificationsSettingsView,
  NotificationsHistoryView,
  ProductDetailsView,
  CookiesPolicyView,
  TransactionsListView,
  TransactionDetailsView,
  PostsListView,
  SinglePostView,
  OfferDetailsView,
  PaymentsSettingsView,
  AddCreditCardView,
  PayuCvvCodeAddView,
  PaymentsHistoryView,
  ChangeUnverifiedUserEmailView,
  SubscriptionDetailsView,
  SubscriptionEditDeliveryView,
  SubscriptionChooseDeliveryPointView,
  SubscriptionContactView,
  FindUsView,
  SubscriptionEditProductsView,
  NotFoundView,
  PaymentView,
  PaymentDetailsView,
  SubscriptionDeliveryNotAuthorizedUpdateView,
  ConfirmEmailView,
  SubscriptionPlanUpgradeConditionsView,
  SubscriptionPlanUpgradeEditProductsView,
  IcMigrationLandingView,
  DashboardForEscProgramLimitedView,
} from '@chic/frontend/views';
import { plRouting } from '@chic/shared/constants';
import { RouteNameEnum } from '@chic/shared/enums';
import { UserSSRData } from '@chic/shared/interfaces';
import { getRouteDetailsByName } from '@chic/shared/utils';

import { AppWrapper } from '../components/appWrapper/appWrapper.component';

const queryClient: QueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      networkMode: 'offlineFirst',
      retry: false,
    }
  },
});

const App: React.FC = (): JSX.Element => {

  return (
    <RoutingContext.Provider value={plRouting}>
      <UserSSRDataContext.Consumer>
        {(userDataIncjectedBySSR: UserSSRData): JSX.Element => (
          <NotificationsProvider>
            <AuthContextProvider userSSRData={userDataIncjectedBySSR}>
              <SocketProvider>
                <QueryClientProvider queryClient={queryClient}>
                  <AppWrapper>
                    <GlobalStyle />
                    <Routes>
                      <Route path={getRouteDetailsByName(RouteNameEnum.Home)?.url} element={
                        <CheckAuth><HomeView /></CheckAuth>
                      } />
                      <Route path={getRouteDetailsByName(RouteNameEnum.Contact)?.url} element={
                        <CheckAuth><ContactView /></CheckAuth>
                      } />
                      <Route path={getRouteDetailsByName(RouteNameEnum.CookiesPolicy)?.url} element={
                        <CheckAuth><CookiesPolicyView /></CheckAuth>
                      } />
                      <Route path={getRouteDetailsByName(RouteNameEnum.Regulations)?.url} element={
                        <CheckAuth><RegulationsView /></CheckAuth>
                      } />
                      <Route path={getRouteDetailsByName(RouteNameEnum.ContactDataVerification)?.url} element={
                        <CheckAuth><ContactDataVerificationView /></CheckAuth>
                      } />
                      <Route path={getRouteDetailsByName(RouteNameEnum.Dashboard)?.url} element={
                        <CheckAuth><DashboardView /></CheckAuth>
                      } />
                      <Route path={getRouteDetailsByName(RouteNameEnum.DashboardForEscProgramLimited)?.url} element={
                        <CheckAuth><DashboardForEscProgramLimitedView /></CheckAuth>
                      } />
                      <Route path={getRouteDetailsByName(RouteNameEnum.BirthdayBonus)?.url} element={
                        <CheckAuth><BirthdayBonusView /></CheckAuth>
                      } />
                      <Route path={getRouteDetailsByName(RouteNameEnum.Faq)?.url} element={
                        <CheckAuth><FaqView /></CheckAuth>
                      } />
                      <Route path={getRouteDetailsByName(RouteNameEnum.FindUs)?.url} element={
                        <CheckAuth><FindUsView /></CheckAuth>
                      } />
                      {/* TODO: temporarily hidden */}
                      {/* <Route path={getRouteDetailsByName(RouteNameEnum.FreeEngraver)?.url} element={
                        <CheckAuth><FreeEngraverView /></CheckAuth>
                      } /> */}
                      <Route path={getRouteDetailsByName(RouteNameEnum.CarePlus)?.url} element={
                        <CheckAuth><CarePlusView /></CheckAuth>
                      } />
                      <Route path={getRouteDetailsByName(RouteNameEnum.ChangeUnverifiedUserEmail)?.url} element={
                        <CheckAuth><ChangeUnverifiedUserEmailView /></CheckAuth>
                      } />
                      <Route path={getRouteDetailsByName(RouteNameEnum.FreeService)?.url} element={
                        <CheckAuth><FreeServiceView /></CheckAuth>
                      } />
                      <Route path={getRouteDetailsByName(RouteNameEnum.Logout)?.url} element={
                        <CheckAuth><LogoutView /></CheckAuth>
                      } />
                      <Route path={getRouteDetailsByName(RouteNameEnum.NotificationsHistory)?.url} element={
                        <CheckAuth><NotificationsHistoryView /></CheckAuth>
                      } />
                      <Route path={getRouteDetailsByName(RouteNameEnum.RemindPassword)?.url} element={
                        <CheckAuth><RemindPasswordView /></CheckAuth>
                      } />
                      <Route path={getRouteDetailsByName(RouteNameEnum.SetPassword)?.url} element={
                        <CheckAuth><SetPasswordView /></CheckAuth>
                      } />
                      <Route path={getRouteDetailsByName(RouteNameEnum.SignIn)?.url} element={
                        <CheckAuth><SignInView /></CheckAuth>
                      } />
                      <Route path={getRouteDetailsByName(RouteNameEnum.SignInIntegration)?.url} element={
                        <CheckAuth><SignInIntegrationView /></CheckAuth>
                      } />
                      <Route path={getRouteDetailsByName(RouteNameEnum.Offers)?.url} element={
                        <CheckAuth><OffersView /></CheckAuth>
                      } />
                      <Route path={getRouteDetailsByName(RouteNameEnum.OfferDetails)?.url} element={
                        <CheckAuth><OfferDetailsView /></CheckAuth>
                      } />
                      <Route path={getRouteDetailsByName(RouteNameEnum.PaymentRedirectLanding)?.url} element={
                        <CheckAuth><PaymentView /></CheckAuth>
                      } />
                      <Route path={getRouteDetailsByName(RouteNameEnum.ProfileChangePassword)?.url} element={
                        <CheckAuth><ChangePasswordView /></CheckAuth>
                      } />
                      <Route path={getRouteDetailsByName(RouteNameEnum.ProfileYourData)?.url} element={
                        <CheckAuth><YourDataView /></CheckAuth>
                      } />
                      <Route path={getRouteDetailsByName(RouteNameEnum.ProfileAgreements)?.url} element={
                        <CheckAuth><AgreementsView /></CheckAuth>
                      } />
                      <Route path={getRouteDetailsByName(RouteNameEnum.ProfileNotificationsSettings)?.url} element={
                        <CheckAuth><NotificationsSettingsView /></CheckAuth>
                      } />
                      <Route path={getRouteDetailsByName(RouteNameEnum.Payment)?.url} element={
                        <CheckAuth><PaymentDetailsView /></CheckAuth>
                      } />
                      <Route path={getRouteDetailsByName(RouteNameEnum.ProfilePaymentsSettings)?.url} element={
                        <CheckAuth><PaymentsSettingsView /></CheckAuth>
                      } />
                      <Route path={getRouteDetailsByName(RouteNameEnum.ProfilePaymentsHistory)?.url} element={
                        <CheckAuth><PaymentsHistoryView /></CheckAuth>
                      } />
                      <Route path={getRouteDetailsByName(RouteNameEnum.ProfileAddCreditCard)?.url} element={
                        <CheckAuth><AddCreditCardView /></CheckAuth>
                      } />
                      <Route path={getRouteDetailsByName(RouteNameEnum.SinglePost)?.url} element={
                        <CheckAuth><SinglePostView /></CheckAuth>
                      } />
                      <Route path={getRouteDetailsByName(RouteNameEnum.SubscriptionDetails)?.url} element={
                        <CheckAuth><SubscriptionDetailsView /></CheckAuth>
                      } />
                      <Route path={getRouteDetailsByName(RouteNameEnum.Subscriptions)?.url} element={
                        <CheckAuth><SubscriptionsView /></CheckAuth>
                      } />
                      <Route path={getRouteDetailsByName(RouteNameEnum.SubscriptionPlanUpgradeConditions)?.url} element={
                        <CheckAuth><SubscriptionPlanUpgradeConditionsView /></CheckAuth>
                      } />
                      <Route path={getRouteDetailsByName(RouteNameEnum.SubscriptionPlanUpgradeEditProducts)?.url} element={
                        <CheckAuth><SubscriptionPlanUpgradeEditProductsView /></CheckAuth>
                      } />
                      <Route path={getRouteDetailsByName(RouteNameEnum.SubscriptionContact)?.url} element={
                        <CheckAuth><SubscriptionContactView /></CheckAuth>
                      } />
                      <Route path={getRouteDetailsByName(RouteNameEnum.SubscriptionEditDelivery)?.url} element={
                        <CheckAuth><SubscriptionEditDeliveryView /></CheckAuth>
                      } />
                      <Route path={getRouteDetailsByName(RouteNameEnum.SubscriptionEditProducts)?.url} element={
                        <CheckAuth><SubscriptionEditProductsView /></CheckAuth>
                      } />
                      <Route path={getRouteDetailsByName(RouteNameEnum.SubscriptionChooseDeliveryPoint)?.url} element={
                        <CheckAuth><SubscriptionChooseDeliveryPointView /></CheckAuth>
                      } />
                      <Route path={getRouteDetailsByName(RouteNameEnum.PostsList)?.url} element={
                        <CheckAuth><PostsListView /></CheckAuth>
                      } />
                      <Route path={getRouteDetailsByName(RouteNameEnum.Products)?.url} element={
                        <CheckAuth><ProductsView /></CheckAuth>
                      } />
                      <Route path={getRouteDetailsByName(RouteNameEnum.ProductDetails)?.url} element={
                        <CheckAuth><ProductDetailsView /></CheckAuth>
                      } />
                      <Route path={getRouteDetailsByName(RouteNameEnum.TechnicalPayuCvvCodeAdd)?.url} element={
                        <CheckAuth><PayuCvvCodeAddView /></CheckAuth>
                      } />
                      <Route path={getRouteDetailsByName(RouteNameEnum.TransactionDetails)?.url} element={
                        <CheckAuth><TransactionDetailsView /></CheckAuth>
                      } />
                      <Route path={getRouteDetailsByName(RouteNameEnum.TransactionsList)?.url} element={
                        <CheckAuth><TransactionsListView /></CheckAuth>
                      } />
                      <Route path={getRouteDetailsByName(RouteNameEnum.TechnicalConfirmEmail)?.url} element={
                        <CheckAuth><ConfirmEmailView /></CheckAuth>
                      } />
                      <Route path={getRouteDetailsByName(RouteNameEnum.TechnicalIcMigrationLanding)?.url} element={
                        <CheckAuth><IcMigrationLandingView /></CheckAuth>
                      } />
                      <Route path={getRouteDetailsByName(RouteNameEnum.SubscriptionDeliveryNotAuthorizedUpdate)?.url} element={
                        <CheckAuth><SubscriptionDeliveryNotAuthorizedUpdateView /></CheckAuth>
                      } />
                      <Route path='*' element={<NotFoundView />} />
                    </Routes>
                  </AppWrapper>
                </QueryClientProvider>
              </SocketProvider>
            </AuthContextProvider>
          </NotificationsProvider>
        )}
      </UserSSRDataContext.Consumer>
    </RoutingContext.Provider>
  );
};

export default App;
