import { RouteNameEnum } from '@chic/shared/enums';

export const pathsWithHeaderMenuTransparentTheme: RouteNameEnum[] = [
  RouteNameEnum.Home,
  RouteNameEnum.TechnicalConfirmEmail,
];

export const pathsWithHeaderMenuElevationLevel1Theme: RouteNameEnum[] = [
  RouteNameEnum.Dashboard,
];
