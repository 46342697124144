import {
  ContactFormSection,
  FindShopsBanner,
  PageHeader,
  PhoneBox,
  useRedirect,
  UseRedirect,
  UseNotifications,
  useNotifications,
  ContactFormValues,
  UseState,
} from '@chic-loyalty/ui';
import React, { useState } from 'react';
import { IGoogleReCaptchaConsumerProps, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { TransProps, useTranslation } from 'react-i18next';
import { useNavigate, NavigateFunction } from 'react-router-dom';

import { generalContactFormRequest } from '@chic/frontend/api/requests';
import { FullscreenAlertAssets } from '@chic/frontend/enums';
import { useAuth } from '@chic/frontend/hooks';
import { UseAuth } from '@chic/frontend/interfaces';
import { FrontendApiError } from '@chic/frontend/models';
import { RouteNameEnum } from '@chic/shared/enums';
import { getRouteDetailsByName } from '@chic/shared/utils';

import {
  Container,
  Hours,
  GridContainer,
  PhoneBoxes,
  Subtitle,
  Title,
  TopSection,
  Row,
  RowTitle,
  CenterSection,
  RowRight,
  StyledMailBox,
} from './contact.styled';

export const ContactView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { showFullscreenAlert, hideFullscreenAlert }: UseNotifications = useNotifications();
  const { redirect }: UseRedirect = useRedirect();
  const navigate: NavigateFunction = useNavigate();
  const { userData }: UseAuth = useAuth();
  const [isLoading, setIsLoading]: UseState<boolean> = useState<boolean>(false);
  const { executeRecaptcha }: IGoogleReCaptchaConsumerProps = useGoogleReCaptcha();

  const onSubmit: (values: ContactFormValues) => Promise<void> = async (values: ContactFormValues): Promise<void> => {
    setIsLoading(true);
    if (!executeRecaptcha) {
      setIsLoading(false);
      return;
    }
    const recaptchaToken: string = await executeRecaptcha();

    generalContactFormRequest({ ...values, recaptchaToken })
      .then((): void => {
        showFullscreenAlert({
          title: t('chic.website.contactView.success.title'),
          description: t('chic.website.contactView.success.description'),
          acceptButtonSettings: {
            label: t('chic.app.global.close'),
            action: (): void => {
              hideFullscreenAlert();
            }
          },
        });
      })
      .catch((error: FrontendApiError): void => {
        showFullscreenAlert({
          description: error.message,
          iconImage: FullscreenAlertAssets.ErrorIcon,
          acceptButtonSettings: {
            label: t('chic.website.global.ok'),
            action: (): void => {
              hideFullscreenAlert();
            }
          },
        });
      })
      .finally((): void => setIsLoading(false));
  };

  return (
    <Container $withLeftPadding={!!userData}>
      <PageHeader
        header={t('chic.website.contactView.contact')}
        onArrowButtonAction={(): void => navigate(-1)} // TODO: make global hook for this 
      />
      <TopSection>
        <GridContainer>
          <Title>{t('chic.website.contactView.havingAccountProblem')}</Title>
          <Subtitle>{t('chic.website.contactView.callUs')}</Subtitle>
          <PhoneBoxes>
            <PhoneBox phone='+48 800 610 610' title={t('chic.website.contactView.subscriptionHelpline')} />
            <PhoneBox phone='+48 800 610 610' title={t('chic.website.contactView.gloHelpline')} />
            <PhoneBox phone='+48 62 738 88 88' title={t('chic.website.contactView.generalHelpline')} />
          </PhoneBoxes>
          <Hours>{t('chic.website.contactView.helplineHours')}</Hours>
        </GridContainer>
      </TopSection>
      <CenterSection>
        <GridContainer>
          <Row>
            <RowTitle>
              {t('chic.website.contactView.mailSending')}
            </RowTitle>
            <RowRight>
              <StyledMailBox title={t('chic.website.contactView.subscription')} mail='subskrypcja@esmokingworld.com' />
              <StyledMailBox title={t('chic.website.contactView.esmokingClub')} mail='esmokingclub@esmokingworld.com' />
              <StyledMailBox title={t('chic.website.contactView.products')} mail='dostepnosc.produktow@esmokingworld.com' />
            </RowRight>
          </Row>
          <Row>
            <ContactFormSection
              initialValues={userData ? {
                ean: userData.card,
                name: userData.name,
                email: userData.email,
                phone: userData.phone,
              } : undefined}
              onSubmit={onSubmit}
              title={t('chic.website.contactView.contactFormTitle')}
              subtitle={t('chic.website.contactView.contactFormSubtitle')}
              isLoading={isLoading}
            />
          </Row>
        </GridContainer>
      </CenterSection>
      <FindShopsBanner onClick={(): void => redirect(getRouteDetailsByName(RouteNameEnum.FindUs)?.url ?? '')} />
    </Container>
  );
};
