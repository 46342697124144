export function getPathWithParams(path: string, params: Record<string, string>): string {
  const paramsEntries: [string, string][] = Object.entries(params);
  let modifiedPath: string = path;

  if (!paramsEntries.length) {
    return path;
  }

  paramsEntries.forEach(([key, value]: [string, string]): void => {
    modifiedPath = modifiedPath.replace(`:${key}`, value);
  });

  return modifiedPath;
}
