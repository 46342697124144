import { AxiosResponse } from 'axios';

import { FrontendApiError } from '@chic/frontend/models';
import { ApiReject, ApiResolve } from '@chic/frontend/types';
import { SystemConfig } from '@chic/shared/types';

import { api } from '../api';

export const getConfig: () => Promise<SystemConfig> = (): Promise<SystemConfig> => new Promise(
  (resolve: ApiResolve<SystemConfig>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get('/config').then(
      (response: AxiosResponse<SystemConfig>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);
