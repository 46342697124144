import { IconName } from '@chic-loyalty/ui';

import { SubscriptionDeliveryCode } from '@chic/shared/enums';

export function getIconByDeliveryCode(code: SubscriptionDeliveryCode): IconName {
  switch (code) {
    case SubscriptionDeliveryCode.InpostMachine:
      return IconName.DeliveryParcelmachine;
    case SubscriptionDeliveryCode.PickupPoint:
      return IconName.DeliveryPos;
    case SubscriptionDeliveryCode.DpdCourier:
    case SubscriptionDeliveryCode.UpsCourier:
    default:
      return IconName.DeliveryCourier;
  }
}
