import { Color, Breakpoint, StyledComponent } from '@chic-loyalty/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  width: 100%;
  background-color: ${Color.White};
  
  @media ${Breakpoint.Mobile} {
    background-color: ${Color.AlmostBlack};
  }
`;
