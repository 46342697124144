import { 
  UseState, 
  PageHeader, 
  PageHeaderPosition, 
  ProductDetailsBox,
  UseRedirect,
  useRedirect,
  Icon,
  IconName,
  Color,
  UseNotifications,
  useNotifications,
  Attachment,
  useFormatDate, 
  UseFormatDate 
} from '@chic-loyalty/ui';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { getPromotionDetails, unlockPromotion } from '@chic/frontend/api/requests';
import { FullscreenAlertAssets, QueryKey } from '@chic/frontend/enums';
import { useAuth } from '@chic/frontend/hooks';
import { UseAuth } from '@chic/frontend/interfaces';
import { FrontendApiError } from '@chic/frontend/models';
import { RouteNameEnum } from '@chic/shared/enums';
import { Promotion, Regulation, UnlockPromotionStatus } from '@chic/shared/interfaces';
import { getRouteDetailsByName } from '@chic/shared/utils';

import { 
  Container, 
  HeaderWrapper,
  ContentWrapper, 
  Content, 
  ImageBox,
  BadgesWrapper,
  BadgeDateTo,
  BadgeUnlocked,
  Image,
  StyledLoader,
} from './offerDetails.styled';

export const OfferDetailsView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const [offerDetails, setOfferDetails]: UseState<Promotion | null> = useState<Promotion | null>(null);
  const [regulations, setRegulations]: UseState<Attachment[]> = useState<Attachment[]> ([]);
  const { showFullscreenAlert, hideFullscreenAlert }: UseNotifications = useNotifications();
  const { userData, updateUserData }: UseAuth = useAuth();
  const { redirect }: UseRedirect = useRedirect();
  const { id: offerId }: Readonly<Record<string, string | undefined>> = useParams();
  const { getElapsedTime }: UseFormatDate = useFormatDate();

  useQuery<Promotion, FrontendApiError>(
    [QueryKey.PromotionsDetails],
    (): Promise<Promotion> => getPromotionDetails(Number(offerId)),
    {
      onSuccess: (data: Promotion) => {
        const offerRegulations: Attachment[] = data.regulations.map((regulation: Regulation): Attachment => ({
          name: regulation.name,
          externalUrl: regulation.path,
          shouldOpenInNewTab: true,
          size: regulation.size
        }));

        setRegulations(offerRegulations);
        setOfferDetails(data);
      },
      onError: (error: FrontendApiError): void => {
        if (error.responseCode === 404) {
          showFullscreenAlert({
            description: t('chic.website.offerDetails.offerNotFound'),
            iconImage: FullscreenAlertAssets.ErrorIcon,
            acceptButtonSettings: {
              label: t('chic.website.global.ok'),
              action: (): void => {
                hideFullscreenAlert();
                redirect(getRouteDetailsByName(RouteNameEnum.Offers)?.url ?? '/');
              }
            },
          });
        }
      },
      retry: false,
    }
  );

  const unlockAction: () => void = (): void => {
    showFullscreenAlert({
      title: t('chic.website.offerDetailsView.unlockAlert.title'),
      description: t('chic.website.offerDetailsView.unlockAlert.description', { value: offerDetails?.cost ?? 0 }),
      iconImage: FullscreenAlertAssets.SuccessIcon,
      acceptButtonSettings: {
        label: t('chic.app.global.confirm'),
        action: (): void => {
          hideFullscreenAlert();
          if (offerDetails?.id) {
            unlockPromotion(offerDetails.id)
              .then((response: UnlockPromotionStatus): void => {
                updateUserData({ points: response.userPointsAfterUnlock });
                setOfferDetails({ ...offerDetails, amount: +offerDetails.amount + 1 });
                showUnlockConfirmation(response.userPointsAfterUnlock);
              })
              .catch((error: FrontendApiError): void => showUnlockError(error.message));
          }
        },
      },
      cancelButtonSettings: {
        label: t('chic.app.global.cancel'),
        action: hideFullscreenAlert,
      },
    });
  };

  const showUnlockConfirmation = (points: number): void => {
    showFullscreenAlert({
      title: t('chic.website.offerDetailsView.offerUnlocked'),
      description: t('chic.website.offerDetailsView.afterUnlockPoints', { value: points }),
      iconImage: FullscreenAlertAssets.SuccessIcon,
      acceptButtonSettings: {
        label: t('chic.website.global.ok'),
        action: hideFullscreenAlert
      },
    });
  };

  const showUnlockError = (error: string): void => {
    showFullscreenAlert({
      title: t('chic.website.offerDetailsView.offerUnlockError'),
      description: error,
      iconImage: FullscreenAlertAssets.SuccessIcon,
      acceptButtonSettings: {
        label: t('chic.website.global.ok'),
        action: hideFullscreenAlert
      },
    });
  };

  return (
    <Container>
      <HeaderWrapper>
        <PageHeader
          header={offerDetails?.name ?? ''}
          headerPosition={PageHeaderPosition.Left}
          onArrowButtonAction={(): void => redirect(getRouteDetailsByName(RouteNameEnum.Offers)?.url ?? '/')}
        />
      </HeaderWrapper>
      <ContentWrapper>
        {!offerDetails && (
          <StyledLoader />
        )}
        {offerDetails && userData && (
          <Content>
            <ImageBox>
              <BadgesWrapper>
                <BadgeDateTo>
                  {t('chic.website.offerDetailsView.dateTo', { value: getElapsedTime(dayjs(offerDetails?.dateTo).unix(), true) })}
                </BadgeDateTo>
                {(offerDetails.unlocked || !offerDetails.cost) &&
                  <BadgeUnlocked>{t('chic.website.offerDetailsView.offerActive')}</BadgeUnlocked>
                }
              </BadgesWrapper>
              {offerDetails.image ? (
                <Image src={offerDetails.image} alt={offerDetails.name} />
              ) : (
                <Icon name={IconName.Photo} size={44} color={Color.Whisper} />
              )} 
            </ImageBox>
            <ProductDetailsBox 
              points={offerDetails.cost ?? 0} 
              description={offerDetails.description ?? ''} 
              canUnlock={!offerDetails.unlocked}
              unlockQuantity={offerDetails.amount}
              unlockButtonAction={unlockAction}
              numberOfPointsNedded={(offerDetails.cost ?? 0) - (userData.points ?? 0)}
              attachments={regulations}
            />
          </Content>
        )}       
      </ContentWrapper>
    </Container>
  );
};
