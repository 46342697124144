export enum SurveyQuestionConditionType {
  VisibleAfterAnswer = 'visible_after_answer',
  HiddenAfterAnswer = 'hidden_after_answer',
  VisibleAfterAnswerEqual = 'visible_after_answer_equal',
  HiddenAfterAnswerEqual = 'hidden_after_answer_equal',
  VisibleAfterAnswerLessThan = 'visible_after_answer_less_than',
  HiddenAfterAnswerLessThan = 'hidden_after_answer_less_than',
  VisibleAfterAnswerGreaterThan = 'visible_after_answer_greater_than',
  HiddenAfterAnswerGreaterThan = 'hidden_after_answer_greater_than',
}
