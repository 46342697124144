import { Breakpoint, Color, FontWeight, Grid, StyledComponent } from '@chic-loyalty/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  background-color: ${Color.AlmostBlack};
  width: calc(100% - 80px);
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${Breakpoint.Mobile} {
    width: 100%;
  }
`;

export const InnerContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  max-width: ${Grid.Desktop};
  margin: 20px;

  @media ${Breakpoint.Tablet} {
    flex-direction: column;
    padding: 20px 0 50px;
  }
`;

export const LeftText: StyledComponent<'div'> = styled.div`
  max-width: 460px;

  @media ${Breakpoint.Mobile} {
    padding: 0 20px;
  }
`;

export const BigText: StyledComponent<'h1'> = styled.h1`
  font-weight: ${FontWeight.Medium};
  font-size: 39px;
  line-height: 47px;
  letter-spacing: -0.03em;
  color: ${Color.SolidHighEmphasis};
  margin-bottom: 20px;

  @media ${Breakpoint.Mobile} {
    font-size: 31px;
    line-height: 40px;
    letter-spacing: -0.02em;
  }
`;

export const Text: StyledComponent<'p'> = styled.p`
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.4px;
  color: ${Color.SolidMediumEmphasis};

  @media ${Breakpoint.Mobile} {
    font-size: 12px;
    line-height: 19px;
  }
`;

export const DevicesText: StyledComponent<'p'> = styled(Text)`
  color: ${Color.Tangerine};
`;

export const EngraversImage: StyledComponent<'img'> = styled.img`
  width: 550px;

  @media ${Breakpoint.Tablet} {
    width: 375px;
    margin-top: 20px;
  }
`;
