import { IsDefined } from 'class-validator';

import { PayuOrder } from './payuOrder.model';

// TODO: check after implementation
export class PayuOrderStatus {
  @IsDefined()
  public order: PayuOrder;

  @IsDefined()
  public properties: unknown;

  constructor(
    order: PayuOrder,
    properties: unknown,
  ) {
    this.order = order;
    this.properties = properties;
  }
}
