import { Color, FontWeight, Breakpoint, StyledComponent, Grid } from '@chic-loyalty/ui';
import styled from 'styled-components';

import { StyledBoxesContainerProps } from './offers.types';

export const Container: StyledComponent<'div'> = styled.div`
  background-color: ${Color.Level1};
  width: calc(100% - 80px);
  margin-left: auto;
  min-height: calc(100vh - 293px);

  @media ${Breakpoint.Mobile} {
    width: 100%;
  }
`;

export const BoxesContainer: StyledComponent<'div', StyledBoxesContainerProps> = styled.div<StyledBoxesContainerProps>`
  width: 100%;
  background-color: ${({ $backgroundColor }: StyledBoxesContainerProps): Color => $backgroundColor ?? Color.Level1};
  padding: 0 20px;
`;

export const BoxesRow: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  max-width: ${Grid.Desktop};
  margin: 0 auto;
  padding: 50px 0;
  background: ${Color.Transparent};

  @media ${Breakpoint.Mobile} {
    gap: 35px;
    padding: 70px 20px;
  }
`;

export const BoxesHeader: StyledComponent<'div'> = styled.div`
  font-size: 16px;
  font-weight: ${FontWeight.Medium};
  color: ${Color.SolidHighEmphasis};
`;

export const BoxesHeaderCount: StyledComponent<'span'> = styled.span`
  position: relative;
  font-size: 12px;
  color: ${Color.Tangerine};
  margin-left: 18px;

  &:before {
    position: absolute;
    top: 1px;
    left: -8px;
    content: "(";
  }

  &:after {
    position: absolute;
    top: 1px;
    right: -8px;
    content: ")";
  }
`;

export const EmptyStateContainer: StyledComponent<'div'> = styled.div`
  padding: 100px 20px 100px 100px;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${Breakpoint.Mobile} {
    padding: 100px 20px;
  }
`;

export const EmptyStateImg: StyledComponent<'img'> = styled.img`
  max-height: 140px;
  width: auto;

  @media ${Breakpoint.Mobile} {
    max-height: 120px;
  }
`;

export const EmptyStateMessage: StyledComponent<'div'> = styled.div`
  font-weight: ${FontWeight.Medium};
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: ${Color.SolidLowEmphasis};
  margin-top: 50px;
`;
