import {
  StyledComponent,
  Color,
  Breakpoint,
  MailToUs,
  Button,
  SearchDeliveryListItem,
  hexToRgba,
  disableScroll
} from '@chic-loyalty/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: calc(100% - 80px);
  background-color: ${Color.Black};
  margin-left: auto;
  position: relative;
  height: 100%;

  @media ${Breakpoint.Mobile} {
    width: 100%;
  }
`;

export const InnerContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;

  @media ${Breakpoint.Tablet} {
    height: 100%;
  }
`;

export const MapContainer: StyledComponent<'div'> = styled.div`
  height: 700px;
  width: 651px;
  margin-left: 117px;
  flex-shrink: 0;

  @media ${Breakpoint.Desktop} {
    margin-left: 60px;
    width: 50%;
  }
`;

export const SearchContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 160px;
  padding-top: 50px;
  box-sizing: border-box;
  position: relative;

  @media ${Breakpoint.Desktop} {
    padding: 50px 0 50px 60px;
  }

  @media ${Breakpoint.Tablet} {
    padding: 50px 60px;
    height: calc(100vh - 200px);
  }

  @media ${Breakpoint.Mobile} {
    padding: 35px 20px;
    height: calc(100vh - 220px);
  }
`;

export const ListContainer: StyledComponent<'div'> = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  ${disableScroll};
`;

export const OuterListContainer: StyledComponent<'div'> = styled.div`
  position: relative;
  max-height: 268px;
  height: 100%;
  display: flex;

  @media ${Breakpoint.Tablet} {
    max-height: 600px;
  }
`;

export const StyledSearchDeliveryListItem: StyledComponent<typeof SearchDeliveryListItem> = styled(SearchDeliveryListItem)`
  display: flex;
  padding-top: 25px;
  padding-bottom: 21px;

  & + & {
    padding-top: 20px;
    border-top: 1px solid ${hexToRgba(Color.White, 0.12)};
  }
`;

export const StyledMailToUs: StyledComponent<typeof MailToUs> = styled(MailToUs)`
  margin-top: 25px;
  padding: 10px 25px;
  margin-bottom: 50px;
  position: relative;
  bottom: 0;

  @media ${Breakpoint.Mobile} {
    margin-bottom: 35px;
  }
`;

export const StyledButton: StyledComponent<typeof Button> = styled(Button)`
  flex-shrink: 0;
`;
