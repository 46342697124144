import { IsDefined, IsEnum, IsString } from 'class-validator';

import { UserContactDataType } from '@chic/shared/enums';

export class PasswordRecoveryInitRequest {
  @IsDefined()
  @IsEnum(UserContactDataType)
  public type: UserContactDataType;

  @IsDefined()
  @IsString()
  public value: string;

  constructor(
    type: UserContactDataType,
    value: string,
  ) {
    this.type = type;
    this.value = value;
  }
}
