import { UseLocalStorage, useLocalStorage } from '@chic-loyalty/ui';
import React, { useMemo, useReducer } from 'react';

import { AuthContext, AuthDispatchContext } from '@chic/frontend/contexts';
import { LocalStorageKey } from '@chic/frontend/enums';
import { authReducer } from '@chic/frontend/reducers';
import { AuthContextData } from '@chic/frontend/types';
import { AuthContextState } from '@chic/shared/interfaces';

import { AuthContextProviderProps } from './authContextProvider.types';

export const AuthContextProvider: React.FC<AuthContextProviderProps> = (props: AuthContextProviderProps): JSX.Element => {
  const { children, userSSRData }: AuthContextProviderProps = props;
  const [authData]: UseLocalStorage<AuthContextState> = useLocalStorage<AuthContextState>(
    LocalStorageKey.AuthData, { userData: null, token: null }
  );
  const [authState, authDispatch]: AuthContextData = useReducer(
    authReducer,
    authData
      ? { userData: authData.userData, token: authData.token }
      : { userData: null, token: null },
  );
  const valueForAuthContext: AuthContextState = useMemo(
    (): AuthContextState => {
      return {
        ...authState,
        ...(userSSRData?.userData
          ? { token: userSSRData.userData.contextToken, userData: userSSRData.userData }
          : {}
        )
      };
    },
    [authState, userSSRData]
  );

  return (
    <AuthDispatchContext.Provider value={authDispatch}>
      <AuthContext.Provider value={valueForAuthContext}>
        {children}
      </AuthContext.Provider>
    </AuthDispatchContext.Provider>
  );
};
