import {
  ActionBoxButtonType,
  ButtonTheme,
  IconName,
  SectionHeader,
  SubscriptionPlanAsset,
  UseState,
  UseTransformations,
  useTransformations,
} from '@chic-loyalty/ui';
import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { getSubscriptionsPlans } from '@chic/frontend/api/requests';
import { BaseViewWithEditProducts } from '@chic/frontend/components';
import { QueryKey } from '@chic/frontend/enums';
import { UseSubscriptionEditProducts } from '@chic/frontend/interfaces';
import { UseSearchParams } from '@chic/frontend/types';
import { SubscriptionPlan, SubscriptionPlanGroup, SubscriptionProductInfo } from '@chic/shared/interfaces';
import { IdValueObject } from '@chic/shared/models';

import { useSubscriptionEditProducts } from '../hooks';

import {
  Container,
  Content,
  ContentWrapper,
  ContentWrapperStepTwo,
  DetailsBox,
  StyledActionBox,
  StyledSubscriptionPlanSummaryBox,
  StyledTransactionProductBox,
} from './subscriptionPlanUpgradeEditProducts.styled';

export const SubscriptionPlanUpgradeEditProductsView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const [subscriptionPlan, setSubscriptionPlan]: UseState<SubscriptionPlan | undefined> = useState<SubscriptionPlan | undefined>();
  const {
    goBack,
    stepNumber,
    onSaveButtonAction,
    setSearchedProductName,
    cancelEdit,
    missingGroupsItemsNames,
    getProductMaxValue,
    filteredSubscriptionProducts,
    chosenProductsSummary,
    chosenProducts,
    isEditValid,
    setChosenProducts,
    setProductMaxCount,
    isSubmitRequestLoading,
  }: UseSubscriptionEditProducts = useSubscriptionEditProducts(subscriptionPlan, true);
  const [searchParams]: UseSearchParams = useSearchParams();
  const planId: string = useMemo((): string => searchParams.get('planId') ?? '', [searchParams]);
  const { getSubscriptionAssetByType }: UseTransformations = useTransformations();
  
  useQuery(
    [QueryKey.SubscriptionPlans, planId],
    (): Promise<SubscriptionPlan[]> => getSubscriptionsPlans(),
    {
      enabled: !!planId,
      onSuccess: (data: SubscriptionPlan[]): void => {
        const chosenProductsCopy: Record<number, IdValueObject<number, number>[]> = {};
        const productMaxCountCopy: Record<number, number> = {};
        const newSubscriptionPlan: SubscriptionPlan | undefined = data
          .find((plan: SubscriptionPlan | undefined) => plan?.id === parseInt(planId ?? '', 10));
        newSubscriptionPlan?.groups.forEach((group: SubscriptionPlanGroup): void => {
          productMaxCountCopy[group.id] = group.amount;
          chosenProductsCopy[group.id] = [];
        });
        setProductMaxCount(productMaxCountCopy);
        setChosenProducts(chosenProductsCopy);
        setSubscriptionPlan(newSubscriptionPlan);
      },
      // TODO: add logger
      onError: (): void => undefined
    }
  );

  const subscriptionPlanGroups: string = useMemo(
    (): string => {
      if (!subscriptionPlan?.groups) {
        return '';
      }

      return subscriptionPlan.groups.map((group: SubscriptionPlanGroup): string => `${group.amount} ${group.name}`)
        .join(', ');
    },
    [subscriptionPlan]
  );

  return (
    <Container>
      <ContentWrapper>
        {subscriptionPlan && (
          <BaseViewWithEditProducts
            setChosenProducts={setChosenProducts}
            setSearchedProductName={setSearchedProductName}
            getProductMaxValue={getProductMaxValue}
            subscriptionPlan={subscriptionPlan}
            filteredSubscriptionProducts={filteredSubscriptionProducts}
            cancelEdit={cancelEdit}
            missingGroupsItemsNames={missingGroupsItemsNames}
            stepNumber={stepNumber}
            isEditValid={isEditValid}
            onSaveButtonAction={onSaveButtonAction}
            goBack={goBack}
          />
        )}
      </ContentWrapper>
      {stepNumber === 2 && subscriptionPlan && (
        <ContentWrapperStepTwo>
          <Content>
            <StyledSubscriptionPlanSummaryBox
              image={getSubscriptionAssetByType(subscriptionPlan.assets, 'packshot', 'ic')}
              planName={subscriptionPlan.name ?? ''}
              planType={subscriptionPlan.type ?? ''}
              planDescription={subscriptionPlanGroups}
              planPrice={subscriptionPlan.price.current ?? 0}
              planIcon={subscriptionPlan.assets.find((asset: SubscriptionPlanAsset): boolean => asset.type === 'icon')?.path ?? ''}
            />
            <DetailsBox>
              <SectionHeader title={t('chic.website.subscriptionPlanUpgradeEditProductsView.sectionHeader.title')} />
              {chosenProductsSummary.map((product: SubscriptionProductInfo): JSX.Element => (
                <StyledTransactionProductBox
                  key={product.id}
                  name={product.name}
                  image={product.photos[0]}
                  category={product.category?.name}
                  amount={
                    Object.values(chosenProducts).flat()
                      .find((chosenProduct: IdValueObject<number, number>): boolean => chosenProduct.id === product.id)?.value
                  }
                />
              ))}
            </DetailsBox>
            <StyledActionBox
              iconName={IconName.BigAlert}
              text={t('chic.website.subscriptionPlanUpgradeEditProductsView.actionBox.text')}
              buttonSettings={{
                buttonTheme: ButtonTheme.PrimaryOrange,
                label: t('chic.website.subscriptionPlanUpgradeEditProductsView.actionBox.buttonLabel'),
                action: onSaveButtonAction,
                isLoading: isSubmitRequestLoading,
              }}
              buttonType={ActionBoxButtonType.Simple}
            />
          </Content>
        </ContentWrapperStepTwo>
      )}
    </Container>
  );
};
