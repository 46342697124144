import { IconName } from '@chic-loyalty/ui';

import { NotFoundMenuPositions } from '@chic/frontend/interfaces';
import { RouteNameEnum } from '@chic/shared/enums';
import { getRouteDetailsByName } from '@chic/shared/utils';

export const menuPositions: NotFoundMenuPositions[] = [
  {
    label: 'chic.website.menuPositions.home.title',
    internalPath: getRouteDetailsByName(RouteNameEnum.Home)?.url,
    icon: IconName.Home,
    shouldDisplayOnlyForGuests: true
  },
  {
    label: 'chic.website.menuPositions.dashboard.title',
    internalPath: getRouteDetailsByName(RouteNameEnum.Dashboard)?.url,
    icon: IconName.Home,
  },
  {
    label: 'chic.website.menuPositions.carePlus.title',
    internalPath: getRouteDetailsByName(RouteNameEnum.CarePlus)?.url,
    icon: IconName.CarePlus,
  },
  {
    label: 'chic.website.menuPositions.rewards.title',
    internalPath: getRouteDetailsByName(RouteNameEnum.Products)?.url,
    icon: IconName.Rewards,
  },
  {
    label: 'chic.website.menuPositions.transactions.title',
    internalPath: getRouteDetailsByName(RouteNameEnum.TransactionsList)?.url,
    icon: IconName.History,
  },
  {
    label: 'chic.website.menuPositions.profile.title',
    internalPath: getRouteDetailsByName(RouteNameEnum.ProfileYourData)?.url,
    icon: IconName.Account,
  },
  {
    label: 'chic.website.menuPositions.contact.title',
    externalUrl: getRouteDetailsByName(RouteNameEnum.Contact)?.url,
    icon: IconName.Email,
    shouldDisplayAlways: true
  },
  {
    label: 'chic.website.menuPositions.signIn.title',
    externalUrl: getRouteDetailsByName(RouteNameEnum.SignIn)?.url,
    icon: IconName.Email,
    shouldDisplayOnlyForGuests: true
  },
];
