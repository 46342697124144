import { Color, FontWeight, Breakpoint, StyledComponent, Markdown, Grid, Loader } from '@chic-loyalty/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 293px);
  width: calc(100% - 80px);
  background-color: ${Color.Level1};
  margin-left: auto;

  @media ${Breakpoint.Mobile} {
    width: 100%;
  }
`;

export const PostMainImage: StyledComponent<'img'> = styled.img`
  height: clamp(196px, 50vw, 300px);
  width: 100%;
  max-width: ${Grid.Desktop};
  object-fit: cover;
  object-position: center;
  margin: 70px auto 0;

  @media ${Breakpoint.Tablet} {
    margin: 35px auto 0;
  }

  @media ${Breakpoint.Mobile} {
    padding: 0;
    margin: 0 auto;
  }
`;

export const PostWrapper: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 100%;
  max-width: 836px;
  margin: 0 auto;
  padding: 50px 20px 100px;
`;

export const Content: StyledComponent<'div'> = styled.div`
  width: 100%;
  background-color: ${Color.Level1};
`;

export const Header: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;

  @media ${Breakpoint.Mobile} {
    gap: 15px;
  }
`;

export const ArrowButton: StyledComponent<'button'> = styled.button`
  margin-right: auto;
  margin-bottom: 10px;
`;

export const Title: StyledComponent<'div'> = styled.div`
  line-height: 46.8px;
  letter-spacing: -3%;
  color: ${Color.SolidHighEmphasis};
  position: relative;
  white-space: break-spaces;
  font-size: 39px;

  @media ${Breakpoint.Mobile} {
    font-size: 25px;
    line-height: 35px;
    letter-spacing: -1%;
  }
`;

export const Label: StyledComponent<'div'> = styled.div`
  border: 1px solid ${Color.SolidLowEmphasis};
  border-radius: 6px;
  padding: 5px 9px;
  font-weight: ${FontWeight.Bold};
  font-size: 7px;
  line-height: 9px;
  letter-spacing: 0.44px;
  text-transform: uppercase;
  color: ${Color.SolidMediumEmphasis};
  width: max-content;
`;

export const PostContentBox: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

export const PostText: StyledComponent<typeof Markdown> = styled(Markdown)`
  display: flex;
  flex-direction: column;
  gap: 25px;
  letter-spacing: 0.4px;

  p {
    font-size: 16px;
    line-height: 24px;
    font-weight: ${FontWeight.Regular};
    color: ${Color.SolidMediumEmphasis};
  }

  a {
    color: ${Color.Tangerine};
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 25px;
    font-size: 16px;
    line-height: 24px;
    font-weight: ${FontWeight.Regular};
    color: ${Color.SolidMediumEmphasis};
    list-style: disc;
    padding-left: 18px;
  }

  ul + p {
    margin-top: 40px;
  }

  strong {
    font-weight: ${FontWeight.Bold};
  }
`;

export const PostImagesBox: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const PostImagesButton: StyledComponent<'button'> = styled.button`
  display: flex;
  flex-direction: column;
  gap: 25px;
  appearance: none;
`;

export const PostImg: StyledComponent<'img'> = styled.img`
  width: 100%;
`;

export const StyledLoader: StyledComponent<typeof Loader> = styled(Loader)`
  position: absolute;
  left: calc(50% - 35px);
  top: calc(50% - 35px);
`;
