import { IsDefined, IsIP, IsOptional, IsString } from 'class-validator';

import { SendStatsForInitPwaRequest } from './sendStatsForInitPwaRequest.model';

export class SendStatsForInitPwaCoreRequest extends SendStatsForInitPwaRequest {
  @IsDefined()
  @IsIP()
  public ip: string;

  @IsOptional()
  @IsString()
  public contextToken?: string;

  @IsOptional()
  @IsString()
  public userAgent?: string;

  constructor(
    fingerPrint: string,
    ip: string,
    contextToken?: string,
    userAgent?: string,
  ) {
    super(fingerPrint);
    this.ip = ip;
    this.contextToken = contextToken;
    this.userAgent = userAgent;
  }
}
