import { AxiosResponse } from 'axios';
import { stringify } from 'query-string';

import { FrontendApiError } from '@chic/frontend/models';
import { ApiReject, ApiResolve } from '@chic/frontend/types';
import {
  AgreementData,
  ConfirmUnverifiedEmailData,
  CustomerStatusWithUserData,
  UserWithToken,
  VerificationStatus
} from '@chic/shared/interfaces';
import {
  ConfirmContactDataRequest,
  PasswordRecoveryInitRequest,
  RegisterRequest,
  SetPasswordAfterRecoveryRequest,
  ChangePasswordRequest,
  UpdateProfileRequest,
  UpdateUserAgreementsRequest,
  ChangeUnverifiedUserEmailRequest,
  UpdateUserPreferencesRequest,
  ConfirmUnverifiedEmailRequest,
} from '@chic/shared/models';

import { api } from '../api';

export const registerRequest: (data: RegisterRequest) => Promise<UserWithToken | void> = (
  data: RegisterRequest
): Promise<UserWithToken | void> => new Promise(
  (resolve: ApiResolve<UserWithToken | void>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.post('/customer/register', data).then(
      (response: AxiosResponse<UserWithToken | void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const initPasswordRecoveryRequest: (data: PasswordRecoveryInitRequest) => Promise<string | void> = (
  data: PasswordRecoveryInitRequest
): Promise<string | void> => new Promise(
  (resolve: ApiResolve<string | void>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.post('/customer/password-recovery/init', data).then(
      (response: AxiosResponse<string | void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getAgreements: () => Promise<AgreementData[]> = (): Promise<AgreementData[]> => new Promise(
  (resolve: ApiResolve<AgreementData[]>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get('/customer/agreements').then(
      (response: AxiosResponse<AgreementData[]>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getContactDataVerificationStatus: (ean: string, token: string) => Promise<VerificationStatus> = (
  ean: string, token: string
): Promise<VerificationStatus> => new Promise(
  (resolve: ApiResolve<VerificationStatus>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get(`/customer/verification/${ean}?${stringify({ token }, { skipEmptyString: true })}`).then(
      (response: AxiosResponse<VerificationStatus>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const confirmContactData: (data: ConfirmContactDataRequest) => Promise<CustomerStatusWithUserData> = (
  data: ConfirmContactDataRequest
): Promise<CustomerStatusWithUserData> => new Promise(
  (resolve: ApiResolve<CustomerStatusWithUserData>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.post('/customer/confirm-contact-data', data).then(
      (response: AxiosResponse<CustomerStatusWithUserData>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const setPasswordAfterRecovery: (data: SetPasswordAfterRecoveryRequest) => Promise<UserWithToken> = (
  data: SetPasswordAfterRecoveryRequest
): Promise<UserWithToken> => new Promise(
  (resolve: ApiResolve<UserWithToken>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.post('/customer/password-recovery/set-password', data).then(
      (response: AxiosResponse<UserWithToken>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const setNewPassword: (data: ChangePasswordRequest) => Promise<UserWithToken> = (
  data: ChangePasswordRequest
): Promise<UserWithToken> => new Promise(
  (resolve: ApiResolve<UserWithToken>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.post('/customer/password', data).then(
      (response: AxiosResponse<UserWithToken>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getProfileData: () => Promise<UserWithToken> = (): Promise<UserWithToken> => new Promise(
  (resolve: ApiResolve<UserWithToken>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get('/customer/profile').then(
      (response: AxiosResponse<UserWithToken>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const setProfileData: (data: UpdateProfileRequest) => Promise<UserWithToken> = (
  data: UpdateProfileRequest
): Promise<UserWithToken> => new Promise(
  (resolve: ApiResolve<UserWithToken>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.patch('/customer/profile', data).then(
      (response: AxiosResponse<UserWithToken>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const setAgreements: (data: UpdateUserAgreementsRequest) => Promise<UserWithToken> = (
  data: UpdateUserAgreementsRequest
): Promise<UserWithToken> => new Promise(
  (resolve: ApiResolve<UserWithToken>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.post('/customer/agreements', data).then(
      (response: AxiosResponse<UserWithToken>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const changeUnverifiedUserEmail: (data: ChangeUnverifiedUserEmailRequest) => Promise<void> = (
  data: ChangeUnverifiedUserEmailRequest
): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.post('/customer/change-email', data).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const updateUserPreferences: (data: UpdateUserPreferencesRequest) => Promise<UserWithToken> = (
  data: UpdateUserPreferencesRequest
): Promise<UserWithToken> => new Promise(
  (resolve: ApiResolve<UserWithToken>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.post('/customer/preferences', data).then(
      (response: AxiosResponse<UserWithToken>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getConfirmUnverifiedEmailData: (userHash: string, authHash: string) => Promise<ConfirmUnverifiedEmailData> = (
  userHash: string,
  authHash: string
): Promise<ConfirmUnverifiedEmailData> => new Promise(
  (resolve: ApiResolve<ConfirmUnverifiedEmailData>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get(`/customer/confirm-email-data?${stringify({ userHash, authHash }, { skipEmptyString: true })}`).then(
      (response: AxiosResponse<ConfirmUnverifiedEmailData>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const setConfirmUnverifiedUserEmail: (data: ConfirmUnverifiedEmailRequest) => Promise<void> = (
  data: ConfirmUnverifiedEmailRequest
): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.post('/customer/confirm-email', data).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getUrlForEscUserMigrateToIc: () => Promise<string> = (): Promise<string> => new Promise(
  (resolve: ApiResolve<string>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get('/customer/ic-migration-url').then(
      (response: AxiosResponse<string>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getUrlForEscUserMigrateToIcWithoutAuth: (userHash: string, authHash: string) => Promise<string> = (
  userHash: string, authHash: string
): Promise<string> => new Promise(
  (resolve: ApiResolve<string>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get(`/customer/ic-migration-url?${stringify({ userHash, authHash }, { skipEmptyString: true })}`).then(
      (response: AxiosResponse<string>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);
