import { 
  Button, 
  ButtonSize, 
  ButtonTheme, 
  InputTheme, 
  UseFormikForm, 
  UseNotifications, 
  UseRedirect, 
  UseState, 
  useFormikForm, 
  useNotifications, 
  useRedirect
} from '@chic-loyalty/ui';
import { useQuery } from '@tanstack/react-query';
import { Formik, FormikProps } from 'formik';
import React, { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { getConfirmUnverifiedEmailData, setConfirmUnverifiedUserEmail } from '@chic/frontend/api/requests';
import { FileFromViews, FullscreenAlertAssets, QueryKey } from '@chic/frontend/enums';
import { FrontendApiError } from '@chic/frontend/models';
import { RouteNameEnum } from '@chic/shared/enums';
import { ConfirmUnverifiedEmailData } from '@chic/shared/interfaces';
import { ConfirmUnverifiedEmailRequest } from '@chic/shared/models';
import { getRouteDetailsByName } from '@chic/shared/utils';

import { useConfirmUnverifiedEmailValidation } from './confirmEmail.hooks';
import { 
  Container, 
  ContentWrapper, 
  Content, 
  HeadingWrapper, 
  Heading, 
  HeadingText,
  StepsWrapper,
  StepWrapper,
  StepHeading,
  StepText,
  StepTextStrong,
  FormikForm,
  StyledInput,
  ImageWrapper, 
  Image 
} from './confirmEmail.styled';

export const ConfirmEmailView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const [emailValue, setEmailValue]: UseState<string> = useState<string>('');
  const [isApiSucceed, setIsApiSucceed]: UseState<boolean> = useState<boolean>(false);
  const { customerHash, authHash }: Readonly<Record<string, string | undefined>> = useParams();
  const { 
    setFormSubmitted, 
    isFormSubmitted,
    isApiRequestInProgress, 
    setApiRequestStart, 
    setApiRequestEnd 
  }: UseFormikForm = useFormikForm();
  const ConfirmUnverifiedEmailValidationSchema: Yup.SchemaOf<ConfirmUnverifiedEmailRequest>
    = useConfirmUnverifiedEmailValidation();
  const { addToast, showFullscreenAlert, hideFullscreenAlert }: UseNotifications = useNotifications();
  const { redirect }: UseRedirect = useRedirect();
  
  useQuery(
    [QueryKey.TechnicalConfirmEmail],
    (): Promise<ConfirmUnverifiedEmailData> => getConfirmUnverifiedEmailData(customerHash ?? '', authHash ?? ''),
    {
      onSuccess: (data: ConfirmUnverifiedEmailData): void => {
        setEmailValue(data.value);
      },
      // TODO: add logger
      onError: (error: FrontendApiError): void => {
        showFullscreenAlert({
          description: t(error.message),
          iconImage: FullscreenAlertAssets.ErrorIcon,
          acceptButtonSettings: {
            label: t('chic.website.global.ok'),
            action: (): void => {
              hideFullscreenAlert();
              redirect(getRouteDetailsByName(RouteNameEnum.Home)?.url ?? '/');
            },
          },
        });
      },
    }
  );

  const onSubmit: (data: ConfirmUnverifiedEmailRequest) => void = (data: ConfirmUnverifiedEmailRequest): void => {
    setApiRequestStart();
    setConfirmUnverifiedUserEmail(data)
      .then((): void => {
        setIsApiSucceed(true);
      })
      .catch((error: FrontendApiError): void => addToast({ content: error.message }))
      .finally((): void => setApiRequestEnd());
  };

  return (
    <Container>
      <ContentWrapper>
        <Content>
          <HeadingWrapper>
            <Heading>{t('chic.website.confirmEmailView.heading')}</Heading>
            <HeadingText>{t('chic.website.confirmEmailView.headingText')}</HeadingText>
          </HeadingWrapper>
          <StepsWrapper>
            <StepWrapper>
              <StepHeading>{t('chic.website.global.firstStep')}</StepHeading>
              <StepText text={t('chic.website.confirmEmailView.firstStep')} />
              <Formik
                initialValues={{
                  value: emailValue,
                  userHash: customerHash ?? '',
                  authHash: authHash ?? ''
                }}
                onSubmit={onSubmit}
                validationSchema={ConfirmUnverifiedEmailValidationSchema}
                validateOnChange={isFormSubmitted}
                validateOnBlur={isFormSubmitted}
                enableReinitialize
              >
                {({ handleSubmit, setFieldValue, errors, values }: FormikProps<ConfirmUnverifiedEmailRequest>) => (
                  <FormikForm onSubmit={handleSubmit}>
                    <StyledInput 
                      label={t('chic.website.global.email')} 
                      value={values.value}
                      onChange={(value: string): void => setFieldValue('value', value)}
                      message={errors.value ? errors.value : isApiSucceed ? t('chic.website.confirmEmailView.congrats') : undefined}
                      inputTheme={errors.value 
                        ? InputTheme.Error 
                        : isApiSucceed 
                          ? InputTheme.Success 
                          : InputTheme.Standard
                      }
                    />
                    {!isApiSucceed && (
                      <Button 
                        label={t('chic.website.global.save')} 
                        buttonTheme={ButtonTheme.PrimaryWhite} 
                        size={ButtonSize.Small} 
                        type='submit'
                        onClick={setFormSubmitted}
                        isLoading={isApiRequestInProgress}
                      />
                    )}
                  </FormikForm>
                )}
              </Formik>
            </StepWrapper>
            <StepWrapper>
              <StepHeading>{t('chic.website.global.secondStep')}</StepHeading>
              <StepText text={t('chic.website.confirmEmailView.secondStep')} />
            </StepWrapper>
            <StepWrapper>
              <StepHeading>{t('chic.website.global.thirdStep')}</StepHeading>
              <StepTextStrong text={t('chic.website.confirmEmailView.thirdStep')} />
            </StepWrapper>
          </StepsWrapper>
        </Content>
      </ContentWrapper>
      <ImageWrapper>
        <Image src={FileFromViews.ConfirmMailBackground} />
      </ImageWrapper>
    </Container>
  );
};
