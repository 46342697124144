import { Country, getRegexForPhone } from '@chic-loyalty/ui';
import { TransProps, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { PasswordRecoveryData } from './remindPassword.types';

export const useRemindPasswordValidation: (
  isPhoneSelected: boolean, country: Country
) => Yup.SchemaOf<PasswordRecoveryData>
= (
  isPhoneSelected: boolean, country: Country
): Yup.SchemaOf<PasswordRecoveryData> => {
  const { t }: TransProps<never> = useTranslation();

  return Yup.object().shape({
    contact: isPhoneSelected
      ? Yup.string()
        .required(t('chic.website.global.requiredField'))
        .matches(getRegexForPhone(country), t('chic.website.global.wrongValue'))
      : Yup.string()
        .required(t('chic.website.global.requiredField'))
        .email(t('chic.website.global.wrongFormat')),
  });
};
