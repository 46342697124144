import { Breakpoint, Color, Grid, Loader, StyledComponent } from '@chic-loyalty/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 50px;
  min-height: calc(100vh - 293px);
  width: 100%;
  background-color: ${Color.AlmostBlack};

  @media ${Breakpoint.Mobile} {
    gap: normal;
  }
`;

export const HeaderWrapper: StyledComponent<'div'> = styled.div`
  padding-left: 80px;

  @media ${Breakpoint.Mobile} {
    padding-left: 0;
  }
`;

export const ContentWrapper: StyledComponent<'div'> = styled.div`
  padding: 0 20px 50px 100px;

  @media ${Breakpoint.Mobile} {
    padding: 0;
  }
`;

export const Content: StyledComponent<'div'> = styled.div`
  display: flex;
  gap: 15px;
  min-height: 500px;
  max-width: ${Grid.Desktop};
  margin: 0 auto;

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
    gap: normal;
    margin-bottom: 35px;
  }
`;

export const ImageBox: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 390px;
  background-color: ${Color.AlmostWhite};
  border-radius: 12px;

  @media ${Breakpoint.Tablet} {
    min-width: 256px;
  }

  @media ${Breakpoint.Mobile} {
    height: 200px;
    width: 100%;
    border-radius: 0;
  }
`;
export const Image: StyledComponent<'img'> = styled.img`
  height: auto;
  max-height: 100%;
  width: 100%;
  max-width: 250px;
  object-fit: contain;
`;

export const StyledLoader: StyledComponent<typeof Loader> = styled(Loader)`
  position: absolute;
  left: calc(50% - 35px);
  top: calc(50% - 35px);
`;
