import { getPathWithParams, UseNotifications, useNotifications } from '@chic-loyalty/ui';
import React, { useEffect } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { NavigateFunction, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { confirmContactData, getContactDataVerificationStatus } from '@chic/frontend/api/requests';
import { FullscreenAlertAssets } from '@chic/frontend/enums';
import { FrontendApiError } from '@chic/frontend/models';
import { UseSearchParams } from '@chic/frontend/types';
import { CustomerStatusType, RouteNameEnum, UserContactDataType } from '@chic/shared/enums';
import { ContactDataStatus, CustomerStatusWithUserData, VerificationStatus } from '@chic/shared/interfaces';
import { getRouteDetailsByName } from '@chic/shared/utils';

import { Container } from './contactDataVerification.styled';

export const ContactDataVerificationView: React.FC = (): JSX.Element => {
  const { ean }: Readonly<Record<string, string | undefined>> = useParams();
  const [searchParams]: UseSearchParams = useSearchParams();
  const { addToast, showFullscreenAlert, hideFullscreenAlert }: UseNotifications = useNotifications();
  const { t }: TransProps<never> = useTranslation();
  const navigate: NavigateFunction = useNavigate();

  const redirectToHome: () => void = (): void => navigate(getRouteDetailsByName(RouteNameEnum.Home)?.url ?? '/');

  const informAboutNextContactDataVerification: (contactDataType: UserContactDataType) => void = (
    contactDataType: UserContactDataType
  ): void => {
    showFullscreenAlert({
      title: t(`chic.website.contactDataVerificationView.popups.${contactDataType}.title`),
      description: t(`chic.website.contactDataVerificationView.popups.${contactDataType}NextVerification.description`),
      image: FullscreenAlertAssets.WomanWithPhone,
      acceptButtonSettings: {
        label: t('chic.website.global.home'),
        action: (): void => {
          hideFullscreenAlert();
          redirectToHome();
        }
      },
    });
  };

  const redirectToSetPassword: (token: string | null) => void = (token: string | null): void => {
    if (token) {
      addToast({ content: t('chic.website.contactDataVerificationView.toasts.successAndSetPassword') });
      navigate(getPathWithParams(getRouteDetailsByName(RouteNameEnum.SetPassword)?.url ?? '/', { token }));
    } else {
      addToast({ content: t('chic.website.contactDataVerificationView.toasts.success') });
      redirectToHome();
    }
  };

  const redirectToHomeAndCompleteFlow: () => void = (): void => {
    showFullscreenAlert({
      title: t('chic.website.contactDataVerificationView.popups.customerFlowCompleted.title'),
      description: t('chic.website.contactDataVerificationView.popups.customerFlowCompleted.description'),
      image: FullscreenAlertAssets.ManWithGlasses,
      acceptButtonSettings: {
        label: t('chic.website.global.home'),
        action: (): void => {
          // TODO: sign in to user account (backend should return correct token)
          hideFullscreenAlert();
          redirectToHome();
        }
      },
    });
  };

  useEffect(
    (): void => {
      const auth: string | null = searchParams.get('auth');
      if (!ean || !auth) {
        redirectToHome();
        return;
      }

      getContactDataVerificationStatus(ean, auth)
        .then((verificationStatus: VerificationStatus): void => {
          const nextNotVerifiedContactData: ContactDataStatus | undefined = verificationStatus.contactsData
            .find((data: ContactDataStatus): boolean => !data.isVerified);

          if (verificationStatus.token.isValid) {
            showFullscreenAlert({
              title: t(`chic.website.contactDataVerificationView.popups.${verificationStatus.token.contactDataType}.title`),
              description: t(`chic.website.contactDataVerificationView.popups.${verificationStatus.token.contactDataType}.description`),
              image: FullscreenAlertAssets.WomanWithPhone,
              acceptButtonSettings: {
                label: t('chic.website.contactDataVerificationView.confirm'),
                action: (): void => {
                  confirmContactData({ ean, type: verificationStatus.token.contactDataType, token: auth })
                    .then((confirmStatus: CustomerStatusWithUserData): void => {
                      hideFullscreenAlert();
                      if ([CustomerStatusType.UnconfirmedEmail, CustomerStatusType.UnconfirmedPhone].includes(confirmStatus.status)) {
                        informAboutNextContactDataVerification(
                          confirmStatus.status === CustomerStatusType.UnconfirmedEmail
                            ? UserContactDataType.Email
                            : UserContactDataType.Mobile,
                        );
                      } else if (confirmStatus.status === CustomerStatusType.UndefinedPassword) {
                        redirectToSetPassword(confirmStatus.token);
                      } else if (confirmStatus.status === CustomerStatusType.Completed) {
                        redirectToHomeAndCompleteFlow();
                      } else {
                        redirectToHome();
                      }
                    })
                    .catch((error: FrontendApiError): void => addToast({ content: t(error.message) }));
                }
              },
            });
          } else if (nextNotVerifiedContactData) {
            informAboutNextContactDataVerification(nextNotVerifiedContactData.type);
          } else {
            showFullscreenAlert({
              title: t('chic.website.contactDataVerificationView.popups.allContactDataConfirmed.title'),
              description: t('chic.website.contactDataVerificationView.popups.allContactDataConfirmed.description'),
              image: FullscreenAlertAssets.WomanWithPhone,
              acceptButtonSettings: {
                label: t('chic.website.global.home'),
                action: (): void => {
                  hideFullscreenAlert();
                  redirectToHome();
                },
              },
            });
          }
        })
        .catch((error: FrontendApiError): void => {
          showFullscreenAlert({
            title: t('chic.website.global.error'),
            description: t(error.message),
            iconImage: FullscreenAlertAssets.ErrorIcon,
            acceptButtonSettings: {
              label: t('chic.website.global.ok'),
              action: (): void => {
                hideFullscreenAlert();
                redirectToHome();
              }
            },
          });
        });
    },
    []
  );

  return (
    <Container />
  );
};
