export enum FileFromViews {
  // home view
  HomeMan = '/assets/views/home/man.jpg',
  HomeComputer = '/assets/views/home/computer.png',
  HomePhone = '/assets/views/home/phone.png',
  HomePhones = '/assets/views/home/phones.png',
  HomeStore = '/assets/views/home/store.png',
  HomeDevices = '/assets/views/home/devices.svg',
  // dashboard view
  DashboardHeroSlide1 = '/assets/views/dashboard/icMigration.png',
  DashboardHeroSlide1Mobile = '/assets/views/dashboard/icMigrationMobile.jpg',
  DashboardHeroSlide2 = '/assets/views/dashboard/inspirationStore.jpg',
  DashboardHeroSlide2Mobile = '/assets/views/dashboard/inspirationStoreMobile.png',
  DashboardHeroSlide3 = '/assets/views/dashboard/vuseGo.jpg',
  DashboardHeroSlide3Mobile = '/assets/views/dashboard/vuseGoMobile.jpg',
  DashboardHeroSlide4 = '/assets/views/dashboard/alterCloud.png',
  DashboardHeroSlide4Mobile = '/assets/views/dashboard/alterCloudMobile.png',
  // birthday bonus view
  BirthdayBonusFriends = '/assets/views/birthdayBonus/friends.jpg',
  BirthdayBonusGiftBox = '/assets/views/birthdayBonus/giftBox.png',
  BirthdayBonusVuseDevice = '/assets/views/birthdayBonus/vuseDevice.png',
  BirthdayBonusVuseLiquid = '/assets/views/birthdayBonus/vuseLiquid.png',
  // free engraver view
  FreeEngraverEngravers = '/assets/views/freeEngraver/engravers.png',
  // carePlus view
  CarePlusBirthdayBonus = '/assets/views/carePlus/birthdayBonus.svg',
  CarePlusLogo = '/assets/views/carePlus/carePlusLogo.png',
  CarePlusDevices = '/assets/views/carePlus/devices.jpg',
  CarePlusFreeEngraver = '/assets/views/carePlus/freeEngraver.svg',
  CarePlusFreeService = '/assets/views/carePlus/freeService.svg',
  CarePlusOffers = '/assets/views/carePlus/offers.svg',
  CarePlusWomanWithMug = '/assets/views/carePlus/womanWithMug.png',
  CarePlusWomanWithMugMobile = '/assets/views/carePlus/womanWithMugMobile.png',
  CarePlusMessages = '/assets/views/carePlus/messages.svg',
  // free service view
  FreeServiceEswPoint = '/assets/views/freeService/eswPoint.jpg',
  FreeServiceVuseDevices = '/assets/views/freeService/vuseDevices.png',
  FreeServiceVuseDevicesMobile = '/assets/views/freeService/vuseDevicesMobile.png',
  FreeServiceVuseDevicesTablet = '/assets/views/freeService/vuseDevicesTablet.png',
  FreeServiceNoBattery = '/assets/views/freeService/noBattery.png',
  // subscriptions view
  SubscriptionFirstStepIcon = '/assets/views/subscriptions/firstStepIcon.svg',
  SubscriptionSecondStepIcon = '/assets/views/subscriptions/secondStepIcon.svg',
  SubscriptionThirdStepIcon = '/assets/views/subscriptions/thirdStepIcon.svg',
  SubscriptionsPackage = '/assets/views/subscriptions/package.jpg',
  SubscriptionsWomanSmartphone = '/assets/views/subscriptions/womanSmartphone.jpg',
  SubscriptionsSmartphoneInHands = '/assets/views/subscriptions/smartphoneInHands.jpg',
  SubscriptionsFaqIcon = '/assets/views/subscriptions/iconFaq.svg',
  SubscriptionsManualWorker = '/assets/views/subscriptions/manualWorker.jpg',
  // contact view
  ContactViewHeaderBackground = '/assets/views/contact/headerBackground.png',
  ContactViewHeaderBackgroundMobile = '/assets/views/contact/headerBackgroundMobile.png',
  // products view
  DefaultProductCategory = '/assets/views/products/category.jpg',
  // confirm mail view
  ConfirmMailBackground = '/assets/views/technicalViews/confirmEmail/confirmEmailBackground.jpg',
  // icMigrationLanding
  IcMigrationLandingBackground = '/assets/views/technicalViews/icMigrationLanding/background.jpg',
  // transactions list view
  TransactionsListEmptyState = 'assets/views/transactions/emptyState.svg',
  // faq view
  Help = 'assets/views/faq/help.svg',
  // payments settings view
  PaymentsSettingsVisaLogo = '/assets/views/paymentsSettings/visa.svg',
  PaymentsSettingsMastercardLogo = '/assets/views/paymentsSettings/mastercard.svg',
  PaymentsSettingsDefaultCardLogo = '/assets/views/paymentsSettings/defaultCard.svg',
  // not found view
  NotFound404 = '/assets/views/notFound/404.svg',
  NotFoundBackground = '/assets/views/notFound/404-background.jpg',
  // offers view
  OffersViewEmptyState = 'assets/views/offers/emptyState.svg',
  // dashboard for esc program limited view
  DashboardForEscProgramLimitedBackground = '/assets/views/dashboardForEscProgramLimited/background.jpg',
}
