import { AxiosResponse } from 'axios';

import { FrontendApiError } from '@chic/frontend/models';
import { ApiReject, ApiResolve } from '@chic/frontend/types';
import { Promotion, UnlockPromotionStatus } from '@chic/shared/interfaces';

import { api } from '../api';

export const getPromotions: () => Promise<Promotion[]> = (): Promise<Promotion[]> => new Promise(
  (resolve: ApiResolve<Promotion[]>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get('/promotions').then(
      (response: AxiosResponse<Promotion[]>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getPromotionsForUnlock: () => Promise<Promotion[]> = (): Promise<Promotion[]> => new Promise(
  (resolve: ApiResolve<Promotion[]>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get('/promotions/available-to-unlock').then(
      (response: AxiosResponse<Promotion[]>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getPromotionDetails: (id: number) => Promise<Promotion> = (id: number): Promise<Promotion> => new Promise(
  (resolve: ApiResolve<Promotion>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get(`/promotions/${id}`).then(
      (response: AxiosResponse<Promotion>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const unlockPromotion: (id: number) => 
  Promise<UnlockPromotionStatus> = (id: number): Promise<UnlockPromotionStatus> => new Promise(
    (resolve: ApiResolve<UnlockPromotionStatus>, reject: ApiReject<FrontendApiError>): Promise<void> => (
      api.post(`/promotions/${id}/unlock`).then(
        (response: AxiosResponse<UnlockPromotionStatus>): void => { resolve(response?.data); }
      ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
    )
  );
