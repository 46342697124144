import { MapPointDelivery, OptionSwitchOption } from '@chic-loyalty/ui';
import { TransProps, useTranslation } from 'react-i18next';

import {
  ParcelLocker,
  PickupPoint,
  PickupPointWithDistance,
  SubscriptionDeliveryDestination,
  SubscriptionDeliveryCost
} from '@chic/shared/interfaces';

import { UseDeliveriesTransformation } from '../interfaces';
import { getIconByDeliveryCode } from '../utils';

export const useDeliveriesTransformation: () => UseDeliveriesTransformation = (): UseDeliveriesTransformation => {
  const { t }: TransProps<never> = useTranslation();
  const currency: string = 'zł';

  const getDeliveryAddress: (address: SubscriptionDeliveryDestination) => string[] = (
    address: SubscriptionDeliveryDestination
  ): string[] => {
    return [
      address?.name ?? '-',
      `${address?.address ?? '-'}
      , ${address?.postalCode ?? ''} ${address?.city ?? ''}`
    ];
  };

  const getParcelLockerAddress: (parcelLocker: ParcelLocker) => string[] = (parcelLocker: ParcelLocker): string[] => {
    return [
      t('chic.website.useDeliveriesTransformation.getParcelLockerAddress.parcelMachine', {
        value: parcelLocker.externalId
      }),
      `${parcelLocker?.address ?? ''}
      , ${parcelLocker?.postalCode ?? ''} ${parcelLocker?.city ?? ''}`
    ];
  };

  const getPickupPointAddress: (pickupPoint: PickupPoint) => string[] = (pickupPoint: PickupPoint): string[] => {
    return [
      pickupPoint?.name ?? '-',
      `${pickupPoint?.location.address ?? ''}, ${pickupPoint?.location.postcode ?? ''} ${pickupPoint?.location.city ?? ''}`
    ];
  };

  const transformParcelLockerToMapPointDelivery: (
    values: ParcelLocker
  ) => MapPointDelivery = (
    values: ParcelLocker
  ): MapPointDelivery => {
    return {
      name: values.name,
      id: values.id,
      address: [values.address, `${`${values.postalCode} ${values.city}`}`],
      lat: values.location.lat ?? 0,
      lng: values.location.lng ?? 0,
      distance: values.distance ?? undefined,
      externalId: values.externalId
    };
  };

  const transformPickupPointWithDistanceToMapPointDelivery: (
    values: PickupPointWithDistance
  ) => MapPointDelivery = (
    values: PickupPointWithDistance
  ): MapPointDelivery => {
    return {
      name: values.name,
      id: values.id,
      address: [values.location.address, `${`${values.location.postcode} ${values.location.city}`}`],
      lat: values.location.latitude,
      lng: values.location.longitude,
      distance: values.distance ?? undefined
    };
  };

  const transformSubscriptionDeliveryCostToOptionSwitchOption: (delivery: SubscriptionDeliveryCost) => OptionSwitchOption = (
    delivery: SubscriptionDeliveryCost
  ): OptionSwitchOption => {
    return {
      name: delivery.code,
      label: delivery.label,
      icon: getIconByDeliveryCode(delivery.code),
      description: !delivery.cost ? t('chic.website.useSubscriptionsActions.freeDelivery') : `${`(${delivery.cost} ${currency})`}`
    };
  };

  return {
    getDeliveryAddress,
    getParcelLockerAddress,
    getPickupPointAddress,
    transformParcelLockerToMapPointDelivery,
    transformPickupPointWithDistanceToMapPointDelivery,
    transformSubscriptionDeliveryCostToOptionSwitchOption,
  };
};
