import {
  PageHeader,
  UseState,
  PageHeaderPosition,
  UseNotifications,
  useNotifications,
  MenuPositionData,
  ValidationBarTheme,
  ContentBoxTheme,
  PaymentsTypesList,
  UseRedirect,
  useRedirect,
  PaymentType,
  PaymentsHistoryTable,
  PaymentDetails,
  DropdownOption,
  SectionTitleSize,
} from '@chic-loyalty/ui';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import {
  getConfig,
  getSubscriptions,
  getUserCreditCards,
  removeCreditCard,
  updateSubscriptionPaymentMethod
} from '@chic/frontend/api/requests';
import { PopupId, PopupImage, QueryKey } from '@chic/frontend/enums';
import { FrontendApiError } from '@chic/frontend/models';
import { getCreditCardLogo } from '@chic/frontend/utils';
import { CreditCardProviderType, RouteNameEnum, SubscriptionPaymentMethod } from '@chic/shared/enums';
import { CreditCard, Subscription } from '@chic/shared/interfaces';
import { SystemConfig } from '@chic/shared/types';
import { getRouteDetailsByName } from '@chic/shared/utils';

import { useProfileTabs } from '../profile.hooks';

import {
  Container,
  ContentWrapper,
  StyledButton,
  StyledContentBox,
  StyledSectionTitle,
  StyledValidationBar,
  SubscriptionsPaymentsWrapper
} from './paymentsSettings.styled';

export const PaymentsSettingsView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { addToast, showPopup, hidePopup }: UseNotifications = useNotifications();
  const profileTabs: MenuPositionData[] = useProfileTabs();
  const { redirect }: UseRedirect = useRedirect();
  const defaultPaymentMethods: PaymentType[] = [
    { label: t('chic.website.paymentsSettingsView.onlinePaymentPayu') },
    { label: t('chic.website.paymentsSettingsView.onlinePaymentPrzelewy24') },
  ];
  const [paymentMethods, setPaymentMethods]: UseState<PaymentType[]> = useState<PaymentType[]>(defaultPaymentMethods);
  const [subscriptionsPaymentsDetails, setSubscriptionsPaymentsDetails]: UseState<PaymentDetails[]> = useState<PaymentDetails[]>([]);
  const [removeRequestInProgress, setRemoveRequestInProgress]: UseState<boolean> = useState<boolean>(false);

  const { data: systemConfig }: UseQueryResult<SystemConfig> = useQuery(
    [QueryKey.Config],
    (): Promise<SystemConfig> => getConfig(),
  );

  const updateCreditCardsList: (cards: CreditCard[]) => void = (cards: CreditCard[]): void => {
    const todayDateTimestamp: number = (new Date()).getTime();
    const oneDayInMs: number = 24 * 60 * 60 * 1000;
    const todayDatePlusOneMonthTimestamp: number = (new Date(todayDateTimestamp)).getTime() + (30 * oneDayInMs);

    setPaymentMethods([
      ...(cards.map((card: CreditCard): PaymentType => ({
        label: card.label.replace(/\*/g, '･'),
        logo: getCreditCardLogo((card.provider?.type ?? '') as CreditCardProviderType),
        expires: todayDatePlusOneMonthTimestamp > (new Date(`${card.expiration.month}/1/${card.expiration.year}`)).getTime(),
        isExpired: todayDateTimestamp > (new Date(`${card.expiration.month}/1/${card.expiration.year}`)).getTime(),
        removeAction: (): void => {
          showPopup({
            id: PopupId.CreditCardRemoveConfirmation,
            image: PopupImage.SubscriptionOutOfStockBg,
            title: t('chic.website.paymentsSettingsView.removeActionPopup.title'),
            description: t('chic.website.paymentsSettingsView.removeActionPopup.description'),
            acceptButtonSettings: {
              label: t('chic.website.paymentsSettingsView.removeActionPopup.acceptLabel'),
              action: (): void => {
                setRemoveRequestInProgress(true);
                removeCreditCard(card.id)
                  .then(updateCreditCardsList)
                  .catch((error: FrontendApiError): void => addToast({ content: error.message }))
                  .finally((): void => {
                    hidePopup({ id: PopupId.CreditCardRemoveConfirmation });
                    setRemoveRequestInProgress(false);
                  });
              },
            },
            cancelButtonSettings: {
              label: t('chic.website.global.cancel'),
              action: (): void => {
                hidePopup({ id: PopupId.CreditCardRemoveConfirmation });
              },
            },
            isLoading: removeRequestInProgress
          });
        }
      }))),
      ...defaultPaymentMethods,
    ]);
  };

  useQuery(
    [QueryKey.UserCreditCards],
    (): Promise<CreditCard[]> => getUserCreditCards(),
    {
      onSuccess: updateCreditCardsList,
      // TODO: add logger
      onError: (): void => undefined
    }
  );

  const paymentsTypes: DropdownOption[] = useMemo(
    (): DropdownOption[] => [
      { name: SubscriptionPaymentMethod.Payu, label: t('chic.website.global.subscriptionPaymentMethods.payu') },
      { name: SubscriptionPaymentMethod.Przelewy24, label: t('chic.website.global.subscriptionPaymentMethods.przelewy24') },
      { name: SubscriptionPaymentMethod.Card, label: t('chic.website.global.subscriptionPaymentMethods.card') },
    ],
    []
  );

  const updateSubscriptionPaymentMethodAction: (subscriptionId: number, method: SubscriptionPaymentMethod) => void = (
    subscriptionId: number, method: SubscriptionPaymentMethod
  ): void => {
    updateSubscriptionPaymentMethod(subscriptionId, { method })
      .then((): void => addToast({ content: t('chic.website.paymentsSettingsView.updatePaymentMethodSuccess') }))
      .catch((error: FrontendApiError): void => addToast({ content: error.message }));
  };

  const { isLoading }: UseQueryResult = useQuery(
    [QueryKey.Subscriptions],
    (): Promise<Subscription[]> => getSubscriptions(),
    {
      onSuccess: (data: Subscription[]): void => {
        setSubscriptionsPaymentsDetails(data
          .filter((subscription: Subscription): boolean => !!subscription.active)
          .map((subscription: Subscription): PaymentDetails => ({
            planColor: subscription.plan.color,
            planName: subscription.plan.name,
            price: subscription.price,
            choosePaymentTypeSettings: {
              options: paymentsTypes,
              initialValue: paymentsTypes.find((item: DropdownOption): boolean => item.name === subscription.paymentMethod),
              onChooseAnswer: (option: DropdownOption): void => updateSubscriptionPaymentMethodAction(
                subscription.id,
                option.name as SubscriptionPaymentMethod
              ),
            }
          }))
        );
      },
      // TODO: add logger
      onError: (): void => undefined
    }
  );

  const moveToAddCreditCardView: () => void = (): void => {
    redirect(getRouteDetailsByName(RouteNameEnum.ProfileAddCreditCard)?.url ?? '', undefined, true);
  };

  return (
    <Container>
      <PageHeader
        header={t('chic.website.meta.profile.title')}
        headerPosition={PageHeaderPosition.CenterLeft}
        tabs={profileTabs}
        activeTabName='paymentSettings'
      />
      <ContentWrapper>
        <StyledContentBox contentBoxTheme={ContentBoxTheme.Medium1}>
          <PaymentsTypesList data={paymentMethods} />
          <StyledButton
            label={t('chic.website.paymentsSettingsView.addCreditCard')}
            onClick={moveToAddCreditCardView}
          />
          <StyledValidationBar
            message={t('chic.website.paymentsSettingsView.cardsPaymentInformation')}
            barTheme={ValidationBarTheme.Gray}
          />
        </StyledContentBox>
        {systemConfig?.subscriptions && (
          <SubscriptionsPaymentsWrapper>
            <StyledSectionTitle
              title={t('chic.website.paymentsSettingsView.paymentsSettingsTable.title')}
              subtitle={t('chic.website.paymentsSettingsView.paymentsSettingsTable.subtitle')}
              size={SectionTitleSize.Small}
            />
            {(!isLoading && !subscriptionsPaymentsDetails.length) ? (
              <StyledValidationBar
                message={t('chic.website.paymentsSettingsView.noSubscriptions')}
                barTheme={ValidationBarTheme.Gray}
              />
            ) : (
              <PaymentsHistoryTable payments={subscriptionsPaymentsDetails} />
            )}
          </SubscriptionsPaymentsWrapper>
        )}
      </ContentWrapper>
    </Container>
  );
};
