import { Breakpoint, Color, Grid, Loader, StyledComponent } from '@chic-loyalty/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  position: relative;
  min-height: calc(100vh - 293px);
  width: calc(100% - 80px);
  background-color: ${Color.Level1};
  margin-left: auto;

  @media ${Breakpoint.Mobile} {
    width: 100%;
  }
`;

export const DetailsInfoSection: StyledComponent<'div'> = styled.div`
  width: 100%;
  padding: 50px 20px;

  @media ${Breakpoint.Mobile} {
    padding: 35px 20px;
  }
`;

export const DetailsInfoWrapper: StyledComponent<'div'> = styled.div`
  max-width: ${Grid.Tablet};
  margin: 0 auto;
`;

export const ProductsSection: StyledComponent<'div'> = styled.div`
  width: 100%;
  background-color: ${Color.AlmostBlack};
  padding: 50px 20px;

  @media ${Breakpoint.Mobile} {
    padding: 35px 20px;
  }
`;

export const ProductsWrapper: StyledComponent<'div'> = styled.div`
  max-width: ${Grid.Tablet};
  margin: 0 auto;
`;

export const TransactionDetailsBoxWrapper: StyledComponent<'div'> = styled.div`
  padding: 30px 0;

  & + & {
    border-top: 1px solid ${Color.SolidLowestEmphasis};
  }
`;

export const StyledLoader: StyledComponent<typeof Loader> = styled(Loader)`
  position: absolute;
  left: calc(50% - 35px);
  top: calc(50% - 35px);
`;
