import {
  PageHeader,
  UseState,
  BoxesSlider,
  PlanCategoryCard,
  OfferCard,
  Color,
  Breakpoint,
  UseRedirect,
  useRedirect,
  UseTransformations,
  useTransformations,
} from '@chic-loyalty/ui';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { getSubscriptionsPlansCategories, getPromotions, getPromotionsForUnlock, getConfig } from '@chic/frontend/api/requests';
import { FileFromViews, QueryKey } from '@chic/frontend/enums';
import { useAuth } from '@chic/frontend/hooks';
import { UseAuth } from '@chic/frontend/interfaces';
import { FrontendUrlsParams, RouteNameEnum } from '@chic/shared/enums';
import { Promotion, SubscriptionPlanCategoryWithImg } from '@chic/shared/interfaces';
import { SystemConfig } from '@chic/shared/types';
import { getRouteDetailsByName } from '@chic/shared/utils';

import {
  Container,
  BoxesContainer,
  BoxesRow,
  BoxesHeader,
  BoxesHeaderCount,
  EmptyStateContainer,
  EmptyStateImg,
  EmptyStateMessage
} from './offers.styled';

export const OffersView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { transformImageToDark }: UseTransformations = useTransformations();
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });
  const { userData }: UseAuth = useAuth();
  const { redirect }: UseRedirect = useRedirect();
  const navigate: NavigateFunction = useNavigate();
  const [promotions, setPromotions]: UseState<Promotion[]> = useState<Promotion[]>([]);
  const [promotionsForUnlock, setPromotionsForUnlock]: UseState<Promotion[]> = useState<Promotion[]>([]);
  const [subscriptionPlansCategories, setSubscriptionPlansCategories]: UseState<SubscriptionPlanCategoryWithImg[]>
    = useState<SubscriptionPlanCategoryWithImg[]>([]);

  const { data: systemConfig }: UseQueryResult<SystemConfig> = useQuery(
    [QueryKey.Config],
    (): Promise<SystemConfig> => getConfig(),
  );

  useQuery(
    [QueryKey.Promotions],
    (): Promise<Promotion[]> => getPromotions(),
    {
      onSuccess: setPromotions,
      onError: (): void => undefined
    }
  );

  useQuery(
    [QueryKey.PromotionsForUnlock],
    (): Promise<Promotion[]> => getPromotionsForUnlock(),
    {
      onSuccess: setPromotionsForUnlock,
      onError: (): void => undefined
    }
  );

  useQuery(
    [QueryKey.SubscriptionPlans],
    (): Promise<SubscriptionPlanCategoryWithImg[]> => getSubscriptionsPlansCategories(),
    {
      onSuccess: setSubscriptionPlansCategories,
      onError: (): void => undefined
    }
  );

  const onPlanCategoryClick = (id: number): void => {
    const url: string | undefined = getRouteDetailsByName(RouteNameEnum.Subscriptions)?.url;
    redirect(url ? `${url}?${FrontendUrlsParams.AvailablePlan}=${id}` : '', undefined, true);
  };

  return (
    <Container>
      <PageHeader
        header={t('chic.website.meta.offers.title')}
        onArrowButtonAction={(): void => navigate(getRouteDetailsByName(RouteNameEnum.Home)?.url ?? '/')}
      />
      {(!subscriptionPlansCategories.length || !systemConfig?.subscriptions) && !promotions.length && !promotionsForUnlock.length && (
        <EmptyStateContainer>
          <EmptyStateImg src={FileFromViews.OffersViewEmptyState} />
          <EmptyStateMessage>
            {t('chic.website.offersView.emptyStateMessage')}
          </EmptyStateMessage>
        </EmptyStateContainer>
      )}
      {!!subscriptionPlansCategories.length && systemConfig?.subscriptions && (
        <BoxesContainer>
          <BoxesRow>
            <BoxesHeader>
              {t('chic.website.offersView.subscriptions')}
              <BoxesHeaderCount>{subscriptionPlansCategories.length}</BoxesHeaderCount>
            </BoxesHeader>
            <BoxesSlider cursorLabel={t('chic.website.global.move')} withPagination={isMobile}>
              {subscriptionPlansCategories.map((item: SubscriptionPlanCategoryWithImg): JSX.Element => (
                <PlanCategoryCard
                  key={item.id}
                  label={`${t('chic.website.offersView.packet')} ${item.name}`}
                  image={transformImageToDark(item.packshot)}
                  onClick={(): void => onPlanCategoryClick(item.id)}
                />
              ))}
            </BoxesSlider>
          </BoxesRow>
        </BoxesContainer>
      )}
      {!!promotions.length && (
        <BoxesContainer $backgroundColor={Color.AlmostBlack}>
          <BoxesRow>
            <BoxesHeader>
              {t('chic.website.offersView.activeOffers')}
              <BoxesHeaderCount>{promotions.length}</BoxesHeaderCount>
            </BoxesHeader>
            <BoxesSlider cursorLabel={t('chic.website.global.move')} withPagination={isMobile}>
              {promotions.map((item: Promotion): JSX.Element => (
                <OfferCard
                  key={item.id}
                  title={item.name}
                  cover={item.image ?? ''}
                  deadlineTimestamp={new Date(item.dateTo).getTime()}
                  points={item.cost ?? undefined}
                  pointsActive={userData?.points && item.cost
                    ? userData?.points >= item.cost
                    : false
                  }
                  unlocksCount={item.amount}
                  onButtonClick={(): void => redirect(getRouteDetailsByName(RouteNameEnum.OfferDetails)?.url ?? '', { id: item.id })}
                />
              ))}
            </BoxesSlider>
          </BoxesRow>
        </BoxesContainer>
      )}
      {!!promotionsForUnlock.length && (
        <BoxesContainer $backgroundColor={Color.AlmostBlack}>
          <BoxesRow>
            <BoxesHeader>
              {t('chic.website.offersView.unlockOffer')}
              <BoxesHeaderCount>{promotionsForUnlock.length}</BoxesHeaderCount>
            </BoxesHeader>
            <BoxesSlider cursorLabel={t('chic.website.global.move')}>
              {promotionsForUnlock.map((item: Promotion): JSX.Element => (
                <OfferCard
                  key={item.id}
                  title={item.name}
                  cover={item.image ?? ''}
                  deadlineTimestamp={new Date(item.dateTo).getTime()}
                  points={item.cost ?? undefined}
                  pointsActive={userData?.points && item.cost
                    ? userData?.points >= item.cost
                    : false
                  }
                  unlocksCount={item.amount}
                  onButtonClick={(): void => redirect(getRouteDetailsByName(RouteNameEnum.OfferDetails)?.url ?? '', { id: item.id })}
                />
              ))}
            </BoxesSlider>
          </BoxesRow>
        </BoxesContainer>
      )}
    </Container>
  );
};
