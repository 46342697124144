import { StyledComponent, ContentBox, Color, Breakpoint, Checkbox, Button, ValidationBar } from '@chic-loyalty/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: calc(100% - 80px);
  background-color: ${Color.AlmostBlack};
  margin-left: auto;

  @media ${Breakpoint.Mobile} {
    width: 100%;
  }
`;

export const ContentWrapper: StyledComponent<'div'> = styled.div`
  min-height: 100vh;
  width: 100%;
  padding: 0 20px;

  @media ${Breakpoint.Tablet} {
    padding: 0;
  }

  @media ${Breakpoint.Mobile} {
    min-height: auto;
  }
`;

export const StyledContentBox: StyledComponent<typeof ContentBox> = styled(ContentBox)`
  max-width: 900px;
  margin: 70px auto;
  position: relative;

  @media ${Breakpoint.Tablet} {
    background: ${Color.Transparent};
    margin: 0;
  }
`;

export const StyledValidationBar: StyledComponent<typeof ValidationBar> = styled(ValidationBar)`
  margin-bottom: 10px;
`;

export const StyledCheckbox: StyledComponent<typeof Checkbox> = styled(Checkbox)`
  padding: 25px 0;
  width: 100%;
  border-bottom: 1px solid ${Color.SolidLowestEmphasis};
`;

export const StyledButton: StyledComponent<typeof Button> = styled(Button)`
  margin-top: 30px;
`;
