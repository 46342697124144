export enum TransactionType {
  Sale = 'SALE',
  Refund = 'REFUND',
  Reward = 'REWARD',
  Bonus = 'BONUS',
  Mgm = 'MGM',
  Correction = 'CORRECTION',
  Coupons = 'COUPONS',
  BuyCoupons = 'BUY_COUPONS',
  UnlockPromotion = 'UNLOCK_PROMOTION',
}
