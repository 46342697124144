import { MenuPositionData } from '@chic-loyalty/ui';

import { RouteNameEnum } from '@chic/shared/enums';
import { getRouteDetailsByName } from '@chic/shared/utils';

export const footerMenuPositions: MenuPositionData[] = [
  {
    name: RouteNameEnum.Contact,
    label: 'chic.website.meta.contact.title',
    internalPath: getRouteDetailsByName(RouteNameEnum.Contact)?.url,
  },
  {
    name: RouteNameEnum.FindUs,
    label: 'chic.website.meta.findUs.title',
    internalPath: getRouteDetailsByName(RouteNameEnum.FindUs)?.url,
  },
  {
    name: RouteNameEnum.CookiesPolicy,
    label: 'chic.website.meta.cookiesPolicy.title',
    internalPath: getRouteDetailsByName(RouteNameEnum.CookiesPolicy)?.url,
  },
  {
    name: RouteNameEnum.PrivacyPolicy,
    label: 'chic.website.meta.privacyPolicy.title',
    externalUrl: 'https://static.esmokingclub.pl/regulations/polityka_prywatnosci_esc_3_0.pdf',
  },
  {
    name: RouteNameEnum.Regulations,
    label: 'chic.website.meta.regulations.title',
    internalPath: getRouteDetailsByName(RouteNameEnum.Regulations)?.url,
  },
];

export const signedUserFooterMenuPositions: MenuPositionData[] = [
  {
    name: 'partners',
    label: 'chic.website.meta.partners.title',
    externalUrl: 'https://static.esmokingclub.pl/regulations/partnerzy.pdf',
  },
  {
    name: RouteNameEnum.Faq,
    label: 'chic.website.meta.faq.title',
    internalPath: getRouteDetailsByName(RouteNameEnum.Faq)?.url,
  },
];
