import {
  PageHeader,
  UseState,
  UseNotifications,
  useNotifications,
  ContentBoxTheme,
  UseRedirect,
  useRedirect,
  Breakpoint,
} from '@chic-loyalty/ui';
import FingerprintJS, { Agent, GetResult } from '@fingerprintjs/fingerprintjs';
import React, { useEffect, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { addNewCreditCard } from '@chic/frontend/api/requests';
import { appConfig } from '@chic/frontend/app.config';
import { FullscreenAlertAssets } from '@chic/frontend/enums';
import { usePayu } from '@chic/frontend/hooks';
import { UsePayu } from '@chic/frontend/interfaces';
import { FrontendApiError } from '@chic/frontend/models';
import { PayuCreditCardRequestStatus, RouteNameEnum } from '@chic/shared/enums';
import { CreditCardAddRequestResponse } from '@chic/shared/interfaces';
import { getRouteDetailsByName } from '@chic/shared/utils';

import {
  Container,
  ContentWrapper,
  PayuCardForm,
  PayuInputContainer,
  PayuInputDescription,
  PayuInputLabel,
  PayuInputsHeader,
  StyledButton,
  StyledContentBox,
  StyledPayuAnnotations,
} from './addCreditCard.styled';

declare const PayU: payu.PayuEntry;

export const AddCreditCardView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { showFullscreenAlert, hideFullscreenAlert }: UseNotifications = useNotifications();
  const [payuSdkForms, setPayuSdkForms]: UseState<payu.PayU | null> = useState<payu.PayU | null>(null);
  const [fingerPrintPromise, setFingerPrintPromise]: UseState<Promise<Agent> | null> = useState<Promise<Agent> | null>(null);
  const { redirect }: UseRedirect = useRedirect();
  const [isCardValid, setIsCardValid]: UseState<boolean> = useState<boolean>(false);
  const [isExpirationValid, setIsExpirationValid]: UseState<boolean> = useState<boolean>(false);
  const [isCvvValid, setIsCvvValid]: UseState<boolean> = useState<boolean>(false);
  const [isLoading, setIsLoading]: UseState<boolean> = useState<boolean>(false);
  const { secureFormOptions, isPayuSdkReady, showSuccessPopupAfterPayuOperation }: UsePayu = usePayu();
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });

  useEffect(
    (): void => {
      if (!isPayuSdkReady() || payuSdkForms) {
        return;
      }

      const payuSdkFormsObject: payu.PayU = PayU(appConfig.payuPosId);
      setPayuSdkForms(payuSdkFormsObject);
      const secureForms: payu.SecureForms = payuSdkFormsObject.secureForms();
      const cardNumber: payu.SecureForm = secureForms.add('number', secureFormOptions);
      const cardDate: payu.SecureForm = secureForms.add('date', secureFormOptions);
      const cardCvv: payu.SecureForm = secureForms.add('cvv', secureFormOptions);

      cardNumber.render('#payu-card-number');
      cardDate.render('#payu-card-date');
      cardCvv.render('#payu-card-cvv');
      cardNumber.on('change', (body: payu.SecureFormChangeResponse): void => setIsCardValid(body.error === false));
      cardDate.on('change', (body: payu.SecureFormChangeResponse): void => setIsExpirationValid(body.error === false));
      cardCvv.on('change', (body: payu.SecureFormChangeResponse): void => setIsCvvValid(body.error === false));

      setFingerPrintPromise(FingerprintJS.load({ monitoring: false }));
    },
    []
  );

  const submitAction: () => void = (): void => {
    if (!payuSdkForms || !fingerPrintPromise) {
      return;
    }

    setIsLoading(true);
    void fingerPrintPromise
      .then((agent: Agent): Promise<GetResult> => agent.get())
      .then((fingerPrintResult: GetResult): void => {
        payuSdkForms.tokenize('MULTI')
          .then((result: payu.TokenizeResultSuccess | payu.TokenizeResultError): void => {
            if (result.status === 'SUCCESS') {
              addNewCreditCard({
                cardMask: result.body.mask,
                token: result.body.token,
                deviceFingerPrint: fingerPrintResult.visitorId,
              }).then((response: CreditCardAddRequestResponse): void => {
                if (response.status === PayuCreditCardRequestStatus.WarningContinue3ds && response.redirectUrl) {
                  redirect(response.redirectUrl, undefined, true);
                } else if (response.status === PayuCreditCardRequestStatus.Success) {
                  showSuccessPopupAfterPayuOperation();
                }
              }).catch((error: FrontendApiError): void => {
                showFullscreenAlert({
                  description: error.message,
                  iconImage: FullscreenAlertAssets.ErrorIcon,
                  acceptButtonSettings: {
                    label: t('chic.website.global.ok'),
                    action: hideFullscreenAlert,
                  },
                });
              });
            } else {
              showFullscreenAlert({
                description: result.error.messages
                  .map((messageItem: payu.SecureFormErrorMessage): string => messageItem.message)
                  .join(', '),
                iconImage: FullscreenAlertAssets.ErrorIcon,
                acceptButtonSettings: {
                  label: t('chic.website.global.ok'),
                  action: (): void => {
                    hideFullscreenAlert();
                  }
                },
              });
            }
          })
          // eslint-disable-next-line @typescript-eslint/naming-convention
          .catch((_: payu.TokenizeResultError): void => undefined);
      })
      .catch((): void => undefined)
      .finally((): void => setIsLoading(false));
  };

  return (
    <Container>
      <PageHeader
        header={t('chic.website.meta.profileAddCreditCard.title')}
        onArrowButtonAction={(): void => redirect(getRouteDetailsByName(RouteNameEnum.ProfilePaymentsSettings)?.url ?? '/')}
      />
      <ContentWrapper>
        <StyledContentBox contentBoxTheme={ContentBoxTheme.Medium1}>
          <PayuInputsHeader>{t('chic.website.addCreditCardView.formTitle')}</PayuInputsHeader>
          <PayuInputContainer>
            <PayuInputLabel>{t('chic.website.global.cardNumber.label')}</PayuInputLabel>
            <PayuCardForm id='payu-card-number' />
          </PayuInputContainer>
          <PayuInputContainer>
            <PayuInputLabel>{t('chic.website.global.expirationDate.label')}</PayuInputLabel>
            <PayuCardForm id='payu-card-date' />
          </PayuInputContainer>
          <PayuInputContainer>
            <PayuInputLabel>{t('chic.website.global.cvvCode.label')}</PayuInputLabel>
            <PayuCardForm id='payu-card-cvv' />
          </PayuInputContainer>
          <PayuInputDescription>{t('chic.website.addCreditCardView.cvvCodeDescription')}</PayuInputDescription>
          <StyledPayuAnnotations />
          <StyledButton
            label={t('chic.website.global.save')}
            onClick={submitAction}
            disabled={!isCardValid || !isExpirationValid || !isCvvValid}
            fullWidth={isMobile}
            isLoading={isLoading}
          />
        </StyledContentBox>
      </ContentWrapper>
    </Container>
  );
};
