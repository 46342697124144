export enum CacheName {
  Config = 'config',
  InpostParcelLockers = 'inpostParcelLockers',
  PickupPoints = 'pickupPoints',
  Products = 'products',
  Regulations = 'regulations',
  Stores = 'stores',
  SubscriptionsPlans = 'subscriptionsPlans',
  TransactionTypes = 'transactionTypes',
}
