import {
  UseState,
  SlideBox,
  VideoBox,
  UseRedirect,
  useRedirect,
  IconName,
  Icon,
  Color,
  useFormatDate,
  UseFormatDate,
  UseNotifications,
  useNotifications,
  Breakpoint,
  Button,
  ButtonTheme
} from '@chic-loyalty/ui';
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';

import { getPostDetails } from '@chic/frontend/api/requests';
import { FullscreenAlertAssets, QueryKey } from '@chic/frontend/enums';
import { useAltercloudRedirect, useAuth } from '@chic/frontend/hooks';
import { UseAltercloudRedirect, UseAuth } from '@chic/frontend/interfaces';
import { FrontendApiError } from '@chic/frontend/models';
import { PostContentAction, RouteNameEnum } from '@chic/shared/enums';
import { PostDetails, PostContent, PostImage } from '@chic/shared/interfaces';
import { getRouteDetailsByName } from '@chic/shared/utils';

import {
  PostMainImage,
  Container,
  PostWrapper,
  Header,
  Content,
  ArrowButton,
  Title,
  Label,
  PostContentBox,
  PostText,
  PostImg,
  PostImagesBox,
  PostImagesButton,
  StyledLoader
} from './singlePost.styled';

export const SinglePostView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { showFullscreenAlert, hideFullscreenAlert }: UseNotifications = useNotifications();
  const [postsDetails, setPostsDetails]: UseState<PostDetails | null> = useState<PostDetails | null>(null);
  const { redirect }: UseRedirect = useRedirect();
  const { id: postId }: Readonly<Record<string, string | undefined>> = useParams();
  const { handleAltercloudRedirect }: UseAltercloudRedirect = useAltercloudRedirect();
  const { formatDate }: UseFormatDate = useFormatDate();
  const isSmallMobile: boolean = useMediaQuery({ query: Breakpoint.SmallMobile });
  const isTablet: boolean = useMediaQuery({ query: Breakpoint.Tablet });
  const { userData }: UseAuth = useAuth();

  useQuery<PostDetails, FrontendApiError>(
    [QueryKey.PostsDetails],
    (): Promise<PostDetails> => getPostDetails(postId || ''),
    {
      onSuccess: setPostsDetails,
      onError: (error: FrontendApiError): void => {
        if (error.responseCode === 404) {
          showFullscreenAlert({
            description: t('chic.website.singlePost.postNotFound'),
            iconImage: FullscreenAlertAssets.ErrorIcon,
            acceptButtonSettings: {
              label: t('chic.website.global.ok'),
              action: (): void => {
                hideFullscreenAlert();
                redirect(getRouteDetailsByName(RouteNameEnum.PostsList)?.url ?? '/');
              }
            },
          });
        }
      },
      retry: false,
    }
  );

  const postContentActionFunctions: { [key in PostContentAction]: () => void } = {
    [PostContentAction.AltercloudRedirect]: handleAltercloudRedirect,
  };

  const handleAction: (action?: PostContentAction) => void = (action?: PostContentAction): void => {
    if (action) {
      const actionFunction: () => void = postContentActionFunctions[action];
      actionFunction();
    }
  };

  return (
    <Container>
      {!postsDetails && (
        <StyledLoader />
      )}
      {!!postsDetails && (
        <>
          <PostMainImage src={(isTablet && postsDetails.smallImage) ? postsDetails.smallImage : postsDetails.image} />
          <PostWrapper>
            <Header>
              <ArrowButton onClick={(): void => redirect(getRouteDetailsByName(RouteNameEnum.PostsList)?.url ?? '')}>
                <Icon name={IconName.ArrowBack} size={24} color={Color.Tangerine} />
              </ArrowButton>
              <Title>{postsDetails.title}</Title>
              <Label>{t('chic.website.singlePost.createdDate', { value: formatDate(postsDetails.createdDate, 'DD.MM.YYYY') })}</Label>
            </Header>
            <Content>
              <PostContentBox>
                {postsDetails.content?.map((item: PostContent, index: number): JSX.Element | undefined => {
                  if (item.type === 'imagesSlider' && item.images) {
                    const images: string[] = item.images.map((image: PostImage): string => image.desktopImg);
                    return <SlideBox slides={images} key={index} />;
                  }
                  if (item.type === 'video' && item.content) {
                    return <VideoBox video={item.content} key={index} />;
                  }
                  if (item.type === 'text' && item.content) {
                    return <PostText text={item.content || ''} key={index} />;
                  }
                  if (item.type === 'button' && item.buttonSettings) {
                    return (
                      <Button
                        buttonTheme={ButtonTheme.PrimaryOrange}
                        key={index}
                        label={item.buttonSettings.label}
                        onClick={(): void => handleAction(item?.buttonSettings?.action)}
                        fullWidth={isSmallMobile}
                      />
                    );
                  }
                  if (item.type === 'image' && item.images) {
                    if (item.action) {
                      return (
                        <PostImagesButton onClick={(): void => handleAction(item.action)} key={index}>
                          {item.images?.map((image: PostImage, imgIndex: number): JSX.Element => (
                            <PostImg
                              src={(isSmallMobile && image.mobileImg)
                                ? image.mobileImg
                                : image.desktopImg
                              }
                              key={imgIndex}
                            />
                          ))}
                        </PostImagesButton>
                      );
                    }
                    return (
                      <PostImagesBox key={index}>
                        {item.images?.map((image: PostImage, imgIndex: number): JSX.Element => (
                          <PostImg
                            src={(isSmallMobile && image.mobileImg)
                              ? image.mobileImg
                              : image.desktopImg
                            }
                            key={imgIndex}
                          />
                        ))}
                      </PostImagesBox>
                    );
                  }
                })}
              </PostContentBox>
            </Content>
          </PostWrapper>
        </>
      )}
    </Container>
  );
};
