import { 
  AdBox,
  DetailsInfoGroup, 
  IconName, 
  OrdersHistoryTable, 
  PageHeader, 
  SectionHeader, 
  UseRedirect, 
  getPathWithParams, 
  useRedirect
} from '@chic-loyalty/ui';
import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { FileFromViews } from '@chic/frontend/enums';
import { useSubscriptionsActions } from '@chic/frontend/hooks';
import { UseSubscriptionsActions } from '@chic/frontend/interfaces';
import { ProductAvailability, RouteNameEnum } from '@chic/shared/enums';
import { SubscriptionProductInfo } from '@chic/shared/interfaces';
import { IdValueObject } from '@chic/shared/models';
import { getRouteDetailsByName } from '@chic/shared/utils';

import { useSubscriptionDetails } from './subscriptionDetails.hooks';
import { 
  Container, 
  ContentContainer, 
  DetailsBox, 
  Subtitle, 
  TextContent, 
  StyledButton, 
  StyledTransactionProductBox,
  AdBoxWrapper,
  OrderHistoryTableWrapper
} from './subscriptionDetails.styled';
import { UseSubscriptionDetails } from './subscriptionDetails.types';

export const SubscriptionDetailsView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { id }: Readonly<Record<string, string | undefined>> = useParams();
  const { redirect }: UseRedirect = useRedirect();
  const { cancelSubscriptionAction }: UseSubscriptionsActions = useSubscriptionsActions();
  const { 
    detailsInfoSettings, 
    subscriptionDetails, 
    tableData, 
    canEditProducts,
    deliveryAddress,
    adBoxSettingsList,
    canChangePaymentMethod,
    paymentMethod
  }: UseSubscriptionDetails = useSubscriptionDetails(id ?? '');

  return (
    <Container>
      <PageHeader 
        header={t('chic.website.meta.subscriptionDetails.title')} 
        onArrowButtonAction={(): void => redirect(getRouteDetailsByName(RouteNameEnum.Subscriptions)?.url ?? '/')}
      />
      <ContentContainer>
        <DetailsBox>
          <DetailsInfoGroup items={detailsInfoSettings} />
        </DetailsBox>
        {!!adBoxSettingsList && (
          <AdBoxWrapper>
            <AdBox adBoxSettingsList={adBoxSettingsList} img={FileFromViews.SubscriptionsSmartphoneInHands} />
          </AdBoxWrapper>
        )}
        {!!deliveryAddress && (
          <DetailsBox>
            <SectionHeader
              title={t('chic.website.subscriptionDetailsView.sectionHeaderWithButton.deliveryAddress')}
              buttonSettings={{
                icon: IconName.Edit,
                label: t('chic.website.common.change'),
                internalPath: getPathWithParams(getRouteDetailsByName(RouteNameEnum.SubscriptionEditDelivery)?.url ?? '', { id: id ?? '' }),
              }}
              buttonDisabled={!subscriptionDetails?.active}
            />
            {deliveryAddress.map((address: string, index: number): JSX.Element => (
              <TextContent key={`${address}-${index}`}>
                {address}
              </TextContent>
            ))}
          </DetailsBox>
        )}
        {canChangePaymentMethod && (
          <DetailsBox>
            <SectionHeader
              title={t('chic.website.subscriptionDetailsView.sectionHeaderWithButton.paymentMethod')}
              buttonSettings={{
                icon: IconName.Edit,
                label: t('chic.website.common.change'),
                internalPath: getRouteDetailsByName(RouteNameEnum.ProfilePaymentsSettings)?.url ?? '',
              }}
              buttonDisabled={!subscriptionDetails?.active}
            />
            <TextContent>{paymentMethod}</TextContent>
          </DetailsBox>
        )}
        <DetailsBox>
          <SectionHeader
            title={t('chic.website.subscriptionDetailsView.sectionHeaderWithButton.productsList')}
            buttonSettings={{
              icon: IconName.Edit,
              label: t('chic.website.common.change'),
              internalPath: getPathWithParams(
                getRouteDetailsByName(RouteNameEnum.SubscriptionEditProducts)?.url ?? '', { subscriptionId: id ?? '' }
              ),
            }}
            buttonDisabled={!subscriptionDetails?.active || !canEditProducts}
          />
          {subscriptionDetails?.products.map((product: IdValueObject<number, number>): JSX.Element => {
            const productDetails: SubscriptionProductInfo | undefined = subscriptionDetails.plan.products
              .find((planProduct: SubscriptionProductInfo): boolean => planProduct.id === product.id);

            return (
              <StyledTransactionProductBox 
                key={product.id}
                name={productDetails?.name ?? '-'} 
                category={productDetails?.category?.name} 
                amount={product.value} 
                unavailable={
                  productDetails?.availability && [ProductAvailability.Unavailable, ProductAvailability.Unknown]
                    .includes(productDetails.availability)
                }
              />
            );
          })}
        </DetailsBox>
        <OrderHistoryTableWrapper>
          <Subtitle>{t('chic.website.subscriptionDetailsView.orderStatus')}</Subtitle>
          <OrdersHistoryTable orders={tableData} />
          {subscriptionDetails?.id && subscriptionDetails.active && (
            <StyledButton 
              label={t('chic.website.subscriptionDetailsView.cancelSubscriptionButton.label')} 
              onClick={(): void => cancelSubscriptionAction(subscriptionDetails.id)} 
            />
          )}
        </OrderHistoryTableWrapper>
      </ContentContainer>
    </Container>
  );
};
