import {
  PageHeader,
  PageHeaderPosition,
  InputWithLocation,
  UseState,
  ComponentColorTheme,
  DeliveryPointMap,
  MapPointDelivery,
  useRedirect,
  UseRedirect,
  Breakpoint,
  MailToUsTheme,
} from '@chic-loyalty/ui';
import { useQuery } from '@tanstack/react-query';
import { stringify } from 'query-string';
import React, { useState, useEffect, useMemo, RefObject, useRef } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Params, useParams, useSearchParams } from 'react-router-dom';

import { getParcelLockerDetailsById, getPickupPointDetails } from '@chic/frontend/api/requests';
import { QueryKey } from '@chic/frontend/enums';
import { useDeliveryMap } from '@chic/frontend/hooks/useDeliveryMap.hook';
import { UseDeliveryMap } from '@chic/frontend/interfaces';
import { UseSearchParams } from '@chic/frontend/types';
import { FrontendUrlsParams, RouteNameEnum, SubscriptionDeliveryCode } from '@chic/shared/enums';
import { ParcelLocker, PickupPoint } from '@chic/shared/interfaces';
import { getRouteDetailsByName } from '@chic/shared/utils';

import {
  Container,
  InnerContainer,
  SearchContainer,
  MapContainer,
  ListContainer,
  StyledSearchDeliveryListItem,
  StyledMailToUs,
  OuterListContainer,
  StyledButton
} from './subscriptionChooseDeliveryPoint.styled';

export const SubscriptionChooseDeliveryPointView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { id }: Readonly<Params<string>> = useParams();
  const [searchParams]: UseSearchParams = useSearchParams();
  const pointId: string = useMemo(
    (): string => (
      searchParams.get(FrontendUrlsParams.PointId)?.match(/^\d+$/) ? searchParams.get(FrontendUrlsParams.PointId) ?? '' : ''
    ),
    [searchParams]
  );
  const deliveryType: string = useMemo((): string => searchParams.get(FrontendUrlsParams.DeliveryType) ?? '', [searchParams]);
  const { redirect }: UseRedirect = useRedirect();
  const listRef: RefObject<HTMLDivElement> = useRef(null);
  const isTablet: boolean = useMediaQuery({ query: Breakpoint.Tablet });
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });
  const [isMapEnabled, setIsMapEnabled]: UseState<boolean> = useState<boolean>(false);

  const {
    inputValue,
    mapPoints,
    activePointId,
    pointPosition,
    shouldFlyToPoint,
    onSearchInputChange,
    onLocationButtonClick,
    onSelectDeliveryPoint,
    onPositionChange,
    setShouldFlyToPoint,
    setActivePointId,
    setPointPosition
  }: UseDeliveryMap = useDeliveryMap(listRef, deliveryType, pointId);

  useEffect(
    (): void => {
      if (!Object.values(SubscriptionDeliveryCode).includes(deliveryType as SubscriptionDeliveryCode)) {
        goBack();
      }
    },
    [deliveryType]
  );

  useQuery(
    [QueryKey.ParcelMachineId, pointId],
    (): Promise<ParcelLocker> => getParcelLockerDetailsById(parseInt(pointId, 10)),
    {
      enabled: !!pointId && deliveryType === SubscriptionDeliveryCode.InpostMachine,
      onSuccess: (data: ParcelLocker): void => {
        if (data.location.lat && data.location.lng) {
          setPointPosition([data.location.lat, data.location.lng]);
        }
      },
      // TODO: add logger
      onError: (): void => undefined
    }
  );

  useQuery(
    [QueryKey.PickupPointId, pointId],
    (): Promise<PickupPoint> => getPickupPointDetails(parseInt(pointId, 10)),
    {
      enabled: !!pointId && deliveryType === SubscriptionDeliveryCode.PickupPoint,
      onSuccess: (data: PickupPoint): void =>  setPointPosition([data.location.latitude, data.location.longitude]),
      // TODO: add logger
      onError: (): void => undefined
    }
  );

  useEffect((): void => setIsMapEnabled(true), []);

  const goBack: () => void = (): void => {
    redirect(getRouteDetailsByName(RouteNameEnum.SubscriptionEditDelivery)?.url ?? '', { id: id ?? '' });
  };

  return (
    <Container>
      <PageHeader
        header={t('chic.website.meta.subscriptionChooseDeliveryPoint.title')}
        headerPosition={PageHeaderPosition.Center}
        onArrowButtonAction={goBack}
      />
      <InnerContainer>
        <SearchContainer>
          <InputWithLocation
            inputValue={inputValue}
            onChange={onSearchInputChange}
            onLocationButtonClick={onLocationButtonClick}
            onPositionChange={onPositionChange}
            placeholder={t('chic.website.subscriptionChooseDeliveryPointView.inputPlaceholder')}
            colorTheme={ComponentColorTheme.Dark}
          />
          <OuterListContainer>
            <ListContainer ref={listRef}>
              {mapPoints.map((point: MapPointDelivery): JSX.Element => (
                <StyledSearchDeliveryListItem
                  data={point}
                  onChoose={(itemId: string): void => {
                    setActivePointId(parseInt(itemId, 10));
                    setShouldFlyToPoint(true);
                  }}
                  radioButtonName='points'
                  checked={point.id === activePointId}
                  key={point.id}
                />
              ))}
            </ListContainer>
          </OuterListContainer>
          <StyledMailToUs
            title={t('chic.website.global.mailToUs.title')}
            actionLabel={t('chic.website.global.mailToUs.actionLabel')}
            actionSettings={{ internalPath: getRouteDetailsByName(RouteNameEnum.SubscriptionContact)?.url ?? '' }}
            mailToUsTheme={MailToUsTheme.Gray}
          />
          <StyledButton
            label={t('chic.website.subscriptionChooseDeliveryPointView.confirm')}
            fullWidth={isMobile}
            onClick={(): void => {
              const url: string = getRouteDetailsByName(RouteNameEnum.SubscriptionEditDelivery)?.url ?? '';
              redirect(
                `${url}?${stringify({pointId: activePointId, deliveryType}, { skipEmptyString: true })}`,
                { id: id ?? '' }
              );
            }}
            disabled={!activePointId}
          />
        </SearchContainer>
        {!isTablet && isMapEnabled && (
          <MapContainer>
            <DeliveryPointMap
              points={mapPoints}
              onSelectDeliveryPoint={onSelectDeliveryPoint}
              enable
              currentPosition={pointPosition}
              shouldFlyToPosition={shouldFlyToPoint}
              onCoordinatesChange={(lat: number, lng: number): void => {
                setActivePointId(null);
                setPointPosition([lat, lng]);
                setShouldFlyToPoint(false);
              }}
            />
          </MapContainer>
        )}
      </InnerContainer>
    </Container>
  );
};
