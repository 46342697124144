import { IsDefined, IsString } from 'class-validator';

export class SendStatsForInitPwaRequest {
  @IsDefined()
  @IsString()
  public fingerPrint: string;

  constructor(
    fingerPrint: string,
  ) {
    this.fingerPrint = fingerPrint;
  }
}
