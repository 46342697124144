import { TransProps, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { ConfirmContactDataParams } from '@chic/frontend/interfaces';
import { profileValidationSmsCodeRegexp } from '@chic/shared/constants';

export const useSmsCodeValidation: () => Yup.SchemaOf<ConfirmContactDataParams> = (): Yup.SchemaOf<ConfirmContactDataParams> => {
  const { t }: TransProps<never> = useTranslation();

  return Yup.object().shape({
    smsCode: Yup.string()
      .matches(profileValidationSmsCodeRegexp, t('chic.website.global.validations.smsFormat'))
      .required(t('chic.website.global.requiredField'))
  });
};
