import { 
  PageHeader, 
  UseState, 
  Button, 
  ButtonTheme, 
  PostCard, 
  UseRedirect, 
  useRedirect, 
  useFormatDate, 
  UseFormatDate 
} from '@chic-loyalty/ui';
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { getPosts } from '@chic/frontend/api/requests';
import { QueryKey } from '@chic/frontend/enums';
import { RouteNameEnum } from '@chic/shared/enums';
import { Post } from '@chic/shared/interfaces';
import { getRouteDetailsByName } from '@chic/shared/utils';

import { 
  Container, 
  Content,
  Wrapper, 
  Header, 
  Posts,
  PostsGrid,
  HeaderImage,
  Image,
  HeaderContent, 
  Label, 
  HeaderTitle, 
  HeaderText, 
} from './postsList.styled';

export const PostsListView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const [posts, setPosts]: UseState<Post[]> = useState<Post[]>([]);
  const { redirect }: UseRedirect = useRedirect();
  const { formatDate }: UseFormatDate = useFormatDate();
  
  useQuery(
    [QueryKey.Posts],
    (): Promise<Post[]> => getPosts(),
    {
      onSuccess: setPosts,
      onError: (): void => undefined
    }
  );

  return (
    <Container>
      <PageHeader
        header={t('chic.website.meta.postsList.title')}
      />
      <Content>
        <Wrapper>
          {!!posts[0] && (
            <Header>
              <HeaderImage>
                <Image src={posts[0].thumbnailImage} />
              </HeaderImage>
              <HeaderContent>
                <Label>{t('chic.ui.postCard.createdDate', { value: formatDate(posts[0].createdDate, 'DD.MM.YYYY') })}</Label>
                <HeaderTitle>{posts[0].title}</HeaderTitle>
                <HeaderText>{posts[0].description}</HeaderText>
                <Button
                  buttonTheme={ButtonTheme.TextOrange}
                  label={t('chic.ui.global.show')}
                  onClick={(): void => redirect(`${getRouteDetailsByName(RouteNameEnum.PostsList)?.url || '/co-nowego'}/${posts[0].id}`)}
                />
              </HeaderContent>
            </Header>
          )}
        </Wrapper>
        {!!posts.length && (
          <Posts>
            <PostsGrid>
              {posts.slice(1).map((item: Post): JSX.Element => (
                <PostCard
                  key={item.title}
                  cover={item.thumbnailImage} 
                  title={item.title} 
                  text={item.description} 
                  createdTimestamp={item.createdDate}
                  onButtonClick={
                    (): void => redirect(`${getRouteDetailsByName(RouteNameEnum.PostsList)?.url || '/co-nowego'}/${item.id}`)
                  }
                />
              ))}
            </PostsGrid>
          </Posts>
        )}
      </Content>
    </Container>
  );
};
