import { 
  Breakpoint,
  Button,
  ButtonSize,
  ButtonTheme,
  UseNotifications,
  UseRedirect,
  useNotifications,
  useRedirect
} from '@chic-loyalty/ui';
import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';

import { getUrlForEscUserMigrateToIcWithoutAuth } from '@chic/frontend/api/requests';
import { FileFromViews, FullscreenAlertAssets } from '@chic/frontend/enums';
import { useAuth } from '@chic/frontend/hooks';
import { UseAuth } from '@chic/frontend/interfaces';
import { FrontendApiError } from '@chic/frontend/models';
import { RouteNameEnum } from '@chic/shared/enums';
import { getRouteDetailsByName } from '@chic/shared/utils';

import { 
  Container, 
  ContentWrapper, 
  Content, 
  HeadingWrapper, 
  Heading, 
  HeadingText,
  ImageWrapper, 
  Image 
} from './icMigrationLanding.styled';

export const IcMigrationLandingView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { customerHash, authHash }: Readonly<Record<string, string | undefined>> = useParams();
  const { showFullscreenAlert, hideFullscreenAlert }: UseNotifications = useNotifications();
  const { redirect }: UseRedirect = useRedirect();
  const { userData, signOut }: UseAuth = useAuth();
  const isSmallMobile: boolean = useMediaQuery({ query: Breakpoint.SmallMobile });

  const onSubmit: () => void = (): void => {
    getUrlForEscUserMigrateToIcWithoutAuth(customerHash ?? '', authHash ?? '')
      .then((path: string): void => {
        if (userData) {
          signOut(undefined, false);
        }
        redirect(path, undefined, true);
      })
      .catch((error: FrontendApiError): void => {
        showFullscreenAlert({
          description: t(error.message),
          iconImage: FullscreenAlertAssets.ErrorIcon,
          acceptButtonSettings: {
            label: t('chic.website.global.ok'),
            action: (): void => {
              hideFullscreenAlert();
              redirect(getRouteDetailsByName(RouteNameEnum.Home)?.url ?? '/');
            },
          },
        });
      });
  };

  return (
    <Container>
      <ContentWrapper $withLeftSpacing={!!userData}>
        <Content>
          <HeadingWrapper>
            <Heading>{t('chic.website.icMigrationLandingView.heading')}</Heading>
            <HeadingText text={t('chic.website.icMigrationLandingView.headingText')} />
          </HeadingWrapper>
          <Button 
            label={t('chic.website.icMigrationLandingView.submit')} 
            buttonTheme={ButtonTheme.PrimaryOrange} 
            size={ButtonSize.Small} 
            type='submit'
            onClick={onSubmit}
            fullWidth={isSmallMobile}
          />
        </Content>
      </ContentWrapper>
      <ImageWrapper>
        <Image src={FileFromViews.IcMigrationLandingBackground} />
      </ImageWrapper>
    </Container>
  );
};
