import {
  americanPhoneRegExp,
  unitedKingdomPhoneRegExp,
  czechPhoneRegExp,
  germanPhoneRegExp,
  irelandPhoneRegExp,
  lithuanianPhoneRegExp,
  norwayPhoneRegExp,
  polishPhoneRegExp,
  slovakPhoneRegExp,
  spainPhoneRegExp,
  ukrainianPhoneRegExp
} from './phones';

export const phoneRegexp: RegExp = new RegExp([
  americanPhoneRegExp,
  czechPhoneRegExp,
  germanPhoneRegExp,
  lithuanianPhoneRegExp,
  norwayPhoneRegExp,
  polishPhoneRegExp,
  slovakPhoneRegExp,
  spainPhoneRegExp,
  ukrainianPhoneRegExp,
  irelandPhoneRegExp,
  unitedKingdomPhoneRegExp,
].map((regex: RegExp, index: number): string => regex.source.replace('<phone>', `<phone${index}>`)).join('|'));
