import { IsDefined, IsEmail } from 'class-validator';

export class ConfirmUnverifiedEmailCoreRequest {
  @IsDefined()
  @IsEmail()
  public value: string;

  constructor(
    value: string,
  ) {
    this.value = value;
  }
}
