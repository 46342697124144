import { IsDefined, IsString } from 'class-validator';

import { UpdateUserAgreementsRequest } from './updateUserAgreementsRequest.model';

export class UpdateUserAgreementsWithClientBrowserRequest extends UpdateUserAgreementsRequest {
  @IsDefined()
  @IsString()
  public clientIp: string;

  @IsDefined()
  @IsString()
  public clientBrowser: string;

  constructor(
    clientIp: string,
    clientBrowser: string,
    marketingAgreement?: number | null,
    marketingAdditionalAgreement?: number | null,
    marketingSocialAgreement?: number | null,
    phoneSurveyAgreement?: number | null,
  ) {
    super(marketingAgreement, marketingAdditionalAgreement, marketingSocialAgreement, phoneSurveyAgreement);
    this.clientIp = clientIp;
    this.clientBrowser = clientBrowser;
  }
}
