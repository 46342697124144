import { FileFromViews } from '@chic/frontend/enums';
import { CreditCardProviderType } from '@chic/shared/enums';

export function getCreditCardLogo(provider: CreditCardProviderType): FileFromViews {
  switch (provider) {
    case CreditCardProviderType.Visa:
      return FileFromViews.PaymentsSettingsVisaLogo;
    case CreditCardProviderType.Mastercard:
      return FileFromViews.PaymentsSettingsMastercardLogo;
    default:
      return FileFromViews.PaymentsSettingsDefaultCardLogo;
  }
}
