import { ButtonTheme, Color, Icon, IconName, MailToUsTheme, PageHeader, ProductBoxSettings } from '@chic-loyalty/ui';
import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { RouteNameEnum } from '@chic/shared/enums';
import { SubscriptionPlanGroup } from '@chic/shared/interfaces';
import { getRouteDetailsByName } from '@chic/shared/utils';

import {
  ButtonsContainer,
  ButtonsWrapper,
  Content,
  ContentTitle,
  GroupContainer,
  IconContainer,
  ItemsCountMessage,
  MissingGroupsItemsNames,
  OuterContainer,
  ProductsList,
  StyledButton,
  StyledInput,
  StyledMailToUs,
  StyledProductBox
} from './baseViewWithEditProducts.styled';
import { BaseViewWithEditProductsProps } from './baseViewWithEditProducts.types';

export const BaseViewWithEditProducts: React.FC<BaseViewWithEditProductsProps> = (props: BaseViewWithEditProductsProps): JSX.Element => {
  const {
    setSearchedProductName,
    subscriptionPlan,
    getProductMaxValue,
    filteredSubscriptionProducts,
    goBack,
    stepNumber,
    missingGroupsItemsNames,
    onSaveButtonAction,
    isEditValid,
    cancelEdit
  }: BaseViewWithEditProductsProps = props;
  const { t }: TransProps<never> = useTranslation();

  return (
    <OuterContainer>
      <PageHeader
        header={t(`chic.website.baseViewWithEditProducts.title.${stepNumber}`)}
        onArrowButtonAction={goBack}
      >
        {!!missingGroupsItemsNames && (
          <MissingGroupsItemsNames
            text={t('chic.website.baseViewWithEditProducts.missingGroupsItemsNames', { missingGroupsItemsNames })}
          />
        )}
      </PageHeader>
      {stepNumber === 1 && (
        <>
          <Content>
            <StyledInput
              placeholder={t('chic.website.baseViewWithEditProducts.input.placeholder')}
              icon={IconName.Search}
              onChange={setSearchedProductName}
            />
            {subscriptionPlan.groups.map((group: SubscriptionPlanGroup): JSX.Element => {
              return filteredSubscriptionProducts[group.id]
                ? (
                  <GroupContainer key={group.id}>
                    <ContentTitle>
                      {t('chic.website.baseViewWithEditProducts.groupContainer.contentTitle')}
                      {group.name}
                      <IconContainer $background={getProductMaxValue(group.id) === 0 ? Color.Success : Color.Validation}>
                        <Icon
                          size={24}
                          name={getProductMaxValue(group.id) === 0 ? IconName.Check : IconName.Error}
                          color={getProductMaxValue(group.id) === 0 ? Color.AlmostWhite : Color.AlmostBlack}
                        />
                      </IconContainer>
                    </ContentTitle>
                    <ItemsCountMessage>
                      {t(
                        'chic.website.baseViewWithEditProducts.itemsCountMessage',
                        { value: filteredSubscriptionProducts[group.id].length }
                      )}
                    </ItemsCountMessage>
                    <ProductsList>
                      {filteredSubscriptionProducts[group.id].map((product: ProductBoxSettings): JSX.Element => (
                        <StyledProductBox
                          {...product}
                          key={product.label}
                        />
                      ))}
                    </ProductsList>
                  </GroupContainer>
                ) : (
                  <></>
                );
            })}
            <StyledMailToUs
              title={t('chic.website.global.mailToUs.title')}
              actionLabel={t('chic.website.global.mailToUs.actionLabel')}
              actionSettings={{ internalPath: getRouteDetailsByName(RouteNameEnum.SubscriptionContact)?.url ?? '' }}
              mailToUsTheme={MailToUsTheme.Orange}
            />
          </Content>
          <ButtonsWrapper>
            <ButtonsContainer>
              <StyledButton
                label={t('chic.website.global.save')}
                buttonTheme={ButtonTheme.PrimaryOrange}
                onClick={onSaveButtonAction}
                disabled={!isEditValid}
              />
              <StyledButton
                label={t('chic.website.global.cancel')}
                buttonTheme={ButtonTheme.SecondaryWhite}
                onClick={cancelEdit}
              />
            </ButtonsContainer>
          </ButtonsWrapper>
        </>
      )}
    </OuterContainer>
  );
};
