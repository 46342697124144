import { TransProps, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { passwordRegexp } from '@chic/shared/constants';

import { SetPasswordFormData } from './setPassword.types';

export const useSetPasswordValidation: () => Yup.SchemaOf<SetPasswordFormData>
= (): Yup.SchemaOf<SetPasswordFormData> => {
  const { t }: TransProps<never> = useTranslation();

  return Yup.object().shape({
    password: Yup.string()
      .required(t('chic.website.global.requiredField'))
      .matches(passwordRegexp, t('chic.website.global.validations.passwordFormat'))
      .oneOf([Yup.ref('repeatedPassword'), null], t('chic.website.global.validations.passwordsMustMatch')),
    repeatedPassword: Yup.string()
      .required(t('chic.website.global.requiredField'))
      .matches(passwordRegexp, t('chic.website.global.wrongValue'))
      .oneOf([Yup.ref('password'), null], t('chic.website.global.validations.passwordsMustMatch')),
  });
};
