import { useNotifications, UseNotifications, useRedirect, UseRedirect } from '@chic-loyalty/ui';
import { useTranslation, TransProps } from 'react-i18next';

import { getAltercloudRedirectPath } from '@chic/frontend/api/requests';
import { FullscreenAlertAssets } from '@chic/frontend/enums';
import { UseAltercloudRedirect } from '@chic/frontend/interfaces';
import { FrontendApiError } from '@chic/frontend/models';

export const useAltercloudRedirect: () => UseAltercloudRedirect = (): UseAltercloudRedirect => {
  const { redirect }: UseRedirect = useRedirect();
  const { showFullscreenAlert, hideFullscreenAlert }: UseNotifications = useNotifications();
  const { t }: TransProps<never> = useTranslation();
  
  const handleAltercloudRedirect = (): void => {
    getAltercloudRedirectPath()
      .then((path: string): void => redirect(path, undefined, true))
      .catch((error: FrontendApiError): void => {
        showFullscreenAlert({
          title: t('chic.website.global.error'),
          description: error.message,
          iconImage: FullscreenAlertAssets.ErrorIcon,
          acceptButtonSettings: {
            label: t('chic.website.global.ok'),
            action: hideFullscreenAlert
          },
        });
      });
  };

  return { handleAltercloudRedirect };
};
