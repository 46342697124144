import {
  BoxesSlider,
  DropdownOption,
  HeroSlide,
  HighlightedNews,
  OfferCard,
  PlanCategoryCard,
  ProductBox,
  SectionTitleTheme,
  StoreMap,
  UseRedirect,
  useRedirect,
  UseState,
  UseTransformations,
  useTransformations
} from '@chic-loyalty/ui';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import React, { useEffect, useMemo, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import {
  getConfig,
  getPosts,
  getPromotionalProducts,
  getPromotions,
  getPromotionsForUnlock,
  getSubscriptionsPlansCategories,
} from '@chic/frontend/api/requests';
import { QueryKey } from '@chic/frontend/enums';
import { useAuth, useOnlineState, useStoreMap } from '@chic/frontend/hooks';
import { UseAuth, UseOnlineState, UseStoreMap } from '@chic/frontend/interfaces';
import { FrontendUrlsParams, RouteNameEnum } from '@chic/shared/enums';
import {
  Post,
  Product,
  Promotion,
  Store,
  SubscriptionPlanCategoryWithImg
} from '@chic/shared/interfaces';
import { SystemConfig } from '@chic/shared/types';
import { getRouteDetailsByName } from '@chic/shared/utils';

import { useDashboardHeroSlides } from './dashboard.hooks';
import {
  BannerWithPlansSection,
  Container,
  InnerGridWrapper,
  MapContainer,
  OffersWithWhatsNewSection,
  PromotionalProductsSection,
  StoresSection,
  StyledFavoriteStore,
  StyledHeroSlider,
  StyledSectionTitle,
  WhatsNewSection
} from './dashboard.styled';
import { PromotionsTab } from './dashboard.types';

export const DashboardView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { userData }: UseAuth = useAuth();
  const { transformImageToDark }: UseTransformations = useTransformations();
  const [subscriptionPlansCategories, setSubscriptionPlansCategories]: UseState<SubscriptionPlanCategoryWithImg[]>
    = useState<SubscriptionPlanCategoryWithImg[]>([]);
  const [promotionalProducts, setPromotionalProducts]: UseState<Product[]> = useState<Product[]>([]);
  const [promotions, setPromotions]: UseState<Promotion[]> = useState<Promotion[]>([]);
  const [promotionsForUnlock, setPromotionsForUnlock]: UseState<Promotion[]> = useState<Promotion[]>([]);
  const [promotionsActiveTab, setPromotionsActiveTab]: UseState<PromotionsTab> = useState<PromotionsTab>('activeOffers');
  const [postDetails, setPostDetails]: UseState<Post | null> = useState<Post | null>(null);
  const { redirect }: UseRedirect = useRedirect();
  const { changeLikeStoreStateAction, mapPoints, stores, favouriteStores }: UseStoreMap = useStoreMap();
  const heroSlides: HeroSlide[] = useDashboardHeroSlides();
  const offersTabs: DropdownOption[] | undefined = useMemo((): DropdownOption[] | undefined => {
    return (promotionsForUnlock.length && promotions.length) ? [
      { name: 'activeOffers', label: t('chic.website.dashboardView.promotionsSection.activeOffers') },
      { name: 'unlockOffers', label: t('chic.website.dashboardView.promotionsSection.unlockOffers') },
    ] : undefined;
  }, [promotionsForUnlock, promotions]);
  const [isMapEnabled, setIsMapEnabled]: UseState<boolean> = useState<boolean>(false);
  const { isAppOnline }: UseOnlineState = useOnlineState();

  const { data: systemConfig }: UseQueryResult<SystemConfig> = useQuery(
    [QueryKey.Config],
    (): Promise<SystemConfig> => getConfig(),
  );

  useQuery(
    [QueryKey.SubscriptionPlansCategories],
    (): Promise<SubscriptionPlanCategoryWithImg[]> => getSubscriptionsPlansCategories(),
    {
      onSuccess: (data: SubscriptionPlanCategoryWithImg[]): void => {
        setSubscriptionPlansCategories(data);
      },
      // TODO: add logger
      onError: (): void => undefined
    }
  );

  useQuery(
    [QueryKey.PromotionalProducts],
    (): Promise<Product[]> => getPromotionalProducts(),
    {
      onSuccess: (data: Product[]): void => setPromotionalProducts(data),
      // TODO: add logger
      onError: (): void => undefined
    }
  );

  useQuery(
    [QueryKey.Promotions],
    (): Promise<Promotion[]> => getPromotions(),
    {
      onSuccess: (data: Promotion[]): void => setPromotions(data),
      // TODO: add logger
      onError: (): void => undefined
    }
  );

  useQuery(
    [QueryKey.PromotionsForUnlock],
    (): Promise<Promotion[]> => getPromotionsForUnlock(),
    {
      onSuccess: (data: Promotion[]): void => setPromotionsForUnlock(data),
      // TODO: add logger
      onError: (): void => undefined
    }
  );

  useQuery(
    [QueryKey.Posts],
    (): Promise<Post[]> => getPosts(),
    {
      onSuccess: (data: Post[]): void => setPostDetails(data[0]),
      // TODO: add logger
      onError: (): void => undefined
    }
  );

  useEffect((): void => setIsMapEnabled(true), []);

  const onPlanCategoryClick = (id: number): void => {
    const url: string | undefined = getRouteDetailsByName(RouteNameEnum.Subscriptions)?.url;
    redirect(url ? `${url}?${FrontendUrlsParams.AvailablePlan}=${id}` : '');
  };

  return (
    <Container>
      <BannerWithPlansSection>
        <InnerGridWrapper>
          <StyledHeroSlider slides={heroSlides} />
          {systemConfig?.subscriptions && (
            <>  
              <StyledSectionTitle
                title={t('chic.website.dashboardView.subscriptionPlansSection.title')}
                subtitle={t('chic.website.dashboardView.subscriptionPlansSection.subtitle')}
                onShowMoreButtonClick={(): void => redirect(getRouteDetailsByName(RouteNameEnum.Subscriptions)?.url ?? '', undefined, true)}
              />
              <BoxesSlider cursorLabel={t('chic.website.global.move')}>
                {subscriptionPlansCategories.map((planCategory: SubscriptionPlanCategoryWithImg): JSX.Element => (
                  <PlanCategoryCard
                    label={planCategory.name}
                    key={planCategory.id}
                    image={transformImageToDark(planCategory.packshot)}
                    onClick={(): void => onPlanCategoryClick(planCategory.id)}
                  />
                ))}
              </BoxesSlider>
            </>
          )}
        </InnerGridWrapper>
      </BannerWithPlansSection>
      <OffersWithWhatsNewSection>
        <InnerGridWrapper>
          <StyledSectionTitle
            title={t('chic.website.dashboardView.promotionsSection.title')}
            subtitle={t('chic.website.dashboardView.promotionsSection.subtitle')}
            onShowMoreButtonClick={(): void => redirect(getRouteDetailsByName(RouteNameEnum.Offers)?.url ?? '')}
            onTabChange={(tabName: string): void => setPromotionsActiveTab(tabName as PromotionsTab)}
            tabs={offersTabs}
          />
          <BoxesSlider cursorLabel={t('chic.website.global.move')}>
            {promotionsActiveTab === 'unlockOffers' ? (
              promotionsForUnlock.map((promotion: Promotion): JSX.Element => (
                <OfferCard
                  cover={promotion.image ?? ''}
                  title={promotion.name}
                  key={promotion.id}
                  deadlineTimestamp={new Date(promotion.dateTo).getTime()}
                  points={promotion.cost ?? undefined}
                  pointsActive={userData?.points && promotion.cost
                    ? userData?.points >= promotion.cost
                    : false
                  }
                  unlocksCount={promotion.amount}
                  onButtonClick={(): void => redirect(getRouteDetailsByName(RouteNameEnum.OfferDetails)?.url ?? '', { id: promotion.id })}
                />
              ))
            ) : (
              promotions
                .filter((promotion: Promotion): boolean => promotion.unlocked || promotion.cost === null)
                .map((promotion: Promotion): JSX.Element => (
                  <OfferCard
                    cover={promotion.image ?? ''}
                    title={promotion.name}
                    key={promotion.id}
                    deadlineTimestamp={new Date(promotion.dateTo).getTime()}
                    points={promotion.cost ?? undefined}
                    unlocksCount={promotion.amount}
                    onButtonClick={(): void => redirect(getRouteDetailsByName(RouteNameEnum.OfferDetails)?.url ?? '', { id: promotion.id })}
                  />
                ))
            )}
          </BoxesSlider>
          <WhatsNewSection>
            <StyledSectionTitle
              title={t('chic.website.dashboardView.whatsNewSection.title')}
              subtitle={t('chic.website.dashboardView.whatsNewSection.subtitle')}
              onShowMoreButtonClick={(): void => redirect(getRouteDetailsByName(RouteNameEnum.PostsList)?.url ?? '')}
            />
            {postDetails && (
              <HighlightedNews
                image={postDetails.thumbnailImage}
                title={postDetails.title}
                dateTimestamp={postDetails.createdDate}
                introduction={postDetails.description}
                onButtonClick={(): void => redirect(getRouteDetailsByName(RouteNameEnum.SinglePost)?.url ?? '', { id: postDetails.id })}
              />
            )}
          </WhatsNewSection>
        </InnerGridWrapper>
      </OffersWithWhatsNewSection>
      <PromotionalProductsSection>
        <InnerGridWrapper>
          <StyledSectionTitle
            title={t('chic.website.dashboardView.promotionalProducts.title')}
            subtitle={t('chic.website.dashboardView.promotionalProducts.subtitle')}
            sectionTitleTheme={SectionTitleTheme.DarkWithLightButton}
            onShowMoreButtonClick={(): void => redirect(getRouteDetailsByName(RouteNameEnum.Products)?.url ?? '')}
          />
          <BoxesSlider cursorLabel={t('chic.website.global.move')}>
            {promotionalProducts.map((promotionalProduct: Product): JSX.Element => (
              <ProductBox
                key={promotionalProduct.id}
                label={promotionalProduct.name}
                image={promotionalProduct.photos.length ? promotionalProduct.photos[0] : undefined}
                points={promotionalProduct.rewardPoints}
                onButtonClick={
                  (): void => redirect(getRouteDetailsByName(RouteNameEnum.ProductDetails)?.url ?? '', { id: promotionalProduct.id })
                }
              />
            ))}
          </BoxesSlider>
        </InnerGridWrapper>
      </PromotionalProductsSection>
      {!!favouriteStores.length && (
        <StoresSection>
          <InnerGridWrapper>
            <StyledSectionTitle
              title={t('chic.website.dashboardView.storesSection.title')}
              subtitle={t('chic.website.dashboardView.storesSection.subtitle')}
              sectionTitleTheme={SectionTitleTheme.Dark}
            />
            {favouriteStores.map((favouriteStore: Store): JSX.Element => (
              <StyledFavoriteStore
                key={favouriteStore.id}
                isFranchise={favouriteStore.isFranchise}
                name={favouriteStore.name}
                address={favouriteStore.address}
                openingHours={favouriteStore.openingHours}
                temporaryClosed={favouriteStore.temporaryClosed}
                onFavoriteButtonClick={(): void => changeLikeStoreStateAction(favouriteStore.id, false)}
              />
            ))}
          </InnerGridWrapper>
        </StoresSection>
      )}
      {!!stores.length && isMapEnabled && isAppOnline && (
        <MapContainer>
          <StoreMap
            points={mapPoints}
            onFavoriteButtonClick={(id: number, isFavorite: boolean): void => changeLikeStoreStateAction(id, !isFavorite)}
            enable
          />
        </MapContainer>
      )}
    </Container>
  );
};
