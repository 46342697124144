import { PageHeader, StoreMap, UseRedirect, UseState, useRedirect } from '@chic-loyalty/ui';
import React, { useEffect, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { useAuth, useOnlineState, useStoreMap } from '@chic/frontend/hooks';
import { UseAuth, UseOnlineState, UseStoreMap } from '@chic/frontend/interfaces';
import { RouteNameEnum } from '@chic/shared/enums';
import { getRouteDetailsByName } from '@chic/shared/utils';

import { Container, MapContainer } from './findUs.styled';

export const FindUsView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { isUserLoggedIn }: UseAuth = useAuth();
  const { redirect }: UseRedirect = useRedirect();
  const { changeLikeStoreStateAction, mapPoints, stores }: UseStoreMap = useStoreMap();
  const [isMapEnabled, setIsMapEnabled]: UseState<boolean> = useState<boolean>(false);
  const { isAppOnline }: UseOnlineState = useOnlineState();

  useEffect((): void => setIsMapEnabled(true), []);

  return (
    <Container $isUserLoggedIn={isUserLoggedIn()}>
      <PageHeader 
        header={t('chic.website.findUsView.pageHeader')} 
        onArrowButtonAction={(): void => redirect(getRouteDetailsByName(
          isUserLoggedIn() ? RouteNameEnum.Dashboard : RouteNameEnum.Home
        )?.url ?? '/')}
      />
      {!!stores.length && isMapEnabled && (
        <MapContainer>
          {isAppOnline && (
            <StoreMap
              points={mapPoints}
              onFavoriteButtonClick={
                isUserLoggedIn() 
                  ? (id: number, isFavorite: boolean): void => changeLikeStoreStateAction(id, !isFavorite) 
                  : undefined
              }
              enable
            />
          )}
        </MapContainer>
      )}
    </Container>
  );
};
