import { Breakpoint, Color, FontWeight, Grid, StyledComponent, MailBox } from '@chic-loyalty/ui';
import styled from 'styled-components';

import { FileFromViews } from '@chic/frontend/enums';

import { ContainerProps } from './contact.types';

export const Container: StyledComponent<'div', ContainerProps> = styled.div<ContainerProps>`
  background-color: ${Color.Level1};
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ $withLeftPadding }: ContainerProps): string | false => $withLeftPadding && 'padding-left: 80px'};

  @media ${Breakpoint.Mobile} {
    padding-left: 0;
  }
`;

export const TopSection: StyledComponent<'div'> = styled.div`
  padding: 100px 20px 84px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(${FileFromViews.ContactViewHeaderBackground});
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;

  @media ${Breakpoint.Mobile} {
    background-image: url(${FileFromViews.ContactViewHeaderBackgroundMobile});
    background-position: bottom right -90px;
  }
`;

export const CenterSection: StyledComponent<'div'> = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 80px 20px 160px;
  background-color: ${Color.AlmostBlack};

  @media ${Breakpoint.Tablet} {
    padding: 50px 20px 70px;
  }
`;

export const GridContainer: StyledComponent<'div'> = styled.div`
  max-width: ${Grid.Desktop};
  width: 100%;
`;

export const Title: StyledComponent<'h2'> = styled.h2`
  font-weight: ${FontWeight.Medium};
  font-size: 36px;
  line-height: 47px;
  color: ${Color.White};
  margin-bottom: 10px;
  max-width: 550px;

  @media ${Breakpoint.Mobile} {
    font-size: 25px;
    line-height: 35px;
  }
`;

export const Subtitle: StyledComponent<typeof Title> = styled(Title)`
  color: ${Color.Tangerine};
`;

export const PhoneBoxes: StyledComponent<'div'> = styled.div`
  display: grid;
  grid-template-columns: repeat(3,1fr);
  gap: 15px;
  margin-top: 70px;

  @media ${Breakpoint.Mobile} {
    grid-template-columns: 1fr;
    margin-top: 35px;
  }
`;

export const Hours: StyledComponent<'p'> = styled.p`
  font-size: 12px;
  line-height: 19px;
  color: ${Color.SolidMediumEmphasis};
  margin-top: 15px;
`;

export const Row: StyledComponent<'div'> = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 80px;

  & + & {
    padding-bottom: 0;
    padding-top: 80px;
    border-top: 1px solid ${Color.SolidLowestEmphasis};
  }

  @media ${Breakpoint.Tablet} {
    flex-direction: column;
  }

  @media ${Breakpoint.Mobile} {
    padding: 50px 0;
  }
`;

export const RowTitle: StyledComponent<'h2'> = styled.h2`
  font-weight: ${FontWeight.Medium};
  font-size: 25px;
  line-height: 35px;
  letter-spacing: -0.01em;
  color: ${Color.SolidHighEmphasis};
  max-width: 280px;
  margin-bottom: 35px;

  @media ${Breakpoint.Mobile} {
    font-size: 20px;
    line-height: 28px;
    max-width: unset;
  }
`;

export const StyledMailBox: StyledComponent<typeof MailBox> = styled(MailBox)`
  & + & {
    margin-top: 10px;
  }
`;

export const RowRight: StyledComponent<'div'> = styled.div`
  max-width: 712px;
  width: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  margin-left: 20px;

  @media ${Breakpoint.Tablet} {
    width: 100%;
    margin-left: 0;
  }
`;
