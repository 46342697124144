import { 
  UseRedirect, 
  useRedirect, 
  UseState, 
  ProductBoxSettings, 
  UseNotifications, 
  useNotifications, 
  getPathWithParams, 
} from '@chic-loyalty/ui';
import { useEffect, useMemo, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { upgradeSubscriptionPlan, updateSubscriptionProducts } from '@chic/frontend/api/requests';
import { FullscreenAlertAssets } from '@chic/frontend/enums';
import { UseSubscriptionEditProducts } from '@chic/frontend/interfaces';
import { FrontendApiError } from '@chic/frontend/models';
import { RouteNameEnum, ProductAvailability } from '@chic/shared/enums';
import { SubscriptionDetails, SubscriptionPlan, SubscriptionPlanGroup, SubscriptionProductInfo } from '@chic/shared/interfaces';
import { IdValueObject } from '@chic/shared/models';
import { getRouteDetailsByName } from '@chic/shared/utils';

export const useSubscriptionEditProducts: (
  subscriptionPlan?: SubscriptionPlan, withMigrationFlow?: boolean
) => UseSubscriptionEditProducts = (
  subscriptionPlan?: SubscriptionPlan, withMigrationFlow: boolean = false,
): UseSubscriptionEditProducts => {
  const { redirect }: UseRedirect = useRedirect();
  const { subscriptionId }: Readonly<Record<string, string | undefined>> = useParams();
  const { t }: TransProps<never> = useTranslation();
  const { showFullscreenAlert, hideFullscreenAlert }: UseNotifications = useNotifications();
  const [searchedProductName, setSearchedProductName]: UseState<string> = useState<string>('');
  const [productMaxCount, setProductMaxCount]: UseState<Record<number, number>> = useState<Record<number, number>>({});
  const [chosenProducts, setChosenProducts]: UseState<Record<number, IdValueObject<number, number>[]>>
    = useState<Record<number, IdValueObject<number, number>[]>>({});
  const [productsIdsChosenInSubscription, setProductsIdsChosenInSubscription]: UseState<number[]> = useState<number[]>([]);
  const [stepNumber, setStepNumber]: UseState<number> = useState<number>(1);
  const [filteredSubscriptionProducts, setFilteredSubscriptionProducts]: UseState<Record<number, ProductBoxSettings[]>> 
    = useState<Record<number, ProductBoxSettings[]>>({});
  const [chosenProductsSummary, setChosenProductsSummary]: UseState<SubscriptionProductInfo[]> = useState<SubscriptionProductInfo[]>([]);
  const [isSubmitRequestLoading, setIsSubmitRequestLoading]: UseState<boolean> = useState<boolean>(false);

  const goBack: () => void = (): void => {
    if (stepNumber === 1) {
      cancelEdit();
    } else if (stepNumber === 2) {
      setStepNumber(1);
      window.scrollTo(0, 0);
    }
  };

  const cancelEdit: () => void = (): void => {
    if (withMigrationFlow) {
      redirect(getRouteDetailsByName(RouteNameEnum.SubscriptionPlanUpgradeConditions)?.url ?? '', { subscriptionId: subscriptionId ?? '' });
    } else {
      redirect(getRouteDetailsByName(RouteNameEnum.SubscriptionDetails)?.url ?? '', { id: subscriptionId ?? '' });
    }
  };

  const isEditValid: boolean = useMemo(
    (): boolean => {
      const chosenProductsCount: number = Object.values(chosenProducts).flat().reduce(
        (accumulator: number, item: IdValueObject<number, number>): number => accumulator + item.value, 0
      );
      const maxProductsCount: number = Object.values(productMaxCount)
        .reduce((accumulator: number, item: number): number => accumulator + item, 0);

      return chosenProductsCount === maxProductsCount;
    },
    [chosenProducts, filteredSubscriptionProducts]
  );

  const onSaveButtonAction: () => void = (): void => {
    if (stepNumber === 1) {
      if (isEditValid && !!subscriptionPlan) {
        const chosenProductsQuantity: IdValueObject<number, number>[] = Object.values(chosenProducts).flat();

        setChosenProductsSummary(subscriptionPlan.products.filter((product: SubscriptionProductInfo): boolean => (
          !!chosenProductsQuantity.find((productIdValue: IdValueObject<number, number>): boolean => productIdValue.id === product.id)
        )));
        setStepNumber(2);
        window.scrollTo(0, 0);
      }
    } else if (stepNumber === 2) {
      savePlanChoices();
    }
  };

  const changeProductCount: (groupId: number, productId: number, count: number) => void = (
    groupId: number, productId: number, count: number
  ): void => {
    const chosenProductsCopy: Record<number, IdValueObject<number, number>[]> = { ...chosenProducts };
    const filteredSubscriptionProductsCopy: Record<number, ProductBoxSettings[]> = { ...filteredSubscriptionProducts };
    if (!Array.isArray(chosenProductsCopy[groupId])) {
      chosenProductsCopy[groupId] = [];
    } else {
      const chosenProductsArray: IdValueObject<number, number>[] = Object.values(chosenProductsCopy[groupId]);

      const productToUpdateIndex: number | undefined = chosenProductsArray.findIndex(
        (product: IdValueObject<number, number>): boolean => product.id === productId
      );

      if (productToUpdateIndex !== -1) {
        chosenProductsArray[productToUpdateIndex].value = count;
      } else {
        chosenProductsCopy[groupId].push({ id: productId, value: count });
      }
    }
    Object.keys(chosenProductsCopy).forEach((groupIdItem: string): void => {
      chosenProductsCopy[Number(groupIdItem)] = chosenProductsCopy[Number(groupIdItem)]
        .filter(((item: IdValueObject<number, number>): boolean => item.value > 0));
    });

    setChosenProducts(chosenProductsCopy);
    subscriptionPlan?.groups.forEach((group: SubscriptionPlanGroup): void => {
      filteredSubscriptionProductsCopy[group.id] = getFilteredProducts(group.id, group.products);
    });
    setFilteredSubscriptionProducts(filteredSubscriptionProductsCopy);
  };

  useEffect(
    (): void => {
      const filteredSubscriptionProductsCopy: Record<number, ProductBoxSettings[]> = { ...filteredSubscriptionProducts };
      subscriptionPlan?.groups.forEach((group: SubscriptionPlanGroup): void => {
        filteredSubscriptionProductsCopy[group.id] = getFilteredProducts(group.id, group.products);
      });
      setFilteredSubscriptionProducts(filteredSubscriptionProductsCopy);
    },
    [subscriptionPlan, searchedProductName, productMaxCount]
  );

  const getFilteredProducts: (groupId: number, productIds: number[]) => ProductBoxSettings[] = (
    groupId: number, productIds: number[]
  ): ProductBoxSettings[] => {
    const filteredProducts: (ProductBoxSettings | null)[] = productIds.map((productId: number): ProductBoxSettings | null => {
      const product: SubscriptionProductInfo | undefined = subscriptionPlan?.products
        .find((subscriptionProduct: SubscriptionProductInfo): boolean => subscriptionProduct.id === productId);
      const isProductAvailable: boolean = (
        (product?.availability === ProductAvailability.Available)
          || (product?.availability === ProductAvailability.PartiallyAvailable && productsIdsChosenInSubscription.includes(product.id))
      );

      return chosenProducts[groupId] && product
        ? {
          label: product.name,
          image: product.photos.length ? product.photos[0] : undefined,
          isAvailable: isProductAvailable,
          isDisabled: product.disabled || getProductMaxValue(groupId, product.id) === 0,
          initialValue: product.disabled || !isProductAvailable
            ? 0 
            : chosenProducts[groupId].find(
              (chosenProduct: IdValueObject<number, number>) => chosenProduct.id === productId
            )?.value ?? 0,
          maxValue: getProductMaxValue(groupId, product.id),
          onChangeProductCount: (value: number): void => changeProductCount(groupId, product.id, value)
        }
        : null;
    });

    return filteredProducts
      .filter((product: ProductBoxSettings | null): product is ProductBoxSettings => !!product)
      .filter((product: ProductBoxSettings): boolean => (
        !!product?.label.toLowerCase().includes(searchedProductName.toLowerCase())
      ));
  };

  const getProductMaxValue: (groupId: number, productId?: number) => number = (groupId: number, productId?: number): number => {
    if (chosenProducts[groupId]) {
      const chosenProductsCount: number = Object.values(chosenProducts[groupId]).reduce(
        (accumulator: number, current: IdValueObject<number, number>): number => accumulator + current.value, 0
      );
      const chosenProductObject: IdValueObject<number, number> | undefined = chosenProducts[groupId]
        .find((product: IdValueObject<number, number>): boolean => product.id === productId);

      return !chosenProductObject || !productId
        ? productMaxCount[groupId] - chosenProductsCount
        : productMaxCount[groupId] - chosenProductsCount + chosenProductObject.value;
    }

    return productMaxCount[groupId];
  };

  const savePlanChoices: () => void = (): void => {
    const products: IdValueObject[] = Object.values(chosenProducts)
      .flat()
      .filter((item: IdValueObject<number, number>): boolean => item.value > 0)
      .map((item: IdValueObject<number, number>): IdValueObject => ({ id: item.id.toString(), value: item.value.toString() }));
    setIsSubmitRequestLoading(true);
    if (withMigrationFlow) {
      upgradeSubscriptionPlan(Number(subscriptionId), { products })
        .then((data: SubscriptionDetails): void => {
          showFullscreenAlert({
            title: t('chic.website.global.success'),
            description: t('chic.website.useSubscriptionEditProducts.updateSubscriptionProducts.showFullscreenAlert.description'),
            iconImage: FullscreenAlertAssets.SuccessIcon,
            acceptButtonSettings: {
              label: t('chic.website.global.ok'),
              action: (): void => {
                redirect(
                  getPathWithParams(
                    getRouteDetailsByName(RouteNameEnum.SubscriptionDetails)?.url ?? '', { id: String(data.id) }
                  )
                );
                hideFullscreenAlert();
              }
            },
          });
        })
        .catch((error: FrontendApiError): void => showFullscreenAlert({
          title: t('chic.website.global.error'),
          description: error.message,
          iconImage: FullscreenAlertAssets.ErrorIcon,
          acceptButtonSettings: {
            label: t('chic.website.global.ok'),
            action: hideFullscreenAlert
          },
        }))
        .finally((): void => setIsSubmitRequestLoading(false));
    } else {
      updateSubscriptionProducts(Number(subscriptionId), { products })
        .then((): void => showFullscreenAlert({
          title: t('chic.website.global.success'),
          description: t('chic.website.useSubscriptionEditProducts.updateSubscriptionProducts.showFullscreenAlert.description'),
          iconImage: FullscreenAlertAssets.SuccessIcon,
          acceptButtonSettings: {
            label: t('chic.website.global.ok'),
            action: (): void => {
              redirect(
                getPathWithParams(
                  getRouteDetailsByName(RouteNameEnum.SubscriptionDetails)?.url ?? '', { id: subscriptionId ?? '' }
                )
              );
              hideFullscreenAlert();
            }
          },
        }))
        .catch((error: FrontendApiError): void => showFullscreenAlert({
          title: t('chic.website.global.error'),
          description: error.message,
          iconImage: FullscreenAlertAssets.ErrorIcon,
          acceptButtonSettings: {
            label: t('chic.website.global.ok'),
            action: hideFullscreenAlert
          },
        }))
        .finally((): void => setIsSubmitRequestLoading(false));
    }
  };

  const missingGroupsItemsNames: string = useMemo(
    (): string => {
      if (!subscriptionPlan) {
        return '';
      }
      return subscriptionPlan.groups
        .map((group: SubscriptionPlanGroup): string => {
          if (!chosenProducts[group.id]) {
            return '';
          }

          const chosenProductsCount: number = Object.values(chosenProducts[group.id]).reduce(
            (accumulator: number, item: IdValueObject<number, number>): number => accumulator + item.value, 0
          );

          return group.amount === chosenProductsCount
            ? ''
            : `${group.amount - chosenProductsCount} ${group.name}`;
        })
        .filter((item: string): boolean => !!item)
        .join(', ');
    },
    [subscriptionPlan, chosenProducts]
  );

  return {
    goBack,
    onSaveButtonAction,
    getProductMaxValue,
    stepNumber,
    setSearchedProductName,
    chosenProducts,
    cancelEdit,
    missingGroupsItemsNames,
    filteredSubscriptionProducts,
    chosenProductsSummary,
    isEditValid,
    setProductMaxCount,
    setProductsIdsChosenInSubscription,
    setChosenProducts,
    isSubmitRequestLoading,
  };
};
