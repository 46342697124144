import {
  UseState,
  PageHeader,
  PageHeaderPosition,
  IconName,
  Color,
  Breakpoint,
  MenuPositionData,
  useNotifications,
  UseNotifications,
} from '@chic-loyalty/ui';
import { useQuery } from '@tanstack/react-query';
import { Formik, FormikProps } from 'formik';
import React, { useState, useMemo, useEffect, RefObject, useRef } from 'react';
import useCollapse from 'react-collapsed';
import { UseCollapseOutput } from 'react-collapsed/dist/types';
import { TransProps, useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useSearchParams } from 'react-router-dom';

import { getAgreements, setAgreements } from '@chic/frontend/api/requests';
import { AgreementFieldId, FullscreenAlertAssets, QueryKey } from '@chic/frontend/enums';
import { useAuth, useAgreements } from '@chic/frontend/hooks';
import { UseAuth, UseAgreements, AgreementDataExtended } from '@chic/frontend/interfaces';
import { FrontendApiError } from '@chic/frontend/models';
import { UseSearchParams } from '@chic/frontend/types';
import { AgreementName, RequiredActionType } from '@chic/shared/enums';
import { AgreementData, RequiredAction, UserWithToken } from '@chic/shared/interfaces';
import { UpdateUserAgreementsRequest } from '@chic/shared/models';

import { useProfileTabs } from '../profile.hooks';

import { AgreementsVersionVariant } from './agreements.enums';
import {
  Container,
  ContentWrapper,
  StyledContentBox,
  StyledCheckbox,
  StyledIcon,
  MarketingAgreementsPrefix,
  StyledButton,
  FormikForm,
  SeparatingLine,
  SmallSeparatingLine,
  AgreementHeader,
  Annotation,
  AgreementWarningBox,
  AgreementWarningMessage,
  NewAgreementsInfo,
  PrivacyPolicyHeader,
  PrivacyPolicyHeaderText,
  PrivacyPolicyContent,
  PrivacyPolicyContentWrapper,
  PrivacyPolicyContentText,
  StyledTabs
} from './agreements.styled';

export const AgreementsView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const marketingAgreementsPrefixRef: RefObject<HTMLDivElement> = useRef(null);
  const [searchParams]: UseSearchParams = useSearchParams();
  const { userData, updateUserData }: UseAuth = useAuth();
  const { addToast, showFullscreenAlert, hideFullscreenAlert }: UseNotifications = useNotifications();
  const isTablet: boolean = useMediaQuery({ query: Breakpoint.Tablet });
  const { getCollapseProps, getToggleProps, isExpanded }: UseCollapseOutput = useCollapse();
  const profileTabs: MenuPositionData[] = useProfileTabs();
  const {
    getAgreementsObject,
    getMarketingAgreementPrefix,
    getAgreementFromUserData,
    getSelectedAgreementVersionFromUserData
  }: UseAgreements = useAgreements();
  const [agreementsData, setAgreementsData]: UseState<AgreementData[]> = useState<AgreementData[]>([]);
  const [allAgreementsSelected, setAllAgreementsSelected]: UseState<boolean> = useState<boolean>(false);
  const [isLoading, setIsLoading]: UseState<boolean> = useState<boolean>(false);
  const [visibleAgreementsVersionVariant, setVisibleAgreementsVersionVariant]: UseState<AgreementsVersionVariant>
    = useState<AgreementsVersionVariant>(AgreementsVersionVariant.New);

  const marketingAgreementsPrefix: string = useMemo((): string => getMarketingAgreementPrefix(agreementsData, true), [agreementsData]);
  const oldMarketingAgreementsPrefix: string = useMemo((): string => getMarketingAgreementPrefix(agreementsData, false), [agreementsData]);
  const agreements: Record<AgreementName, AgreementDataExtended> | null = useMemo(
    (): Record<AgreementName, AgreementDataExtended> | null => agreementsData.length ? getAgreementsObject(agreementsData, true) : null,
    [agreementsData]
  );
  const oldAgreements: Record<AgreementName, AgreementDataExtended> | null = useMemo(
    (): Record<AgreementName, AgreementDataExtended> | null => agreementsData.length ? getAgreementsObject(agreementsData, false) : null,
    [agreementsData]
  );
  const formikInitialValues: UpdateUserAgreementsRequest = useMemo(
    (): UpdateUserAgreementsRequest => {
      return {
        marketingAgreement: getSelectedAgreementVersionFromUserData(AgreementName.MarketingAgreement),
        marketingAdditionalAgreement: getSelectedAgreementVersionFromUserData(AgreementName.MarketingAdditionalAgreement),
        marketingSocialAgreement: getSelectedAgreementVersionFromUserData(AgreementName.MarketingSocialAgreement),
        phoneSurveyAgreement: getSelectedAgreementVersionFromUserData(AgreementName.PhoneSurveyAgreement),
      };
    },
    [userData]
  );
  const shouldRenderOldAndNewAgreements: boolean = useMemo(
    (): boolean => !!userData?.requiredActions
      .find((action: RequiredAction): boolean => action.type === RequiredActionType.AgreementsUpdateRequired),
    [userData]
  );

  useEffect(
    (): void => {
      const initAgreementsVariant: string | null = searchParams.get('initAgreementsVariant');
      if (
        agreementsData.length
        && initAgreementsVariant
        && Object.values(AgreementsVersionVariant).includes(initAgreementsVariant as AgreementsVersionVariant)
      ) {
        setVisibleAgreementsVersionVariant(initAgreementsVariant as AgreementsVersionVariant);
        marketingAgreementsPrefixRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
      // TODO: clear searchParams
    },
    [searchParams, agreementsData]
  );

  useQuery(
    [QueryKey.Agreements],
    (): Promise<AgreementData[]> => getAgreements(),
    {
      onSuccess: (data: AgreementData[]): void => setAgreementsData(data),
      // TODO: add logger
      onError: (): void => undefined
    }
  );

  const onSubmit: (data: UpdateUserAgreementsRequest) => void = (data: UpdateUserAgreementsRequest): void => {
    setIsLoading(true);
    setAgreements(data)
      .then((response: UserWithToken): void => {
        const { contextToken, ...user }: UserWithToken = response;
        if (visibleAgreementsVersionVariant === AgreementsVersionVariant.Old) {
          setVisibleAgreementsVersionVariant(AgreementsVersionVariant.New);
        }
        showFullscreenAlert({
          title: t('chic.website.global.success'),
          description: t('chic.website.global.successMessage'),
          iconImage: FullscreenAlertAssets.SuccessIcon,
          acceptButtonSettings: {
            label: t('chic.website.global.close'),
            action: hideFullscreenAlert,
          },
        });
        updateUserData(user);
      })
      .catch((error: FrontendApiError): void => addToast({ content: t(error.message) }))
      .finally((): void => setIsLoading(false));
  };

  const toggleAllCheckbox: (setFieldValue: (field: string, value: number | undefined) => void) => void = (
    setFieldValue: (field: string, value: number | undefined) => void
  ): void => {
    if (agreements) {
      Object.values(agreements).forEach((agreement: AgreementDataExtended): void => {
        setFieldValue(agreement.fieldId, !allAgreementsSelected ? agreement.versionId : undefined);
      });
      setAllAgreementsSelected(!allAgreementsSelected);
    }
  };

  return (
    <Container>
      <PageHeader
        header={t('chic.website.meta.profile.title')}
        headerPosition={PageHeaderPosition.CenterLeft}
        tabs={profileTabs}
        activeTabName='agreements'
      />
      <ContentWrapper>
        {(agreements && userData?.agreements) && (
          <Formik
            initialValues={formikInitialValues}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ handleSubmit, setFieldValue, values }: FormikProps<UpdateUserAgreementsRequest>) => (
              <FormikForm onSubmit={handleSubmit}>
                {!shouldRenderOldAndNewAgreements && (
                  <StyledContentBox>
                    <StyledCheckbox
                      onChange={(): void => toggleAllCheckbox(setFieldValue)}
                      checked={allAgreementsSelected}
                      label={t('chic.website.agreementsView.checkAll')}
                      id='all'
                    />
                  </StyledContentBox>
                )}
                <StyledContentBox>
                  <StyledCheckbox
                    checked={getAgreementFromUserData(AgreementName.DeclarationOfAge)?.value}
                    label={agreements[AgreementName.DeclarationOfAge].markdown}
                    id={agreements[AgreementName.DeclarationOfAge].fieldId}
                    disabled
                  />
                  <SmallSeparatingLine />
                  <Annotation>{t('chic.website.agreementsView.declarationOfAgeAnnotation')}</Annotation>
                </StyledContentBox>
                <StyledContentBox>
                  <StyledCheckbox
                    checked={getAgreementFromUserData(AgreementName.PersonalDataAgreement)?.value}
                    label={agreements?.[AgreementName.PersonalDataAgreement].markdown ?? ''}
                    id={agreements?.[AgreementName.PersonalDataAgreement]?.fieldId ?? ''}
                    disabled
                  />
                </StyledContentBox>
                <StyledContentBox>
                  <AgreementHeader>{t('chic.website.agreementsView.agreementsSmallHeader')}</AgreementHeader>
                  {shouldRenderOldAndNewAgreements && (
                    <StyledTabs
                      tabs={[
                        { name: AgreementsVersionVariant.New, label: t('chic.website.agreementsView.newVersion') },
                        { name: AgreementsVersionVariant.Old, label: t('chic.website.agreementsView.oldVersion') },
                      ]}
                      onTabClick={(name: string): void => setVisibleAgreementsVersionVariant(name as AgreementsVersionVariant)}
                      activeTabName={visibleAgreementsVersionVariant}
                    />
                  )}
                  <MarketingAgreementsPrefix ref={marketingAgreementsPrefixRef}>
                    {visibleAgreementsVersionVariant === AgreementsVersionVariant.New
                      ? marketingAgreementsPrefix
                      : oldMarketingAgreementsPrefix
                    }
                  </MarketingAgreementsPrefix>
                  {[AgreementName.MarketingAgreement,
                    AgreementName.MarketingAdditionalAgreement,
                    AgreementName.MarketingSocialAgreement].map(
                    (agreementName: AgreementName): JSX.Element => (
                      <StyledCheckbox
                        onChange={(value: boolean): void => setFieldValue(
                          agreements[agreementName].fieldId,
                          value
                            ? (visibleAgreementsVersionVariant === AgreementsVersionVariant.New ? agreements : oldAgreements)
                              ?.[agreementName]
                              ?.versionId
                            : undefined
                        )}
                        checked={
                          values[agreements[agreementName].fieldId as keyof UpdateUserAgreementsRequest] === (
                            (visibleAgreementsVersionVariant === AgreementsVersionVariant.New ? agreements : oldAgreements)
                              ?.[agreementName]
                              ?.versionId
                          )
                        }
                        label={
                          (visibleAgreementsVersionVariant === AgreementsVersionVariant.New ? agreements : oldAgreements)
                            ?.[agreementName]
                            ?.markdown ?? ''
                        }
                        id={
                          (visibleAgreementsVersionVariant === AgreementsVersionVariant.New ? agreements : oldAgreements)
                            ?.[agreementName]
                            ?.fieldId ?? ''
                        }
                        key={`${agreements[agreementName].fieldId}_${agreements[agreementName].versionId}`}
                        disabled={
                          visibleAgreementsVersionVariant === AgreementsVersionVariant.Old
                          && (
                            values[agreements[agreementName].fieldId as keyof UpdateUserAgreementsRequest]
                              !== oldAgreements?.[agreementName]?.versionId
                          )
                        }
                      />
                    ))
                  }
                  {visibleAgreementsVersionVariant === AgreementsVersionVariant.Old && (
                    <AgreementWarningBox $withMarginTop>
                      <StyledIcon name={IconName.BigAlert} size={16} color={Color.Validation} />
                      <AgreementWarningMessage>{t('chic.website.agreementsView.changeNotPossible')}</AgreementWarningMessage>
                    </AgreementWarningBox>
                  )}
                  <SeparatingLine />
                  <AgreementWarningBox>
                    <StyledIcon name={IconName.BigAlert} size={16} color={Color.Validation} />
                    <AgreementWarningMessage>{t('chic.website.agreementsView.marketingDiscountWarning')}</AgreementWarningMessage>
                  </AgreementWarningBox>
                  {shouldRenderOldAndNewAgreements && (
                    <>
                      <SeparatingLine />
                      <NewAgreementsInfo text={t('chic.website.agreementsView.newAgreementsInfo')} />
                    </>
                  )}
                </StyledContentBox>
                <StyledContentBox>
                  <StyledCheckbox
                    onChange={(value: boolean): void => setFieldValue(
                      agreements[AgreementName.PhoneSurveyAgreement].fieldId,
                      value
                        ? agreements[AgreementName.PhoneSurveyAgreement].versionId
                        : null
                    )}
                    checked={values[AgreementFieldId.PhoneSurveyAgreement] === agreements[AgreementName.PhoneSurveyAgreement].versionId}
                    label={agreements[AgreementName.PhoneSurveyAgreement].markdown}
                    id={agreements[AgreementName.PhoneSurveyAgreement].fieldId}
                    key={agreements[AgreementName.PhoneSurveyAgreement].fieldId}
                  />
                </StyledContentBox>
                <PrivacyPolicyHeader {...getToggleProps()}>
                  <PrivacyPolicyHeaderText>
                    {t('chic.website.agreementsView.privacyPolicyHeaderText')}
                  </PrivacyPolicyHeaderText>
                  <StyledIcon
                    size={20}
                    name={isExpanded ? IconName.ArrowUp : IconName.ArrowDown}
                    color={Color.Tangerine}
                  />
                </PrivacyPolicyHeader>
                <PrivacyPolicyContent {...getCollapseProps()}>
                  <PrivacyPolicyContentWrapper>
                    <PrivacyPolicyContentText text={t('chic.website.agreementsView.privacyPolicyContentText')} />
                  </PrivacyPolicyContentWrapper>
                </PrivacyPolicyContent>
                <StyledButton
                  label={t('chic.website.agreementsView.save')}
                  type='submit'
                  fullWidth={isTablet}
                  isLoading={isLoading}
                />
              </FormikForm>
            )}
          </Formik>
        )}
      </ContentWrapper>
    </Container>
  );
};
