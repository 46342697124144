import { SlideMenuPosition } from '@chic-loyalty/ui';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { TransProps, useTranslation } from 'react-i18next';

import { getConfig } from '@chic/frontend/api/requests';
import { QueryKey } from '@chic/frontend/enums';
import { useAuth } from '@chic/frontend/hooks';
import { UseAuth } from '@chic/frontend/interfaces';
import { RouteNameEnum } from '@chic/shared/enums';
import { SystemConfig } from '@chic/shared/types';

import { slideMenuPositions } from '../configs';

export const useSlideMenuPositions: () => SlideMenuPosition[] = (): SlideMenuPosition[] => {
  const { t }: TransProps<never> = useTranslation();
  const { userData }: UseAuth = useAuth();

  const { data: systemConfig }: UseQueryResult<SystemConfig> = useQuery(
    [QueryKey.Config],
    (): Promise<SystemConfig> => getConfig(),
  );

  return slideMenuPositions
    .filter((position: SlideMenuPosition): boolean => {
      if (systemConfig?.escLoyaltyProgramLimited && position.name !== RouteNameEnum.Dashboard) {
        return false;
      }
      if (position.name === RouteNameEnum.Subscriptions) {
        return !!systemConfig?.subscriptions || !!userData?.hasActiveSubscriptions;
      }

      return true;
    })
    .map((position: SlideMenuPosition): SlideMenuPosition => ({
      ...position,
      label: t(position.label ?? ''),
      labelOnMobile: position.labelOnMobile ? t(position.labelOnMobile) : undefined,
    }));
};
