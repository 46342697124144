import i18n, { i18n as i18nType, Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';

import { injectedInternalUrlsRegexp } from '@chic/shared/constants';
import { injectInternalUrlToMarkdown } from '@chic/shared/utils';

export const translationsInit = (resources: Resource, showKeys: boolean = false): i18nType => {
  void i18n
    .use(initReactI18next)
    .use({
      type: 'postProcessor',
      name: 'emptyKey',
      process: (value: string, keys: string[]): string => {
        if (!keys[0].match(/^([a-zA-Z0-9_]+\.{1}[a-zA-Z0-9_]+)+$/)) {
          return keys[0];
        }
        if (!!keys && keys[0].length !== 0 && value.length === 0) {
          return `No translation (${keys[0]})`;
        }
        if (!!keys && keys[0].length !== 0 && value.length !== 0) {
          if (showKeys) {
            return `${value}(${keys[0]})`;
          }

          return value;
        }

        return '';
      },
    })
    .use({
      type: 'postProcessor',
      name: 'injectedInternalUrls',
      process: (value: string): string => {
        if (!injectedInternalUrlsRegexp.test(value)) {
          return value;
        }

        return injectInternalUrlToMarkdown(value);
      },
    })
    .init({
      resources,
      debug: false,
      lng: 'pl',
      supportedLngs: ['pl'],
      preload: ['pl'],
      postProcess: ['emptyKey', 'injectedInternalUrls'],
    });

  return i18n;
};
