import React from 'react';

import { TopSection } from './components/topSection/topSection.component';
import { Container } from './home.styled';

export const HomeView: React.FC = (): JSX.Element => {

  return (
    <Container>
      <TopSection />
    </Container>
  );
};
