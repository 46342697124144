import { Color, DetailsInfoSettings } from '@chic-loyalty/ui';
import { TransProps, useTranslation } from 'react-i18next';

import { PaymentStatusCode, SubscriptionPaymentType } from '@chic/shared/enums';
import { SubscriptionPaymentStatus } from '@chic/shared/interfaces';

import { UsePaymentDetails } from './paymentDetails.types';

export const usePaymentDetails: () => UsePaymentDetails = (): UsePaymentDetails => {
  const { t }: TransProps<never> = useTranslation();

  const getPaymentOperatorLabel = (type: SubscriptionPaymentType): string => {
    switch (type) {
      case SubscriptionPaymentType.Przelewy24:
      case SubscriptionPaymentType.Przelewy24Pending:
        return t('chic.website.usePaymentDetails.przelewy24');
      case SubscriptionPaymentType.PayU:
      case SubscriptionPaymentType.PayUPending:
        return t('chic.website.usePaymentDetails.payu');
      case SubscriptionPaymentType.ManuallyAccepted:
        return t('chic.website.usePaymentDetails.manual');
      default:
        return type;
    }
  };

  const getPaymentStatusColor = (subscriptionPaymentInfo: SubscriptionPaymentStatus): Color => {
    switch (subscriptionPaymentInfo.status.name) {
      case PaymentStatusCode.Failed:
      case PaymentStatusCode.Rejected:
        return Color.Validation;
      case PaymentStatusCode.Success:
        return Color.LTGreenDark;
      case PaymentStatusCode.Pending:
      default:
        return Color.Tangerine;
    }
  };

  const getDetailsTableData = (subscriptionPaymentInfo: SubscriptionPaymentStatus): DetailsInfoSettings[] => {
    return [
      {
        label: t('chic.website.usePaymentDetails.paymentId.label'),
        value: subscriptionPaymentInfo.id.toString(),
      },
      {
        label: t('chic.website.usePaymentDetails.orderId.label'),
        value: subscriptionPaymentInfo.orderId.toString(),
      },
      {
        label: t('chic.website.usePaymentDetails.subscriptionName.label'),
        value: subscriptionPaymentInfo.subscription.value,
      },
      {
        label: t('chic.website.usePaymentDetails.paymentType.label'),
        value: getPaymentOperatorLabel(subscriptionPaymentInfo.type),
      },
      {
        label: t('chic.website.usePaymentDetails.paymentStatus.label'),
        value: subscriptionPaymentInfo.status.value,
        valueColor: getPaymentStatusColor(subscriptionPaymentInfo),
      },
      {
        label: t('chic.website.usePaymentDetails.scheduledDeliveryDate.label'),
        value: subscriptionPaymentInfo.deliveryDate,
      },
      {
        label: t('chic.website.usePaymentDetails.price.label'),
        value: `${subscriptionPaymentInfo.amount} zł`,
      },
    ];
  };

  return { getDetailsTableData };
};
