import { TransProps, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { postalCodeRegexp } from '@chic/shared/constants';

import { UpdateSubscriptionNotAuthorizedDestinationBasicData } from '../subscriptionDeliveryNotAuthorizedUpdate.types';

export const useSubscriptionDeliveryNotAuthorizedValidations: () => Yup.SchemaOf<UpdateSubscriptionNotAuthorizedDestinationBasicData> 
  = (): Yup.SchemaOf<UpdateSubscriptionNotAuthorizedDestinationBasicData> => {
    const { t }: TransProps<never> = useTranslation();

    return Yup.object().shape({
      name: Yup.string()
        .min(2, t('chic.website.global.validations.name'))
        .max(64, t('chic.website.global.maxLength'))
        .required(t('chic.website.global.requiredField')),
      address: Yup.string()
        .min(2, t('chic.website.global.validations.address'))
        .max(128, t('chic.website.global.maxLength'))
        .required(t('chic.website.global.requiredField')),
      postalCode: Yup.string()
        .matches(postalCodeRegexp, t('chic.website.global.wrongValue'))
        .required(t('chic.website.global.requiredField')),
      city: Yup.string()
        .min(2, t('chic.website.global.validations.city'))
        .max(64, t('chic.website.global.maxLength'))
        .required(t('chic.website.global.requiredField')),
    });
  };
