import { IsDefined } from 'class-validator';

export class WebsocketSignInData {
  @IsDefined()
  public contextToken: string;

  constructor(
    contextToken: string,
  ) {
    this.contextToken = contextToken;
  }
}
