export enum CreditCardProviderType {
  Visa = 'visa',
  Amex = 'amex',
  Jcb = 'jcb',
  Maestro = 'maestro',
  Solo = 'solo',
  Mastercard = 'mastercard',
  Switch = 'switch',
  Unknown = 'unknown',
}
