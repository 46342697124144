import {
  PageHeader,
  UseState,
  UseNotifications,
  useNotifications,
  ContentBoxTheme,
  Loader,
  UseRedirect,
  useRedirect,
  Breakpoint,
} from '@chic-loyalty/ui';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useParams, useSearchParams } from 'react-router-dom';

import { getPayuCardAddRequestStatus } from '@chic/frontend/api/requests';
import { appConfig } from '@chic/frontend/app.config';
import { FullscreenAlertAssets } from '@chic/frontend/enums';
import { usePayu } from '@chic/frontend/hooks';
import { UsePayu } from '@chic/frontend/interfaces';
import { FrontendApiError } from '@chic/frontend/models';
import { UseSearchParams } from '@chic/frontend/types';
import { PayuCreditCardOrderStatus, PayuCreditCardRequestStatus, RouteNameEnum } from '@chic/shared/enums';
import { getRouteDetailsByName } from '@chic/shared/utils';

import {
  Container,
  ContentWrapper,
  FormCover,
  FormCoverText,
  PayuCardForm,
  PayuInputContainer,
  PayuInputLabel,
  StyledButton,
  StyledContentBox,
  StyledPayuAnnotations,
} from './payuCvvCodeAdd.styled';

declare const PayU: payu.PayuEntry;

export const PayuCvvCodeAddView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { showFullscreenAlert, hideFullscreenAlert, addToast }: UseNotifications = useNotifications();
  const [payuSdkForms, setPayuSdkForms]: UseState<payu.PayU | null> = useState<payu.PayU | null>(null);
  const { redirect }: UseRedirect = useRedirect();
  const [isCvvValid, setIsCvvValid]: UseState<boolean> = useState<boolean>(false);
  const [payuRefReqId, setPayuRefReqId]: UseState<string | null> = useState<string | null>(null);
  const [isCvvFormAvailable, setIsCvvFormAvailable]: UseState<boolean> = useState<boolean>(false);
  const checkCreditCardAddRequestStatusCount: MutableRefObject<number> = useRef<number>(0);
  const { creditCardId }: Readonly<Record<string, string | undefined>> = useParams();
  const [searchParams]: UseSearchParams = useSearchParams();
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });
  const { secureFormOptions, isPayuSdkReady, showSuccessPopupAfterPayuOperation }: UsePayu = usePayu();

  const getCreditCardAddRequestStatus: (cardId: string) => void = (cardId: string): void => {
    getPayuCardAddRequestStatus(Number(cardId))
      .then((status: PayuCreditCardOrderStatus): void => {
        if (status === PayuCreditCardOrderStatus.Completed) {
          showSuccessPopupAfterPayuOperation();
        } else if (
          status === PayuCreditCardOrderStatus.Canceled
          || (status === PayuCreditCardOrderStatus.Pending && checkCreditCardAddRequestStatusCount.current >= 60)
        ) {
          showFullscreenAlert({
            title: t('chic.website.global.error'),
            description: t('chic.website.payuCvvCodeAddView.errorPopup.description'),
            iconImage: FullscreenAlertAssets.ErrorIcon,
            acceptButtonSettings: {
              label: t('chic.website.global.ok'),
              action: (): void => {
                hideFullscreenAlert();
                redirect(getRouteDetailsByName(RouteNameEnum.ProfilePaymentsSettings)?.url ?? '');
              },
            },
          });
        } else {
          checkCreditCardAddRequestStatusCount.current = checkCreditCardAddRequestStatusCount.current + 1;
          setTimeout((): void => getCreditCardAddRequestStatus(cardId), 1000);
        }
      })
      .catch((error: FrontendApiError): void => addToast({ content: t(error.message) }));
  };

  useEffect(
    (): void => {
      if (searchParams.get('statusCode') === PayuCreditCardRequestStatus.WarningContinueCvv) {
        setIsCvvFormAvailable(true);
      } else if (creditCardId) {
        getCreditCardAddRequestStatus(creditCardId);
      }
    },
    [searchParams]
  );

  useEffect(
    (): void => {
      if (!isPayuSdkReady() || payuSdkForms) {
        return;
      }

      const payuSdkFormsObject: payu.PayU = PayU(appConfig.payuPosId);
      setPayuSdkForms(payuSdkFormsObject);
      setPayuRefReqId(payuSdkFormsObject.extractRefReqId(location.href));
      const secureForms: payu.SecureForms = payuSdkFormsObject.secureForms();
      const cardCvv: payu.SecureForm = secureForms.add('cvv', secureFormOptions);
      cardCvv.render('#payu-card-cvv');
      cardCvv.on('change', (body: payu.SecureFormChangeResponse): void => setIsCvvValid(body.error === false));
    },
    []
  );

  const submitAction: () => void = (): void => {
    if (!payuSdkForms || !payuRefReqId) {
      return;
    }

    payuSdkForms.sendCvv(payuRefReqId)
      .then((result: payu.SendCvvResultSuccess | payu.SendCvvResultError): void => {
        if (result.status === 'SUCCESS') {
          showSuccessPopupAfterPayuOperation();
        } else {
          showFullscreenAlert({
            description: result.error.messages
              .map((messageItem: payu.SecureFormErrorMessage): string => messageItem.message)
              .join(', '),
            iconImage: FullscreenAlertAssets.ErrorIcon,
            acceptButtonSettings: {
              label: t('chic.website.global.ok'),
              action: hideFullscreenAlert,
            },
          });
        }
      })
      // eslint-disable-next-line @typescript-eslint/naming-convention
      .catch((_: payu.TokenizeResultError): void => undefined);
  };

  return (
    <Container>
      <PageHeader
        header={t('chic.website.meta.payuCvvCodeAdd.title')}
        onArrowButtonAction={(): void => redirect(getRouteDetailsByName(RouteNameEnum.ProfilePaymentsSettings)?.url ?? '/')}
      />
      <ContentWrapper>
        <StyledContentBox contentBoxTheme={ContentBoxTheme.Medium1}>
          <PayuInputContainer>
            <PayuInputLabel>{t('chic.website.global.cvvCode.label')}</PayuInputLabel>
            <PayuCardForm id='payu-card-cvv' />
          </PayuInputContainer>
          <StyledPayuAnnotations />
          <StyledButton
            label={t('chic.website.global.save')}
            onClick={submitAction}
            disabled={!isCvvValid}
            fullWidth={isMobile}
          />
        </StyledContentBox>
      </ContentWrapper>
      {!isCvvFormAvailable && (
        <FormCover>
          <Loader />
          <FormCoverText text={t('chic.website.payuCvvCodeAddView.loadingDescription')} />
        </FormCover>
      )}
    </Container>
  );
};
