import { IsDefined, IsEnum, IsString } from 'class-validator';

import { CacheName } from '@chic/shared/enums';

export class RefreshAplicationCacheRequest {
  @IsDefined()
  @IsEnum(CacheName)
  public cacheName: CacheName;

  @IsDefined()
  @IsString()
  public token: string;

  constructor(
    cacheName: CacheName,
    token: string,
  ) {
    this.cacheName = cacheName;
    this.token = token;
  }
}
