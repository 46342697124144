import { Loader, UseNotifications, UseRedirect, useNotifications, useRedirect } from '@chic-loyalty/ui';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { getSubscriptionPaymentProviderUrl } from '@chic/frontend/api/requests';
import { FullscreenAlertAssets, QueryKey } from '@chic/frontend/enums';
import { FrontendApiError } from '@chic/frontend/models';
import { RouteNameEnum } from '@chic/shared/enums';
import { getRouteDetailsByName } from '@chic/shared/utils';

import { Container } from './payment.styled';

export const PaymentView: React.FC = (): JSX.Element => {
  const { showFullscreenAlert, hideFullscreenAlert }: UseNotifications = useNotifications();
  const { orderId, paymentId }: Readonly<Record<string, string | undefined>> = useParams();
  const { redirect }: UseRedirect = useRedirect();
  const { t }: TransProps<never> = useTranslation();

  const isNumber: (value: string | undefined) => boolean = (value: string | undefined): boolean => {
    return !!value && /^\d+$/.test(value);
  };
    
  useQuery(
    [QueryKey.PaymentProviderUrl],
    (): Promise<string> => getSubscriptionPaymentProviderUrl(orderId ?? '', paymentId ?? ''),
    {
      enabled: isNumber(orderId) && isNumber(paymentId),
      onSuccess: (paymentUrl: string): void => {
        showFullscreenAlert({
          title: t('chic.website.paymentView.payment'),
          description: t('chic.website.paymentView.clickBelowToStartPayment'),
          acceptButtonSettings: {
            label: t('chic.website.paymentView.redirectToPayment'),
            action: (): void => {
              redirect(paymentUrl, undefined, true);
            },
          },
        });  
      },
      onError: (error: FrontendApiError): void => {
        showFullscreenAlert({
          description: error.message,
          iconImage: FullscreenAlertAssets.ErrorIcon,
          acceptButtonSettings: {
            label: t('chic.website.global.ok'),
            action: (): void => {
              hideFullscreenAlert();
              redirect(getRouteDetailsByName(RouteNameEnum.Dashboard)?.url ?? '/');
            },
          },
        });
      },
    }
  );

  return (
    <Container>
      <Loader />
    </Container>
  );
};
