/* eslint-disable max-lines */
import {
  Breakpoint,
  Color,
  DropdownOption,
  Icon,
  IconName,
  InputTheme,
  PageHeader,
  UseFormikForm,
  useFormikForm,
  UseNotifications,
  useNotifications,
  UseState
} from '@chic-loyalty/ui';
import { useQuery } from '@tanstack/react-query';
import { Formik, FormikProps } from 'formik';
import React, { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import useCollapse from 'react-collapsed';
import { UseCollapseOutput } from 'react-collapsed/dist/types';
import { IGoogleReCaptchaConsumerProps, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { TransProps, useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { getAgreements, registerRequest } from '@chic/frontend/api/requests';
import { AgreementFieldId, FullscreenAlertAssets, QueryKey } from '@chic/frontend/enums';
import { useAgreements, useAuth } from '@chic/frontend/hooks';
import { ConfirmContactDataParams, FormField, FormRow, UseAgreements, UseAuth } from '@chic/frontend/interfaces';
import { FrontendApiError } from '@chic/frontend/models';
import { AgreementName, RouteNameEnum } from '@chic/shared/enums';
import { AgreementData, UserWithToken } from '@chic/shared/interfaces';
import { RegisterRequest } from '@chic/shared/models';
import { getRouteDetailsByName } from '@chic/shared/utils';

import { ScrollToError } from './components/scrollToError/scrollToError.component';
import { useRegistrationFormValidation, useSmsCodeFormValidation } from './hooks/';
import { initialValues, registrationFields } from './registration.constants';
import { RegistrationField } from './registration.enums';
import {
  AgreementsSmallHeader,
  Annotation,
  Container,
  EscRegulationInfo,
  FormikForm,
  MarketingAgreementsPrefix,
  MarketingDiscountWarning,
  PrivacyPolicyContent,
  PrivacyPolicyContentText,
  PrivacyPolicyContentWrapper,
  PrivacyPolicyHeader,
  PrivacyPolicyHeaderText,
  RefWrapper,
  RequiredFieldsLabel,
  SeparatingLine,
  SmsBoxBottomRef,
  SmsCodeHeader,
  StyledButton,
  StyledCheckbox,
  StyledContentBox,
  StyledDropdown,
  StyledFieldsRow,
  StyledInput,
  StyledValidationBar,
} from './registration.styled';
import { AgreementDataExtended, RegisterRequestWithRepatedPassword } from './registration.types';

export const RegistrationView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const smsBoxRef: RefObject<HTMLDivElement> = useRef(null);
  const registrationFieldsRef: RefObject<Record<string, HTMLDivElement>> = useRef<Record<string, HTMLDivElement>>({});
  const isTablet: boolean = useMediaQuery({ query: Breakpoint.Tablet });
  const { setFormSubmitted, isFormSubmitted }: UseFormikForm = useFormikForm();
  const { signIn }: UseAuth = useAuth();
  const { getCollapseProps, getToggleProps, isExpanded }: UseCollapseOutput = useCollapse();
  const navigate: NavigateFunction = useNavigate();
  const RegistrationValidation: Record<RegistrationField & AgreementFieldId, Yup.BaseSchema> = useRegistrationFormValidation();
  const SmsCodeValidation: Record<RegistrationField.SmsCode, Yup.BaseSchema> = useSmsCodeFormValidation();
  const [apiError, setApiError]: UseState<string> = useState<string>('');
  const [shouldShowSmsCode, setShouldShowSmsCode]: UseState<boolean> = useState<boolean>(false);
  const [allAgreementsSelected, setAllAgreementsSelected]: UseState<boolean> = useState<boolean>(false);
  const [agreementsArray, setAgreementsArray]: UseState<AgreementData[]> = useState<AgreementData[]>([]);
  const [formData, setFormData]: UseState<RegisterRequestWithRepatedPassword> = useState<RegisterRequestWithRepatedPassword>(initialValues);
  const [isRegistrationButtonLoading, setIsRegistrationButtonLoading]: UseState<boolean> = useState<boolean>(false);
  const [isSmsButtonLoading, setIsSmsButtonLoading]: UseState<boolean> = useState<boolean>(false);
  const { showFullscreenAlert, hideFullscreenAlert }: UseNotifications = useNotifications();
  const { getAgreementsObject, getMarketingAgreementPrefix }: UseAgreements = useAgreements();
  const agreements: Record<AgreementName, AgreementDataExtended> | null = useMemo(
    (): Record<AgreementName, AgreementDataExtended> | null => agreementsArray.length ? getAgreementsObject(agreementsArray, true) : null,
    [agreementsArray]
  );
  const marketingAgreementsPrefix: string = useMemo((): string => getMarketingAgreementPrefix(agreementsArray, true), [agreementsArray]);
  const { executeRecaptcha }: IGoogleReCaptchaConsumerProps = useGoogleReCaptcha();

  useQuery(
    [QueryKey.Agreements],
    (): Promise<AgreementData[]> => getAgreements(),
    {
      onSuccess: (data: AgreementData[]): void => setAgreementsArray(data),
      // TODO: add logger
      onError: (): void => undefined
    }
  );

  useEffect(() => {
    if (shouldShowSmsCode) {
      setTimeout(() => smsBoxRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'end' }), 300);
    }
  }, [shouldShowSmsCode]);

  const onSubmitWithoutSmsCode: (data: RegisterRequestWithRepatedPassword) => void = async (
    data: RegisterRequestWithRepatedPassword
  ): Promise<void> => {
    if (!executeRecaptcha || !agreements) {
      return;
    }
    const recaptchaToken: string = await executeRecaptcha();

    setIsRegistrationButtonLoading(true);
    const requestData: RegisterRequest = new RegisterRequest(
      data.firstName,
      data.lastName,
      data.phone,
      data.email,
      data.birthday,
      data.preferredLanguage,
      data.password,
      agreements[AgreementName.DeclarationOfAge].versionId,
      agreements[AgreementName.PersonalDataAgreement].versionId,
      recaptchaToken,
      undefined,
      data.marketingAgreement ? agreements[AgreementName.MarketingAgreement].versionId : undefined,
      data.marketingAdditionalAgreement ? agreements[AgreementName.MarketingAdditionalAgreement].versionId : undefined,
      data.marketingSocialAgreement ? agreements[AgreementName.MarketingSocialAgreement].versionId : undefined,
      data.phoneSurveyAgreement ? agreements[AgreementName.PhoneSurveyAgreement].versionId : undefined,
      undefined,
      data.postalCode || undefined,
    );
    setFormData(data);

    registerRequest(requestData)
      .then((): void => {
        setApiError('');
        setShouldShowSmsCode(true);
      })
      .catch((error: FrontendApiError): void => setApiError(t(error.message)))
      .finally((): void => setIsRegistrationButtonLoading(false));
  };

  const onSubmit: ({ smsCode }: ConfirmContactDataParams) => void = async ({ smsCode }: ConfirmContactDataParams): Promise<void> => {
    if (!executeRecaptcha || !agreements) {
      return;
    }
    const recaptchaToken: string = await executeRecaptcha();

    setIsSmsButtonLoading(true);
    setFormData({ ...formData, smsCode });
    const requestData: RegisterRequest = new RegisterRequest(
      formData.firstName,
      formData.lastName,
      formData.phone,
      formData.email,
      formData.birthday,
      formData.preferredLanguage,
      formData.password,
      agreements[AgreementName.DeclarationOfAge].versionId,
      agreements[AgreementName.PersonalDataAgreement].versionId,
      recaptchaToken,
      smsCode,
      formData.marketingAgreement ? agreements[AgreementName.MarketingAgreement].versionId : undefined,
      formData.marketingAdditionalAgreement ? agreements[AgreementName.MarketingAdditionalAgreement].versionId : undefined,
      formData.marketingSocialAgreement ? agreements[AgreementName.MarketingSocialAgreement].versionId : undefined,
      formData.phoneSurveyAgreement ? agreements[AgreementName.PhoneSurveyAgreement].versionId : undefined,
      undefined,
      formData.postalCode || undefined,
    );
    registerRequest(requestData)
      .then((response: UserWithToken | void): void => {
        setApiError('');
        setShouldShowSmsCode(true);
        if (response) {
          const { contextToken, ...user }: UserWithToken = response;
          showFullscreenAlert({
            title: t('chic.website.registrationView.success.title'),
            description: t('chic.website.registrationView.success.description'),
            image: FullscreenAlertAssets.WomanWithPhone,
            acceptButtonSettings: {
              label: t('chic.website.registrationView.success.buttonLabel'),
              action: (): void => {
                signIn(user, contextToken);
                hideFullscreenAlert();
              }
            },
          });
        }
      })
      .catch((error: FrontendApiError): void => setApiError(t(error.message)))
      .finally((): void => setIsSmsButtonLoading(false));
  };

  const toggleAllCheckbox = (setFieldValue: (field: string, value: boolean) => void): void => {
    if (agreements) {
      setFieldValue(AgreementFieldId.DeclarationOfAge, !allAgreementsSelected);
      setFieldValue(AgreementFieldId.PersonalDataAgreement, !allAgreementsSelected);
      setFieldValue(AgreementFieldId.MarketingAgreement, !allAgreementsSelected);
      setFieldValue(AgreementFieldId.MarketingAdditionalAgreement, !allAgreementsSelected);
      setFieldValue(AgreementFieldId.MarketingSocialAgreement, !allAgreementsSelected);
      setFieldValue(AgreementFieldId.PhoneSurveyAgreement, !allAgreementsSelected);
      setAllAgreementsSelected(!allAgreementsSelected);
    }
  };
  
  const applyRef = (element: HTMLDivElement): void => {
    if (element && registrationFieldsRef.current) {
      registrationFieldsRef.current[element.id] = element;
    }
  };

  return (
    <Container>
      <PageHeader
        header={t('chic.website.registrationView.header')}
        subheader={t('chic.website.registrationView.subheader')}
        onArrowButtonAction={(): void => navigate(getRouteDetailsByName(RouteNameEnum.Home)?.url ?? '/')}
      />
      <StyledContentBox $withBottomMargin={!shouldShowSmsCode}>
        {agreements && (
          <Formik
            initialValues={formData}
            onSubmit={onSubmitWithoutSmsCode}
            validationSchema={Yup.object().shape(RegistrationValidation)}
            validateOnChange={isFormSubmitted}
            validateOnBlur={isFormSubmitted}
          >
            {({ handleSubmit, setFieldValue, errors, values }: FormikProps<RegisterRequestWithRepatedPassword>) => (
              <FormikForm onSubmit={handleSubmit}>
                {registrationFields.map((row: FormRow<RegistrationField>, index: number): JSX.Element => (
                  <StyledFieldsRow key={index}>
                    {row.fields.map((field: FormField<RegistrationField>): JSX.Element => (
                      <RefWrapper key={field.name} id={field.name} ref={applyRef}>
                        {field.type !== 'dropdown' ? (
                          <StyledInput
                            label={t(field.label)}
                            placeholder={t(field?.placeholder ?? '')}
                            type={field.type}
                            numericMask={field.numericMask ?? undefined}
                            placeholderMask={field.placeholderMask ?? undefined}
                            onChange={(value: string): void => setFieldValue(field.name, value)}
                            onBlur={(value: string): void => setFieldValue(field.name, value)}
                            message={t(errors[field.name] as string ?? '')}
                            value={values[field.name]}
                            inputTheme={errors[field.name] ? InputTheme.Error : InputTheme.Standard}
                            isDateInput={field.isDateInput}
                            hasPhonePrefix={field.hasPhonePrefix}
                            disabled={shouldShowSmsCode}
                            required={field.required}
                          />
                        ) : (
                          <StyledDropdown
                            label={t(field.label)}
                            placeholder={t(field?.placeholder ?? '')}
                            options={field?.options ?? []}
                            onChooseAnswer={(value: DropdownOption): void => setFieldValue(field.name, value.name)}
                            message={t(errors[field.name] as string ?? '')}
                            invalid={!!errors[field.name]}
                            disabled={shouldShowSmsCode}
                            initialValue={field.initialValue}
                          />
                        )}
                      </RefWrapper>
                    ))}
                  </StyledFieldsRow>
                ))}
                <RequiredFieldsLabel>{t('chic.website.registrationView.requiredFieldsLabel')}</RequiredFieldsLabel>
                <EscRegulationInfo text={t('chic.website.registrationView.escRegulationInfo')} />
                <SeparatingLine />
                <StyledCheckbox
                  onChange={(): void => toggleAllCheckbox(setFieldValue)}
                  checked={allAgreementsSelected}
                  label={t('chic.website.registrationView.checkAll')}
                  id='all'
                  disabled={shouldShowSmsCode}
                />
                <SeparatingLine />
                <RefWrapper
                  id={`${AgreementFieldId.DeclarationOfAge}_ref`}
                  ref={applyRef}
                  key={agreements[AgreementName.DeclarationOfAge].fieldId}
                >
                  <StyledCheckbox
                    onChange={(value: boolean) => setFieldValue(agreements[AgreementName.DeclarationOfAge].fieldId, value)}
                    checked={!!values[agreements[AgreementName.DeclarationOfAge].fieldId]}
                    label={agreements[AgreementName.DeclarationOfAge].markdown ?? ''}
                    id={agreements[AgreementName.DeclarationOfAge].fieldId}
                    disabled={shouldShowSmsCode}
                    invalid={!!errors[agreements[AgreementName.DeclarationOfAge].fieldId]}
                  />
                </RefWrapper>
                <Annotation>{t('chic.website.registrationView.declarationOfAgeAnnotation')}</Annotation>
                <SeparatingLine />
                <RefWrapper
                  id={`${AgreementFieldId.PersonalDataAgreement}_ref`}
                  ref={applyRef}
                  key={agreements[AgreementName.PersonalDataAgreement].fieldId}
                >
                  <StyledCheckbox
                    onChange={(value: boolean) => setFieldValue(agreements[AgreementName.PersonalDataAgreement].fieldId, value)}
                    checked={!!values[agreements[AgreementName.PersonalDataAgreement].fieldId]}
                    label={agreements[AgreementName.PersonalDataAgreement].markdown ?? ''}
                    id={agreements[AgreementName.PersonalDataAgreement].fieldId}
                    disabled={shouldShowSmsCode}
                    invalid={!!errors[agreements[AgreementName.PersonalDataAgreement].fieldId]}
                  />
                </RefWrapper>
                <SeparatingLine />
                <AgreementsSmallHeader>{t('chic.website.registrationView.agreementsSmallHeader')}</AgreementsSmallHeader>
                <MarketingAgreementsPrefix>{marketingAgreementsPrefix}</MarketingAgreementsPrefix>
                {[AgreementName.MarketingAgreement, AgreementName.MarketingAdditionalAgreement,
                  AgreementName.MarketingSocialAgreement].map(
                  (agreementName: AgreementName): JSX.Element => (
                    <StyledCheckbox
                      onChange={(value: boolean) => setFieldValue(agreements[agreementName].fieldId, value)}
                      checked={!!values[agreements[agreementName].fieldId]}
                      label={agreements[agreementName].markdown ?? ''}
                      id={agreements[agreementName].fieldId}
                      key={agreements[agreementName].fieldId}
                      disabled={shouldShowSmsCode}
                      invalid={!!errors[agreements[agreementName].fieldId]}
                    />
                  ))
                }
                <SeparatingLine />
                <StyledCheckbox
                  onChange={(value: boolean) => setFieldValue(agreements[AgreementName.PhoneSurveyAgreement].fieldId, value)}
                  checked={!!values[agreements[AgreementName.PhoneSurveyAgreement].fieldId]}
                  label={agreements[AgreementName.PhoneSurveyAgreement].markdown ?? ''}
                  id={agreements[AgreementName.PhoneSurveyAgreement].fieldId}
                  key={agreements[AgreementName.PhoneSurveyAgreement].fieldId}
                  disabled={shouldShowSmsCode}
                  invalid={!!errors[agreements[AgreementName.PhoneSurveyAgreement].fieldId]}
                />
                <SeparatingLine />
                <MarketingDiscountWarning>{t('chic.website.registrationView.marketingDiscountWarning')}</MarketingDiscountWarning>
                <PrivacyPolicyHeader {...getToggleProps()}>
                  <PrivacyPolicyHeaderText>
                    {t('chic.website.registrationView.privacyPolicyHeaderText')}
                  </PrivacyPolicyHeaderText>
                  <Icon
                    size={20}
                    name={isExpanded ? IconName.ArrowUp : IconName.ArrowDown}
                    color={Color.Tangerine}
                  />
                </PrivacyPolicyHeader>
                <PrivacyPolicyContent {...getCollapseProps()}>
                  <PrivacyPolicyContentWrapper>
                    <PrivacyPolicyContentText text={t('chic.website.registrationView.privacyPolicyContentText')} />
                  </PrivacyPolicyContentWrapper>
                </PrivacyPolicyContent>
                <StyledButton
                  label={t('chic.website.registrationView.register')}
                  type='submit'
                  onClick={setFormSubmitted}
                  fullWidth={isTablet}
                  disabled={shouldShowSmsCode}
                  isLoading={isRegistrationButtonLoading}
                />
                <ScrollToError fieldsRef={registrationFieldsRef} />
              </FormikForm>
            )}
          </Formik>
        )}
        <StyledValidationBar message={apiError} />
      </StyledContentBox>
      {shouldShowSmsCode && (
        <StyledContentBox $withBottomMargin>
          <SmsCodeHeader>{t('chic.website.registrationView.smsCodeHeader')}</SmsCodeHeader>
          <Formik
            initialValues={{ smsCode: '' }}
            onSubmit={onSubmit}
            validationSchema={Yup.object().shape(SmsCodeValidation)}
            validateOnChange={false}
            validateOnBlur={isFormSubmitted}
          >
            {({ handleSubmit, setFieldValue, errors }: FormikProps<ConfirmContactDataParams>) => (
              <FormikForm onSubmit={handleSubmit}>
                <StyledInput
                  label={t('chic.website.registrationView.form.smsCode.label')}
                  placeholder={t('chic.website.registrationView.form.smsCode.placeholder')}
                  type='text'
                  onChange={(value: string): void => setFieldValue(RegistrationField.SmsCode, value)}
                  onBlur={(value: string): void => setFieldValue(RegistrationField.SmsCode, value)}
                  message={t(errors.smsCode ?? '')}
                  inputTheme={errors.smsCode ? InputTheme.Error : InputTheme.Standard}
                />
                <StyledButton
                  label={t('chic.website.registrationView.confirm')}
                  type='submit'
                  onClick={setFormSubmitted}
                  fullWidth={isTablet}
                  isLoading={isSmsButtonLoading}
                />
              </FormikForm>
            )}
          </Formik>
          <SmsBoxBottomRef ref={smsBoxRef} />
        </StyledContentBox>
      )}
    </Container>
  );
};
